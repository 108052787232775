.labelL,
.labelR {
  margin-top: -35px;
  text-align: center;
  writing-mode: vertical-lr;
}

.labelL {
  transform: rotate(180deg);
}

.cont {
  width: 100%;
}

.hLineSep {
  margin: 15px 20%;
  border-top: 2px solid #004ca8;
}
.vLineSep {
  border-right: 2px solid #004ca8;
  padding-right: 3rem !important;

  @media (max-width: 992px) {
    padding-right: 0;
    border-right: none;
  }
}
.chartsRow {
}
.chartsCol {
  &.borderSeparator {
    border-right: 2px solid #004ca8;
    //padding-right: 3rem !important;

    @media (max-width: 992px) {
      //padding-right: 0;
      border-right: none;
    }
  }

  .chartContent {
    height: 100%;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .hLineSep {
      position: absolute;
      bottom: 0;
      left: 20%;
      width: 60%;
      margin: 0;
      border-top: 2px solid #004ca8;

      &.wideHide {
        display: none;

        @media (max-width: 992px) {
          display: block;
        }
      }
    }
  }

  .chartGrow {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.legendCol {
  max-width: 50%;
}

.legendMarker {
  display: inline-block;
  width: 26px;
  min-width: 26px;
  height: 14px;
  margin: 1px 5px 2px 0;
  border-radius: 3px;
  cursor: pointer;
}

.legendText {
  padding-top: 1px;
  cursor: pointer;
  line-height: 16px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 1400px) {
    font-size: 10px;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ffffff;
  border-bottom: 2px solid #004ca8;

  .title {
    font-size: 32px;
    line-height: 50px;
    color: #00afef;
    margin: 40px 0;
    display: inline-block;

    div {
      display: inline-block;
    }

    @media (max-width: 1400px) {
      font-size: 28px;
      line-height: 40px;
    }

    @media (max-width: 1200px) {
      font-size: 24px;
      line-height: 38px;
    }

    @media (max-width: 992px) {
      font-size: 18px;
      line-height: 35px;
    }
  }

  .exportBlock {
    button {
      height: 40px;

      @media (max-width: 1400px) {
        font-size: 16px;
      }

      @media (max-width: 1200px) {
        font-size: 14px;
      }

      @media (max-width: 992px) {
        font-size: 12px;
      }
    }

    .exportBtn,
    .scheduleBtn {
      width: 180px;
      height: 50px;
      font-size: 16px;

      &.disabled {
        cursor: not-allowed; // remove when functionality added
      }

      @media (max-width: 1400px) {
        width: 150px;
        height: 40px;
        font-size: 16px;
      }

      @media (max-width: 1200px) {
        width: 130px;
        height: 38px;
        font-size: 14px;
      }

      @media (max-width: 992px) {
        width: 120px;
        height: 35px;
        font-size: 12px;
      }
    }
  }
}

.reportTitle {
  display: none; // temporary hidden, may be used for pdf report
  font-size: 42px;
  line-height: 50px;
  color: #00afef;
  margin: 40px 0;
  text-align: center;

  div {
    display: inline-block;
  }

  @media (max-width: 992px) {
    font-size: 24px;
    line-height: 30px;
  }
}

.reportTabs {
  button {
    font-size: 18px;
    font-weight: bold;

    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
}

.ratioSelector {
  display: flex;
  justify-content: center;

  button {
    outline: none;
  }

  .selected {
    background-color: #00afef;
    color: #ffffff;

    &:hover {
      background-color: #004ca8;
    }
  }
}
