.form {
  max-width: 400px;
  background-color: #fff;
  padding: 2em;
  margin: 2em auto 0;
  border-radius: 5px;
}

.submit {
  margin: 1em 0 0;
}
