.controls > *:not(:first-child) {
  margin-left: 5px;
}

.input {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    display: none;
    appearance: none;
  }
  border: 1px solid #4e555b;
  margin: 0;
}
