.custom-tooltip {
    display: inline-block;
    margin-left: 7px;
}
button .custom-tooltip {
    margin-left: 0;
}

.custom-tooltip i.disabled {
    opacity: 0.3;
}

.custom-tooltip-wrapper {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s, visibility .3s;
    transform: translate(10px, 20px);
}

.custom-tooltip-wrapper-text {
    position: relative;
    left: 5px;
    z-index: 1;
    display: inline-block;
    width: auto;
    min-width: 100px;
    padding: 10px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    background-color: #495057;
}

.custom-tooltip:hover .custom-tooltip-wrapper {
    visibility: visible;
    opacity: 1;
    z-index: 1;
}
