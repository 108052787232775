#loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background: hsla(0,0%,100%,.6)
}

#loader.global {
  position: fixed
}

#loader.inverse {
  background: rgba(48,48,48,.6)
}

#loader .loader-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 12px;
  margin: auto auto auto -80px
}

#loader .loader-content h4 {
  margin-top: 30px;
  text-align: center;
  text-transform: uppercase
}

#loader .loader-content .loader-content-line {
  position: absolute;
  top: 5px;
  left: 0;
  width: 200px;
  height: 2px;
  background-color: #303030
}

#loader .loader-content .loader-content-ball {
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  background-color: #303030;
  border-radius: 6px;
  animation-name: loader-bounce-ball;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: normal
}

@keyframes loader-bounce-ball {
  0% {
    left: 0
  }

  50% {
    left: 187px
  }

  to {
    left: 0
  }
}
