.imageWrapper {
  display: inline-block;
  position: relative;

  &:hover .clearIcon {
    opacity: 1;
  }
}

.clearIcon {
  font-size: 18px;
  opacity: 0;
  position: absolute;
  right: 1em;
  top: 1em;
  transition: opacity 0.4s;
}
