.tabsContainer {
  position: relative;
  border-bottom: 1px solid #2196f3;
}

.tabLabel {
  outline: none !important;
  font-family: 'Nunito Sans', 'Open Sans', sans-serif;

  @media (max-width: 992px) {
    font-size: 12px;
  }
}

.tabPanel {
  padding-top: 0.75rem;
}

.tabsActions {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  position: absolute;
  right: 0;
  top: 0;
}
