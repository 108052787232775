.changes-list {
}
.history-item {
  border: 1px solid #d6d6d6;
  padding: 1px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.history-item .expandable-header {
  padding: 7px;
  border-radius: 2px;
  background-color: #eee;
  /*font-weight: 600;*/
}
.history-item .close {
  font-size: 1.3rem;
  line-height: 1.3rem;
  font-weight: 400;
}

.history-item .message {
  padding: 10px;
}
.history-item .message p {
  line-height: 12px;
  margin-bottom:5px;
}
