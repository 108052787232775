.expandable-container {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

}
.expandable-container .expandable-header {
    background-color: #eee;
    padding: 0.5rem;
}
.expandable-container .expandable-header .float.right {
    float: right;
}

.expandable-container .expandable-body {
    padding: 0.5rem;
}
.expandable-container .expandable-body-container {
    max-height: 0;
    transition: max-height 0.25s ease-out;
    overflow: hidden;
}

.expandable-container .header-arrow {
    display:block;
    float: left;
    margin-right: 10px;
}
.expandable-container .header-body {
    min-height: 1rem;
    padding-left: 1rem;
}

.expandable-container.expanded .expandable-body-container {
    max-height: 1500px;
}