.form-group.required label:after {
  content: '*';
  color: red;
}
.form-group.controlled {
  position: relative;
  padding-right: 125px;
}
.form-group.controlled button {
  position: absolute;
  top: 25px;
  right: 0;
}

.login-page {
  margin: 10% 35% !important;
  min-width: 30%;
}
.forgot-page,
.reset-page {
  height: 75vh;
}

body {
  color: #111;
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
  font-family: 'Nunito Sans', 'Open Sans', sans-serif;
  background: top / cover url('./../images/abstract_bg3.jpg') no-repeat !important;
}

.x-menu {
  z-index: 2;
}

.p-15 {
  padding: 15px !important;
}

.dropzone {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  color: #303030;
  text-align: center;
  background-color: #f8f9fa;
  border: 2px solid #dee2e6;
  border-radius: 2px;
  cursor: pointer;
  transition: border 0.2s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

.dropzone.is-invalid {
  border-color: #dc3545;
}

.dropzone-multiple pre {
  margin-bottom: 0;
}

.dropzone.active,
.dropzone:focus {
  border-color: #0a6ebd;
  opacity: 0.8;
}

.dropzone.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.dropzone .dropzone-image-wrapper {
  width: 100%;
}

.dropzone img {
  cursor: default;
  max-height: 150px;
}

.dropzone-actions-wrapper {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
}

.dropzone-actions-wrapper .btn {
  padding: 5px;
}

.dropzone-actions-wrapper .btn + .btn {
  margin-left: 5px;
}

.thumbnails {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  transition: border 0.2s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

.thumbnail {
  position: relative;
  padding: 20px;
  padding-bottom: 60px;
  color: #303030;
  text-align: center;
  background-color: #f8f9fa;
  border: 2px solid #dee2e6;
  border-radius: 2px;
  height: 250px;
  width: 250px;
  margin: 15px 15px 0 0;
}

.thumbnail img {
  max-width: 100%;
  max-height: 100%;
}

.cropper-container {
  margin: auto;
}

.m-w-200 {
  max-width: 200px;
}

.page {
  margin: 5px 5%;
}

.page .card-header {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  line-height: 22px;
}

.navbar {
  padding: 6px 8px 6px 4px;
}

.navbar .navbar-brand {
  padding: 0;
}

.navbar .dropdown .dropdown-toggle {
  font: 600 13px/15px 'Nunito Sans', 'Open Sans', sans-serif;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 0;
  margin-right: 8px;
  line-height: 18px;
}

.navbar .dropdown .dropdown-toggle:hover {
  background-color: rgba(17, 17, 17, 0.075);
}

.navbar .dropdown .dropdown-toggle i {
  color: #2196f3;
  font-size: 16px;
  line-height: 16px;
  margin-right: 2px;
}

.navbar .dropdown .dropdown-toggle span {
  padding: 0 5px;
}

.navbar .dropdown .dropdown-toggle::after {
  color: #2196f3;
  margin-left: 8px;
}

.navbar .dropdown .dropdown-toggle.active {
  background-image: none;
  background-color: #2196f3;
  box-shadow: none;
}

.navbar .dropdown .dropdown-toggle.active i,
.navbar .dropdown .dropdown-toggle.active::after {
  color: white;
}

.navbar .dropdown .dropdown-menu {
  border-radius: 0;
  margin-top: 0;
}

.navbar .dropdown .dropdown-menu .dropdown-item {
  outline: none;
}

.navbar .dropdown .dropdown-menu .dropdown-item:active {
  color: #212529;
  background: white;
}

.navbar .dropdown .dropdown-menu.dropdown-menu-right {
  right: 7px;
}

.navbar .dropdown.accounts-list {
  position: relative;
}

.navbar .dropdown.accounts-list span {
  padding-right: 16px;
  color: #2196f3;
}

.navbar .dropdown.accounts-list::after {
  border-right: 1px solid #2196f3;
  position: absolute;
  display: block;
  top: 0;
  right: 45px;
  height: 100%;
  content: '';
}

.navbar .dropdown.accounts-list .dropdown-toggle.active span {
  color: white;
}

.navbar .dropdown.accounts-list .dropdown-menu {
  max-height: 400px;
  overflow: auto;
}

.navbar .dropdown.accounts-list .dropdown-menu .dropdown-item {
  font-size: 14px;
  line-height: 31px;
  padding-top: 0;
  padding-bottom: 0;
}

img {
  max-width: 100%;
}

.preview-container {
  min-height: 300px;
}

.preview-block {
  padding: 5px;
  background: #e5e5e5;
}

.preview-block.with-left-padding {
  padding-left: 20%;
}

.preview-block p {
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0.5rem;
}

.preview-block .preview-item {
  overflow: hidden;
}

.preview-block .preview-item + .preview-item {
  margin-top: 25px;
}

.btn-circle {
  border-radius: 50%;
  padding: 6px;
  line-height: 16px;
}

.btn-circle i {
  height: 16px;
  width: 16px;
}

.preview-block .preview-item.preview-native {
  width: 480px;
  height: 120px;
}

.preview-block .preview-item.preview-image {
  width: 300px;
  height: 250px;
}

.preview-block .preview-item.preview-image > * {
  width: 100%;
}

.preview-video .preview-video-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 56.25%;
  padding-top: 25px;
}

.preview-video .preview-video-wrapper iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.preview-video .preview-video-text {
  position: absolute;
  background: white;
  bottom: 15px;
  left: 25px;
  width: 70%;
  padding: 5px;
}

.custom-card-transparent .card {
  background: transparent;
}

.network_icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  background-size: 20px auto;
  background-repeat: no-repeat;
  line-height: 20px;
}

.network_icon.disabled {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.campaign_type {
  display: inline-block;
  height: 14px;
  width: 14px;
  background-size: 14px auto;
  background-repeat: no-repeat;
  line-height: 14px;
}

.campaign_type.google_search {
  background-image: url(./../images/google.svg);
}

.campaign_type.google_discovery {
  background-image: url(./../images/google-discover.svg);
}

.network_icon.network_icon_googleads {
  background-image: url('./../images/network_logos/googleads.png');
}

.network_icon.network_icon_amazon {
  background-image: url('./../images/network_logos/amazon.png');
}

.network_icon.network_icon_dv360 {
  background-image: url('./../images/network_logos/dv360.png');
}

.network_icon.network_icon_facebook {
  background-image: url('./../images/network_logos/facebook.png');
}

.form-step-item .form-step-item-circle {
  display: inline-block;
  position: absolute;
  top: -20px;
  left: 50%;
  width: 24px;
  height: 24px;
  padding: 4px;
  line-height: 16px;
  border-radius: 50%;
  background-color: #e9ecef;
  border: 1px solid white;
  transform: translateX(-50%);
}

.form-step-item .form-step-item-label {
  display: inline-block;
  padding: 10px 0;
}

.form-step-item.active .form-step-item-circle {
  background-color: #08528d;
  color: white;
}

form fieldset .add-btn {
  margin-bottom: 10px;
}

.form-group.controlled button.load-data-button {
  position: absolute;
  right: 125px;
}

form fieldset {
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 25px;
}

form fieldset .status {
  position: absolute;
  right: 40px;
  width: 65px;
  top: 33px;
}

form fieldset legend {
  display: block;
  width: auto;
  max-width: 500px;
  padding: 0 5px;
  font-size: 16px;
  color: rgb(100, 100, 100);
}
.pos-t0 {
  top: 0 !important;
}

.grid-filter-label {
  margin: 0.25rem;
}
.grid-filter-button {
  min-width: 4rem;
}
