.panel {
  display: flex;
}

.actionContainer {
  height: 100px;
  width: 120px;
  margin: 10px 15px;

  text-align: center;
  display: flex;
  flex-direction: column;

  border-top-left-radius: 50%;
  border-top-right-radius: 50%;

  span {
    text-decoration: none;
    color: #0b2e13;
  }

  &:hover {
    text-decoration: none;

    .actionIcon {
      background: #3972c7;
    }

    span {
      color: #3972c7;
    }
  }
}

.actionIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  background: #5da8e5;
  border-width: 1px;
  width: 80px;
  height: 80px;
  align-self: center;
  border-radius: 50%;

  i {
    color: #ffffff;
  }
}

a.actionContainer {
  text-decoration: none;
}
