@import url("https://fonts.googleapis.com/css?family=Nunito+Sans|Open+Sans");

body {
    background: top / cover url('./../images/abstract_bg3.jpg') no-repeat !important;
}
.x-size-monitored {
    position: relative;
}

.x-size-monitors {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    overflow: hidden;
}

.x-size-monitors > * {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.x-size-monitors.scroll > *.shrink::after {
    content: '';
    display: block;
    width: 200%;
    height: 200%;
    min-width: 1px;
    min-height: 1px;
}

.x-size-monitors.scroll > *.expand::after {
    content: '';
    display: block;
    width: 100000px;
    height: 100000px;
}

.x-size-monitors.overflowchanged > *.shrink > * {
    width: 100%;
    height: 100%;
}

.x-size-monitors.overflowchanged > *.expand > * {
    width: 200%;
    height: 200%;
}

.x-size-change-detector {
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.x-size-change-detector > * {
    visibility: hidden;
}

.x-size-change-detector-shrink > * {
    width: 200%;
    height: 200%;
}

.x-size-change-detector-expand > * {
    width: 100000px;
    height: 100000px;
}

@-webkit-keyframes x-paint-monitor-helper {
    from {
        zoom: 1;
    }
    to {
        zoom: 1;
    }
}

@keyframes x-paint-monitor-helper {
    from {
        zoom: 1;
    }
    to {
        zoom: 1;
    }
}

.x-paint-monitored {
    position: relative;
}

.x-paint-monitor {
    width: 0 !important;
    height: 0 !important;
    visibility: hidden;
}

.x-paint-monitor.cssanimation {
    -webkit-animation-duration: 0.0001ms;
    -webkit-animation-name: x-paint-monitor-helper;
    animation-duration: 0.0001ms;
    animation-name: x-paint-monitor-helper;
}

.x-paint-monitor.overflowchange {
    overflow: hidden;
}

.x-paint-monitor.overflowchange::after {
    content: '';
    display: block;
    width: 1px !important;
    height: 1px !important;
}

.x-unselectable {
    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-drag: none;
    cursor: default;
}

.x-hidden-clip {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
}

.x-hidden-display {
    display: none !important;
}

.x-hidden-offsets,
.x-hidden {
    display: block !important;
    visibility: hidden !important;
    position: absolute !important;
    top: -10000px !important;
}

.x-hidden-opacity {
    opacity: 0 !important;
    pointer-events: none;
}

.x-hidden-visibility {
    visibility: hidden !important;
}

.x-pressed .x-ripple-transition,
.x-pressing .x-ripple-transition {
    transition: background-color 0.15s 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.x-pressed .x-ripple-transition .x-icon-el,
.x-pressed .x-ripple-transition .x-arrow-el,
.x-pressing .x-ripple-transition .x-icon-el,
.x-pressing .x-ripple-transition .x-arrow-el {
    transition: background-color 0.15s 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.x-rippling {
    position: relative;
}

.x-rippling.x-component-confirm .x-ripple {
    background-color: green;
}

.x-rippling.x-component-decline .x-ripple {
    background-color: red;
}

.x-rippling.x-rippling-unbound {
    overflow: visible !important;
}

.x-rippling.x-rippling-unbound .x-ripple-container {
    overflow: visible;
    position: absolute;
}

.x-safari .x-rippling:not(.x-rippling-unbound) .x-ripple-container {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
}

.x-ripple-container {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
}

.x-ripple-wrapper {
    z-index: 1000;
}

.x-button-fab .x-ripple-container,
.x-button-round .x-ripple-container {
    border-radius: 10000px;
}

.x-splitButton-fab .x-ripple-container,
.x-splitButton-round .x-ripple-container {
    border-radius: 10000px;
}

.x-ripple {
    position: absolute;
    border-radius: 50%;
    background-color: white;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.4;
    pointer-events: none;
}

.x-rippling.x-rippling-unbound .x-ripple {
    -webkit-animation: x-ripple-unbound-animation 0.3s ease-out forwards;
    animation: x-ripple-unbound-animation 0.3s ease-out forwards;
}

.x-rippling:not(.x-rippling-unbound) .x-ripple {
    -webkit-animation: x-ripple-animation 0.35s ease-out forwards;
    animation: x-ripple-animation 0.35s ease-out forwards;
}

@-webkit-keyframes x-ripple-animation {
    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: 0.4;
    }
    75% {
        -webkit-transform: scale(2, 2);
        -moz-transform: scale(2, 2);
        -ms-transform: scale(2, 2);
        -o-transform: scale(2, 2);
        transform: scale(2, 2);
        opacity: 0.2;
    }
    100% {
        -webkit-transform: scale(2.35, 2.35);
        -moz-transform: scale(2.35, 2.35);
        -ms-transform: scale(2.35, 2.35);
        -o-transform: scale(2.35, 2.35);
        transform: scale(2.35, 2.35);
        opacity: 0;
    }
}

@keyframes x-ripple-animation {
    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: 0.4;
    }
    75% {
        -webkit-transform: scale(2, 2);
        -moz-transform: scale(2, 2);
        -ms-transform: scale(2, 2);
        -o-transform: scale(2, 2);
        transform: scale(2, 2);
        opacity: 0.2;
    }
    100% {
        -webkit-transform: scale(2.35, 2.35);
        -moz-transform: scale(2.35, 2.35);
        -ms-transform: scale(2.35, 2.35);
        -o-transform: scale(2.35, 2.35);
        transform: scale(2.35, 2.35);
        opacity: 0;
    }
}

@-webkit-keyframes x-ripple-unbound-animation {
    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: 0.4;
    }
    75% {
        -webkit-transform: scale(1.75, 1.75);
        -moz-transform: scale(1.75, 1.75);
        -ms-transform: scale(1.75, 1.75);
        -o-transform: scale(1.75, 1.75);
        transform: scale(1.75, 1.75);
        opacity: 0.2;
    }
    100% {
        -webkit-transform: scale(2, 2);
        -moz-transform: scale(2, 2);
        -ms-transform: scale(2, 2);
        -o-transform: scale(2, 2);
        transform: scale(2, 2);
        opacity: 0;
    }
}

@keyframes x-ripple-unbound-animation {
    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: 0.4;
    }
    75% {
        -webkit-transform: scale(1.75, 1.75);
        -moz-transform: scale(1.75, 1.75);
        -ms-transform: scale(1.75, 1.75);
        -o-transform: scale(1.75, 1.75);
        transform: scale(1.75, 1.75);
        opacity: 0.2;
    }
    100% {
        -webkit-transform: scale(2, 2);
        -moz-transform: scale(2, 2);
        -ms-transform: scale(2, 2);
        -o-transform: scale(2, 2);
        transform: scale(2, 2);
        opacity: 0;
    }
}

.x-progress {
    overflow: hidden;
    position: relative;
}

.x-progress-bar {
    height: 100%;
    width: 0;
}

.x-progress-text {
    overflow: hidden;
}

.x-progress-bar {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-touch-action-pan-y {
    overflow-x: hidden !important;
}

.x-touch-action-pan-x {
    overflow-y: hidden !important;
}

.x-drag-body {
    cursor: default;
}

.x-drag-body * {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.x-drag-dragging {
    z-index: 1000000 !important;
    pointer-events: none;
}

.x-treelist {
    background-color: #fff;
    background-position: 16px 0%;
    overflow: hidden;
    padding: 0 0 0 0;
}

.x-treelist-container,
.x-treelist-root-container {
    width: 100%;
}

.x-treelist-toolstrip {
    display: none;
}

.x-treelist-micro > .x-treelist-toolstrip {
    display: inline-block;
}

.x-treelist-micro > .x-treelist-root-container {
    display: none;
}

.x-treelist-item,
.x-treelist-container,
.x-treelist-root-container {
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    margin: 0;
}

.x-treelist-item-tool,
.x-treelist-row,
.x-treelist-item-wrap {
    position: relative;
}

.x-treelist-item-icon,
.x-treelist-item-expander {
    display: none;
    position: absolute;
    top: 0;
}

.x-treelist-item-expander {
    right: 0;
    cursor: pointer;
}

.x-treelist-expander-only .x-treelist-item-expandable > * > .x-treelist-item-wrap > * {
    cursor: pointer;
}

.x-treelist-item-text {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
}

.x-treelist-item-collapsed > .x-treelist-container {
    display: none;
}

.x-treelist-item-expandable > * > * > .x-treelist-item-expander,
.x-treelist-item-icon {
    display: block;
}

.x-treelist-item-floated > * > * > .x-treelist-item-expander,
.x-treelist-item-floated > * > * > .x-treelist-item-icon {
    display: none;
}

.x-treelist-expander-first .x-treelist-item-expander {
    left: 0;
    right: auto;
}

.x-treelist-toolstrip {
    background-color: #e0e0e0;
}

.x-treelist-item-selected > .x-treelist-row {
    background-color: #e0e0e0;
}

.x-treelist-item-selected > .x-treelist-row-over {
    background-color: #e0e0e0;
}

.x-treelist-item-tool {
    padding-left: 8px;
    padding-right: 8px;
}

.x-treelist-item-icon:before,
.x-treelist-item-tool:before,
.x-treelist-item-expander {
    line-height: 48px;
}

.x-treelist-item-icon,
.x-treelist-item-tool,
.x-treelist-item-expander {
    text-align: center;
    background-repeat: no-repeat;
    background-position: 0 center;
}

.x-treelist-item-loading .x-treelist-item-icon {
    background-image: url(./../images/tree/loading.gif);
    color: transparent;
}

.x-treelist-item-icon,
.x-treelist-item-tool {
    color: #9e9e9e;
    font-size: 18px;
    width: 16px;
}

.x-treelist-item-tool {
    width: 32px;
}

.x-treelist-item-expander {
    color: #9e9e9e;
    font-size: 16px;
    width: 18px;
}

.x-treelist-item-expander:after {
    content: 'chevron_right';
    font: 18px/48px 'Material Icons', 'Font Awesome 5 Free';
}

.x-treelist-item-expanded > * > * > .x-treelist-item-expander:after {
    content: 'expand_more';
    font: 18px/48px 'Material Icons', 'Font Awesome 5 Free';
}

.x-treelist-item-text {
    color: #111;
    margin-left: 22px;
    margin-right: 18px;
    font-size: 13px;
    line-height: 48px;
    text-overflow: ellipsis;
}

.x-treelist-row {
    padding-left: 8px;
    padding-right: 8px;
}

.x-treelist-item-floated .x-treelist-container {
    width: auto;
}

.x-treelist-item-floated > .x-treelist-row {
    background-color: #e0e0e0;
}

.x-treelist-item-floated > .x-treelist-container {
    margin-left: -8px;
}

.x-big .x-treelist-item-floated > .x-treelist-container {
    margin-left: -8px;
}

.x-treelist-item-floated > * > * > .x-treelist-item-text {
    margin-left: 0;
}

.x-treelist-item-floated > * .x-treelist-row {
    cursor: pointer;
    padding-left: 0;
}

.x-treelist-item-floated .x-treelist-row:before {
    width: 0;
}

.x-treelist-item-floated > .x-treelist-row-over {
    background-color: #e0e0e0;
}

.x-treelist-item-floated > .x-treelist-row-over > * > .x-treelist-item-text {
    color: #111;
}

.x-treelist-item-expanded > .x-treelist-item-expander:after {
    content: 'expand_more';
    font: 18px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-treelist-item-collapsed > * > .x-treelist-item-expander:after {
    content: 'chevron_right';
    font: 18px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-icon {
    color: #9e9e9e;
    transition: color 0.5s;
}

.x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-text {
    color: #111;
    transition: color 0.5s;
}

.x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-expander {
    color: #9e9e9e;
    transition: color 0.5s;
}

.x-treelist-row-over {
    background-color: #eee;
}

.x-treelist-row-over > * > .x-treelist-item-icon {
    color: #9e9e9e;
    transition: color 0.5s;
}

.x-treelist-row-over > * > .x-treelist-item-text {
    color: #111;
    transition: color 0.5s;
}

.x-treelist-row-over > * > .x-treelist-item-expander {
    color: #9e9e9e;
    transition: color 0.5s;
}

.x-treelist-expander-first .x-treelist-item-icon {
    left: 18px;
}

.x-treelist-expander-first .x-treelist-item-text {
    margin-left: 40px;
    margin-right: 0;
}

.x-treelist-expander-first .x-treelist-item-hide-icon > * > * > .x-treelist-item-text {
    margin-left: 21px;
}

.x-treelist-item-hide-icon > * > * > .x-treelist-item-text {
    margin-left: 3px;
}

.x-treelist-nav {
    background-color: #32404e;
    background-position: 24px 0%;
    padding: 0 0 0 0;
}

.x-treelist-nav .x-treelist-toolstrip {
    background-color: #32404e;
}

.x-treelist-nav .x-treelist-item-selected.x-treelist-item-tool {
    color: #f0f0f0;
    background-color: #5fa2dd;
}

.x-treelist-nav .x-treelist-item-selected > .x-treelist-row {
    background-color: #3f505f;
}

.x-treelist-nav .x-treelist-item-tool {
    padding-left: 10px;
    padding-right: 10px;
}

.x-treelist-nav .x-treelist-item-tool-floated:after {
    height: 44px;
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 4px;
    background-color: #5fa2dd;
}

.x-treelist-nav .x-treelist-item-icon:before,
.x-treelist-nav .x-treelist-item-tool:before,
.x-treelist-nav .x-treelist-item-expander {
    line-height: 44px;
}

.x-treelist-nav .x-treelist-item-icon,
.x-treelist-nav .x-treelist-item-tool,
.x-treelist-nav .x-treelist-item-expander {
    text-align: center;
    background-repeat: no-repeat;
    background-position: 0 center;
}

.x-treelist-nav .x-treelist-item-loading .x-treelist-item-icon {
    background-image: url(./../images/tree/loading.gif);
    color: transparent;
}

.x-treelist-nav .x-treelist-item-icon,
.x-treelist-nav .x-treelist-item-tool {
    color: #adb3b8;
    font-size: 18px;
    width: 24px;
}

.x-treelist-nav .x-treelist-item-tool {
    width: 44px;
}

.x-treelist-nav .x-treelist-item-expander {
    color: #adb3b8;
    font-size: 16px;
    width: 24px;
}

.x-treelist-nav .x-treelist-item-text {
    color: #adb3b8;
    margin-left: 30px;
    margin-right: 24px;
    font-size: 16px;
    line-height: 44px;
}

.x-treelist-nav .x-treelist-row {
    padding-left: 10px;
    padding-right: 10px;
}

.x-treelist-nav .x-treelist-row-over:before,
.x-treelist-nav .x-treelist-item-selected > .x-treelist-row:before {
    content: " ";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
}

.x-treelist-nav .x-treelist-row-over:before {
    background-color: #51adf6;
}

.x-treelist-nav .x-treelist-item-selected > .x-treelist-row-over:before {
    background-color: #51adf6;
}

.x-treelist-nav .x-treelist-item-selected > .x-treelist-row:before {
    background-color: #2196f3;
}

.x-treelist-nav .x-treelist-item-floated .x-treelist-container {
    width: auto;
}

.x-treelist-nav .x-treelist-item-floated > .x-treelist-row {
    background-color: #32404e;
}

.x-treelist-nav .x-treelist-item-floated > .x-treelist-container {
    margin-left: -10px;
}

.x-big .x-treelist-nav .x-treelist-item-floated > .x-treelist-container {
    margin-left: -10px;
}

.x-treelist-nav .x-treelist-item-floated > * > * > .x-treelist-item-text {
    margin-left: 0;
}

.x-treelist-nav .x-treelist-item-floated > * .x-treelist-row {
    cursor: pointer;
    padding-left: 0;
}

.x-treelist-nav .x-treelist-item-floated .x-treelist-row:before {
    width: 0;
}

.x-treelist-nav .x-treelist-item-floated > .x-treelist-row-over {
    background-color: #32404e;
}

.x-treelist-nav .x-treelist-item-floated > .x-treelist-row-over > * > .x-treelist-item-text {
    color: #adb3b8;
}

.x-treelist-nav .x-treelist-item-expanded {
    background-color: #2c3845;
}

.x-treelist-nav .x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-icon {
    color: #fff;
}

.x-treelist-nav .x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-text {
    color: #fff;
}

.x-treelist-nav .x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-expander {
    color: #fff;
}

.x-treelist-nav .x-treelist-row-over {
    background-color: #4f606f;
}

.x-treelist-nav .x-treelist-row-over > * > .x-treelist-item-icon {
    color: #fff;
}

.x-treelist-nav .x-treelist-row-over > * > .x-treelist-item-text {
    color: #fff;
}

.x-treelist-nav .x-treelist-row-over > * > .x-treelist-item-expander {
    color: #fff;
}

.x-treelist-nav .x-treelist-expander-first .x-treelist-item-icon {
    left: 24px;
}

.x-treelist-nav .x-treelist-expander-first .x-treelist-item-text {
    margin-left: 54px;
    margin-right: 0;
}

.x-treelist-nav .x-treelist-expander-first .x-treelist-item-hide-icon > * > * > .x-treelist-item-text {
    margin-left: 27px;
}

.x-treelist-nav .x-treelist-item-hide-icon > * > * > .x-treelist-item-text {
    margin-left: 3px;
}

.x-scroller {
    -webkit-overflow-scrolling: touch;
    overflow-anchor: none;
}

.x-scroller-spacer {
    position: absolute;
    top: 0;
    overflow: hidden;
    height: 1px;
    width: 1px;
    font-size: 0;
    line-height: 0;
    pointer-events: none;
}

.x-scroller-snappable {
    -ms-scroll-snap-type: mandatory;
    -webkit-scroll-snap-type: mandatory;
    scroll-snap-type: mandatory;
}

.x-no-scrollbars {
    -ms-overflow-style: none;
}

.x-no-scrollbars::-webkit-scrollbar {
    width: 0;
}

.x-body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@-ms-viewport {
    width: device-width;
}

img {
    border: 0;
}

.x-border-box,
.x-border-box * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.x-rtl {
    direction: rtl;
}

.x-ltr {
    direction: ltr;
}

.x-clear {
    overflow: hidden;
    clear: both;
    font-size: 0;
    line-height: 0;
    display: table;
}

.x-layer {
    position: absolute !important;
    top: 0;
    left: 0;
    overflow: hidden;
}

.x-fixed-layer {
    position: fixed !important;
    overflow: hidden;
}

.x-shim {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-hidden-clip {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
}

.x-masked-relative {
    position: relative;
}

.x-ie-shadow {
    background-color: #777;
    position: absolute;
    overflow: hidden;
}

.x-selectable {
    cursor: auto;
    -moz-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
    -o-user-select: text;
}

.x-list-plain {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.x-table-plain {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1em;
}

.x-frame-tl,
.x-frame-tr,
.x-frame-tc,
.x-frame-bl,
.x-frame-br,
.x-frame-bc {
    overflow: hidden;
    background-repeat: no-repeat;
}

.x-frame-tc,
.x-frame-bc {
    background-repeat: repeat-x;
}

td.x-frame-tl,
td.x-frame-tr,
td.x-frame-bl,
td.x-frame-br {
    width: 1px;
}

.x-frame-mc {
    background-repeat: repeat-x;
    overflow: hidden;
}

.x-proxy-el {
    position: absolute;
    background: #b4b4b4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-css-shadow {
    position: absolute;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.x-item-disabled,
.x-item-disabled * {
    cursor: default;
    pointer-events: none;
}

.x-component,
.x-container {
    position: relative;
}

:focus {
    outline: none;
}

.x-body .x-sync-repaint:before,
.x-body .x-sync-repaint:after {
    content: none !important;
}

.x-position-relative {
    position: relative !important;
}

.x-tab-guard {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.x-box-item {
    position: absolute !important;
    left: 0;
    top: 0;
}

.x-rtl > .x-box-item {
    right: 0;
    left: auto;
}

.x-autocontainer-outerCt {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.x-mobile-safari .x-autocontainer-outerCt {
    transform: translateZ(0);
}

.x-clipped .x-autocontainer-outerCt {
    transform: initial;
}

.x-autocontainer-innerCt {
    display: table-cell;
    height: 100%;
    vertical-align: top;
}

.x-mask {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none !important;
}

.x-ie8 .x-mask {
    background-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}

.x-mask-fixed {
    position: fixed;
}

.x-mask-msg {
    position: absolute;
}

.x-progress {
    border-style: solid;
}

.x-btn {
    display: inline-block;
    outline: 0;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: top;
    overflow: hidden;
    position: relative;
}

.x-btn > .x-frame {
    height: 100%;
    width: 100%;
}

.x-btn-wrap {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.x-btn-wrap.x-btn-arrow-bottom,
.x-btn-wrap.x-btn-split-bottom {
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
}

.x-ie9m .x-btn-wrap {
    display: table;
    border-spacing: 0;
}

.x-btn-button {
    white-space: nowrap;
    line-height: 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 0;
}

.x-btn-button.x-btn-icon-top,
.x-btn-button.x-btn-icon-bottom {
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.x-ie10p .x-btn-button.x-btn-icon-top,
.x-ie10p .x-btn-button.x-btn-icon-bottom {
    overflow: hidden;
}

.x-ie9m .x-btn-button {
    display: table-cell;
    vertical-align: middle;
}

.x-btn-inner {
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-flex-negative: 1;
    display: block;
}

.x-ie9m .x-btn-inner {
    display: inline-block;
    vertical-align: middle;
}

.x-btn-icon.x-btn-no-text > .x-btn-inner {
    display: none;
}

.x-btn-icon-el {
    display: none;
    vertical-align: middle;
    text-align: center;
    background-position: center center;
    background-repeat: no-repeat;
    flex-shrink: 0;
}

.x-btn-icon > .x-btn-icon-el {
    display: block;
}

.x-ie9m .x-btn-icon-left > .x-btn-icon-el,
.x-ie9m .x-btn-icon-right > .x-btn-icon-el {
    display: inline-block;
}

.x-btn-button-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.x-ie9m .x-btn-button-center {
    text-align: center;
}

.x-btn-button-center.x-btn-icon-top,
.x-btn-button-center.x-btn-icon-bottom {
    text-align: center;
}

.x-btn-button-left {
    text-align: left;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.x-rtl.x-btn-button-left {
    text-align: right;
}

.x-btn-button-right {
    text-align: right;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.x-rtl.x-btn-button-right {
    text-align: left;
}

.x-btn-arrow:after,
.x-btn-split:after {
    display: block;
    background-repeat: no-repeat;
    content: '';
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    text-align: center;
}

.x-btn-arrow-right:after,
.x-btn-split-right:after {
    background-position: right center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.x-ie9m .x-btn-arrow-right:after,
.x-ie9m .x-btn-split-right:after {
    display: table-cell;
    vertical-align: middle;
}

.x-rtl.x-btn-arrow-right:after,
.x-rtl.x-btn-split-right:after {
    background-position: left center;
}

.x-btn-arrow-bottom:after,
.x-btn-split-bottom:after {
    background-position: center bottom;
    content: '\00a0';
    line-height: 0;
}

.x-ie9m .x-btn-arrow-bottom:after,
.x-ie9m .x-btn-split-bottom:after {
    display: table-row;
}

.x-btn-split-right > .x-btn-button:after {
    position: absolute;
    display: block;
    top: -100%;
    right: 0;
    height: 300%;
    content: '';
}

.x-rtl.x-btn-split-right > .x-btn-button:after {
    left: 0;
    right: auto;
}

.x-btn-split-bottom > .x-btn-button:after {
    position: absolute;
    display: block;
    bottom: 0;
    left: -100%;
    font-size: 0;
    width: 300%;
    content: '';
}

.x-btn-mc {
    overflow: visible;
}

.x-segmented-button {
    display: table;
    table-layout: fixed;
}

.x-segmented-button-item {
    display: table-cell;
    vertical-align: top;
}

.x-segmented-button-item > .x-frame {
    width: 100%;
    height: 100%;
}

.x-segmented-button-item .x-btn-mc {
    width: 100%;
}

.x-segmented-button-item-horizontal {
    display: table-cell;
    height: 100%;
}

.x-segmented-button-item-horizontal.x-segmented-button-first {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.x-segmented-button-item-horizontal.x-segmented-button-first .x-btn-tr,
.x-segmented-button-item-horizontal.x-segmented-button-first .x-btn-mr,
.x-segmented-button-item-horizontal.x-segmented-button-first .x-btn-br {
    display: none;
}

.x-segmented-button-item-horizontal.x-segmented-button-middle {
    border-radius: 0;
    border-left: 0;
}

.x-segmented-button-item-horizontal.x-segmented-button-middle .x-btn-tl,
.x-segmented-button-item-horizontal.x-segmented-button-middle .x-btn-tr,
.x-segmented-button-item-horizontal.x-segmented-button-middle .x-btn-ml,
.x-segmented-button-item-horizontal.x-segmented-button-middle .x-btn-mr,
.x-segmented-button-item-horizontal.x-segmented-button-middle .x-btn-bl,
.x-segmented-button-item-horizontal.x-segmented-button-middle .x-btn-br {
    display: none;
}

.x-segmented-button-item-horizontal.x-segmented-button-last {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.x-segmented-button-item-horizontal.x-segmented-button-last .x-btn-tl,
.x-segmented-button-item-horizontal.x-segmented-button-last .x-btn-ml,
.x-segmented-button-item-horizontal.x-segmented-button-last .x-btn-bl {
    display: none;
}

.x-segmented-button-row {
    display: table-row;
}

.x-segmented-button-item-vertical.x-segmented-button-first {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.x-segmented-button-item-vertical.x-segmented-button-first .x-btn-bl,
.x-segmented-button-item-vertical.x-segmented-button-first .x-btn-bc,
.x-segmented-button-item-vertical.x-segmented-button-first .x-btn-br {
    display: none;
}

.x-segmented-button-item-vertical.x-segmented-button-middle {
    border-radius: 0;
    border-top: 0;
}

.x-segmented-button-item-vertical.x-segmented-button-middle .x-btn-tl,
.x-segmented-button-item-vertical.x-segmented-button-middle .x-btn-tc,
.x-segmented-button-item-vertical.x-segmented-button-middle .x-btn-tr,
.x-segmented-button-item-vertical.x-segmented-button-middle .x-btn-bl,
.x-segmented-button-item-vertical.x-segmented-button-middle .x-btn-bc,
.x-segmented-button-item-vertical.x-segmented-button-middle .x-btn-br {
    display: none;
}

.x-segmented-button-item-vertical.x-segmented-button-last {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.x-segmented-button-item-vertical.x-segmented-button-last .x-btn-tl,
.x-segmented-button-item-vertical.x-segmented-button-last .x-btn-tc,
.x-segmented-button-item-vertical.x-segmented-button-last .x-btn-tr {
    display: none;
}

.x-title-icon {
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    line-height: 1;
    text-align: center;
}

.x-title {
    display: table;
    table-layout: fixed;
}

.x-rtl.x-title {
    -o-text-overflow: clip;
    text-overflow: clip;
}

.x-title-text {
    display: table-cell;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.x-title-align-left {
    text-align: left;
}

.x-title-align-left.x-rtl {
    text-align: right;
}

.x-title-align-center {
    text-align: center;
}

.x-title-align-right {
    text-align: right;
}

.x-title-align-right.x-rtl {
    text-align: left;
}

.x-title-rotate-right {
    -webkit-transform: rotate(90deg);
    -webkit-transform-origin: 0 0;
    -moz-transform: rotate(90deg);
    -moz-transform-origin: 0 0;
    -ms-transform: rotate(90deg);
    -ms-transform-origin: 0 0;
    transform: rotate(90deg);
    transform-origin: 0 0;
}

.x-ie8 .x-title-rotate-right {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-title-rotate-right.x-rtl {
    -webkit-transform: rotate(270deg);
    -webkit-transform-origin: 100% 0;
    -moz-transform: rotate(270deg);
    -moz-transform-origin: 100% 0;
    -ms-transform: rotate(270deg);
    -ms-transform-origin: 100% 0;
    transform: rotate(270deg);
    transform-origin: 100% 0;
}

.x-ie8 .x-title-rotate-right.x-rtl {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-title-rotate-left {
    -webkit-transform: rotate(270deg);
    -webkit-transform-origin: 100% 0;
    -moz-transform: rotate(270deg);
    -moz-transform-origin: 100% 0;
    -ms-transform: rotate(270deg);
    -ms-transform-origin: 100% 0;
    transform: rotate(270deg);
    transform-origin: 100% 0;
}

.x-ie8 .x-title-rotate-left {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-title-rotate-left.x-rtl {
    -webkit-transform: rotate(90deg);
    -webkit-transform-origin: 0 0;
    -moz-transform: rotate(90deg);
    -moz-transform-origin: 0 0;
    -ms-transform: rotate(90deg);
    -ms-transform-origin: 0 0;
    transform: rotate(90deg);
    transform-origin: 0 0;
}

.x-ie8 .x-title-rotate-left.x-rtl {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-horizontal.x-header .x-title-rotate-right.x-title-align-left > .x-title-item {
    vertical-align: bottom;
}

.x-horizontal.x-header .x-title-rotate-right.x-title-align-center > .x-title-item {
    vertical-align: middle;
}

.x-horizontal.x-header .x-title-rotate-right.x-title-align-right > .x-title-item {
    vertical-align: top;
}

.x-horizontal.x-header .x-title-rotate-left.x-title-align-left > .x-title-item {
    vertical-align: top;
}

.x-horizontal.x-header .x-title-rotate-left.x-title-align-center > .x-title-item {
    vertical-align: middle;
}

.x-horizontal.x-header .x-title-rotate-left.x-title-align-right > .x-title-item {
    vertical-align: bottom;
}

.x-vertical.x-header .x-title-rotate-none.x-title-align-left > .x-title-item {
    vertical-align: top;
}

.x-vertical.x-header .x-title-rotate-none.x-title-align-center > .x-title-item {
    vertical-align: middle;
}

.x-vertical.x-header .x-title-rotate-none.x-title-align-right > .x-title-item {
    vertical-align: bottom;
}

.x-title-icon-wrap {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    line-height: 0;
}

.x-title-icon-wrap.x-title-icon-top,
.x-title-icon-wrap.x-title-icon-bottom {
    display: table-row;
}

.x-title-icon {
    display: inline-block;
    vertical-align: middle;
    background-position: center;
    background-repeat: no-repeat;
}

.x-tool {
    font-size: 16px;
    line-height: 1;
}

.x-header > .x-box-inner,
.x-panel-header-mc > .x-box-inner,
.x-window-header-mc > .x-box-inner {
    overflow: visible;
}

.x-splitter {
    font-size: 1px;
}

.x-splitter-horizontal {
    cursor: e-resize;
    cursor: row-resize;
}

.x-splitter-vertical {
    cursor: e-resize;
    cursor: col-resize;
}

.x-splitter-collapsed,
.x-splitter-horizontal-noresize,
.x-splitter-vertical-noresize {
    cursor: default;
}

.x-splitter-active {
    z-index: 4;
}

.x-collapse-el {
    position: absolute;
    background-repeat: no-repeat;
}

.x-splitter-focus {
    z-index: 4;
}

.x-box-layout-ct {
    overflow: hidden;
}

.x-box-target {
    position: absolute;
    width: 20000px;
    top: 0;
    left: 0;
    min-height: 1px;
}

.x-rtl.x-box-target {
    left: auto;
    right: 0;
}

.x-box-inner {
    overflow: hidden;
    position: relative;
    left: 0;
    top: 0;
}

.x-box-scroller {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    line-height: 0;
    font-size: 0;
    text-align: center;
}

.x-box-scroller-top {
    top: 0;
}

.x-box-scroller-right {
    right: 0;
}

.x-box-scroller-bottom {
    bottom: 0;
}

.x-box-scroller-left {
    left: 0;
}

.x-box-menu-body-horizontal {
    float: left;
}

.x-box-menu-after {
    position: relative;
    float: left;
}

.x-box-scroller-body-vertical,
.x-box-scroller-body-horizontal {
    -ms-overflow-style: none;
}

.x-box-scroller-body-vertical .x-scroller,
.x-box-scroller-body-horizontal .x-scroller {
    -ms-overflow-style: auto;
}

.x-box-scroller-body-vertical :not(.x-scroller)::-webkit-scrollbar,
.x-box-scroller-body-horizontal :not(.x-scroller)::-webkit-scrollbar {
    display: none;
}

.x-toolbar {
    width: 100%;
    height: 50px;
}

.x-toolbar-text {
    white-space: nowrap;
}

.x-toolbar-separator {
    display: block;
    font-size: 1px;
    overflow: hidden;
    cursor: default;
    border: 0;
    width: 0;
    height: 0;
    line-height: 0;
}

.x-toolbar-scroller {
    padding-left: 0;
}

.x-toolbar-plain {
    border: 0;
}

.x-dd-drag-proxy,
.x-dd-drag-current {
    z-index: 1000000 !important;
    pointer-events: none;
}

.x-dd-drag-proxy {
    display: table;
}

.x-dd-drag-repair .x-dd-drag-ghost {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-dd-drag-repair .x-dd-drop-icon {
    display: none;
}

.x-dd-drag-ghost,
.x-dd-drop-icon {
    display: table-cell;
    vertical-align: middle;
}

.x-dd-drag-ghost {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
    opacity: 0.85;
    white-space: nowrap;
}

.x-dd-drop-icon {
    height: 26px;
    width: 26px;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
}

.x-rtl .x-dd-drag-ghost {
    padding-left: 5px;
    padding-right: 20px;
}

.x-rtl .x-dd-drop-icon {
    left: auto;
    right: 3px;
}

.x-docked {
    position: absolute !important;
    z-index: 2;
}

.x-docked-vertical {
    position: static;
}

.x-docked-top {
    border-bottom-width: 0 !important;
}

.x-docked-bottom {
    border-top-width: 0 !important;
}

.x-docked-left {
    border-right-width: 0 !important;
}

.x-docked-right {
    border-left-width: 0 !important;
}

.x-docked-noborder-top {
    border-top-width: 0 !important;
}

.x-docked-noborder-right {
    border-right-width: 0 !important;
}

.x-docked-noborder-bottom {
    border-bottom-width: 0 !important;
}

.x-docked-noborder-left {
    border-left-width: 0 !important;
}

.x-noborder-l {
    border-left-width: 0 !important;
}

.x-noborder-b {
    border-bottom-width: 0 !important;
}

.x-noborder-bl {
    border-bottom-width: 0 !important;
    border-left-width: 0 !important;
}

.x-noborder-r {
    border-right-width: 0 !important;
}

.x-noborder-rl {
    border-right-width: 0 !important;
    border-left-width: 0 !important;
}

.x-noborder-rb {
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
}

.x-noborder-rbl {
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
    border-left-width: 0 !important;
}

.x-noborder-t {
    border-top-width: 0 !important;
}

.x-noborder-tl {
    border-top-width: 0 !important;
    border-left-width: 0 !important;
}

.x-noborder-tb {
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
}

.x-noborder-tbl {
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
    border-left-width: 0 !important;
}

.x-noborder-tr {
    border-top-width: 0 !important;
    border-right-width: 0 !important;
}

.x-noborder-trl {
    border-top-width: 0 !important;
    border-right-width: 0 !important;
    border-left-width: 0 !important;
}

.x-noborder-trb {
    border-top-width: 0 !important;
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
}

.x-noborder-trbl {
    border-width: 0 !important;
}

.x-panel,
.x-plain {
    overflow: hidden;
    position: relative;
}

.x-panel {
    outline: none;
}

td.x-frame-mc {
    vertical-align: top;
}

.x-panel-bodyWrap {
    overflow: hidden;
    position: static;
    height: 100%;
    width: 100%;
}

.x-panel-body {
    overflow: hidden;
    position: relative;
}

.x-panel-header-plain,
.x-panel-body-plain {
    border: 0;
    padding: 0;
}

.x-panel-collapsed-mini {
    visibility: hidden;
}

.x-viewport > .x-body.x-panel > .x-panel-bodyWrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-table-layout {
    font-size: 1em;
}

.x-btn-group {
    position: relative;
    overflow: hidden;
}

.x-btn-group-body {
    position: relative;
}

.x-btn-group-body .x-table-layout-cell {
    vertical-align: top;
}

.x-btn-group-body .x-table-layout-cell .x-btn {
    width: 100%;
}

.x-viewport,
.x-viewport > .x-body {
    margin: 0;
    padding: 0;
    border: 0 none;
}

.x-viewport {
    width: 100%;
    height: 100%;
    position: static;
}

.x-viewport > .x-body {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
}

@media print {
    .x-viewport > .x-body {
        position: static;
    }
}

.x-column {
    float: left;
}

.x-rtl > .x-column {
    float: right;
}

.x-resizable-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 200000;
    background-color: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-window {
    outline: none;
    overflow: hidden;
}

.x-window .x-window-wrap {
    position: relative;
}

.x-window-body {
    position: relative;
    overflow: hidden;
}

.x-form-item {
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: separate;
}

.x-form-item-label {
    overflow: hidden;
}

.x-form-item.x-form-item-no-label > .x-form-item-label {
    display: none;
}

.x-form-item-label,
.x-form-item-body {
    display: table-cell;
}

.x-form-item-body {
    vertical-align: middle;
    height: 100%;
}

.x-form-item-label-inner {
    display: inline-block;
}

.x-form-item-label-top {
    display: table-row;
    height: 1px;
}

.x-form-item-label-top > .x-form-item-label-inner {
    display: table-cell;
}

.x-form-item-label-top-side-error:after {
    display: table-cell;
    content: '';
}

.x-form-item-label-right {
    text-align: right;
}

.x-form-item-label-right.x-rtl {
    text-align: left;
}

.x-form-error-wrap-side {
    display: table-cell;
    vertical-align: middle;
}

.x-form-error-wrap-under {
    display: table-row;
    height: 1px;
}

.x-form-error-wrap-under > .x-form-error-msg {
    display: table-cell;
}

.x-form-error-wrap-under-side-label:before {
    display: table-cell;
    content: '';
    pointer-events: none;
}

.x-form-invalid-icon {
    overflow: hidden;
}

.x-form-invalid-icon ul {
    display: none;
}

.x-form-trigger-wrap {
    display: table;
    width: 100%;
}

.x-form-text-heighted .x-form-trigger-wrap {
    height: 100%;
}

.x-gecko .x-form-trigger-wrap {
    display: -moz-inline-box;
    display: inline-flex;
    vertical-align: top;
}

.x-form-type-text.x-has-min-height {
    height: 1px;
}

.x-form-type-text.x-has-min-height .x-form-text-field-body,
.x-form-type-text.x-has-min-height .x-form-trigger-wrap,
.x-form-type-text.x-has-min-height .x-form-text-wrap {
    min-height: inherit;
}

.x-ie8 .x-form-type-text.x-has-min-height .x-form-text-field-body,
.x-ie8 .x-form-type-text.x-has-min-height .x-form-trigger-wrap,
.x-ie8 .x-form-type-text.x-has-min-height .x-form-text-wrap {
    min-height: inherit;
}

.x-ie8 .x-form-type-text.x-has-min-height .x-form-text {
    min-height: inherit;
}

.x-form-type-text.x-has-min-height .x-form-text {
    height: 100%;
}

.x-form-text-wrap {
    display: table-cell;
    overflow: hidden;
    height: 100%;
    position: relative;
}

.x-gecko .x-form-text-wrap {
    display: block;
    -moz-box-flex: 1;
    flex: 1;
}

.x-form-text-wrap .x-placeholder-label {
    position: absolute;
    top: 0;
    left: 0;
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
}

.x-form-item-body.x-form-text-grow {
    min-width: inherit;
    max-width: inherit;
}

.x-form-text {
    border: 0;
    margin: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    display: block;
    background: repeat-x 0 0;
    width: 100%;
    height: 100%;
}

.x-form-trigger {
    display: table-cell;
    vertical-align: top;
    cursor: pointer;
    overflow: hidden;
    background-repeat: no-repeat;
    text-align: center;
    line-height: 0;
    white-space: nowrap;
}

.x-item-disabled .x-form-trigger {
    cursor: default;
}

.x-form-trigger.x-form-trigger-cmp {
    background: none;
    border: 0;
}

.x-form-trigger.x-form-trigger-cmp.x-rtl {
    background: none;
    border: 0;
}

.x-gecko .x-form-trigger {
    display: block;
}

.x-form-textarea-body.x-form-text-grow > .x-form-trigger-wrap {
    height: 100%;
}

.x-form-textarea {
    overflow: auto;
    resize: none;
}

div.x-form-text-grow .x-form-textarea {
    min-height: inherit;
}

.x-message-box .x-form-display-field {
    height: auto;
}

.x-safari .x-form-fieldcontainer {
    table-layout: auto;
}

.x-ie8 .x-form-fieldcontainer > .x-form-item-body.x-field-container-body-vertical {
    display: table-row;
}

.x-form-cb-wrap {
    vertical-align: top;
}

.x-form-cb-wrap-inner {
    position: relative;
}

.x-form-cb {
    position: absolute;
    left: 0;
    right: auto;
    vertical-align: top;
    overflow: hidden;
    padding: 0;
    border: 0;
}

.x-form-cb::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.x-form-cb-input {
    position: absolute;
    margin: 0;
    padding: 0;
    border: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-rtl.x-form-cb {
    right: 0;
    left: auto;
}

.x-form-cb-after {
    left: auto;
    right: 0;
}

.x-rtl.x-form-cb-after {
    left: 0;
    right: auto;
}

.x-form-cb-label {
    display: inline-block;
}

.x-form-cb-label.x-form-cb-no-box-label {
    display: none;
}

.x-fieldset {
    display: block;
    position: relative;
    overflow: hidden;
}

.x-safari:not(.x-safari10m) .x-fieldset {
    overflow: visible;
}

.x-fieldset-header {
    overflow: hidden;
}

.x-fieldset-header .x-form-item,
.x-fieldset-header .x-tool {
    float: left;
}

.x-fieldset-header .x-fieldset-header-text {
    float: left;
}

.x-fieldset-header .x-form-cb-wrap {
    font-size: 0;
    line-height: 0;
    min-height: 0;
    height: auto;
}

.x-fieldset-header .x-form-cb {
    margin: 0;
    position: static;
    display: inherit;
}

.x-fieldset-body {
    overflow: hidden;
}

.x-fieldset-collapsed {
    padding-bottom: 0 !important;
}

.x-fieldset-collapsed > .x-fieldset-body {
    display: none;
}

.x-fieldset-header-text-collapsible {
    cursor: pointer;
}

.x-rtl.x-fieldset-header .x-form-item,
.x-rtl.x-fieldset-header .x-tool {
    float: right;
}

.x-rtl.x-fieldset-header .x-fieldset-header-text {
    float: right;
}

.x-keyboard-mode .x-view-item-focused {
    outline: 1px dashed #2196f3 !important;
    outline-offset: -1px;
}

.x-ie9 .x-boundlist-list-ct {
    min-height: 0%;
}

.x-datepicker {
    position: relative;
}

.x-datepicker .x-monthpicker {
    left: 0;
    top: 0;
    display: block;
}

.x-datepicker .x-monthpicker-months,
.x-datepicker .x-monthpicker-years {
    height: 100%;
}

.x-datepicker-inner {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
}

.x-datepicker-cell {
    padding: 0;
}

.x-datepicker-header {
    position: relative;
}

.x-datepicker-arrow {
    position: absolute;
    outline: none;
    font-size: 0;
    display: table;
}

.x-datepicker-arrow:before {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.x-datepicker-column-header {
    padding: 0;
}

.x-datepicker-date {
    display: block;
    text-decoration: none;
}

.x-monthpicker {
    display: table;
}

.x-monthpicker-body {
    height: 100%;
    position: relative;
}

.x-monthpicker-months,
.x-monthpicker-years {
    float: left;
}

.x-monthpicker-item {
    float: left;
}

.x-monthpicker-item-inner {
    display: block;
    text-decoration: none;
}

.x-monthpicker-yearnav-button-ct {
    float: left;
    text-align: center;
}

.x-monthpicker-yearnav-button {
    display: inline-block;
    outline: none;
    font-size: 0;
}

.x-monthpicker-buttons {
    width: 100%;
}

.x-datepicker .x-monthpicker-buttons {
    position: absolute;
    bottom: 0;
}

.x-datepicker-month .x-btn-split > .x-btn-button:after {
    content: none;
}

.x-form-display-field-body {
    vertical-align: top;
}

.x-form-display-field-body .x-form-display-field {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.x-form-trigger.x-form-trigger-cmp:before {
    content: none;
}

.x-form-file-btn {
    overflow: hidden;
    position: relative;
}

.x-form-file-input {
    border: 0;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-indent: -10000px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    font-size: 1000px;
}

.x-rtl.x-form-file-input {
    right: auto;
    left: 0;
}

.x-form-item-hidden {
    margin: 0;
}

.x-tip {
    position: absolute;
    overflow: visible;
}

.x-tip-body {
    overflow: hidden;
    position: relative;
}

.x-tip-anchor {
    position: absolute;
    border-style: solid;
    height: 0;
    width: 0;
}

.x-color-picker-item {
    float: left;
    text-decoration: none;
}

.x-color-picker-item-inner {
    display: block;
    font-size: 1px;
}

.x-html-editor-tb .x-toolbar {
    position: static !important;
}

.x-htmleditor-iframe,
.x-htmleditor-textarea {
    display: block;
    overflow: auto;
    width: 100%;
    height: 100%;
    border: 0;
}

.x-tagfield-body {
    vertical-align: top;
}

.x-tagfield {
    height: auto !important;
    cursor: text;
    overflow-y: auto;
}

.x-tagfield .x-tagfield-list {
    padding: 0;
    margin: 0;
}

.x-tagfield-list.x-tagfield-singleselect {
    white-space: nowrap;
    overflow: hidden;
}

.x-tagfield-input,
.x-tagfield-item {
    vertical-align: top;
    display: inline-block;
    position: relative;
}

.x-tagfield-input-field {
    font: inherit;
    border: 0;
    margin: 0;
    background: none;
    width: 100%;
}

.x-tagfield-stacked .x-tagfield-item {
    display: block;
}

.x-tagfield-item {
    cursor: default;
}

.x-tagfield-item-close {
    cursor: pointer;
    position: absolute;
}

.x-tagfield-arialist {
    list-style-type: none;
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.x-tagfield-arialist-item {
    list-style-type: none;
}

.x-fit-item {
    position: relative;
}

.x-grid-view {
    overflow: hidden;
    position: relative;
}

.x-grid-row-table {
    width: 0;
    table-layout: fixed;
    border: 0 none;
    border-collapse: separate;
    border-spacing: 0;
}

.x-grid-item {
    table-layout: fixed;
    outline: none;
    position: relative;
}

.x-ie10 .x-grid-item {
    transform: translateZ(0);
}

.x-grid-row {
    outline: none;
}

.x-grid-td {
    overflow: hidden;
    border-width: 0;
    vertical-align: top;
}

.x-grid-cell-inner {
    overflow: hidden;
    white-space: nowrap;
}

.x-wrap-cell .x-grid-cell-inner {
    white-space: normal;
}

.x-grid-resize-marker {
    position: absolute;
    z-index: 5;
    top: 0;
}

.x-grid-item-container {
    min-height: 1px;
    position: relative;
}

.x-col-move-top,
.x-col-move-bottom {
    position: absolute;
    top: 0;
    line-height: 0;
    font-size: 0;
    overflow: hidden;
    z-index: 20000;
    background: no-repeat center top transparent;
}

.x-grid-header-ct {
    cursor: default;
    z-index: 3;
}

.x-column-header {
    display: inline-block;
    /*position: absolute;*/
    overflow: hidden;
    background-repeat: repeat-x;
}

.x-column-header-inner {
    white-space: nowrap;
    position: relative;
    overflow: hidden;
}

.x-leaf-column-header {
    height: 100%;
}

.x-leaf-column-header .x-column-header-text-container {
    height: 100%;
}

.x-column-header-text-container {
    width: 100%;
    display: table;
    table-layout: fixed;
}

.x-column-header-text-container.x-column-header-text-container-auto {
    table-layout: auto;
}

.x-column-header-text-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.x-column-header-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.x-column-header-sort-DESC .x-column-header-text,
.x-column-header-sort-ASC .x-column-header-text {
    overflow: visible;
}

.x-column-header-text-inner {
    background-repeat: no-repeat;
}

.x-column-header-inner-empty .x-column-header-text-inner {
    display: none;
}

.x-column-header-inner-empty .x-column-header-text-container {
    line-height: 0;
}

.x-rtl.x-column-header-text {
    display: inline-block;
}

.x-column-header-trigger {
    display: none;
    height: 100%;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
}

.x-ie9m .x-column-header-trigger {
    z-index: 6;
}

.x-rtl.x-column-header-trigger {
    left: 0;
    right: auto;
}

.x-column-header-over .x-column-header-trigger,
.x-column-header-open .x-column-header-trigger {
    display: block;
}

.x-column-header-align-right {
    text-align: right;
}

.x-rtl.x-column-header-align-right {
    text-align: left;
}

.x-column-header-align-left {
    text-align: left;
}

.x-rtl.x-column-header-align-left {
    text-align: right;
}

.x-column-header-align-center {
    text-align: center;
}

.x-grid-cell-inner-action-col {
    line-height: 0;
    font-size: 0;
}

.x-keyboard-mode .x-grid-cell-inner-action-col :focus {
    outline: 0 solid #2196f3;
}

.x-action-col-icon {
    display: inline-block;
    line-height: 1;
}

.x-column-header-checkbox .x-column-header-text {
    margin-bottom: 0.4em;
}

.x-column-header-checkbox .x-column-header-inner-empty .x-column-header-text {
    margin-bottom: 0;
}

.x-grid-checkcolumn-cell-inner {
    line-height: 0;
}

.x-keyboard-mode .x-grid-checkcolumn-cell-inner :focus {
    outline: 0 solid #2196f3;
}

.x-group-hd-container {
    overflow: hidden;
}

.x-grid-group-hd {
    white-space: nowrap;
    outline: none;
}

.x-grid-group-title {
    position: relative;
}

.x-grid-group-title:before {
    position: absolute;
    left: 0;
}

.x-rtl.x-grid-view .x-grid-group-title:before {
    right: 0;
}

.x-grid-row-body-hidden,
.x-grid-group-collapsed {
    display: none;
}

.x-grid-row-body-hidden {
    display: none;
}

.x-menu {
    outline: none;
}

.x-menu-user-dropdown {
    width: 168px;
    height: 48px;
    left: auto;
    right: 0;
    top: 32px;
}

.x-menu-accounts-list {
    height: 563px;
    width: 197px;
    top: 32px;
    left: auto;
    right: 0;
}

.x-menu-accounts-list .x-menu-bodyWrap {
    max-height: 100%;
    overflow-y: scroll;
}

.x-menu-main {
    margin: 15px 0 0;
    z-index: 29000;
    left: 4px;
    top: 26px;
    width: calc(100% - 4px);
    box-shadow: 0 0 6px rgb(136, 136, 136);
}

.x-menu-top {
    top: 5px;
}

.x-menu-container {
    height: auto;
}

.x-menu-body {
    position: relative;
}

.x-menu-item {
    white-space: nowrap;
    overflow: hidden;
    border-color: transparent;
    border-style: solid;
    position: relative;
}

.x-menu-item-cmp {
    margin: 2px;
}

.x-menu-item-cmp .x-field-label-cell {
    vertical-align: middle;
}

.x-menu-icon-separator {
    position: absolute;
    top: 0;
    z-index: 0;
    height: 100%;
    overflow: hidden;
}

.x-menu-plain .x-menu-icon-separator {
    display: none;
}

.x-menu-item-link {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    text-decoration: none;
    outline: 0;
    display: block;
}

.x-menu-item-link-href {
    -webkit-touch-callout: default;
}

.x-menu-item-text {
    display: inline-block;
}

.x-menu-item-icon,
.x-menu-item-icon-right,
.x-menu-item-arrow {
    font-size: 0;
    position: absolute;
    text-align: center;
    background-repeat: no-repeat;
}

.x-grid-scroll-container {
    position: absolute;
    z-index: 1;
}

.x-grid-scrollbar-clipper-floated,
.x-grid-scrollbar-floated {
    z-index: 2;
}

.x-grid-scroll-body {
    width: 100%;
    position: relative;
}

.x-grid-scrollbar-clipper {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.x-grid-scrollbar-clipper > .x-grid-view,
.x-grid-scrollbar-clipper > .x-tree-view {
    overflow-y: hidden !important;
}

.x-grid-with-col-lines .x-grid-scrollbar-clipper-locked .x-grid-cell-last {
    border-right-width: 0;
}

.x-rtl.x-grid-scrollbar-clipper {
    left: auto;
    right: 0;
}

.x-grid-with-col-lines .x-rtl.x-grid-scrollbar-clipper-locked .x-grid-cell-last {
    border-left-width: 0;
}

.x-grid-scrollbar {
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
}

.x-rtl.x-grid-scrollbar {
    left: auto;
    right: 0;
}

.x-grid-scrollbar-visible {
    display: block;
}

.x-grid-locking-body > .x-grid {
    z-index: 2;
    pointer-events: none;
}

.x-grid-locking-body > .x-grid .x-docked {
    pointer-events: auto;
}

.x-grid-locking-body .x-grid-body {
    background: transparent;
}

.x-grid-locking-body > .x-splitter {
    z-index: 2;
}

.x-grid-locking-body > .x-splitter-active {
    z-index: 4;
}

.x-grid-locking-body > .x-splitter-focus {
    z-index: 4;
}

.x-grid-inner-locked.x-border-region-slide-in {
    z-index: 3;
}

.x-grid-inner-locked.x-border-region-slide-in > .x-panel-bodyWrap > .x-grid-body > .x-grid-view,
.x-grid-inner-locked.x-border-region-slide-in > .x-panel-bodyWrap > .x-grid-body > .x-tree-view {
    overflow-y: hidden !important;
}

.x-grid-editor .x-form-cb-wrap {
    text-align: center;
}

.x-grid-editor .x-form-cb {
    position: static;
    display: inline-block;
}

.x-grid-editor .x-form-display-field {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
}

.x-grid-editor div.x-form-action-col-field {
    line-height: 0;
}

.x-grid-row-editor-wrap {
    position: absolute;
    overflow: visible;
    z-index: 2;
}

.x-grid-row-editor {
    position: absolute;
    z-index: 0;
}

.x-grid-row-editor-buttons {
    position: absolute;
    white-space: nowrap;
}

.x-grid-row-expander {
    font-size: 0;
    line-height: 0;
    text-align: center;
}

.x-keyboard-mode .x-grid-row-expander:focus {
    outline: 0 solid #2196f3;
}

.x-grid-hide-row-expander-spacer .x-grid-row-expander-spacer {
    display: none;
}

.x-grid-row-expander-spacer {
    border: 0 none;
}

.x-ssm-row-numberer-hd {
    cursor: se-resize !important;
}

.x-ssm-row-numberer-cell {
    cursor: e-resize;
}

.x-ssm-column-select .x-column-header {
    cursor: s-resize;
}

.x-ssm-extender-drag-handle {
    position: absolute;
    z-index: 1;
    cursor: crosshair;
}

.x-ssm-extender-mask {
    position: absolute;
    z-index: 1;
    pointer-events: none;
}

.x-abs-layout-ct {
    position: relative;
}

.x-abs-layout-item {
    position: absolute !important;
}

.x-border-layout-ct {
    overflow: hidden;
}

.x-border-layout-ct {
    position: relative;
}

.x-border-region-slide-in {
    z-index: 6;
}

.x-region-collapsed-placeholder {
    z-index: 4;
}

.x-center-layout-item {
    position: absolute;
}

.x-center-target {
    position: relative;
}

.x-form-layout-wrap {
    display: table;
    width: 100%;
    border-collapse: separate;
}

.x-form-layout-colgroup {
    display: table-column-group;
}

.x-form-layout-column {
    display: table-column;
}

.x-form-layout-auto-label > * > .x-form-item-label {
    width: auto !important;
}

.x-form-layout-auto-label > * > .x-form-item-label > .x-form-item-label-inner {
    width: auto !important;
    white-space: nowrap;
}

.x-form-layout-auto-label > * > .x-form-layout-label-column {
    width: 1px;
}

.x-form-layout-sized-label > * > .x-form-item-label {
    width: auto !important;
}

.x-form-form-item {
    display: table-row;
}

.x-form-form-item > .x-form-item-label {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
}

.x-form-form-item > .x-form-item-body {
    max-width: none;
}

.x-form-form-item.x-form-item-no-label:before {
    content: ' ';
    display: table-cell;
    pointer-events: none;
}

.x-resizable-wrapped {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.x-slider {
    outline: none;
    position: relative;
}

.x-slider-inner {
    position: relative;
    left: 0;
    top: 0;
    overflow: visible;
}

.x-slider-vert .x-slider-inner {
    background: repeat-y 0 0;
}

.x-slider-thumb {
    position: absolute;
    background: no-repeat 0 0;
    cursor: default;
    -webkit-user-callout: none;
    display: table;
    table-layout: fixed;
}

.x-slider-horz .x-slider-thumb {
    left: 0;
}

.x-slider-vert .x-slider-thumb {
    bottom: 0;
}

.x-slider-thumb:before {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.x-tab {
    display: block;
    outline: 0;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: top;
    overflow: hidden;
    position: relative;
}

.x-tab > .x-frame {
    height: 100%;
    width: 100%;
}

.x-tab-wrap {
    height: 100%;
    width: 100%;
    display: table;
    border-spacing: 0;
}

.x-tab-button {
    white-space: nowrap;
    line-height: 0;
    position: relative;
    display: table-cell;
    vertical-align: middle;
}

.x-tab-inner {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}

.x-tab-icon.x-tab-no-text > .x-tab-inner {
    display: none;
}

.x-tab-icon-el {
    display: none;
    vertical-align: middle;
    text-align: center;
    background-position: center center;
    background-repeat: no-repeat;
}

.x-tab-icon > .x-tab-icon-el {
    display: inline-block;
}

.x-tab-icon-top > .x-tab-icon-el,
.x-tab-icon-bottom > .x-tab-icon-el {
    display: block;
}

.x-ie9m .x-tab-button-center {
    text-align: center;
}

.x-tab-button-center.x-tab-icon-top,
.x-tab-button-center.x-tab-icon-bottom {
    text-align: center;
}

.x-tab-button-left {
    text-align: left;
}

.x-rtl.x-tab-button-left {
    text-align: right;
}

.x-tab-button-right {
    text-align: right;
}

.x-rtl.x-tab-button-right {
    text-align: left;
}

.x-tab-mc {
    overflow: visible;
}

.x-tab {
    z-index: 1;
}

.x-tab-active {
    z-index: 3;
}

.x-tab-button {
    position: relative;
}

.x-tab-close-btn {
    display: block;
    position: absolute;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
}

.x-tab-rotate-left {
    -webkit-transform: rotate(270deg);
    -webkit-transform-origin: 100% 0;
    -moz-transform: rotate(270deg);
    -moz-transform-origin: 100% 0;
    -ms-transform: rotate(270deg);
    -ms-transform-origin: 100% 0;
    transform: rotate(270deg);
    transform-origin: 100% 0;
}

.x-ie8 .x-tab-rotate-left {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-tab-rotate-left.x-rtl {
    -webkit-transform: rotate(90deg);
    -webkit-transform-origin: 0 0;
    -moz-transform: rotate(90deg);
    -moz-transform-origin: 0 0;
    -ms-transform: rotate(90deg);
    -ms-transform-origin: 0 0;
    transform: rotate(90deg);
    transform-origin: 0 0;
}

.x-ie8 .x-tab-rotate-left.x-rtl {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-tab-rotate-right {
    -webkit-transform: rotate(90deg);
    -webkit-transform-origin: 0 0;
    -moz-transform: rotate(90deg);
    -moz-transform-origin: 0 0;
    -ms-transform: rotate(90deg);
    -ms-transform-origin: 0 0;
    transform: rotate(90deg);
    transform-origin: 0 0;
}

.x-ie8 .x-tab-rotate-right {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-tab-rotate-right.x-rtl {
    -webkit-transform: rotate(270deg);
    -webkit-transform-origin: 100% 0;
    -moz-transform: rotate(270deg);
    -moz-transform-origin: 100% 0;
    -ms-transform: rotate(270deg);
    -ms-transform-origin: 100% 0;
    transform: rotate(270deg);
    transform-origin: 100% 0;
}

.x-ie8 .x-tab-rotate-right.x-rtl {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-tab-tr,
.x-tab-br,
.x-tab-mr,
.x-tab-tl,
.x-tab-bl,
.x-tab-ml {
    width: 1px;
}

.x-tab-bar {
    z-index: 0;
    position: relative;
}

.x-tab-bar-strip {
    position: absolute;
    line-height: 0;
    font-size: 0;
    z-index: 2;
}

.x-tab-bar-top > .x-tab-bar-strip {
    bottom: 0;
}

.x-tab-bar-bottom > .x-tab-bar-strip {
    top: 0;
}

.x-tab-bar-left > .x-tab-bar-strip {
    right: 0;
}

.x-tab-bar-left > .x-tab-bar-strip.x-rtl {
    right: auto;
    left: 0;
}

.x-tab-bar-right > .x-tab-bar-strip {
    left: 0;
}

.x-tab-bar-right > .x-tab-bar-strip.x-rtl {
    left: auto;
    right: 0;
}

.x-tab-bar-horizontal .x-tab-bar-strip {
    width: 100%;
    left: 0;
}

.x-tab-bar-vertical {
    display: table-cell;
}

.x-tab-bar-vertical .x-tab-bar-strip {
    height: 100%;
    top: 0;
}

.x-tab-bar-plain {
    background: transparent !important;
}

.x-box-scroller-plain {
    background-color: transparent !important;
}

.x-breadcrumb-btn .x-box-target:first-child {
    margin: 0;
}

.x-breadcrumb-btn > .x-btn-split-right > .x-btn-button:after {
    content: none;
}

.x-breadcrumb-btn.x-btn-over > .x-btn-split-right > .x-btn-button:after,
.x-breadcrumb-btn.x-btn-menu-active > .x-btn-split-right > .x-btn-button:after {
    content: '';
}

.x-autowidth-table .x-grid-item-container {
    overflow: visible;
}

.x-autowidth-table .x-grid-item {
    table-layout: auto;
    width: auto !important;
}

.x-tree-view {
    overflow: hidden;
}

.x-tree-elbow-img,
.x-tree-icon {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-position: 0 center;
    vertical-align: top;
}

.x-tree-checkbox {
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.x-tree-animator-wrap {
    overflow: hidden;
}

.x-body {
    color: #111;
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    background: #f6f6f6;
}

.x-animating-size,
.x-collapsed {
    overflow: hidden !important;
}

.x-animating-size {
    z-index: 10000;
}

.x-editor .x-form-item-body {
    padding-bottom: 0;
}

.x-mask {
    background-image: none;
    background-color: rgba(208, 208, 208, 0.5);
    cursor: default;
}

.x-ie8 .x-mask {
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#80d0d0d0, endColorstr=#80d0d0d0)";
    zoom: 1;
}

body > .x-mask {
    background-image: none;
    background-color: rgba(0, 0, 0, 0.75);
}

.x-ie8 body > .x-mask {
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#bf000000, endColorstr=#bf000000)";
    zoom: 1;
}

.x-mask-msg {
    padding: 10px;
    background: #fff;
}

.x-mask-msg-inner {
    padding: 16px 0;
    background-color: transparent;
    color: #111;
    font: 400 13px 'Nunito Sans', 'Open Sans', sans-serif;
}

.x-mask-msg-text {
    padding: 25px 0 0;
    background-image: url(./../images/loadmask/loading.gif);
    background-repeat: no-repeat;
    background-position: center 0;
}

.x-rtl.x-mask-msg-text {
    padding: 25px 0 0 0;
}

.x-progress-default {
    background-color: #bbdefb;
    border-width: 0;
    height: 24px;
    border-color: #2196f3;
    border-style: solid;
}

.x-progress-default .x-progress-bar-default {
    background-image: none;
    background-color: #2196f3;
}

.x-progress-default .x-progress-text {
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    text-align: center;
    line-height: 24px;
}

.x-progress-default .x-progress-text-back {
    color: #111;
    line-height: 24px;
}

.x-keyboard-mode .x-progress-default.x-progress-focus:after {
    position: absolute;
    content: ' ';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 0 solid #2196f3;
    pointer-events: none;
}

.x-progressbar-default-cell > .x-grid-cell-inner,
.x-progress-default-cell > .x-grid-cell-inner,
.x-progressbarwidget-default-cell > .x-grid-cell-inner {
    padding-top: 12px;
    padding-bottom: 12px;
}

.x-progressbar-default-cell > .x-grid-cell-inner .x-progress-default,
.x-progress-default-cell > .x-grid-cell-inner .x-progress-default,
.x-progressbarwidget-default-cell > .x-grid-cell-inner .x-progress-default {
    height: 24px;
}

.x-btn-default-small {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 8px 16px 8px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-btn-default-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-default-small {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-default-small-frameInfo {
    font-family: th-2-2-2-2-0-0-0-0-8-16-8-16;
}

.x-btn-default-small-tl {
    background-position: 0 -4px;
}

.x-btn-default-small-tr {
    background-position: right -6px;
}

.x-btn-default-small-bl {
    background-position: 0 -8px;
}

.x-btn-default-small-br {
    background-position: right -10px;
}

.x-btn-default-small-ml {
    background-position: 0 top;
}

.x-btn-default-small-mr {
    background-position: right top;
}

.x-btn-default-small-tc {
    background-position: 0 0;
}

.x-btn-default-small-bc {
    background-position: 0 -2px;
}

.x-btn-default-small-tr,
.x-btn-default-small-br,
.x-btn-default-small-mr {
    padding-right: 2px;
}

.x-btn-default-small-tl,
.x-btn-default-small-bl,
.x-btn-default-small-ml {
    padding-left: 2px;
}

.x-btn-default-small-tc {
    height: 2px;
}

.x-btn-default-small-bc {
    height: 2px;
}

.x-btn-default-small-mc {
    padding: 6px 14px 6px 14px;
}

.x-btn-default-small {
    border-color: #5da8e5;
}

.x-btn-button-default-small {
    min-height: 16px;
}

.x-ie9m .x-btn-button-default-small {
    min-height: auto;
    height: 16px;
}

.x-btn-inner-default-small {
    font: 600 13px/15px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #2196f3;
    padding: 0 5px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-default-small,
.x-ie9 .x-btn-icon-left > .x-btn-inner-default-small {
    max-width: calc(100% - 16px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-default-small,
.x-safari8m .x-btn-icon-left > .x-btn-inner-default-small {
    max-width: calc(100% - 15px);
}

.x-ie10p .x-btn-inner-default-small {
    max-width: none;
}

.x-btn-icon-el-default-small {
    font-size: 16px;
    height: 16px;
    color: #2196f3;
    line-height: 16px;
}

.x-btn-icon-left > .x-btn-icon-el-default-small,
.x-btn-icon-right > .x-btn-icon-el-default-small {
    width: 16px;
}

.x-btn-icon-top > .x-btn-icon-el-default-small,
.x-btn-icon-bottom > .x-btn-icon-el-default-small {
    min-width: 16px;
}

.x-ie8 .x-btn-icon-el-default-small.x-btn-glyph {
    color: #2196f3;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-small {
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-small.x-rtl {
    margin-right: 0;
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-small {
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-small.x-rtl {
    margin-left: 0;
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-default-small {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-default-small {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-small {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-small {
    margin-right: 5px;
}

.x-btn-arrow-bottom > .x-btn-button-default-small,
.x-btn-split-bottom > .x-btn-button-default-small {
    padding-bottom: 8px;
}

.x-btn-wrap-default-small.x-btn-arrow-right:after {
    width: 18px;
    content: 'arrow_drop_down';
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-default-small.x-btn-arrow-bottom:after {
    height: 12px;
    content: 'arrow_drop_down';
    font: 16px/12px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-default-small.x-btn-split-right > .x-btn-button {
    padding-right: 16px;
}

.x-btn-wrap-default-small.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 16px;
}

.x-btn-wrap-default-small.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #2196f3;
}

.x-btn-wrap-default-small.x-btn-split-right:after {
    width: 16px;
    content: 'arrow_drop_down';
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
    padding-left: 16px;
}

.x-btn-wrap-default-small.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 16px;
}

.x-btn-wrap-default-small.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-default-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #2196f3;
}

.x-btn-wrap-default-small.x-btn-split-bottom:after {
    height: 16px;
    content: 'arrow_drop_down';
    font: 16px/16px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-small {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-small {
    margin-right: 5px;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-small {
    background-image: none;
    background-color: #42a5f5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-small .x-btn-inner {
    color: #fff;
}

.x-btn-default-small .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 32px;
    pointer-events: none;
}

.x-btn-default-small .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-default-small.x-arrow-focus .x-btn-arrow-el {
    border: 0 solid #d9edfd;
}

.x-btn-over.x-btn-default-small {
    border-color: #569bd2;
    background-image: none;
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-small {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-menu-active.x-btn-default-small,
.x-btn.x-btn-pressed.x-btn-default-small {
    border-color: #467eac;
    background-image: none;
    background-color: #0c7ce6;
}

.x-btn.x-btn-menu-active.x-btn-default-small .x-btn-inner,
.x-btn.x-btn-pressed.x-btn-default-small .x-btn-inner {
    color: #fff;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-default-small,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-default-small {
    background-image: none;
    background-color: #6ab8f7;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-disabled.x-btn-default-small {
    background-image: none;
    background-color: transparent;
}

.x-btn.x-btn-disabled.x-btn-default-small .x-btn-inner {
    color: rgba(0, 0, 0, 0.26);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-small-mc {
    background-color: #42a5f5;
}

.x-btn-over .x-btn-default-small-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-small-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-btn.x-btn-menu-active .x-btn-default-small-mc,
.x-btn.x-btn-pressed .x-btn-default-small-mc {
    background-color: #0c7ce6;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-small-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-small-mc {
    background-color: #6ab8f7;
}

.x-btn.x-btn-disabled .x-btn-default-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-default-small {
    background-image: none;
}

.x-btn-disabled.x-btn-default-small {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=)";
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-first {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-first .x-btn-default-small-mc {
    padding-right: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-middle {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-middle .x-btn-default-small-mc {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-last .x-btn-default-small-mc {
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-first {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-first .x-btn-default-small-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-middle {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-middle .x-btn-default-small-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-last .x-btn-default-small-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-default-small:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-first:after {
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-last:after {
    right: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-first:after {
    top: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-last:after {
    bottom: 0;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-default-small:after {
    border-width: 0;
    border-color: #d9edfd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-default-small:after {
    border-width: 0;
    border-color: rgba(33, 149, 241, 0.8150000000000001);
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-default-small:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-default-small:after {
    border-width: 0;
    border-color: #e1f1fd;
}

.x-button-default-small-cell > .x-grid-cell-inner {
    padding-top: 8px;
    padding-bottom: 8px;
}

.x-button-default-small-cell > .x-grid-cell-inner > .x-btn-default-small {
    vertical-align: top;
}

.x-btn-default-medium {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 8px 16px 8px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-btn-default-medium-mc {
    background-color: transparent;
}

.x-nbr .x-btn-default-medium {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-default-medium-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-8-16-8-16;
}

.x-btn-default-medium-tl {
    background-position: 0 0;
}

.x-btn-default-medium-tr {
    background-position: right 0;
}

.x-btn-default-medium-bl {
    background-position: 0 0;
}

.x-btn-default-medium-br {
    background-position: right 0;
}

.x-btn-default-medium-ml {
    background-position: 0 top;
}

.x-btn-default-medium-mr {
    background-position: right top;
}

.x-btn-default-medium-tc {
    background-position: 0 0;
}

.x-btn-default-medium-bc {
    background-position: 0 0;
}

.x-btn-default-medium-tr,
.x-btn-default-medium-br,
.x-btn-default-medium-mr {
    padding-right: 0;
}

.x-btn-default-medium-tl,
.x-btn-default-medium-bl,
.x-btn-default-medium-ml {
    padding-left: 0;
}

.x-btn-default-medium-tc {
    height: 0;
}

.x-btn-default-medium-bc {
    height: 0;
}

.x-btn-default-medium-mc {
    padding: 8px 16px 8px 16px;
}

.x-btn-default-medium {
    border-color: #5da8e5;
}

.x-btn-button-default-medium {
    min-height: 20px;
}

.x-ie9m .x-btn-button-default-medium {
    min-height: auto;
    height: 20px;
}

.x-btn-inner-default-medium {
    font: 600 14px/16px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #2196f3;
    padding: 0 8px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-default-medium,
.x-ie9 .x-btn-icon-left > .x-btn-inner-default-medium {
    max-width: calc(100% - 20px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-default-medium,
.x-safari8m .x-btn-icon-left > .x-btn-inner-default-medium {
    max-width: calc(100% - 19px);
}

.x-ie10p .x-btn-inner-default-medium {
    max-width: none;
}

.x-btn-icon-el-default-medium {
    font-size: 20px;
    height: 20px;
    color: #2196f3;
    line-height: 20px;
}

.x-btn-icon-left > .x-btn-icon-el-default-medium,
.x-btn-icon-right > .x-btn-icon-el-default-medium {
    width: 20px;
}

.x-btn-icon-top > .x-btn-icon-el-default-medium,
.x-btn-icon-bottom > .x-btn-icon-el-default-medium {
    min-width: 20px;
}

.x-ie8 .x-btn-icon-el-default-medium.x-btn-glyph {
    color: #2196f3;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-medium {
    margin-right: 0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-medium.x-rtl {
    margin-right: 0;
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-medium {
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-medium.x-rtl {
    margin-left: 0;
    margin-right: 0;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-default-medium {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-default-medium {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-medium {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-medium {
    margin-right: 8px;
}

.x-btn-arrow-bottom > .x-btn-button-default-medium,
.x-btn-split-bottom > .x-btn-button-default-medium {
    padding-bottom: 8px;
}

.x-btn-wrap-default-medium.x-btn-arrow-right:after {
    width: 20px;
    content: 'arrow_drop_down';
    font: 20px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-default-medium.x-btn-arrow-bottom:after {
    height: 14px;
    content: 'arrow_drop_down';
    font: 20px/14px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button {
    padding-right: 16px;
}

.x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 16px;
}

.x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #2196f3;
}

.x-btn-wrap-default-medium.x-btn-split-right:after {
    width: 24px;
    content: 'arrow_drop_down';
    font: 20px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
    padding-left: 16px;
}

.x-btn-wrap-default-medium.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 16px;
}

.x-btn-wrap-default-medium.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-default-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #2196f3;
}

.x-btn-wrap-default-medium.x-btn-split-bottom:after {
    height: 24px;
    content: 'arrow_drop_down';
    font: 20px/24px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-medium {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-medium {
    margin-right: 8px;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-medium {
    background-image: none;
    background-color: #42a5f5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-medium .x-btn-inner {
    color: #fff;
}

.x-btn-default-medium .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 40px;
    pointer-events: none;
}

.x-btn-default-medium .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-default-medium.x-arrow-focus .x-btn-arrow-el {
    border: 0 solid #d9edfd;
}

.x-btn-over.x-btn-default-medium {
    border-color: #569bd2;
    background-image: none;
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-medium {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-menu-active.x-btn-default-medium,
.x-btn.x-btn-pressed.x-btn-default-medium {
    border-color: #467eac;
    background-image: none;
    background-color: #0c7ce6;
}

.x-btn.x-btn-menu-active.x-btn-default-medium .x-btn-inner,
.x-btn.x-btn-pressed.x-btn-default-medium .x-btn-inner {
    color: #fff;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-default-medium,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-default-medium {
    background-image: none;
    background-color: #6ab8f7;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-disabled.x-btn-default-medium {
    background-image: none;
    background-color: transparent;
}

.x-btn.x-btn-disabled.x-btn-default-medium .x-btn-inner {
    color: rgba(0, 0, 0, 0.26);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-medium-mc {
    background-color: #42a5f5;
}

.x-btn-over .x-btn-default-medium-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-medium-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-btn.x-btn-menu-active .x-btn-default-medium-mc,
.x-btn.x-btn-pressed .x-btn-default-medium-mc {
    background-color: #0c7ce6;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-medium-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-medium-mc {
    background-color: #6ab8f7;
}

.x-btn.x-btn-disabled .x-btn-default-medium-mc {
    background-color: transparent;
}

.x-nbr .x-btn-default-medium {
    background-image: none;
}

.x-btn-disabled.x-btn-default-medium {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=)";
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-first {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-first .x-btn-default-medium-mc {
    padding-right: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-middle {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-middle .x-btn-default-medium-mc {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-last .x-btn-default-medium-mc {
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-first {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-first .x-btn-default-medium-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-middle {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-middle .x-btn-default-medium-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-last .x-btn-default-medium-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-default-medium:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-first:after {
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-last:after {
    right: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-first:after {
    top: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-last:after {
    bottom: 0;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-default-medium:after {
    border-width: 0;
    border-color: #d9edfd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-default-medium:after {
    border-width: 0;
    border-color: rgba(33, 149, 241, 0.8150000000000001);
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-default-medium:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-default-medium:after {
    border-width: 0;
    border-color: #e1f1fd;
}

.x-button-default-medium-cell > .x-grid-cell-inner {
    padding-top: 6px;
    padding-bottom: 6px;
}

.x-button-default-medium-cell > .x-grid-cell-inner > .x-btn-default-medium {
    vertical-align: top;
}

.x-btn-default-large {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 8px 16px 8px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-btn-default-large-mc {
    background-color: transparent;
}

.x-nbr .x-btn-default-large {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-default-large-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-8-16-8-16;
}

.x-btn-default-large-tl {
    background-position: 0 0;
}

.x-btn-default-large-tr {
    background-position: right 0;
}

.x-btn-default-large-bl {
    background-position: 0 0;
}

.x-btn-default-large-br {
    background-position: right 0;
}

.x-btn-default-large-ml {
    background-position: 0 top;
}

.x-btn-default-large-mr {
    background-position: right top;
}

.x-btn-default-large-tc {
    background-position: 0 0;
}

.x-btn-default-large-bc {
    background-position: 0 0;
}

.x-btn-default-large-tr,
.x-btn-default-large-br,
.x-btn-default-large-mr {
    padding-right: 0;
}

.x-btn-default-large-tl,
.x-btn-default-large-bl,
.x-btn-default-large-ml {
    padding-left: 0;
}

.x-btn-default-large-tc {
    height: 0;
}

.x-btn-default-large-bc {
    height: 0;
}

.x-btn-default-large-mc {
    padding: 8px 16px 8px 16px;
}

.x-btn-default-large {
    border-color: #5da8e5;
}

.x-btn-button-default-large {
    min-height: 24px;
}

.x-ie9m .x-btn-button-default-large {
    min-height: auto;
    height: 24px;
}

.x-btn-inner-default-large {
    font: 600 16px/20px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #2196f3;
    padding: 0 10px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-default-large,
.x-ie9 .x-btn-icon-left > .x-btn-inner-default-large {
    max-width: calc(100% - 24px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-default-large,
.x-safari8m .x-btn-icon-left > .x-btn-inner-default-large {
    max-width: calc(100% - 23px);
}

.x-ie10p .x-btn-inner-default-large {
    max-width: none;
}

.x-btn-icon-el-default-large {
    font-size: 24px;
    height: 24px;
    color: #2196f3;
    line-height: 24px;
}

.x-btn-icon-left > .x-btn-icon-el-default-large,
.x-btn-icon-right > .x-btn-icon-el-default-large {
    width: 24px;
}

.x-btn-icon-top > .x-btn-icon-el-default-large,
.x-btn-icon-bottom > .x-btn-icon-el-default-large {
    min-width: 24px;
}

.x-ie8 .x-btn-icon-el-default-large.x-btn-glyph {
    color: #2196f3;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-large {
    margin-right: 0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-large.x-rtl {
    margin-right: 0;
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-large {
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-large.x-rtl {
    margin-left: 0;
    margin-right: 0;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-default-large {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-default-large {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-large {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-large {
    margin-right: 10px;
}

.x-btn-arrow-bottom > .x-btn-button-default-large,
.x-btn-split-bottom > .x-btn-button-default-large {
    padding-bottom: 8px;
}

.x-btn-wrap-default-large.x-btn-arrow-right:after {
    width: 22px;
    content: 'arrow_drop_down';
    font: 24px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-default-large.x-btn-arrow-bottom:after {
    height: 16px;
    content: 'arrow_drop_down';
    font: 24px/16px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-default-large.x-btn-split-right > .x-btn-button {
    padding-right: 16px;
}

.x-btn-wrap-default-large.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 16px;
}

.x-btn-wrap-default-large.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #2196f3;
}

.x-btn-wrap-default-large.x-btn-split-right:after {
    width: 32px;
    content: 'arrow_drop_down';
    font: 24px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
    padding-left: 16px;
}

.x-btn-wrap-default-large.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 16px;
}

.x-btn-wrap-default-large.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-default-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #2196f3;
}

.x-btn-wrap-default-large.x-btn-split-bottom:after {
    height: 32px;
    content: 'arrow_drop_down';
    font: 24px/32px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-large {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-large {
    margin-right: 10px;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-large {
    background-image: none;
    background-color: #42a5f5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-large .x-btn-inner {
    color: #fff;
}

.x-btn-default-large .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 48px;
    pointer-events: none;
}

.x-btn-default-large .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-default-large.x-arrow-focus .x-btn-arrow-el {
    border: 0 solid #d9edfd;
}

.x-btn-over.x-btn-default-large {
    border-color: #569bd2;
    background-image: none;
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-large {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-menu-active.x-btn-default-large,
.x-btn.x-btn-pressed.x-btn-default-large {
    border-color: #467eac;
    background-image: none;
    background-color: #0c7ce6;
}

.x-btn.x-btn-menu-active.x-btn-default-large .x-btn-inner,
.x-btn.x-btn-pressed.x-btn-default-large .x-btn-inner {
    color: #fff;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-default-large,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-default-large {
    background-image: none;
    background-color: #6ab8f7;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-disabled.x-btn-default-large {
    background-image: none;
    background-color: transparent;
}

.x-btn.x-btn-disabled.x-btn-default-large .x-btn-inner {
    color: rgba(0, 0, 0, 0.26);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-large-mc {
    background-color: #42a5f5;
}

.x-btn-over .x-btn-default-large-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-large-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-btn.x-btn-menu-active .x-btn-default-large-mc,
.x-btn.x-btn-pressed .x-btn-default-large-mc {
    background-color: #0c7ce6;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-large-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-large-mc {
    background-color: #6ab8f7;
}

.x-btn.x-btn-disabled .x-btn-default-large-mc {
    background-color: transparent;
}

.x-nbr .x-btn-default-large {
    background-image: none;
}

.x-btn-disabled.x-btn-default-large {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=)";
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-first {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-first .x-btn-default-large-mc {
    padding-right: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-middle {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-middle .x-btn-default-large-mc {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-last .x-btn-default-large-mc {
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-first {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-first .x-btn-default-large-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-middle {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-middle .x-btn-default-large-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-last .x-btn-default-large-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-default-large:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-first:after {
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-last:after {
    right: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-first:after {
    top: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-last:after {
    bottom: 0;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-default-large:after {
    border-width: 0;
    border-color: #d9edfd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-default-large:after {
    border-width: 0;
    border-color: rgba(33, 149, 241, 0.8150000000000001);
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-default-large:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-default-large:after {
    border-width: 0;
    border-color: #e1f1fd;
}

.x-button-default-large-cell > .x-grid-cell-inner {
    padding-top: 4px;
    padding-bottom: 4px;
}

.x-button-default-large-cell > .x-grid-cell-inner > .x-btn-default-large {
    vertical-align: top;
}

.x-btn-default-toolbar-small {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 8px 16px 8px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-btn-default-toolbar-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-default-toolbar-small {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-default-toolbar-small-frameInfo {
    font-family: th-2-2-2-2-0-0-0-0-8-16-8-16;
}

.x-btn-default-toolbar-small-tl {
    background-position: 0 -4px;
}

.x-btn-default-toolbar-small-tr {
    background-position: right -6px;
}

.x-btn-default-toolbar-small-bl {
    background-position: 0 -8px;
}

.x-btn-default-toolbar-small-br {
    background-position: right -10px;
}

.x-btn-default-toolbar-small-ml {
    background-position: 0 top;
}

.x-btn-default-toolbar-small-mr {
    background-position: right top;
}

.x-btn-default-toolbar-small-tc {
    background-position: 0 0;
}

.x-btn-default-toolbar-small-bc {
    background-position: 0 -2px;
}

.x-btn-default-toolbar-small-tr,
.x-btn-default-toolbar-small-br,
.x-btn-default-toolbar-small-mr {
    padding-right: 2px;
}

.x-btn-default-toolbar-small-tl,
.x-btn-default-toolbar-small-bl,
.x-btn-default-toolbar-small-ml {
    padding-left: 2px;
}

.x-btn-default-toolbar-small-tc {
    height: 2px;
}

.x-btn-default-toolbar-small-bc {
    height: 2px;
}

.x-btn-default-toolbar-small-mc {
    padding: 6px 14px 6px 14px;
}

.x-btn-default-toolbar-small {
    border-color: #e4e4e4;
}

.x-btn-button-default-toolbar-small {
    min-height: 16px;
}

.x-ie9m .x-btn-button-default-toolbar-small {
    min-height: auto;
    height: 16px;
}

.x-btn-inner-default-toolbar-small {
    font: 600 13px/15px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #2196f3;
    padding: 0 5px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-default-toolbar-small,
.x-ie9 .x-btn-icon-left > .x-btn-inner-default-toolbar-small {
    max-width: calc(100% - 16px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-default-toolbar-small,
.x-safari8m .x-btn-icon-left > .x-btn-inner-default-toolbar-small {
    max-width: calc(100% - 15px);
}

.x-ie10p .x-btn-inner-default-toolbar-small {
    max-width: none;
}

.x-btn-icon-el-default-toolbar-small {
    font-size: 16px;
    height: 16px;
    color: #2196f3;
    line-height: 16px;
}

.x-btn-icon-left > .x-btn-icon-el-default-toolbar-small,
.x-btn-icon-right > .x-btn-icon-el-default-toolbar-small {
    width: 16px;
}

.x-btn-icon-top > .x-btn-icon-el-default-toolbar-small,
.x-btn-icon-bottom > .x-btn-icon-el-default-toolbar-small {
    min-width: 16px;
}

.x-ie8 .x-btn-icon-el-default-toolbar-small.x-btn-glyph {
    color: #2196f3;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-toolbar-small {
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-toolbar-small.x-rtl {
    margin-right: 0;
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-small {
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-small.x-rtl {
    margin-left: 0;
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-default-toolbar-small {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-default-toolbar-small {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-toolbar-small {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-small {
    margin-right: 5px;
}

.x-btn-arrow-bottom > .x-btn-button-default-toolbar-small,
.x-btn-split-bottom > .x-btn-button-default-toolbar-small {
    padding-bottom: 8px;
}

.x-btn-wrap-default-toolbar-small.x-btn-arrow-right:after {
    width: 18px;
    content: 'arrow_drop_down';
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-default-toolbar-small.x-btn-arrow-bottom:after {
    height: 12px;
    content: 'arrow_drop_down';
    font: 16px/12px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button {
    padding-right: 16px;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 16px;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #2196f3;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-right:after {
    width: 16px;
    content: 'arrow_drop_down';
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
    padding-left: 16px;
}

.x-btn-wrap-default-toolbar-small.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 16px;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #2196f3;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-bottom:after {
    height: 16px;
    content: 'arrow_drop_down';
    font: 16px/16px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-toolbar-small {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-small {
    margin-right: 5px;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-toolbar-small {
    background-image: none;
    background-color: #42a5f5;
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-toolbar-small .x-btn-inner {
    color: #fff;
}

.x-btn-default-toolbar-small .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 32px;
    pointer-events: none;
}

.x-btn-default-toolbar-small .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-default-toolbar-small.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #2196f3;
}

.x-btn-over.x-btn-default-toolbar-small {
    border-color: #dbdbdb;
    background-image: none;
    background-color: rgba(17, 17, 17, 0.075);
}

.x-btn-over-hover.x-btn-default-toolbar-small:hover {
    border-color: #dbdbdb;
    background-image: none;
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-toolbar-small {
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-toolbar-small .x-btn-inner {
    color: #fff;
}

.x-btn.x-btn-menu-active.x-btn-default-toolbar-small,
.x-btn.x-btn-pressed.x-btn-default-toolbar-small {
    border-color: #d2d2d2;
    background-image: none;
    background-color: #2196f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-menu-active.x-btn-default-toolbar-small .x-btn-inner,
.x-btn.x-btn-pressed.x-btn-default-toolbar-small .x-btn-inner {
    color: #fff;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-default-toolbar-small,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-default-toolbar-small {
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-btn.x-btn-disabled.x-btn-default-toolbar-small {
    background-image: none;
    background-color: transparent;
}

.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-small-mc {
    background-color: #42a5f5;
}

.x-btn-over .x-btn-default-toolbar-small-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-small-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-btn.x-btn-menu-active .x-btn-default-toolbar-small-mc,
.x-btn.x-btn-pressed .x-btn-default-toolbar-small-mc {
    background-color: #2196f3;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-small-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-small-mc {
    background-color: #2196f3;
}

.x-btn.x-btn-disabled .x-btn-default-toolbar-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-default-toolbar-small {
    background-image: none;
}

.x-btn-disabled.x-btn-default-toolbar-small {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-first {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-first .x-btn-default-toolbar-small-mc {
    padding-right: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-middle {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-middle .x-btn-default-toolbar-small-mc {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-last .x-btn-default-toolbar-small-mc {
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-first {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-first .x-btn-default-toolbar-small-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-middle {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-middle .x-btn-default-toolbar-small-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-last .x-btn-default-toolbar-small-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-default-toolbar-small:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-first:after {
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-last:after {
    right: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-first:after {
    top: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-last:after {
    bottom: 0;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-default-toolbar-small:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-default-toolbar-small:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-nbr .x-segmented-button-item.x-btn.x-btn-menu-active.x-btn-default-toolbar-small:after,
.x-nbr .x-segmented-button-item.x-btn.x-btn-pressed.x-btn-default-toolbar-small:after {
    border-width: 0;
    border-color: #51adf6;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-default-toolbar-small:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-default-toolbar-small:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-button-default-toolbar-small-cell > .x-grid-cell-inner {
    padding-top: 8px;
    padding-bottom: 8px;
}

.x-button-default-toolbar-small-cell > .x-grid-cell-inner > .x-btn-default-toolbar-small {
    vertical-align: top;
}

.x-btn-default-toolbar-medium {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 8px 16px 8px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-btn-default-toolbar-medium-mc {
    background-color: transparent;
}

.x-nbr .x-btn-default-toolbar-medium {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-default-toolbar-medium-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-8-16-8-16;
}

.x-btn-default-toolbar-medium-tl {
    background-position: 0 0;
}

.x-btn-default-toolbar-medium-tr {
    background-position: right 0;
}

.x-btn-default-toolbar-medium-bl {
    background-position: 0 0;
}

.x-btn-default-toolbar-medium-br {
    background-position: right 0;
}

.x-btn-default-toolbar-medium-ml {
    background-position: 0 top;
}

.x-btn-default-toolbar-medium-mr {
    background-position: right top;
}

.x-btn-default-toolbar-medium-tc {
    background-position: 0 0;
}

.x-btn-default-toolbar-medium-bc {
    background-position: 0 0;
}

.x-btn-default-toolbar-medium-tr,
.x-btn-default-toolbar-medium-br,
.x-btn-default-toolbar-medium-mr {
    padding-right: 0;
}

.x-btn-default-toolbar-medium-tl,
.x-btn-default-toolbar-medium-bl,
.x-btn-default-toolbar-medium-ml {
    padding-left: 0;
}

.x-btn-default-toolbar-medium-tc {
    height: 0;
}

.x-btn-default-toolbar-medium-bc {
    height: 0;
}

.x-btn-default-toolbar-medium-mc {
    padding: 8px 16px 8px 16px;
}

.x-btn-default-toolbar-medium {
    border-color: #e4e4e4;
}

.x-btn-button-default-toolbar-medium {
    min-height: 20px;
}

.x-ie9m .x-btn-button-default-toolbar-medium {
    min-height: auto;
    height: 20px;
}

.x-btn-inner-default-toolbar-medium {
    font: 600 14px/16px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #2196f3;
    padding: 0 8px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-default-toolbar-medium,
.x-ie9 .x-btn-icon-left > .x-btn-inner-default-toolbar-medium {
    max-width: calc(100% - 20px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-default-toolbar-medium,
.x-safari8m .x-btn-icon-left > .x-btn-inner-default-toolbar-medium {
    max-width: calc(100% - 19px);
}

.x-ie10p .x-btn-inner-default-toolbar-medium {
    max-width: none;
}

.x-btn-icon-el-default-toolbar-medium {
    font-size: 20px;
    height: 20px;
    color: #2196f3;
    line-height: 20px;
}

.x-btn-icon-left > .x-btn-icon-el-default-toolbar-medium,
.x-btn-icon-right > .x-btn-icon-el-default-toolbar-medium {
    width: 20px;
}

.x-btn-icon-top > .x-btn-icon-el-default-toolbar-medium,
.x-btn-icon-bottom > .x-btn-icon-el-default-toolbar-medium {
    min-width: 20px;
}

.x-ie8 .x-btn-icon-el-default-toolbar-medium.x-btn-glyph {
    color: #2196f3;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-toolbar-medium {
    margin-right: 0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-toolbar-medium.x-rtl {
    margin-right: 0;
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-medium {
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-medium.x-rtl {
    margin-left: 0;
    margin-right: 0;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-default-toolbar-medium {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-default-toolbar-medium {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-toolbar-medium {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-medium {
    margin-right: 8px;
}

.x-btn-arrow-bottom > .x-btn-button-default-toolbar-medium,
.x-btn-split-bottom > .x-btn-button-default-toolbar-medium {
    padding-bottom: 8px;
}

.x-btn-wrap-default-toolbar-medium.x-btn-arrow-right:after {
    width: 20px;
    content: 'arrow_drop_down';
    font: 20px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-default-toolbar-medium.x-btn-arrow-bottom:after {
    height: 14px;
    content: 'arrow_drop_down';
    font: 20px/14px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button {
    padding-right: 16px;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 16px;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #2196f3;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-right:after {
    width: 24px;
    content: 'arrow_drop_down';
    font: 20px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
    padding-left: 16px;
}

.x-btn-wrap-default-toolbar-medium.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 16px;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #2196f3;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-bottom:after {
    height: 24px;
    content: 'arrow_drop_down';
    font: 20px/24px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-toolbar-medium {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-medium {
    margin-right: 8px;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-toolbar-medium {
    background-image: none;
    background-color: #42a5f5;
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-toolbar-medium .x-btn-inner {
    color: #fff;
}

.x-btn-default-toolbar-medium .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 40px;
    pointer-events: none;
}

.x-btn-default-toolbar-medium .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-default-toolbar-medium.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #2196f3;
}

.x-btn-over.x-btn-default-toolbar-medium {
    border-color: #dbdbdb;
    background-image: none;
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-toolbar-medium {
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-toolbar-medium .x-btn-inner {
    color: #fff;
}

.x-btn.x-btn-menu-active.x-btn-default-toolbar-medium,
.x-btn.x-btn-pressed.x-btn-default-toolbar-medium {
    border-color: #d2d2d2;
    background-image: none;
    background-color: #2196f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-menu-active.x-btn-default-toolbar-medium .x-btn-inner,
.x-btn.x-btn-pressed.x-btn-default-toolbar-medium .x-btn-inner {
    color: #fff;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-default-toolbar-medium,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-default-toolbar-medium {
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-btn.x-btn-disabled.x-btn-default-toolbar-medium {
    background-image: none;
    background-color: transparent;
}

.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-medium-mc {
    background-color: #42a5f5;
}

.x-btn-over .x-btn-default-toolbar-medium-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-medium-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-btn.x-btn-menu-active .x-btn-default-toolbar-medium-mc,
.x-btn.x-btn-pressed .x-btn-default-toolbar-medium-mc {
    background-color: #2196f3;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-medium-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-medium-mc {
    background-color: #2196f3;
}

.x-btn.x-btn-disabled .x-btn-default-toolbar-medium-mc {
    background-color: transparent;
}

.x-nbr .x-btn-default-toolbar-medium {
    background-image: none;
}

.x-btn-disabled.x-btn-default-toolbar-medium {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-first {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-first .x-btn-default-toolbar-medium-mc {
    padding-right: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-middle {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-middle .x-btn-default-toolbar-medium-mc {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-last .x-btn-default-toolbar-medium-mc {
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-first {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-first .x-btn-default-toolbar-medium-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-middle {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-middle .x-btn-default-toolbar-medium-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-last .x-btn-default-toolbar-medium-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-default-toolbar-medium:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-first:after {
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-last:after {
    right: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-first:after {
    top: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-last:after {
    bottom: 0;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-default-toolbar-medium:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-default-toolbar-medium:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-nbr .x-segmented-button-item.x-btn.x-btn-menu-active.x-btn-default-toolbar-medium:after,
.x-nbr .x-segmented-button-item.x-btn.x-btn-pressed.x-btn-default-toolbar-medium:after {
    border-width: 0;
    border-color: #51adf6;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-default-toolbar-medium:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-default-toolbar-medium:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-button-default-toolbar-medium-cell > .x-grid-cell-inner {
    padding-top: 6px;
    padding-bottom: 6px;
}

.x-button-default-toolbar-medium-cell > .x-grid-cell-inner > .x-btn-default-toolbar-medium {
    vertical-align: top;
}

.x-btn-default-toolbar-large {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 8px 16px 8px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-btn-default-toolbar-large-mc {
    background-color: transparent;
}

.x-nbr .x-btn-default-toolbar-large {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-default-toolbar-large-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-8-16-8-16;
}

.x-btn-default-toolbar-large-tl {
    background-position: 0 0;
}

.x-btn-default-toolbar-large-tr {
    background-position: right 0;
}

.x-btn-default-toolbar-large-bl {
    background-position: 0 0;
}

.x-btn-default-toolbar-large-br {
    background-position: right 0;
}

.x-btn-default-toolbar-large-ml {
    background-position: 0 top;
}

.x-btn-default-toolbar-large-mr {
    background-position: right top;
}

.x-btn-default-toolbar-large-tc {
    background-position: 0 0;
}

.x-btn-default-toolbar-large-bc {
    background-position: 0 0;
}

.x-btn-default-toolbar-large-tr,
.x-btn-default-toolbar-large-br,
.x-btn-default-toolbar-large-mr {
    padding-right: 0;
}

.x-btn-default-toolbar-large-tl,
.x-btn-default-toolbar-large-bl,
.x-btn-default-toolbar-large-ml {
    padding-left: 0;
}

.x-btn-default-toolbar-large-tc {
    height: 0;
}

.x-btn-default-toolbar-large-bc {
    height: 0;
}

.x-btn-default-toolbar-large-mc {
    padding: 8px 16px 8px 16px;
}

.x-btn-default-toolbar-large {
    border-color: #e4e4e4;
}

.x-btn-button-default-toolbar-large {
    min-height: 24px;
}

.x-ie9m .x-btn-button-default-toolbar-large {
    min-height: auto;
    height: 24px;
}

.x-btn-inner-default-toolbar-large {
    font: 600 16px/20px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #2196f3;
    padding: 0 10px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-default-toolbar-large,
.x-ie9 .x-btn-icon-left > .x-btn-inner-default-toolbar-large {
    max-width: calc(100% - 24px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-default-toolbar-large,
.x-safari8m .x-btn-icon-left > .x-btn-inner-default-toolbar-large {
    max-width: calc(100% - 23px);
}

.x-ie10p .x-btn-inner-default-toolbar-large {
    max-width: none;
}

.x-btn-icon-el-default-toolbar-large {
    font-size: 24px;
    height: 24px;
    color: #2196f3;
    line-height: 24px;
}

.x-btn-icon-left > .x-btn-icon-el-default-toolbar-large,
.x-btn-icon-right > .x-btn-icon-el-default-toolbar-large {
    width: 24px;
}

.x-btn-icon-top > .x-btn-icon-el-default-toolbar-large,
.x-btn-icon-bottom > .x-btn-icon-el-default-toolbar-large {
    min-width: 24px;
}

.x-ie8 .x-btn-icon-el-default-toolbar-large.x-btn-glyph {
    color: #2196f3;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-toolbar-large {
    margin-right: 0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-toolbar-large.x-rtl {
    margin-right: 0;
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-large {
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-large.x-rtl {
    margin-left: 0;
    margin-right: 0;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-default-toolbar-large {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-default-toolbar-large {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-toolbar-large {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-large {
    margin-right: 10px;
}

.x-btn-arrow-bottom > .x-btn-button-default-toolbar-large,
.x-btn-split-bottom > .x-btn-button-default-toolbar-large {
    padding-bottom: 8px;
}

.x-btn-wrap-default-toolbar-large.x-btn-arrow-right:after {
    width: 22px;
    content: 'arrow_drop_down';
    font: 24px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-default-toolbar-large.x-btn-arrow-bottom:after {
    height: 16px;
    content: 'arrow_drop_down';
    font: 24px/16px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button {
    padding-right: 16px;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 16px;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #2196f3;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-right:after {
    width: 32px;
    content: 'arrow_drop_down';
    font: 24px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
    padding-left: 16px;
}

.x-btn-wrap-default-toolbar-large.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 16px;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #2196f3;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-bottom:after {
    height: 32px;
    content: 'arrow_drop_down';
    font: 24px/32px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-toolbar-large {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-large {
    margin-right: 10px;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-toolbar-large {
    background-image: none;
    background-color: #42a5f5;
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-toolbar-large .x-btn-inner {
    color: #fff;
}

.x-btn-default-toolbar-large .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 48px;
    pointer-events: none;
}

.x-btn-default-toolbar-large .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-default-toolbar-large.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #2196f3;
}

.x-btn-over.x-btn-default-toolbar-large {
    border-color: #dbdbdb;
    background-image: none;
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-toolbar-large {
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-toolbar-large .x-btn-inner {
    color: #fff;
}

.x-btn.x-btn-menu-active.x-btn-default-toolbar-large,
.x-btn.x-btn-pressed.x-btn-default-toolbar-large {
    border-color: #d2d2d2;
    background-image: none;
    background-color: #2196f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-menu-active.x-btn-default-toolbar-large .x-btn-inner,
.x-btn.x-btn-pressed.x-btn-default-toolbar-large .x-btn-inner {
    color: #fff;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-default-toolbar-large,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-default-toolbar-large {
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-btn.x-btn-disabled.x-btn-default-toolbar-large {
    background-image: none;
    background-color: transparent;
}

.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-large-mc {
    background-color: #42a5f5;
}
.x-btn-over .x-btn-default-toolbar-large-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-large-mc {
    background-color: rgba(17, 17, 17, 0.075);
}
.x-btn.x-btn-menu-active .x-btn-default-toolbar-large-mc,
.x-btn.x-btn-pressed .x-btn-default-toolbar-large-mc {
    background-color: #2196f3;
}
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-large-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-large-mc {
    background-color: #2196f3;
}


.x-btn.x-btn-disabled .x-btn-default-toolbar-large-mc {
    background-color: transparent;
}

.x-nbr .x-btn-default-toolbar-large {
    background-image: none;
}

.x-btn-disabled.x-btn-default-toolbar-large {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-first {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-first .x-btn-default-toolbar-large-mc {
    padding-right: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-middle {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-middle .x-btn-default-toolbar-large-mc {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-last .x-btn-default-toolbar-large-mc {
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-first {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-first .x-btn-default-toolbar-large-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-middle {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-middle .x-btn-default-toolbar-large-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-last .x-btn-default-toolbar-large-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-default-toolbar-large:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-first:after {
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-last:after {
    right: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-first:after {
    top: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-last:after {
    bottom: 0;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-default-toolbar-large:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-default-toolbar-large:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-nbr .x-segmented-button-item.x-btn.x-btn-menu-active.x-btn-default-toolbar-large:after,
.x-nbr .x-segmented-button-item.x-btn.x-btn-pressed.x-btn-default-toolbar-large:after {
    border-width: 0;
    border-color: #51adf6;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-default-toolbar-large:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-default-toolbar-large:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-button-default-toolbar-large-cell > .x-grid-cell-inner {
    padding-top: 4px;
    padding-bottom: 4px;
}

.x-button-default-toolbar-large-cell > .x-grid-cell-inner > .x-btn-default-toolbar-large {
    vertical-align: top;
}

.x-tool {
    cursor: pointer;
}

.x-tool-tool-el {
    overflow: hidden;
    width: 16px;
    height: 16px;
    margin: 0;
    color: #fff;
    text-align: center;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-tool-over .x-tool-tool-el {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-tool-pressed .x-tool-tool-el {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-keyboard-mode .x-tool-focus {
    outline: 1px solid #2196f3;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-tool-focus,
.x-edge .x-keyboard-mode .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #2196f3;
    pointer-events: none;
}

.x-tool-img {
    background-image: url(./../images/tools/tool-sprites.png);
}

.x-tool-placeholder {
    visibility: hidden;
}

.x-tool-close {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-tool-close:before {
    content: 'close';
}

.x-tool-minimize {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none;
}

.x-tool-minimize:before {
    content: 'fullscreen_exit';
}

.x-tool-maximize {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none;
}

.x-tool-maximize:before {
    content: 'fullscreen';
}

.x-tool-restore {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none;
}

.x-tool-restore:before {
    content: '\f066';
}

.x-tool-toggle {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-toggle:before {
    content: '\f077';
}

.x-panel-collapsed .x-tool-toggle {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-panel-collapsed .x-tool-toggle:before {
    content: '\f078';
}

.x-tool-gear {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-tool-gear:before {
    content: 'settings';
}

.x-tool-prev {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-prev:before {
    content: '\f053';
}

.x-tool-next {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-next:before {
    content: '\f054';
}

.x-tool-pin {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-pin:before {
    content: '\f08d';
}

.x-tool-unpin {
    font: 16px/1 ExtJS;
    background: none !important;
}

.x-tool-unpin:before {
    content: "\e611";
}

.x-tool-right {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-right:before {
    content: '\f0da';
}

.x-tool-left {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-left:before {
    content: '\f0d9';
}

.x-tool-down {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-down:before {
    content: '\f0d7';
}

.x-tool-up {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-up:before {
    content: '\f0d8';
}

.x-tool-refresh {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-refresh:before {
    content: '\f021';
}

.x-tool-plus {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-plus:before {
    content: '\f067';
}

.x-tool-minus {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-minus:before {
    content: '\f068';
}

.x-tool-search {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-search:before {
    content: '\f002';
}

.x-tool-save {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-save:before {
    content: '\f00c';
}

.x-tool-help {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-help:before {
    content: '\f128';
}

.x-tool-print {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-print:before {
    content: '\f02f';
}

.x-tool-expand {
    font: 16px/1 ExtJS;
    background: none !important;
}

.x-tool-expand:before {
    content: "\e600";
}

.x-tool-collapse {
    font: 16px/1 ExtJS;
    background: none !important;
}

.x-tool-collapse:before {
    content: "\e606";
}

.x-tool-resize {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-resize:before {
    content: '\f337';
}

.x-tool-move {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-move:before {
    content: '\f31e';
}

.x-tool-expand-bottom {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-tool-expand-bottom:before {
    content: 'keyboard_arrow_down';
}

.x-tool-collapse-bottom {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-tool-collapse-bottom:before {
    content: 'keyboard_arrow_down';
}

.x-tool-expand-top {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-tool-expand-top:before {
    content: 'keyboard_arrow_up';
}

.x-tool-collapse-top {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-tool-collapse-top:before {
    content: 'keyboard_arrow_up';
}

.x-tool-expand-left {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-tool-expand-left:before {
    content: 'keyboard_arrow_left';
}

.x-tool-collapse-left {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-tool-collapse-left:before {
    content: 'keyboard_arrow_left';
}

.x-tool-expand-right {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-tool-expand-right:before {
    content: 'keyboard_arrow_right';
}

.x-tool-collapse-right {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-tool-collapse-right:before {
    content: 'keyboard_arrow_right';
}

.x-rtl.x-tool-expand-left {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-rtl.x-tool-expand-left:before {
    content: 'keyboard_arrow_right';
}

.x-rtl.x-tool-collapse-left {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-rtl.x-tool-collapse-left:before {
    content: 'keyboard_arrow_right';
}

.x-rtl.x-tool-expand-right {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-rtl.x-tool-expand-right:before {
    content: 'keyboard_arrow_left';
}

.x-rtl.x-tool-collapse-right {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-rtl.x-tool-collapse-right:before {
    content: 'keyboard_arrow_left';
}

.x-header-draggable,
.x-header-ghost {
    cursor: move;
}

.x-header-text {
    white-space: nowrap;
}

.x-collapse-el {
    cursor: pointer;
    color: #fff;
    display: table;
    table-layout: fixed;
    background-color: #d6d6d6;
}

.x-collapse-el:before {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.x-layout-split-left {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-layout-split-left:before {
    content: '\f0d9';
}

.x-layout-split-right {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    padding: 0 0 0 2px;
}

.x-layout-split-right:before {
    content: '\f0da';
}

.x-rtl.x-layout-split-left {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    padding: 0 0 0 2px;
}

.x-rtl.x-layout-split-left:before {
    content: '\f0da';
}

.x-rtl.x-layout-split-right {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-rtl.x-layout-split-right:before {
    content: '\f0d9';
}

.x-layout-split-top {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    padding: 0 0 2px 0;
}

.x-layout-split-top:before {
    content: '\f0d8';
}

.x-layout-split-bottom {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-layout-split-bottom:before {
    content: '\f0d7';
}

.x-splitter-collapsed .x-layout-split-left {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    padding: 0 0 0 2px;
}

.x-splitter-collapsed .x-layout-split-left:before {
    content: '\f0da';
}

.x-splitter-collapsed .x-layout-split-right {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-splitter-collapsed .x-layout-split-right:before {
    content: '\f0d9';
}

.x-splitter-collapsed .x-rtl.x-layout-split-left {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-splitter-collapsed .x-rtl.x-layout-split-left:before {
    content: '\f0d9';
}

.x-splitter-collapsed .x-rtl.x-layout-split-right {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    padding: 0 0 0 2px;
}

.x-splitter-collapsed .x-rtl.x-layout-split-right:before {
    content: '\f0da';
}

.x-splitter-collapsed .x-layout-split-top {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-splitter-collapsed .x-layout-split-top:before {
    content: '\f0d7';
}

.x-splitter-collapsed .x-layout-split-bottom {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    padding: 0 0 2px 0;
}

.x-splitter-collapsed .x-layout-split-bottom:before {
    content: '\f0d8';
}

.x-splitter-active {
    background-color: #b4b4b4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-splitter-active .x-collapse-el {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.3;
}

.x-layout-split-left,
.x-layout-split-right {
    top: 50%;
    margin-top: -24px;
    width: 100%;
    height: 48px;
}

.x-layout-split-top,
.x-layout-split-bottom {
    left: 50%;
    width: 48px;
    height: 100%;
    margin-left: -24px;
}

.x-layout-split-top:before,
.x-layout-split-bottom:before {
    width: 100%;
    line-height: 10px;
}

.x-keyboard-mode .x-splitter-focus:after {
    position: absolute;
    content: ' ';
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    border: 1px solid #1976d2;
    pointer-events: none;
}

.x-toolbar-default {
    padding: 6px 0 6px 8px;
    border-style: solid;
    border-color: #d0d0d0;
    border-width: 1px;
    background-image: none;
    background-color: #f5f5f5;
}

.x-toolbar-default.x-rtl {
    padding: 6px 8px 6px 0;
}

.x-toolbar-default .x-tool-img {
    color: #919191;
    background-color: #f5f5f5;
}

.x-toolbar-default .x-toolbar-item {
    margin: 0 8px 0 0;
}

.x-toolbar-item.x-toolbar-menu-item {
    margin-top: 3px;
}

.x-toolbar-default .x-toolbar-item.x-rtl {
    margin: 0 0 0 8px;
}

.x-toolbar-default .x-toolbar-separator-horizontal {
    margin: 0 8px 0 0;
    height: 14px;
    border-style: none;
    border-width: 0 0 0 1px;
    border-left-color: #d0d0d0;
    border-right-color: #fff;
}

.x-toolbar-default .x-box-menu-after {
    margin: 0 8px;
}

.x-toolbar-default-vertical {
    padding: 6px 8px 0;
}

.x-toolbar-default-vertical.x-rtl {
    padding: 6px 8px 0;
}

.x-toolbar-default-vertical .x-toolbar-item {
    margin: 0 0 6px 0;
}

.x-toolbar-default-vertical .x-toolbar-item.x-rtl {
    margin: 0 0 6px 0;
}

.x-toolbar-default-vertical .x-toolbar-separator-vertical {
    margin: 0 5px 6px;
    border-style: solid none;
    border-width: 1px 0 0;
    border-top-color: #d0d0d0;
    border-bottom-color: #fff;
}

.x-toolbar-default-vertical .x-box-menu-after {
    margin: 6px 0;
}

.x-toolbar-text-default {
    padding: 8px 16px;
    color: #40464b;
    font: 600 16px/16px 'Nunito Sans', 'Open Sans', sans-serif;
}

.x-toolbar-spacer-default {
    width: 2px;
}

.x-toolbar-default-scroller .x-box-scroller-body-horizontal {
    margin-left: 24px;
}

.x-toolbar-default-scroller.x-rtl .x-box-scroller-body-horizontal {
    margin-left: 0;
    margin-right: 24px;
}

.x-toolbar-default-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 26px;
}

.x-box-scroller-toolbar-default {
    cursor: pointer;
    color: #919191;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-box-scroller-toolbar-default.x-box-scroller-hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-box-scroller-toolbar-default.x-box-scroller-pressed {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-box-scroller-toolbar-default.x-box-scroller-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    opacity: 0.25;
    cursor: default;
}

.x-box-scroller-toolbar-default.x-box-scroller-left,
.x-box-scroller-toolbar-default.x-box-scroller-right {
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
}

.x-box-scroller-toolbar-default.x-box-scroller-left {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    font: 16px/24px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-toolbar-default.x-box-scroller-left:before {
    content: '\f053';
}

.x-box-scroller-toolbar-default.x-box-scroller-right {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    font: 16px/24px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-toolbar-default.x-box-scroller-right:before {
    content: '\f054';
}

.x-box-scroller-toolbar-default.x-box-scroller-top,
.x-box-scroller-toolbar-default.x-box-scroller-bottom {
    height: 24px;
    width: 24px;
    left: 50%;
    margin-left: -12px;
}

.x-box-scroller-toolbar-default.x-box-scroller-top {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 4px;
    font: 16px/24px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-toolbar-default.x-box-scroller-top:before {
    content: '\f077';
}

.x-box-scroller-toolbar-default.x-box-scroller-bottom {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 4px;
    font: 16px/24px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-toolbar-default.x-box-scroller-bottom:before {
    content: '\f078';
}

.x-ie8 .x-box-scroller-toolbar-default {
    background-color: #f5f5f5;
}

.x-toolbar-more-icon {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-toolbar-more-icon:before {
    content: '\f0c9';
}

.x-toolbar-footer {
    padding: 6px 0 6px 6px;
    border-style: solid;
    border-color: #d0d0d0;
    border-width: 0;
    background-image: none;
    background-color: #fafafa;
}

.x-toolbar-footer.x-rtl {
    padding: 6px 6px 6px 0;
}

.x-toolbar-footer .x-tool-img {
    color: #919191;
    background-color: #fafafa;
}

.x-toolbar-footer .x-toolbar-item {
    margin: 0 6px 0 0;
}

.x-toolbar-footer .x-toolbar-item.x-rtl {
    margin: 0 0 0 6px;
}

.x-toolbar-footer .x-toolbar-separator-horizontal {
    margin: 0 8px 0 0;
    height: 14px;
    border-style: none;
    border-width: 0 0 0 1px;
    border-left-color: #d0d0d0;
    border-right-color: #fff;
}

.x-toolbar-footer .x-box-menu-after {
    margin: 0 6px;
}

.x-toolbar-footer-vertical {
    padding: 6px 6px 0;
}

.x-toolbar-footer-vertical.x-rtl {
    padding: 6px 6px 0;
}

.x-toolbar-footer-vertical .x-toolbar-item {
    margin: 0 0 6px 0;
}

.x-toolbar-footer-vertical .x-toolbar-item.x-rtl {
    margin: 0 0 6px 0;
}

.x-toolbar-footer-vertical .x-toolbar-separator-vertical {
    margin: 0 5px 6px;
    border-style: solid none;
    border-width: 1px 0 0;
    border-top-color: #d0d0d0;
    border-bottom-color: #fff;
}

.x-toolbar-footer-vertical .x-box-menu-after {
    margin: 6px 0;
}

.x-toolbar-text-footer {
    padding: 8px 16px;
    color: #40464b;
    font: 600 16px/16px 'Nunito Sans', 'Open Sans', sans-serif;
}

.x-toolbar-spacer-footer {
    width: 2px;
}

.x-toolbar-footer-scroller .x-box-scroller-body-horizontal {
    margin-left: 26px;
}

.x-toolbar-footer-scroller.x-rtl .x-box-scroller-body-horizontal {
    margin-left: 0;
    margin-right: 26px;
}

.x-toolbar-footer-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 26px;
}

.x-box-scroller-toolbar-footer {
    cursor: pointer;
    color: #919191;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-box-scroller-toolbar-footer.x-box-scroller-hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-box-scroller-toolbar-footer.x-box-scroller-pressed {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-box-scroller-toolbar-footer.x-box-scroller-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    opacity: 0.25;
    cursor: default;
}

.x-box-scroller-toolbar-footer.x-box-scroller-left,
.x-box-scroller-toolbar-footer.x-box-scroller-right {
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
}

.x-box-scroller-toolbar-footer.x-box-scroller-left {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    font: 16px/24px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-toolbar-footer.x-box-scroller-left:before {
    content: '\f053';
}

.x-box-scroller-toolbar-footer.x-box-scroller-right {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    font: 16px/24px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-toolbar-footer.x-box-scroller-right:before {
    content: '\f054';
}

.x-ie8 .x-box-scroller-toolbar-footer {
    background-color: #fafafa;
}

.x-toolbar-more-icon {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-toolbar-more-icon:before {
    content: '\f0c9';
}

.x-dd-drag-proxy {
    color: #111;
    font: 400 13px/17px 'Nunito Sans', 'Open Sans', sans-serif;
    border: 1px solid #d0d0d0;
    background-color: #fff;
}

.x-dd-drag-ghost,
.x-dd-drop-icon {
    padding: 5px;
}

.x-rtl .x-dd-drag-ghost,
.x-rtl .x-dd-drop-icon {
    padding: 5px 5px 5px 5px;
}

.x-dd-drag-ghost {
    padding-left: 0;
}

.x-rtl .x-dd-drag-ghost {
    padding-left: 5px;
    padding-right: 0;
}

.x-dd-drop-ok .x-dd-drop-icon {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #a4cf6d;
}

.x-dd-drop-ok .x-dd-drop-icon:before {
    content: '\f058';
}

.x-dd-drop-ok-add .x-dd-drop-icon {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #a4cf6d;
}

.x-dd-drop-ok-add .x-dd-drop-icon:before {
    content: '\f058';
}

.x-dd-drop-nodrop div.x-dd-drop-icon {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #d9705d;
}

.x-dd-drop-nodrop div.x-dd-drop-icon:before {
    content: '\f057';
}

.x-panel-ghost {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-panel-default {
    border-color: #2196f3;
    padding: 0;
}

.x-panel-default.x-masked {
    border-color: #90cbf9;
}

.x-panel-header-default {
    font-size: 18px;
    border: 1px solid #2196f3;
}

.x-panel-header-default .x-tool-tool-el {
    background-color: #2196f3;
}

.x-panel-header-default-horizontal {
    padding: 16px;
}

.x-panel-header-default-horizontal .x-panel-header-default-tab-bar {
    margin-top: -16px;
    margin-bottom: -16px;
}

.x-panel-header-default-horizontal.x-header-noborder {
    padding: 17px 17px 16px 17px;
}

.x-panel-header-default-horizontal.x-header-noborder .x-panel-header-default-tab-bar {
    margin-top: -17px;
    margin-bottom: -16px;
}

.x-panel-header-default-vertical {
    padding: 16px 16px 16px 16px;
}

.x-panel-header-default-vertical .x-panel-header-default-tab-bar {
    margin-right: -16px;
    margin-left: -16px;
}

.x-panel-header-default-vertical.x-header-noborder {
    padding: 17px 17px 17px 16px;
}

.x-panel-header-default-vertical.x-header-noborder .x-panel-header-default-tab-bar {
    margin-right: -17px;
    margin-left: -16px;
}

.x-rtl.x-panel-header-default-vertical {
    padding: 16px 16px 16px 16px;
}

.x-rtl.x-panel-header-default-vertical .x-panel-header-default-tab-bar {
    margin-left: -16px;
    margin-right: -16px;
}

.x-rtl.x-panel-header-default-vertical.x-header-noborder {
    padding: 17px 16px 17px 17px;
}

.x-rtl.x-panel-header-default-vertical.x-header-noborder .x-panel-header-default-tab-bar {
    margin-left: -17px;
    margin-right: -16px;
}

.x-panel-header-title-default {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 22px;
}

.x-keyboard-mode .x-panel-header-title-default.x-title-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-title-default.x-title-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-title-default.x-title-focus,
.x-edge .x-keyboard-mode .x-panel-header-title-default.x-title-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-title-default.x-title-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-title-default.x-title-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-title-default.x-title-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-panel-header-title-default > .x-title-text-default {
    text-transform: none;
    padding: 0;
}

.x-panel-header-title-default > .x-title-text-default:after {
    font-size: 40.5px;
    content: 'd';
    position: absolute;
    top: 0;
    right: 0;
    color: #7ac0f8;
}

.x-panel-header-title-default > .x-title-text-default.x-rtl:after {
    right: auto;
    left: 0;
}

.x-panel-header-title-default > .x-title-icon-wrap-default.x-title-icon-top {
    height: 24px;
    padding-bottom: 8px;
}

.x-panel-header-title-default > .x-title-icon-wrap-default.x-title-icon-right {
    width: 24px;
    padding-left: 8px;
}

.x-panel-header-title-default > .x-title-icon-wrap-default.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 8px;
}

.x-panel-header-title-default > .x-title-icon-wrap-default.x-title-icon-bottom {
    height: 24px;
    padding-top: 8px;
}

.x-panel-header-title-default > .x-title-icon-wrap-default.x-title-icon-left {
    width: 24px;
    padding-right: 8px;
}

.x-panel-header-title-default > .x-title-icon-wrap-default.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-panel-header-title-default > .x-title-icon-wrap-default > .x-title-icon-default {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: #fff;
    background-position: center center;
}

.x-ie8 .x-panel-header-title-default > .x-title-icon-wrap-default > .x-title-icon-default.x-title-glyph {
    color: #fff;
}

.x-panel-body-default {
    background: #fff;
    border-color: #d0d0d0;
    color: #111;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    border-width: 1px;
    border-style: solid;
}

.x-panel-header-default {
    background-image: none;
    background-color: #2196f3;
}

.x-panel-header-default-vertical {
    background-image: none;
    background-color: #2196f3;
}

.x-rtl.x-panel-header-default-vertical {
    background-image: none;
    background-color: #2196f3;
}

.x-panel .x-panel-header-default-collapsed-border-top {
    border-bottom-width: 1px !important;
}

.x-panel .x-panel-header-default-collapsed-border-right {
    border-left-width: 1px !important;
}

.x-panel .x-panel-header-default-collapsed-border-bottom {
    border-top-width: 1px !important;
}

.x-panel .x-panel-header-default-collapsed-border-left {
    border-right-width: 1px !important;
}

.x-panel-header-default-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-panel-header-default-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-panel-header-default-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-panel-header-default-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-panel-header-default-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-default-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-default-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-panel-header-default-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-panel-header-default .x-tool-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-default .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-default .x-tool-focus,
.x-edge .x-keyboard-mode .x-panel-header-default .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-default .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-default .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-default .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-rtl.x-panel-header-default-collapsed-border-right {
    border-right-width: 1px !important;
}

.x-rtl.x-panel-header-default-collapsed-border-left {
    border-left-width: 1px !important;
}

.x-panel-default-resizable .x-panel-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-panel-default-outer-border-l {
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-b {
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-outer-border-bl {
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-r {
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
}

.x-panel-default-outer-border-rl {
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-rb {
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-outer-border-rbl {
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-t {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
}

.x-panel-default-outer-border-tl {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-tb {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-outer-border-tbl {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-tr {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
}

.x-panel-default-outer-border-trl {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-trb {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-outer-border-trbl {
    border-color: #2196f3 !important;
    border-width: 1px !important;
}

.x-panel-default-framed {
    border-color: #e1e1e1;
    padding: 0;
}

.x-panel-default-framed.x-masked {
    border-color: #f0f0f0;
}

.x-panel-header-default-framed {
    font-size: 18px;
    border: 1px solid #e1e1e1;
}

.x-panel-header-default-framed .x-tool-tool-el {
    background-color: #2196f3;
}

.x-panel-header-default-framed-horizontal {
    padding: 16px 16px 15px 16px;
}

.x-panel-header-default-framed-horizontal .x-panel-header-default-framed-tab-bar {
    margin-top: -16px;
    margin-bottom: -15px;
}

.x-panel-header-default-framed-horizontal.x-header-noborder {
    padding: 17px 17px 15px 17px;
}

.x-panel-header-default-framed-horizontal.x-header-noborder .x-panel-header-default-framed-tab-bar {
    margin-top: -17px;
    margin-bottom: -15px;
}

.x-panel-header-default-framed-vertical {
    padding: 16px 16px 16px 15px;
}

.x-panel-header-default-framed-vertical .x-panel-header-default-framed-tab-bar {
    margin-right: -16px;
    margin-left: -15px;
}

.x-panel-header-default-framed-vertical.x-header-noborder {
    padding: 17px 17px 17px 15px;
}

.x-panel-header-default-framed-vertical.x-header-noborder .x-panel-header-default-framed-tab-bar {
    margin-right: -17px;
    margin-left: -15px;
}

.x-rtl.x-panel-header-default-framed-vertical {
    padding: 16px 15px 16px 16px;
}

.x-rtl.x-panel-header-default-framed-vertical .x-panel-header-default-framed-tab-bar {
    margin-left: -16px;
    margin-right: -15px;
}

.x-rtl.x-panel-header-default-framed-vertical.x-header-noborder {
    padding: 17px 15px 17px 17px;
}

.x-rtl.x-panel-header-default-framed-vertical.x-header-noborder .x-panel-header-default-framed-tab-bar {
    margin-left: -17px;
    margin-right: -15px;
}

.x-panel-header-title-default-framed {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 22px;
}

.x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus,
.x-edge .x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-panel-header-title-default-framed > .x-title-text-default-framed {
    text-transform: none;
    padding: 0;
}

.x-panel-header-title-default-framed > .x-title-text-default-framed:after {
    font-size: 39.75px;
    content: 'd';
    position: absolute;
    top: 0;
    right: 0;
    color: #7ac0f8;
}

.x-panel-header-title-default-framed > .x-title-text-default-framed.x-rtl:after {
    right: auto;
    left: 0;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed.x-title-icon-top {
    height: 24px;
    padding-bottom: 8px;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed.x-title-icon-right {
    width: 24px;
    padding-left: 8px;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 8px;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed.x-title-icon-bottom {
    height: 24px;
    padding-top: 8px;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed.x-title-icon-left {
    width: 24px;
    padding-right: 8px;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed > .x-title-icon-default-framed {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: #fff;
    background-position: center center;
}

.x-ie8 .x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed > .x-title-icon-default-framed.x-title-glyph {
    color: #fff;
}

.x-panel-body-default-framed {
    background: #fff;
    border-color: #d0d0d0;
    color: #111;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    border-width: 1px;
    border-style: solid;
}

.x-panel-default-framed {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 0 0 0;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-panel-default-framed-mc {
    background-color: #fff;
}

.x-nbr .x-panel-default-framed {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-default-framed-frameInfo {
    font-family: dh-1-1-1-1-1-1-1-1-0px-0px-0px-0px;
}

.x-panel-default-framed-tl {
    background-position: 0 -2px;
}

.x-panel-default-framed-tr {
    background-position: right -3px;
}

.x-panel-default-framed-bl {
    background-position: 0 -4px;
}

.x-panel-default-framed-br {
    background-position: right -5px;
}

.x-panel-default-framed-ml {
    background-position: 0 top;
}

.x-panel-default-framed-mr {
    background-position: right top;
}

.x-panel-default-framed-tc {
    background-position: 0 0;
}

.x-panel-default-framed-bc {
    background-position: 0 -1px;
}

.x-panel-default-framed-tr,
.x-panel-default-framed-br,
.x-panel-default-framed-mr {
    padding-right: 1px;
}

.x-panel-default-framed-tl,
.x-panel-default-framed-bl,
.x-panel-default-framed-ml {
    padding-left: 1px;
}

.x-panel-default-framed-tc {
    height: 1px;
}

.x-panel-default-framed-bc {
    height: 1px;
}

.x-panel-default-framed-tl,
.x-panel-default-framed-bl,
.x-panel-default-framed-tr,
.x-panel-default-framed-br,
.x-panel-default-framed-tc,
.x-panel-default-framed-bc,
.x-panel-default-framed-ml,
.x-panel-default-framed-mr {
    /*background-image: url(./../images/panel/panel-default-framed-corners.gif);*/
}

.x-panel-default-framed-ml,
.x-panel-default-framed-mr {
    /*background-image: url(./../images/panel/panel-default-framed-sides.gif);*/
    background-repeat: repeat-y;
}

.x-panel-default-framed-mc {
    padding: 0 0 0 0;
}

.x-panel-header-default-framed-top {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 15px 16px;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    background-color: #2196f3;
}

.x-panel-header-default-framed-top-mc {
    background-color: #2196f3;
}

.x-nbr .x-panel-header-default-framed-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-top-frameInfo {
    font-family: dh-1-1-0-1-1-1-0-1-16-16-15-16;
}

.x-panel-header-default-framed-top-tl {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-top-tr {
    background-position: right -3px;
}

.x-panel-header-default-framed-top-bl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-top-br {
    background-position: right -5px;
}

.x-panel-header-default-framed-top-ml {
    background-position: 0 top;
}

.x-panel-header-default-framed-top-mr {
    background-position: right top;
}

.x-panel-header-default-framed-top-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-top-bc {
    background-position: 0 -1px;
}

.x-panel-header-default-framed-top-tr,
.x-panel-header-default-framed-top-br,
.x-panel-header-default-framed-top-mr {
    padding-right: 1px;
}

.x-panel-header-default-framed-top-tl,
.x-panel-header-default-framed-top-bl,
.x-panel-header-default-framed-top-ml {
    padding-left: 1px;
}

.x-panel-header-default-framed-top-tc {
    height: 1px;
}

.x-panel-header-default-framed-top-bc {
    height: 0;
}

.x-panel-header-default-framed-top-tl,
.x-panel-header-default-framed-top-bl,
.x-panel-header-default-framed-top-tr,
.x-panel-header-default-framed-top-br,
.x-panel-header-default-framed-top-tc,
.x-panel-header-default-framed-top-bc,
.x-panel-header-default-framed-top-ml,
.x-panel-header-default-framed-top-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-top-corners.gif);*/
}

.x-panel-header-default-framed-top-ml,
.x-panel-header-default-framed-top-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-top-sides.gif);*/
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-top-mc {
    padding: 16px 16px 15px 16px;
}

.x-panel-header-default-framed-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 16px 15px;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    background-color: #2196f3;
}

.x-rtl.x-panel-header-default-framed-right {
    background-image: none;
    background-color: #2196f3;
}

.x-panel-header-default-framed-right-mc {
    background-color: #2196f3;
}

.x-nbr .x-panel-header-default-framed-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-right-frameInfo {
    font-family: dh-1-1-1-0-1-1-1-0-16-16-16-15;
}

.x-panel-header-default-framed-right-tl {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-right-tr {
    background-position: right -3px;
}

.x-panel-header-default-framed-right-bl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-right-br {
    background-position: right -5px;
}

.x-panel-header-default-framed-right-ml {
    background-position: right 0;
}

.x-panel-header-default-framed-right-mr {
    background-position: right 0;
}

.x-panel-header-default-framed-right-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-right-bc {
    background-position: 0 -1px;
}

.x-panel-header-default-framed-right-tr,
.x-panel-header-default-framed-right-br,
.x-panel-header-default-framed-right-mr {
    padding-right: 1px;
}

.x-panel-header-default-framed-right-tl,
.x-panel-header-default-framed-right-bl,
.x-panel-header-default-framed-right-ml {
    padding-left: 0;
}

.x-panel-header-default-framed-right-tc {
    height: 1px;
}

.x-panel-header-default-framed-right-bc {
    height: 1px;
}

.x-panel-header-default-framed-right-tl,
.x-panel-header-default-framed-right-bl,
.x-panel-header-default-framed-right-tr,
.x-panel-header-default-framed-right-br,
.x-panel-header-default-framed-right-tc,
.x-panel-header-default-framed-right-bc,
.x-panel-header-default-framed-right-ml,
.x-panel-header-default-framed-right-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-right-corners.gif);*/
}

.x-rtl.x-panel-header-default-framed-right-tl,
.x-rtl.x-panel-header-default-framed-right-ml,
.x-rtl.x-panel-header-default-framed-right-bl,
.x-rtl.x-panel-header-default-framed-right-tr,
.x-rtl.x-panel-header-default-framed-right-mr,
.x-rtl.x-panel-header-default-framed-right-br {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-right-corners-rtl.gif);*/
}

.x-panel-header-default-framed-right-ml,
.x-panel-header-default-framed-right-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-right-sides.gif);*/
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-right-mc {
    padding: 16px 16px 16px 15px;
}

.x-panel-header-default-framed-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 15px 16px;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    background-color: #2196f3;
}

.x-panel-header-default-framed-bottom-mc {
    background-color: #2196f3;
}

.x-nbr .x-panel-header-default-framed-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-bottom-frameInfo {
    font-family: dh-0-1-1-1-0-1-1-1-16-16-15-16;
}

.x-panel-header-default-framed-bottom-tl {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-bottom-tr {
    background-position: right -3px;
}

.x-panel-header-default-framed-bottom-bl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-bottom-br {
    background-position: right -5px;
}

.x-panel-header-default-framed-bottom-ml {
    background-position: 0 bottom;
}

.x-panel-header-default-framed-bottom-mr {
    background-position: right bottom;
}

.x-panel-header-default-framed-bottom-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-bottom-bc {
    background-position: 0 -1px;
}

.x-panel-header-default-framed-bottom-tr,
.x-panel-header-default-framed-bottom-br,
.x-panel-header-default-framed-bottom-mr {
    padding-right: 1px;
}

.x-panel-header-default-framed-bottom-tl,
.x-panel-header-default-framed-bottom-bl,
.x-panel-header-default-framed-bottom-ml {
    padding-left: 1px;
}

.x-panel-header-default-framed-bottom-tc {
    height: 0;
}

.x-panel-header-default-framed-bottom-bc {
    height: 1px;
}

.x-panel-header-default-framed-bottom-tl,
.x-panel-header-default-framed-bottom-bl,
.x-panel-header-default-framed-bottom-tr,
.x-panel-header-default-framed-bottom-br,
.x-panel-header-default-framed-bottom-tc,
.x-panel-header-default-framed-bottom-bc,
.x-panel-header-default-framed-bottom-ml,
.x-panel-header-default-framed-bottom-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-bottom-corners.gif);*/
}

.x-panel-header-default-framed-bottom-ml,
.x-panel-header-default-framed-bottom-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-bottom-sides.gif);*/
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-bottom-mc {
    padding: 16px 16px 15px 16px;
}

.x-panel-header-default-framed-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 16px 15px;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    background-color: #2196f3;
}

.x-rtl.x-panel-header-default-framed-left {
    background-image: none;
    background-color: #2196f3;
}

.x-panel-header-default-framed-left-mc {
    background-color: #2196f3;
}

.x-nbr .x-panel-header-default-framed-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-left-frameInfo {
    font-family: dh-1-0-1-1-1-0-1-1-16-16-16-15;
}

.x-panel-header-default-framed-left-tl {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-left-tr {
    background-position: right -3px;
}

.x-panel-header-default-framed-left-bl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-left-br {
    background-position: right -5px;
}

.x-panel-header-default-framed-left-ml {
    background-position: left 0;
}

.x-panel-header-default-framed-left-mr {
    background-position: left 0;
}

.x-panel-header-default-framed-left-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-left-bc {
    background-position: 0 -1px;
}

.x-panel-header-default-framed-left-tr,
.x-panel-header-default-framed-left-br,
.x-panel-header-default-framed-left-mr {
    padding-right: 0;
}

.x-panel-header-default-framed-left-tl,
.x-panel-header-default-framed-left-bl,
.x-panel-header-default-framed-left-ml {
    padding-left: 1px;
}

.x-panel-header-default-framed-left-tc {
    height: 1px;
}

.x-panel-header-default-framed-left-bc {
    height: 1px;
}

.x-panel-header-default-framed-left-tl,
.x-panel-header-default-framed-left-bl,
.x-panel-header-default-framed-left-tr,
.x-panel-header-default-framed-left-br,
.x-panel-header-default-framed-left-tc,
.x-panel-header-default-framed-left-bc,
.x-panel-header-default-framed-left-ml,
.x-panel-header-default-framed-left-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-left-corners.gif);*/
}

.x-rtl.x-panel-header-default-framed-left-tl,
.x-rtl.x-panel-header-default-framed-left-ml,
.x-rtl.x-panel-header-default-framed-left-bl,
.x-rtl.x-panel-header-default-framed-left-tr,
.x-rtl.x-panel-header-default-framed-left-mr,
.x-rtl.x-panel-header-default-framed-left-br {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-left-corners-rtl.gif);*/
}

.x-panel-header-default-framed-left-ml,
.x-panel-header-default-framed-left-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-left-sides.gif);*/
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-left-mc {
    padding: 16px 16px 16px 15px;
}

.x-panel-header-default-framed-collapsed-top {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 15px 16px;
    border-width: 1px;
    border-style: solid;
    background-color: #2196f3;
}

.x-panel-header-default-framed-collapsed-top-mc {
    background-color: #2196f3;
}

.x-nbr .x-panel-header-default-framed-collapsed-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-collapsed-top-frameInfo {
    font-family: dh-1-1-1-1-1-1-1-1-16-16-15-16;
}

.x-panel-header-default-framed-collapsed-top-tl {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-collapsed-top-tr {
    background-position: right -3px;
}

.x-panel-header-default-framed-collapsed-top-bl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-collapsed-top-br {
    background-position: right -5px;
}

.x-panel-header-default-framed-collapsed-top-ml {
    background-position: 0 top;
}

.x-panel-header-default-framed-collapsed-top-mr {
    background-position: right top;
}

.x-panel-header-default-framed-collapsed-top-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-collapsed-top-bc {
    background-position: 0 -1px;
}

.x-panel-header-default-framed-collapsed-top-tr,
.x-panel-header-default-framed-collapsed-top-br,
.x-panel-header-default-framed-collapsed-top-mr {
    padding-right: 1px;
}

.x-panel-header-default-framed-collapsed-top-tl,
.x-panel-header-default-framed-collapsed-top-bl,
.x-panel-header-default-framed-collapsed-top-ml {
    padding-left: 1px;
}

.x-panel-header-default-framed-collapsed-top-tc {
    height: 1px;
}

.x-panel-header-default-framed-collapsed-top-bc {
    height: 1px;
}

.x-panel-header-default-framed-collapsed-top-tl,
.x-panel-header-default-framed-collapsed-top-bl,
.x-panel-header-default-framed-collapsed-top-tr,
.x-panel-header-default-framed-collapsed-top-br,
.x-panel-header-default-framed-collapsed-top-tc,
.x-panel-header-default-framed-collapsed-top-bc,
.x-panel-header-default-framed-collapsed-top-ml,
.x-panel-header-default-framed-collapsed-top-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-collapsed-top-corners.gif);*/
}

.x-panel-header-default-framed-collapsed-top-ml,
.x-panel-header-default-framed-collapsed-top-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-collapsed-top-sides.gif);*/
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-collapsed-top-mc {
    padding: 16px 16px 15px 16px;
}

.x-panel-header-default-framed-collapsed-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 16px 15px;
    border-width: 1px;
    border-style: solid;
    background-color: #2196f3;
}

.x-rtl.x-panel-header-default-framed-collapsed-right {
    background-image: none;
    background-color: #2196f3;
}

.x-panel-header-default-framed-collapsed-right-mc {
    background-color: #2196f3;
}

.x-nbr .x-panel-header-default-framed-collapsed-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-collapsed-right-frameInfo {
    font-family: dh-1-1-1-1-1-1-1-1-16-16-16-15;
}

.x-panel-header-default-framed-collapsed-right-tl {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-collapsed-right-tr {
    background-position: right -3px;
}

.x-panel-header-default-framed-collapsed-right-bl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-collapsed-right-br {
    background-position: right -5px;
}

.x-panel-header-default-framed-collapsed-right-ml {
    background-position: right 0;
}

.x-panel-header-default-framed-collapsed-right-mr {
    background-position: right 0;
}

.x-panel-header-default-framed-collapsed-right-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-collapsed-right-bc {
    background-position: 0 -1px;
}

.x-panel-header-default-framed-collapsed-right-tr,
.x-panel-header-default-framed-collapsed-right-br,
.x-panel-header-default-framed-collapsed-right-mr {
    padding-right: 1px;
}

.x-panel-header-default-framed-collapsed-right-tl,
.x-panel-header-default-framed-collapsed-right-bl,
.x-panel-header-default-framed-collapsed-right-ml {
    padding-left: 1px;
}

.x-panel-header-default-framed-collapsed-right-tc {
    height: 1px;
}

.x-panel-header-default-framed-collapsed-right-bc {
    height: 1px;
}

.x-panel-header-default-framed-collapsed-right-tl,
.x-panel-header-default-framed-collapsed-right-bl,
.x-panel-header-default-framed-collapsed-right-tr,
.x-panel-header-default-framed-collapsed-right-br,
.x-panel-header-default-framed-collapsed-right-tc,
.x-panel-header-default-framed-collapsed-right-bc,
.x-panel-header-default-framed-collapsed-right-ml,
.x-panel-header-default-framed-collapsed-right-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-collapsed-right-corners.gif);*/
}

.x-rtl.x-panel-header-default-framed-collapsed-right-tl,
.x-rtl.x-panel-header-default-framed-collapsed-right-ml,
.x-rtl.x-panel-header-default-framed-collapsed-right-bl,
.x-rtl.x-panel-header-default-framed-collapsed-right-tr,
.x-rtl.x-panel-header-default-framed-collapsed-right-mr,
.x-rtl.x-panel-header-default-framed-collapsed-right-br {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-collapsed-right-corners-rtl.gif);*/
}

.x-panel-header-default-framed-collapsed-right-ml,
.x-panel-header-default-framed-collapsed-right-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-collapsed-right-sides.gif);*/
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-collapsed-right-mc {
    padding: 16px 16px 16px 15px;
}

.x-panel-header-default-framed-collapsed-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 15px 16px;
    border-width: 1px;
    border-style: solid;
    background-color: #2196f3;
}

.x-panel-header-default-framed-collapsed-bottom-mc {
    background-color: #2196f3;
}

.x-nbr .x-panel-header-default-framed-collapsed-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-collapsed-bottom-frameInfo {
    font-family: dh-1-1-1-1-1-1-1-1-16-16-15-16;
}

.x-panel-header-default-framed-collapsed-bottom-tl {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-collapsed-bottom-tr {
    background-position: right -3px;
}

.x-panel-header-default-framed-collapsed-bottom-bl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-collapsed-bottom-br {
    background-position: right -5px;
}

.x-panel-header-default-framed-collapsed-bottom-ml {
    background-position: 0 bottom;
}

.x-panel-header-default-framed-collapsed-bottom-mr {
    background-position: right bottom;
}

.x-panel-header-default-framed-collapsed-bottom-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-collapsed-bottom-bc {
    background-position: 0 -1px;
}

.x-panel-header-default-framed-collapsed-bottom-tr,
.x-panel-header-default-framed-collapsed-bottom-br,
.x-panel-header-default-framed-collapsed-bottom-mr {
    padding-right: 1px;
}

.x-panel-header-default-framed-collapsed-bottom-tl,
.x-panel-header-default-framed-collapsed-bottom-bl,
.x-panel-header-default-framed-collapsed-bottom-ml {
    padding-left: 1px;
}

.x-panel-header-default-framed-collapsed-bottom-tc {
    height: 1px;
}

.x-panel-header-default-framed-collapsed-bottom-bc {
    height: 1px;
}

.x-panel-header-default-framed-collapsed-bottom-tl,
.x-panel-header-default-framed-collapsed-bottom-bl,
.x-panel-header-default-framed-collapsed-bottom-tr,
.x-panel-header-default-framed-collapsed-bottom-br,
.x-panel-header-default-framed-collapsed-bottom-tc,
.x-panel-header-default-framed-collapsed-bottom-bc,
.x-panel-header-default-framed-collapsed-bottom-ml,
.x-panel-header-default-framed-collapsed-bottom-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-collapsed-bottom-corners.gif);*/
}

.x-panel-header-default-framed-collapsed-bottom-ml,
.x-panel-header-default-framed-collapsed-bottom-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-collapsed-bottom-sides.gif);*/
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-collapsed-bottom-mc {
    padding: 16px 16px 15px 16px;
}

.x-panel-header-default-framed-collapsed-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 16px 15px;
    border-width: 1px;
    border-style: solid;
    background-color: #2196f3;
}

.x-rtl.x-panel-header-default-framed-collapsed-left {
    background-image: none;
    background-color: #2196f3;
}

.x-panel-header-default-framed-collapsed-left-mc {
    background-color: #2196f3;
}

.x-nbr .x-panel-header-default-framed-collapsed-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-collapsed-left-frameInfo {
    font-family: dh-1-1-1-1-1-1-1-1-16-16-16-15;
}

.x-panel-header-default-framed-collapsed-left-tl {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-collapsed-left-tr {
    background-position: right -3px;
}

.x-panel-header-default-framed-collapsed-left-bl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-collapsed-left-br {
    background-position: right -5px;
}

.x-panel-header-default-framed-collapsed-left-ml {
    background-position: left 0;
}

.x-panel-header-default-framed-collapsed-left-mr {
    background-position: left 0;
}

.x-panel-header-default-framed-collapsed-left-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-collapsed-left-bc {
    background-position: 0 -1px;
}

.x-panel-header-default-framed-collapsed-left-tr,
.x-panel-header-default-framed-collapsed-left-br,
.x-panel-header-default-framed-collapsed-left-mr {
    padding-right: 1px;
}

.x-panel-header-default-framed-collapsed-left-tl,
.x-panel-header-default-framed-collapsed-left-bl,
.x-panel-header-default-framed-collapsed-left-ml {
    padding-left: 1px;
}

.x-panel-header-default-framed-collapsed-left-tc {
    height: 1px;
}

.x-panel-header-default-framed-collapsed-left-bc {
    height: 1px;
}

.x-panel-header-default-framed-collapsed-left-tl,
.x-panel-header-default-framed-collapsed-left-bl,
.x-panel-header-default-framed-collapsed-left-tr,
.x-panel-header-default-framed-collapsed-left-br,
.x-panel-header-default-framed-collapsed-left-tc,
.x-panel-header-default-framed-collapsed-left-bc,
.x-panel-header-default-framed-collapsed-left-ml,
.x-panel-header-default-framed-collapsed-left-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-collapsed-left-corners.gif);*/
}

.x-rtl.x-panel-header-default-framed-collapsed-left-tl,
.x-rtl.x-panel-header-default-framed-collapsed-left-ml,
.x-rtl.x-panel-header-default-framed-collapsed-left-bl,
.x-rtl.x-panel-header-default-framed-collapsed-left-tr,
.x-rtl.x-panel-header-default-framed-collapsed-left-mr,
.x-rtl.x-panel-header-default-framed-collapsed-left-br {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-collapsed-left-corners-rtl.gif);*/
}

.x-panel-header-default-framed-collapsed-left-ml,
.x-panel-header-default-framed-collapsed-left-mr {
    /*background-image: url(./../images/panel-header/panel-header-default-framed-collapsed-left-sides.gif);*/
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-collapsed-left-mc {
    padding: 16px 16px 16px 15px;
}

.x-panel .x-panel-header-default-framed-top {
    border-bottom-width: 1px !important;
}

.x-panel .x-panel-header-default-framed-right {
    border-left-width: 1px !important;
}

.x-panel .x-panel-header-default-framed-bottom {
    border-top-width: 1px !important;
}

.x-panel .x-panel-header-default-framed-left {
    border-right-width: 1px !important;
}

.x-nbr .x-panel-header-default-framed-collapsed-top {
    border-bottom-width: 0 !important;
}

.x-nbr .x-panel-header-default-framed-collapsed-right {
    border-left-width: 0 !important;
}

.x-nbr .x-panel-header-default-framed-collapsed-bottom {
    border-top-width: 0 !important;
}

.x-nbr .x-panel-header-default-framed-collapsed-left {
    border-right-width: 0 !important;
}

.x-panel-header-default-framed-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-panel-header-default-framed-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-panel-header-default-framed-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-panel-header-default-framed-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-panel-header-default-framed-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-default-framed-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-default-framed-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-panel-header-default-framed-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-panel-header-default-framed .x-tool-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-default-framed .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-default-framed .x-tool-focus,
.x-edge .x-keyboard-mode .x-panel-header-default-framed .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-default-framed .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-default-framed .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-default-framed .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-rtl.x-panel-header-default-framed-collapsed-border-right {
    border-right-width: 1px !important;
}

.x-rtl.x-panel-header-default-framed-collapsed-border-left {
    border-left-width: 1px !important;
}

.x-panel-default-framed-resizable .x-panel-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-panel-default-framed-outer-border-l {
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-b {
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-framed-outer-border-bl {
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-r {
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
}

.x-panel-default-framed-outer-border-rl {
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-rb {
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-framed-outer-border-rbl {
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-t {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
}

.x-panel-default-framed-outer-border-tl {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-tb {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-framed-outer-border-tbl {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-tr {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
}

.x-panel-default-framed-outer-border-trl {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-trb {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-framed-outer-border-trbl {
    border-color: #2196f3 !important;
    border-width: 1px !important;
}

.x-btn-group-default {
    border-color: #d0d0d0;
    -webkit-box-shadow: #fff 0 1px 0 0 inset, #fff 0 -1px 0 0 inset, #fff -1px 0 0 0 inset, #fff 1px 0 0 0 inset;
    -moz-box-shadow: #fff 0 1px 0 0 inset, #fff 0 -1px 0 0 inset, #fff -1px 0 0 0 inset, #fff 1px 0 0 0 inset;
    box-shadow: #fff 0 1px 0 0 inset, #fff 0 -1px 0 0 inset, #fff -1px 0 0 0 inset, #fff 1px 0 0 0 inset;
}

.x-btn-group-header-default {
    padding: 8px 8px 7px;
    line-height: 16px;
    background: #fff;
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
}

.x-btn-group-header-default .x-tool-img {
    background-image: url(./../images/tools/tool-sprites-dark.png);
    background-color: #fff;
}

.x-btn-group-header-title-default {
    font: 400 14px 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 16px;
    color: rgba(17, 17, 17, 0.54);
}

.x-btn-group-body-default {
    padding: 0 1px;
}

.x-btn-group-body-default .x-table-layout {
    border-spacing: 6px;
}

.x-btn-group-default-framed {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 1px 1px 1px 1px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-btn-group-default-framed-mc {
    background-color: #fff;
}

.x-nbr .x-btn-group-default-framed {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-group-default-framed-frameInfo {
    font-family: dh-2-2-2-2-1-1-1-1-1-1-1-1;
}

.x-btn-group-default-framed-tl {
    background-position: 0 -4px;
}

.x-btn-group-default-framed-tr {
    background-position: right -6px;
}

.x-btn-group-default-framed-bl {
    background-position: 0 -8px;
}

.x-btn-group-default-framed-br {
    background-position: right -10px;
}

.x-btn-group-default-framed-ml {
    background-position: 0 top;
}

.x-btn-group-default-framed-mr {
    background-position: right top;
}

.x-btn-group-default-framed-tc {
    background-position: 0 0;
}

.x-btn-group-default-framed-bc {
    background-position: 0 -2px;
}

.x-btn-group-default-framed-tr,
.x-btn-group-default-framed-br,
.x-btn-group-default-framed-mr {
    padding-right: 2px;
}

.x-btn-group-default-framed-tl,
.x-btn-group-default-framed-bl,
.x-btn-group-default-framed-ml {
    padding-left: 2px;
}

.x-btn-group-default-framed-tc {
    height: 2px;
}

.x-btn-group-default-framed-bc {
    height: 2px;
}

.x-btn-group-default-framed-tl,
.x-btn-group-default-framed-bl,
.x-btn-group-default-framed-tr,
.x-btn-group-default-framed-br,
.x-btn-group-default-framed-tc,
.x-btn-group-default-framed-bc,
.x-btn-group-default-framed-ml,
.x-btn-group-default-framed-mr {
    /*background-image: url('./../images/btn-group/btn-group-default-framed-corners.gif');*/
}

.x-btn-group-default-framed-ml,
.x-btn-group-default-framed-mr {
    /*background-image: url(./../images/btn-group/btn-group-default-framed-sides.gif);*/
    background-repeat: repeat-y;
}

.x-btn-group-default-framed-mc {
    padding: 0 0 0 0;
}

.x-btn-group-default-framed-notitle {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 1px 1px 1px 1px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-btn-group-default-framed-notitle-mc {
    background-color: #fff;
}

.x-nbr .x-btn-group-default-framed-notitle {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-group-default-framed-notitle-frameInfo {
    font-family: dh-2-2-2-2-1-1-1-1-1-1-1-1;
}

.x-btn-group-default-framed-notitle-tl {
    background-position: 0 -4px;
}

.x-btn-group-default-framed-notitle-tr {
    background-position: right -6px;
}

.x-btn-group-default-framed-notitle-bl {
    background-position: 0 -8px;
}

.x-btn-group-default-framed-notitle-br {
    background-position: right -10px;
}

.x-btn-group-default-framed-notitle-ml {
    background-position: 0 top;
}

.x-btn-group-default-framed-notitle-mr {
    background-position: right top;
}

.x-btn-group-default-framed-notitle-tc {
    background-position: 0 0;
}

.x-btn-group-default-framed-notitle-bc {
    background-position: 0 -2px;
}

.x-btn-group-default-framed-notitle-tr,
.x-btn-group-default-framed-notitle-br,
.x-btn-group-default-framed-notitle-mr {
    padding-right: 2px;
}

.x-btn-group-default-framed-notitle-tl,
.x-btn-group-default-framed-notitle-bl,
.x-btn-group-default-framed-notitle-ml {
    padding-left: 2px;
}

.x-btn-group-default-framed-notitle-tc {
    height: 2px;
}

.x-btn-group-default-framed-notitle-bc {
    height: 2px;
}

.x-btn-group-default-framed-notitle-tl,
.x-btn-group-default-framed-notitle-bl,
.x-btn-group-default-framed-notitle-tr,
.x-btn-group-default-framed-notitle-br,
.x-btn-group-default-framed-notitle-tc,
.x-btn-group-default-framed-notitle-bc,
.x-btn-group-default-framed-notitle-ml,
.x-btn-group-default-framed-notitle-mr {
    /*background-image: url(./../images/btn-group/btn-group-default-framed-notitle-corners.gif);*/
}

.x-btn-group-default-framed-notitle-ml,
.x-btn-group-default-framed-notitle-mr {
    /*background-image: url(./../images/btn-group/btn-group-default-framed-notitle-sides.gif);*/
    background-repeat: repeat-y;
}

.x-btn-group-default-framed-notitle-mc {
    padding: 0 0 0 0;
}

.x-btn-group-default-framed {
    border-color: #d0d0d0;
    -webkit-box-shadow: #fff 0 1px 0 0 inset, #fff 0 -1px 0 0 inset, #fff -1px 0 0 0 inset, #fff 1px 0 0 0 inset;
    -moz-box-shadow: #fff 0 1px 0 0 inset, #fff 0 -1px 0 0 inset, #fff -1px 0 0 0 inset, #fff 1px 0 0 0 inset;
    box-shadow: #fff 0 1px 0 0 inset, #fff 0 -1px 0 0 inset, #fff -1px 0 0 0 inset, #fff 1px 0 0 0 inset;
}

.x-btn-group-header-default-framed {
    padding: 8px 8px 7px;
    line-height: 16px;
    background: #fff;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
}

.x-btn-group-header-default-framed .x-tool-img {
    background-image: url(./../images/tools/tool-sprites-dark.png);
    background-color: #fff;
}

.x-btn-group-header-title-default-framed {
    font: 400 14px 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 16px;
    color: rgba(17, 17, 17, 0.54);
}

.x-btn-group-body-default-framed {
    padding: 0 1px;
}

.x-btn-group-body-default-framed .x-table-layout {
    border-spacing: 6px;
}

.x-dashboard-column {
    padding: 0 0 7px 0;
}

.x-dashboard-panel {
    margin-top: 7px;
}

.x-dashboard-column-first {
    padding-left: 4px;
    clear: left;
}

.x-dashboard-column-last {
    padding-right: 4px;
}

.x-dashboard .x-panel-dd-spacer {
    border: 2px dashed #99bbe8;
    background: #f6f6f6;
    border-radius: 4px;
    -moz-border-radius: 4px;
    margin-top: 7px;
}

.x-panel .x-dashboard {
    padding: 0 3px;
}

.x-dashboard-dd-over {
    overflow: hidden !important;
}

.x-window-ghost {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-window-default {
    border-color: #2196f3;
    -webkit-border-radius: 0 0 0 0;
    -moz-border-radius: 0 0 0 0;
    -ms-border-radius: 0 0 0 0;
    -o-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0;
}

.x-window-default {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 0 0 0;
    border-width: 0;
    border-style: solid;
    background-color: #fff;
}

.x-window-default-mc {
    background-color: #fff;
}

.x-nbr .x-window-default {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-default-frameInfo {
    font-family: dh-0-0-0-0-0-0-0-0-0-0-0-0;
}

.x-window-default-tl {
    background-position: 0 0;
}

.x-window-default-tr {
    background-position: right 0;
}

.x-window-default-bl {
    background-position: 0 0;
}

.x-window-default-br {
    background-position: right 0;
}

.x-window-default-ml {
    background-position: 0 top;
}

.x-window-default-mr {
    background-position: right top;
}

.x-window-default-tc {
    background-position: 0 0;
}

.x-window-default-bc {
    background-position: 0 0;
}

.x-window-default-tr,
.x-window-default-br,
.x-window-default-mr {
    padding-right: 0;
}

.x-window-default-tl,
.x-window-default-bl,
.x-window-default-ml {
    padding-left: 0;
}

.x-window-default-tc {
    height: 0;
}

.x-window-default-bc {
    height: 0;
}

.x-window-default-tl,
.x-window-default-bl,
.x-window-default-tr,
.x-window-default-br,
.x-window-default-tc,
.x-window-default-bc,
.x-window-default-ml,
.x-window-default-mr {
    /*background-image: url(./../images/window/window-default-corners.gif);*/
}

.x-window-default-ml,
.x-window-default-mr {
    /*background-image: url(./../images/window/window-default-sides.gif);*/
    background-repeat: repeat-y;
}

.x-window-default-mc {
    padding: 0 0 0 0;
}

.x-window-body-default {
    border-color: #2196f3;
    border-width: 1px;
    border-style: solid;
    background: #fff;
    color: #000;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
}

.x-window-header-default {
    font-size: 18px;
    border-color: #2196f3;
    background-color: transparent;
}

.x-window-header-default .x-tool-img {
    background-color: transparent;
}

.x-window-header-default-horizontal .x-window-header-default-tab-bar {
    margin-top: -16px;
    margin-bottom: -15px;
}

.x-window-header-default-vertical .x-window-header-default-tab-bar {
    margin-right: -16px;
    margin-left: -15px;
}

.x-rtl.x-window-header-default-vertical .x-window-header-default-tab-bar {
    margin-left: -16px;
    margin-right: -15px;
}

.x-window-header-title-default {
    color: #111;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 22px;
}

.x-window-header-title-default > .x-title-text-default {
    padding: 0;
    text-transform: none;
}

.x-window-header-title-default > .x-title-text-default:after {
    font-size: 39.75px;
    content: 'd';
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(17, 17, 17, 0.4);
}

.x-window-header-title-default > .x-title-text-default.x-rtl:after {
    right: auto;
    left: 0;
}

.x-window-header-title-default > .x-title-icon-wrap-default.x-title-icon-top {
    height: 24px;
    padding-bottom: 8px;
}

.x-window-header-title-default > .x-title-icon-wrap-default.x-title-icon-right {
    width: 24px;
    padding-left: 8px;
}

.x-window-header-title-default > .x-title-icon-wrap-default.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 8px;
}

.x-window-header-title-default > .x-title-icon-wrap-default.x-title-icon-bottom {
    height: 24px;
    padding-top: 8px;
}

.x-window-header-title-default > .x-title-icon-wrap-default.x-title-icon-left {
    width: 24px;
    padding-right: 8px;
}

.x-window-header-title-default > .x-title-icon-wrap-default.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-window-header-title-default > .x-title-icon-wrap-default > .x-title-icon-default {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: #111;
    background-position: center center;
}

.x-ie8 .x-window-header-title-default > .x-title-icon-wrap-default > .x-title-icon-default.x-title-glyph {
    color: #111;
}

.x-window-header-default-top {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 15px 16px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: transparent;
}

.x-window-header-default-top-mc {
    background-color: transparent;
}

.x-nbr .x-window-header-default-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-top-frameInfo {
    font-family: dh-0-0-0-0-0-0-0-0-16-16-15-16;
}

.x-window-header-default-top-tl {
    background-position: 0 0;
}

.x-window-header-default-top-tr {
    background-position: right 0;
}

.x-window-header-default-top-bl {
    background-position: 0 0;
}

.x-window-header-default-top-br {
    background-position: right 0;
}

.x-window-header-default-top-ml {
    background-position: 0 top;
}

.x-window-header-default-top-mr {
    background-position: right top;
}

.x-window-header-default-top-tc {
    background-position: 0 0;
}

.x-window-header-default-top-bc {
    background-position: 0 0;
}

.x-window-header-default-top-tr,
.x-window-header-default-top-br,
.x-window-header-default-top-mr {
    padding-right: 0;
}

.x-window-header-default-top-tl,
.x-window-header-default-top-bl,
.x-window-header-default-top-ml {
    padding-left: 0;
}

.x-window-header-default-top-tc {
    height: 0;
}

.x-window-header-default-top-bc {
    height: 0;
}

.x-window-header-default-top-mc {
    padding: 16px 16px 15px 16px;
}

.x-window-header-default-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 16px 15px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: transparent;
}

.x-window-header-default-right-mc {
    background-color: transparent;
}

.x-nbr .x-window-header-default-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-right-frameInfo {
    font-family: dh-0-0-0-0-0-0-0-0-16-16-16-15;
}

.x-window-header-default-right-tl {
    background-position: 0 0;
}

.x-window-header-default-right-tr {
    background-position: right 0;
}

.x-window-header-default-right-bl {
    background-position: 0 0;
}

.x-window-header-default-right-br {
    background-position: right 0;
}

.x-window-header-default-right-ml {
    background-position: 0 top;
}

.x-window-header-default-right-mr {
    background-position: right top;
}

.x-window-header-default-right-tc {
    background-position: 0 0;
}

.x-window-header-default-right-bc {
    background-position: 0 0;
}

.x-window-header-default-right-tr,
.x-window-header-default-right-br,
.x-window-header-default-right-mr {
    padding-right: 0;
}

.x-window-header-default-right-tl,
.x-window-header-default-right-bl,
.x-window-header-default-right-ml {
    padding-left: 0;
}

.x-window-header-default-right-tc {
    height: 0;
}

.x-window-header-default-right-bc {
    height: 0;
}

.x-window-header-default-right-mc {
    padding: 16px 16px 16px 15px;
}

.x-window-header-default-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 15px 16px 16px 16px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: transparent;
}

.x-window-header-default-bottom-mc {
    background-color: transparent;
}

.x-nbr .x-window-header-default-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-bottom-frameInfo {
    font-family: dh-0-0-0-0-0-0-0-0-15-16-16-16;
}

.x-window-header-default-bottom-tl {
    background-position: 0 0;
}

.x-window-header-default-bottom-tr {
    background-position: right 0;
}

.x-window-header-default-bottom-bl {
    background-position: 0 0;
}

.x-window-header-default-bottom-br {
    background-position: right 0;
}

.x-window-header-default-bottom-ml {
    background-position: 0 top;
}

.x-window-header-default-bottom-mr {
    background-position: right top;
}

.x-window-header-default-bottom-tc {
    background-position: 0 0;
}

.x-window-header-default-bottom-bc {
    background-position: 0 0;
}

.x-window-header-default-bottom-tr,
.x-window-header-default-bottom-br,
.x-window-header-default-bottom-mr {
    padding-right: 0;
}

.x-window-header-default-bottom-tl,
.x-window-header-default-bottom-bl,
.x-window-header-default-bottom-ml {
    padding-left: 0;
}

.x-window-header-default-bottom-tc {
    height: 0;
}

.x-window-header-default-bottom-bc {
    height: 0;
}

.x-window-header-default-bottom-mc {
    padding: 15px 16px 16px 16px;
}

.x-window-header-default-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 15px 16px 16px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: transparent;
}

.x-window-header-default-left-mc {
    background-color: transparent;
}

.x-nbr .x-window-header-default-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-left-frameInfo {
    font-family: dh-0-0-0-0-0-0-0-0-16-15-16-16;
}

.x-window-header-default-left-tl {
    background-position: 0 0;
}

.x-window-header-default-left-tr {
    background-position: right 0;
}

.x-window-header-default-left-bl {
    background-position: 0 0;
}

.x-window-header-default-left-br {
    background-position: right 0;
}

.x-window-header-default-left-ml {
    background-position: 0 top;
}

.x-window-header-default-left-mr {
    background-position: right top;
}

.x-window-header-default-left-tc {
    background-position: 0 0;
}

.x-window-header-default-left-bc {
    background-position: 0 0;
}

.x-window-header-default-left-tr,
.x-window-header-default-left-br,
.x-window-header-default-left-mr {
    padding-right: 0;
}

.x-window-header-default-left-tl,
.x-window-header-default-left-bl,
.x-window-header-default-left-ml {
    padding-left: 0;
}

.x-window-header-default-left-tc {
    height: 0;
}

.x-window-header-default-left-bc {
    height: 0;
}

.x-window-header-default-left-mc {
    padding: 16px 15px 16px 16px;
}

.x-window-header-default-collapsed-top {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 15px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-window-header-default-collapsed-top-mc {
    background-color: transparent;
}

.x-nbr .x-window-header-default-collapsed-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-collapsed-top-frameInfo {
    font-family: dh-0-0-0-0-0-0-0-0-16-16-15-16;
}

.x-window-header-default-collapsed-top-tl {
    background-position: 0 0;
}

.x-window-header-default-collapsed-top-tr {
    background-position: right 0;
}

.x-window-header-default-collapsed-top-bl {
    background-position: 0 0;
}

.x-window-header-default-collapsed-top-br {
    background-position: right 0;
}

.x-window-header-default-collapsed-top-ml {
    background-position: 0 top;
}

.x-window-header-default-collapsed-top-mr {
    background-position: right top;
}

.x-window-header-default-collapsed-top-tc {
    background-position: 0 0;
}

.x-window-header-default-collapsed-top-bc {
    background-position: 0 0;
}

.x-window-header-default-collapsed-top-tr,
.x-window-header-default-collapsed-top-br,
.x-window-header-default-collapsed-top-mr {
    padding-right: 0;
}

.x-window-header-default-collapsed-top-tl,
.x-window-header-default-collapsed-top-bl,
.x-window-header-default-collapsed-top-ml {
    padding-left: 0;
}

.x-window-header-default-collapsed-top-tc {
    height: 0;
}

.x-window-header-default-collapsed-top-bc {
    height: 0;
}

.x-window-header-default-collapsed-top-mc {
    padding: 16px 16px 15px 16px;
}

.x-window-header-default-collapsed-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 16px 15px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-window-header-default-collapsed-right-mc {
    background-color: transparent;
}

.x-nbr .x-window-header-default-collapsed-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-collapsed-right-frameInfo {
    font-family: dh-0-0-0-0-0-0-0-0-16-16-16-15;
}

.x-window-header-default-collapsed-right-tl {
    background-position: 0 0;
}

.x-window-header-default-collapsed-right-tr {
    background-position: right 0;
}

.x-window-header-default-collapsed-right-bl {
    background-position: 0 0;
}

.x-window-header-default-collapsed-right-br {
    background-position: right 0;
}

.x-window-header-default-collapsed-right-ml {
    background-position: 0 top;
}

.x-window-header-default-collapsed-right-mr {
    background-position: right top;
}

.x-window-header-default-collapsed-right-tc {
    background-position: 0 0;
}

.x-window-header-default-collapsed-right-bc {
    background-position: 0 0;
}

.x-window-header-default-collapsed-right-tr,
.x-window-header-default-collapsed-right-br,
.x-window-header-default-collapsed-right-mr {
    padding-right: 0;
}

.x-window-header-default-collapsed-right-tl,
.x-window-header-default-collapsed-right-bl,
.x-window-header-default-collapsed-right-ml {
    padding-left: 0;
}

.x-window-header-default-collapsed-right-tc {
    height: 0;
}

.x-window-header-default-collapsed-right-bc {
    height: 0;
}

.x-window-header-default-collapsed-right-mc {
    padding: 16px 16px 16px 15px;
}

.x-window-header-default-collapsed-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 15px 16px 16px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-window-header-default-collapsed-bottom-mc {
    background-color: transparent;
}

.x-nbr .x-window-header-default-collapsed-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-collapsed-bottom-frameInfo {
    font-family: dh-0-0-0-0-0-0-0-0-15-16-16-16;
}

.x-window-header-default-collapsed-bottom-tl {
    background-position: 0 0;
}

.x-window-header-default-collapsed-bottom-tr {
    background-position: right 0;
}

.x-window-header-default-collapsed-bottom-bl {
    background-position: 0 0;
}

.x-window-header-default-collapsed-bottom-br {
    background-position: right 0;
}

.x-window-header-default-collapsed-bottom-ml {
    background-position: 0 top;
}

.x-window-header-default-collapsed-bottom-mr {
    background-position: right top;
}

.x-window-header-default-collapsed-bottom-tc {
    background-position: 0 0;
}

.x-window-header-default-collapsed-bottom-bc {
    background-position: 0 0;
}

.x-window-header-default-collapsed-bottom-tr,
.x-window-header-default-collapsed-bottom-br,
.x-window-header-default-collapsed-bottom-mr {
    padding-right: 0;
}

.x-window-header-default-collapsed-bottom-tl,
.x-window-header-default-collapsed-bottom-bl,
.x-window-header-default-collapsed-bottom-ml {
    padding-left: 0;
}

.x-window-header-default-collapsed-bottom-tc {
    height: 0;
}

.x-window-header-default-collapsed-bottom-bc {
    height: 0;
}

.x-window-header-default-collapsed-bottom-mc {
    padding: 15px 16px 16px 16px;
}

.x-window-header-default-collapsed-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 15px 16px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-window-header-default-collapsed-left-mc {
    background-color: transparent;
}

.x-nbr .x-window-header-default-collapsed-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-collapsed-left-frameInfo {
    font-family: dh-0-0-0-0-0-0-0-0-16-15-16-16;
}

.x-window-header-default-collapsed-left-tl {
    background-position: 0 0;
}

.x-window-header-default-collapsed-left-tr {
    background-position: right 0;
}

.x-window-header-default-collapsed-left-bl {
    background-position: 0 0;
}

.x-window-header-default-collapsed-left-br {
    background-position: right 0;
}

.x-window-header-default-collapsed-left-ml {
    background-position: 0 top;
}

.x-window-header-default-collapsed-left-mr {
    background-position: right top;
}

.x-window-header-default-collapsed-left-tc {
    background-position: 0 0;
}

.x-window-header-default-collapsed-left-bc {
    background-position: 0 0;
}

.x-window-header-default-collapsed-left-tr,
.x-window-header-default-collapsed-left-br,
.x-window-header-default-collapsed-left-mr {
    padding-right: 0;
}

.x-window-header-default-collapsed-left-tl,
.x-window-header-default-collapsed-left-bl,
.x-window-header-default-collapsed-left-ml {
    padding-left: 0;
}

.x-window-header-default-collapsed-left-tc {
    height: 0;
}

.x-window-header-default-collapsed-left-bc {
    height: 0;
}

.x-window-header-default-collapsed-left-mc {
    padding: 16px 15px 16px 16px;
}

.x-window-header-default .x-window-header-icon {
    width: 16px;
    height: 16px;
    color: #111;
    font-size: 16px;
    line-height: 16px;
    background-position: center center;
}

.x-window-header-default .x-window-header-glyph {
    color: #111;
    font-size: 16px;
    line-height: 16px;
}

.x-ie8 .x-window-header-default .x-window-header-glyph {
    color: #111;
}

.x-window-header-default-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-window-header-default-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-window-header-default-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-window-header-default-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-window-header-default-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-window-header-default-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-window-header-default-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-window-header-default-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-window-header-default .x-tool-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-window-header-default .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-window-header-default .x-tool-focus,
.x-edge .x-keyboard-mode .x-window-header-default .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-window-header-default .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-window-header-default .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-window-header-default .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-window-header-default {
    border-width: 0 !important;
}

.x-nbr .x-window-default-collapsed .x-window-header {
    border-width: 0 !important;
}

.x-window-default-outer-border-l {
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-b {
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
}

.x-window-default-outer-border-bl {
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-r {
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
}

.x-window-default-outer-border-rl {
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-rb {
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
}

.x-window-default-outer-border-rbl {
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-t {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
}

.x-window-default-outer-border-tl {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-tb {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
}

.x-window-default-outer-border-tbl {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-tr {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
}

.x-window-default-outer-border-trl {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-left-color: #2196f3 !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-trb {
    border-top-color: #2196f3 !important;
    border-top-width: 1px !important;
    border-right-color: #2196f3 !important;
    border-right-width: 1px !important;
    border-bottom-color: #2196f3 !important;
    border-bottom-width: 1px !important;
}

.x-window-default-outer-border-trbl {
    border-color: #2196f3 !important;
    border-width: 1px !important;
}

.x-window-body-plain {
    background-color: transparent;
}

.x-form-item-label-default {
    color: rgba(17, 17, 17, 0.54);
    font: 400 13px/16px 'Nunito Sans', 'Open Sans', sans-serif;
    min-height: 32px;
    padding-top: 8px;
    padding-right: 5px;
}

.x-form-item-label-default.x-rtl {
    padding-left: 5px;
    padding-right: 0;
}

.x-ie8 .x-form-item-label-default {
    min-height: 24px;
}

.x-form-item-label-default.x-form-item-label-top {
    height: 1px;
}

.x-form-item-label-default.x-form-item-label-top > .x-form-item-label-inner {
    padding-top: 8px;
    padding-bottom: 5px;
}

.x-form-item-label-default.x-form-item-label-top-side-error:after {
    width: 26px;
}

.x-form-item-body-default {
    min-height: 32px;
}

.x-form-invalid-icon-default {
    width: 16px;
    height: 16px;
    margin: 0 5px;
    /*background: url(./../images/form/exclamation.png) no-repeat;*/
}

.x-form-invalid-under-default {
    padding: 2px 2px 2px 20px;
    color: #cf4c35;
    font: 400 13px/16px 'Nunito Sans', 'Open Sans', sans-serif;
    background: no-repeat 0 2px;
    /*background-image: url(./../images/form/exclamation.png);*/
}

.x-form-error-wrap-default.x-form-error-wrap-side {
    width: 26px;
}

.x-form-item-default.x-item-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.3;
}

.x-autocontainer-form-item,
.x-anchor-form-item,
.x-vbox-form-item,
.x-table-form-item {
    margin-bottom: 10px;
}

.x-form-text-field-body-default {
    min-width: 170px;
    max-width: 170px;
}

.x-form-trigger-wrap-default {
    border-width: 0 0 3px 0;
    border-style: solid;
    border-color: #d0d0d0;
}

.x-form-trigger-wrap-default.x-form-trigger-wrap-focus {
    border-color: #42a6f5;
}

.x-form-trigger-wrap-default.x-form-trigger-wrap-invalid {
    border-color: #cf4c35;
}

.x-form-text-default {
    color: #111;
    padding: 5px 10px 4px;
    background-color: #fff;
    font: 400 13px/20px 'Nunito Sans', 'Open Sans', sans-serif;
    min-height: 29px;
}

.x-ie8 .x-form-text-default {
    min-height: 20px;
}

.x-form-text-default.x-form-textarea {
    line-height: 16px;
    min-height: 64px;
}

.x-ie8 .x-form-text-default.x-form-textarea {
    min-height: 55px;
}

.x-form-text-default.x-form-text-file {
    color: grey;
}

.x-form-text-default.x-webkit-border-box-bug {
    height: calc(100% + 9px);
}

.x-placeholder-label-default {
    padding: 5px 10px 4px;
}

.x-form-empty-field-default + .x-placeholder-label-default {
    color: grey;
}

.x-form-text-default:-ms-input-placeholder {
    color: grey;
}

.x-form-invalid-field-default {
    background-color: #fff;
}

.x-form-trigger-default {
    width: 32px;
    font: 16px/29px 'Material Icons', 'Font Awesome 5 Free';
    background: transparent;
    color: #919191;
}

.x-form-trigger-default:before {
    content: '\f0d7';
}

.x-form-trigger-default.x-form-trigger-over {
    background-position: -32px center;
}

.x-form-trigger-default.x-form-trigger-over.x-form-trigger-focus {
    background-position: -128px center;
}

.x-form-trigger-default.x-form-trigger-focus {
    background-position: -96px center;
}

.x-form-trigger.x-form-trigger-default.x-form-trigger-click {
    background-position: -64px center;
}

.x-textfield-default-cell > .x-grid-cell-inner {
    padding-top: 8px;
    padding-bottom: 8px;
}

.x-form-clear-trigger {
    font-size: 16px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
}

.x-form-clear-trigger:before {
    content: '\f00d';
}

.x-form-search-trigger {
    font-size: 16px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
}

.x-form-search-trigger:before {
    content: '\f002';
}

.x-message-box .x-window-body {
    background-color: #fff;
    border-width: 0;
}

.x-message-box-info,
.x-message-box-warning,
.x-message-box-question,
.x-message-box-error {
    background-position: left top;
    background-repeat: no-repeat;
}

.x-rtl.x-message-box-info,
.x-rtl.x-message-box-warning,
.x-rtl.x-message-box-question,
.x-rtl.x-message-box-error {
    background-position: right top;
}

.x-message-box-icon {
    height: 44px;
    width: 44px;
    margin-right: 10px;
}

.x-message-box-info {
    font: 40px/1 'Material Icons', 'Font Awesome 5 Free';
    color: grey;
}

.x-message-box-info:before {
    content: '\f05a';
}

.x-message-box-warning {
    font: 40px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #f8d400;
}

.x-message-box-warning:before {
    content: '\f071';
}

.x-message-box-question {
    font: 40px/1 'Material Icons', 'Font Awesome 5 Free';
    color: grey;
}

.x-message-box-question:before {
    content: 'warning';
}

.x-message-box-error {
    font: 40px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #ee611f;
}

.x-message-box-error:before {
    content: '\f057';
}

.x-form-cb-wrap-default {
    height: 32px;
    min-width: 18px;
}

.x-form-cb-default {
    margin-top: 7px;
}

.x-form-checkbox-default,
.x-form-radio-default {
    width: 18px;
    height: 18px;
}

.x-form-radio-default {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    color: rgba(17, 17, 17, 0.54);
}

.x-form-radio-default:before {
    content: 'radio_button_unchecked';
}

.x-form-cb-checked .x-form-radio-default {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
}

.x-form-cb-checked .x-form-radio-default:before {
    content: 'radio_button_checked';
}

.x-form-checkbox-default {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    color: rgba(17, 17, 17, 0.54);
}

.x-form-checkbox-default:before {
    content: 'check_box_outline_blank';
}

.x-form-cb-checked .x-form-checkbox-default {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
}

.x-form-cb-checked .x-form-checkbox-default:before {
    content: 'check_box';
}

.x-keyboard-mode .x-form-checkbox-focus.x-form-radio-default {
    color: #2196f3;
}

.x-keyboard-mode .x-form-checkbox-focus.x-form-checkbox-default {
    color: #2196f3;
}

.x-keyboard-mode .x-form-cb-checked .x-form-checkbox-focus.x-form-radio-default {
    color: #2196f3;
}

.x-keyboard-mode .x-form-cb-checked .x-form-checkbox-focus.x-form-checkbox-default {
    color: #2196f3;
}

.x-form-cb-label-default {
    margin-top: 8px;
    font: 400 12px/16px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #111;
}

.x-form-cb-label-default.x-form-cb-label-before {
    padding-right: 30px;
}

.x-form-cb-label-default.x-form-cb-label-before.x-rtl {
    padding-right: 0;
    padding-left: 30px;
}

.x-form-cb-label-default.x-form-cb-label-after {
    padding-left: 30px;
}

.x-form-cb-label-default.x-rtl {
    padding-left: 0;
    padding-right: 30px;
}

.x-checkbox-default-cell > .x-grid-cell-inner {
    padding-top: 8px;
    padding-bottom: 8px;
}

.x-form-item-body-default.x-form-checkboxgroup-body {
    padding: 0 4px;
}

.x-form-invalid .x-form-item-body-default.x-form-checkboxgroup-body {
    border-width: 1px;
    border-style: solid;
    border-color: #cf4c35;
}

.x-fieldset-default {
    border: 1px solid #e0e0e0;
    padding: 16px 8px;
    margin: 0 0 10px 0;
}

.x-ie8 .x-fieldset-default {
    padding-top: 0;
}

.x-ie8 .x-fieldset-body-default {
    padding-top: 16px;
}

.x-fieldset-header-default {
    padding: 0 8px;
    line-height: 20px;
}

.x-fieldset-header-default > .x-fieldset-header-text {
    font: 600 16px/20px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #919191;
    padding: 1px 0;
}

.x-fieldset-header-checkbox-default {
    margin: 2px 4px 0 0;
    line-height: 20px;
}

.x-fieldset-header-checkbox-default.x-rtl {
    margin: 2px 0 0 4px;
}

.x-fieldset-header-tool-default {
    margin: 2px 4px 0 0;
    padding: 0;
}

.x-fieldset-header-tool-default.x-rtl {
    margin: 2px 0 0 4px;
}

.x-fieldset-header-tool-default > .x-tool-img {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
    height: 16px;
    width: 16px;
}

.x-fieldset-header-tool-default.x-tool-over > .x-tool-img {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    opacity: 0.9;
}

.x-fieldset-header-tool-default.x-tool-pressed > .x-tool-img {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-fieldset-header-tool-default > .x-tool-toggle {
    font: 14px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #919191;
}

.x-fieldset-header-tool-default > .x-tool-toggle:before {
    content: '\f146';
}

.x-keyboard-mode .x-fieldset-header-tool-default.x-focus {
    outline: 1px solid #42a6f5;
}

.x-fieldset-default.x-fieldset-collapsed {
    border-width: 1px 1px 0 1px;
    border-left-color: transparent;
    border-right-color: transparent;
}

.x-fieldset-default.x-fieldset-collapsed .x-tool-toggle {
    font: 14px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-fieldset-default.x-fieldset-collapsed .x-tool-toggle:before {
    content: '\f0fe';
}

.x-form-trigger-spinner-default {
    width: 32px;
}

.x-form-trigger-spinner-default:before {
    content: '';
}

.x-form-spinner-default {
    background-color: #fff;
    width: 32px;
    height: 14.5px;
    color: #919191;
}

.x-form-spinner-up-default {
    font: 16px/1 ExtJS;
}

.x-form-spinner-up-default:before {
    content: "\e61c";
}

.x-form-spinner-down-default {
    font: 16px/1 ExtJS;
}

.x-form-spinner-down-default:before {
    content: "\e61b";
}

.x-form-spinner-up-default {
    background-position: 0 0;
}

.x-form-spinner-up-default.x-form-spinner-over {
    background-position: -32px 0;
}

.x-form-spinner-up-default.x-form-spinner-over.x-form-spinner-focus {
    background-position: -128px 0;
}

.x-form-spinner-up-default.x-form-spinner-focus {
    background-position: -96px 0;
}

.x-form-spinner-up-default.x-form-spinner.x-form-spinner-click {
    background-position: -64px 0;
}

.x-form-spinner-down-default {
    background-position: 0 -14.5px;
}

.x-form-spinner-down-default.x-form-spinner-over {
    background-position: -32px -14.5px;
}

.x-form-spinner-down-default.x-form-spinner-over.x-form-spinner-focus {
    background-position: -128px -14.5px;
}

.x-form-spinner-down-default.x-form-spinner-focus {
    background-position: -96px -14.5px;
}

.x-form-spinner-down-default.x-form-spinner.x-form-spinner-click {
    background-position: -64px -14.5px;
}

.x-tbar-page-number {
    width: 30px;
}

.x-btn-icon-el.x-tbar-page-first {
    font: 16px/16px ExtJS;
    color: #2196f3;
}

.x-btn-icon-el.x-tbar-page-first:before {
    content: "\e617";
}

.x-btn-icon-el.x-tbar-page-prev {
    font: 16px/16px ExtJS;
    color: #2196f3;
}

.x-btn-icon-el.x-tbar-page-prev:before {
    content: "\e615";
}

.x-btn-icon-el.x-tbar-page-next {
    font: 16px/16px ExtJS;
    color: #2196f3;
}

.x-btn-icon-el.x-tbar-page-next:before {
    content: "\e616";
}

.x-btn-icon-el.x-tbar-page-last {
    font: 16px/16px ExtJS;
    color: #2196f3;
}

.x-btn-icon-el.x-tbar-page-last:before {
    content: "\e618";
}

.x-btn-icon-el.x-tbar-loading {
    font: 16px/16px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-icon-el.x-tbar-loading:before {
    content: '\f021';
}

.x-rtl.x-tbar-page-first {
    font: 16px/16px ExtJS;
}

.x-rtl.x-tbar-page-first:before {
    content: "\e618";
}

.x-rtl.x-tbar-page-prev {
    font: 16px/16px ExtJS;
}

.x-rtl.x-tbar-page-prev:before {
    content: "\e616";
}

.x-rtl.x-tbar-page-next {
    font: 16px/16px ExtJS;
}

.x-rtl.x-tbar-page-next:before {
    content: "\e615";
}

.x-rtl.x-tbar-page-last {
    font: 16px/16px ExtJS;
}

.x-rtl.x-tbar-page-last:before {
    content: "\e617";
}

.x-boundlist {
    border-width: 1px;
    border-style: solid;
    border-color: #d0d0d0;
    background: #fff;
}

.x-boundlist-item {
    padding: 0 10px;
    font: normal 13px 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 30px;
    cursor: pointer;
    cursor: hand;
    position: relative;
    border-width: 1px;
    border-style: dotted;
    border-color: #fff;
    color: #111;
}

.x-boundlist-selected {
    color: #fff;
    background: #2196f3;
    border-color: #2196f3;
}

.x-boundlist-item-over {
    color: #fff;
    background: #64b5f6;
    border-color: #64b5f6;
}

.x-boundlist-floating {
    border-top-width: 0;
}

.x-boundlist-above {
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.x-datepicker {
    border-width: 1px;
    border-style: solid;
    border-color: #d0d0d0;
    background-color: #fff;
    width: 282px;
}

.x-datepicker-header {
    padding: 0;
    text-align: center;
    background-color: #fff;
}

.x-datepicker-arrow {
    width: 32px;
    height: 44px;
    top: 0;
    cursor: pointer;
    -webkit-touch-callout: none;
    color: #919191;
    background-color: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    opacity: 0.7;
}

div.x-datepicker-arrow:hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-datepicker-next {
    right: 0;
    font: 18px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-datepicker-next:before {
    content: '\f105';
}

.x-datepicker-prev {
    left: 0;
    font: 18px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-datepicker-prev:before {
    content: '\f104';
}

.x-datepicker-month {
    background: transparent;
}

.x-datepicker-month .x-btn,
.x-datepicker-month .x-btn .x-btn-tc,
.x-datepicker-month .x-btn .x-btn-tl,
.x-datepicker-month .x-btn .x-btn-tr,
.x-datepicker-month .x-btn .x-btn-mc,
.x-datepicker-month .x-btn .x-btn-ml,
.x-datepicker-month .x-btn .x-btn-mr,
.x-datepicker-month .x-btn .x-btn-bc,
.x-datepicker-month .x-btn .x-btn-bl,
.x-datepicker-month .x-btn .x-btn-br {
    background: transparent;
    border-width: 0 !important;
}

.x-datepicker-month .x-btn-inner {
    color: #111;
}

.x-datepicker-month .x-btn-split-right:after,
.x-datepicker-month .x-btn-over .x-btn-split-right:after {
    content: '\f107';
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #111;
    background: none;
    padding: 0;
    text-align: right;
    width: 16px;
}

.x-datepicker-month .x-btn {
    padding: 14px;
}

.x-datepicker-month .x-btn-over {
    border-color: transparent;
    background: #f2f2f2;
}

.x-datepicker-month .x-btn.x-btn-pressed {
    border-color: transparent;
    background: #e6e6e6;
}

.x-datepicker-month .x-btn-inner {
    font-size: 14px;
}

.x-datepicker-column-header {
    width: 40px;
    color: #919191;
    font: 600 13px 'Nunito Sans', 'Open Sans', sans-serif;
    text-align: right;
    background-color: #fff;
}

.x-datepicker-column-header-inner {
    line-height: 40px;
    padding: 0 15px 0 0;
}

.x-datepicker-cell {
    text-align: right;
    border: 0;
}

.x-datepicker-date {
    padding: 0 15px 0 0;
    font: 400 13px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #111;
    cursor: pointer;
    line-height: 40px;
}

div.x-datepicker-date:hover {
    color: #111;
    background-color: transparent;
}

.x-datepicker-selected {
    border-style: none;
    border-color: transparent;
}

.x-datepicker-selected div.x-datepicker-date {
    background-color: #2196f3;
    color: #fff;
    font-weight: 600;
}

.x-datepicker-today {
    border-color: darkred;
    border-style: none;
    background-color: none;
}

.x-datepicker-prevday .x-datepicker-date,
.x-datepicker-nextday .x-datepicker-date {
    color: #111;
}

.x-datepicker-disabled .x-datepicker-date {
    background-color: none;
    cursor: default;
    color: #919191;
}

.x-datepicker-disabled div.x-datepicker-date:hover {
    background-color: none;
    color: #919191;
}

.x-datepicker-footer,
.x-monthpicker-buttons {
    padding: 6px 0;
    background-color: #fff;
    text-align: center;
}

.x-datepicker-footer .x-btn,
.x-monthpicker-buttons .x-btn {
    margin: 0 3px 0 2px;
}

.x-monthpicker {
    width: 282px;
    border-width: 1px;
    border-style: solid;
    border-color: #d0d0d0;
    background-color: #fff;
}

.x-monthpicker-months {
    border-width: 0 1px 0 0;
    border-color: #d0d0d0;
    border-style: solid;
    width: 140px;
}

.x-monthpicker-months .x-monthpicker-item {
    width: 69px;
}

.x-monthpicker-years {
    width: 140px;
}

.x-monthpicker-years .x-monthpicker-item {
    width: 70px;
}

.x-monthpicker-item {
    margin: 5px 0 5px;
    font: 400 13px 'Nunito Sans', 'Open Sans', sans-serif;
    text-align: center;
}

.x-monthpicker-item-inner {
    margin: 0 5px 0 5px;
    color: #111;
    border: 0;
    line-height: 37px;
    cursor: pointer;
}

a.x-monthpicker-item-inner:hover {
    color: #111;
    background-color: transparent;
}

.x-monthpicker-item a.x-monthpicker-selected {
    background-color: #2196f3;
    color: #fff;
    border-style: none;
    border-color: transparent;
}

.x-monthpicker-yearnav {
    height: 47px;
}

.x-monthpicker-yearnav-button-ct {
    width: 70px;
}

.x-monthpicker-yearnav-button {
    height: 16px;
    width: 16px;
    cursor: pointer;
    margin-top: 15px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    opacity: 0.7;
    -webkit-touch-callout: none;
    color: #919191;
    background-color: #fff;
}

a.x-monthpicker-yearnav-button:hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-monthpicker-yearnav-next {
    font: 18px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-monthpicker-yearnav-next:before {
    content: '\f105';
}

.x-monthpicker-yearnav-prev {
    font: 18px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-monthpicker-yearnav-prev:before {
    content: '\f104';
}

.x-monthpicker-small .x-monthpicker-item {
    margin: 2px 0 2px;
}

.x-monthpicker-small .x-monthpicker-item-inner {
    margin: 0 5px 0 5px;
}

.x-monthpicker-small .x-monthpicker-yearnav {
    height: 41px;
}

.x-monthpicker-small .x-monthpicker-yearnav-button {
    margin-top: 12px;
}

.x-form-field-date .x-form-date-trigger {
    font-size: 16px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
}

.x-form-field-date .x-form-date-trigger:before {
    content: '\f073';
}

.x-form-display-field-default {
    min-height: 32px;
    font: 400 12px/17px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #111;
    margin-top: 8px;
}

.x-keyboard-mode .x-form-display-field-default.x-field-form-focus {
    outline: 1px solid #42a6f5;
    outline-offset: -1px;
}

.x-ie .x-keyboard-mode .x-form-display-field-default.x-field-form-focus,
.x-ie10p .x-keyboard-mode .x-form-display-field-default.x-field-form-focus,
.x-edge .x-keyboard-mode .x-form-display-field-default.x-field-form-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-form-display-field-default.x-field-form-focus:after,
.x-ie10p .x-keyboard-mode .x-form-display-field-default.x-field-form-focus:after,
.x-edge .x-keyboard-mode .x-form-display-field-default.x-field-form-focus:after {
    position: absolute;
    content: ' ';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid #42a6f5;
    pointer-events: none;
}

.x-ie8 .x-form-display-field-default.x-field-form-focus {
    position: relative;
}

.x-tip-default {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 1px 1px 1px 1px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-tip-default-mc {
    background-color: #fff;
}

.x-nbr .x-tip-default {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tip-default-frameInfo {
    font-family: th-2-2-2-2-1-1-1-1-1-1-1-1;
}

.x-tip-default-tl {
    background-position: 0 -4px;
}

.x-tip-default-tr {
    background-position: right -6px;
}

.x-tip-default-bl {
    background-position: 0 -8px;
}

.x-tip-default-br {
    background-position: right -10px;
}

.x-tip-default-ml {
    background-position: 0 top;
}

.x-tip-default-mr {
    background-position: right top;
}

.x-tip-default-tc {
    background-position: 0 0;
}

.x-tip-default-bc {
    background-position: 0 -2px;
}

.x-tip-default-tr,
.x-tip-default-br,
.x-tip-default-mr {
    padding-right: 2px;
}

.x-tip-default-tl,
.x-tip-default-bl,
.x-tip-default-ml {
    padding-left: 2px;
}

.x-tip-default-tc {
    height: 2px;
}

.x-tip-default-bc {
    height: 2px;
}

.x-tip-default-tl,
.x-tip-default-bl,
.x-tip-default-tr,
.x-tip-default-br,
.x-tip-default-tc,
.x-tip-default-bc,
.x-tip-default-ml,
.x-tip-default-mr {
    /*background-image: url(./../images/tip/tip-default-corners.gif);*/
}

.x-tip-default-ml,
.x-tip-default-mr {
    /*background-image: url(./../images/tip/tip-default-sides.gif);*/
    background-repeat: repeat-y;
}

.x-tip-default-mc {
    padding: 0 0 0 0;
}

.x-tip-default {
    background-color: #fff;
    border-color: #fff;
}

.x-tip-default .x-tool-img {
    background-image: url(./../images/tools/tool-sprites-dark.png);
    background-color: #fff;
}

.x-tip-header-default .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-tip-header-default .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-tip-header-default .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-tip-header-default .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-tip-header-default {
    padding: 8px 8px 0 8px;
}

.x-tip-header-title-default {
    color: #111;
    font-size: 18px;
    font-weight: 600;
}

.x-tip-body-default {
    padding: 16px 8px;
    color: #111;
    font-size: 12px;
    font-weight: 400;
}

.x-tip-body-default a {
    color: #111;
}

.x-tip-default .x-tip-anchor {
    border: 10px solid transparent;
    _border-color: pink;
    _filter: chroma(color=pink);
}

.x-tip-default .x-tip-anchor-top {
    border-top-width: 0;
    border-bottom: 10px solid #fff;
    top: -10px;
}

.x-tip-default .x-tip-anchor-bottom {
    border-bottom-width: 0;
    border-top: 10px solid #fff;
    bottom: -10px;
}

.x-tip-default .x-tip-anchor-left {
    border-left-width: 0;
    border-right: 10px solid #fff;
    left: -10px;
}

.x-tip-default .x-tip-anchor-right {
    border-right-width: 0;
    border-left: 10px solid #fff;
    right: -10px;
}

.x-tip-form-invalid {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 1px 1px 1px 1px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-tip-form-invalid-mc {
    background-color: #fff;
}

.x-nbr .x-tip-form-invalid {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tip-form-invalid-frameInfo {
    font-family: th-2-2-2-2-1-1-1-1-1-1-1-1;
}

.x-tip-form-invalid-tl {
    background-position: 0 -4px;
}

.x-tip-form-invalid-tr {
    background-position: right -6px;
}

.x-tip-form-invalid-bl {
    background-position: 0 -8px;
}

.x-tip-form-invalid-br {
    background-position: right -10px;
}

.x-tip-form-invalid-ml {
    background-position: 0 top;
}

.x-tip-form-invalid-mr {
    background-position: right top;
}

.x-tip-form-invalid-tc {
    background-position: 0 0;
}

.x-tip-form-invalid-bc {
    background-position: 0 -2px;
}

.x-tip-form-invalid-tr,
.x-tip-form-invalid-br,
.x-tip-form-invalid-mr {
    padding-right: 2px;
}

.x-tip-form-invalid-tl,
.x-tip-form-invalid-bl,
.x-tip-form-invalid-ml {
    padding-left: 2px;
}

.x-tip-form-invalid-tc {
    height: 2px;
}

.x-tip-form-invalid-bc {
    height: 2px;
}

.x-tip-form-invalid-tl,
.x-tip-form-invalid-bl,
.x-tip-form-invalid-tr,
.x-tip-form-invalid-br,
.x-tip-form-invalid-tc,
.x-tip-form-invalid-bc,
.x-tip-form-invalid-ml,
.x-tip-form-invalid-mr {
    /*background-image: url(./../images/tip/tip-form-invalid-corners.gif);*/
}

.x-tip-form-invalid-ml,
.x-tip-form-invalid-mr {
    /*background-image: url(./../images/tip/tip-form-invalid-sides.gif);*/
    background-repeat: repeat-y;
}

.x-tip-form-invalid-mc {
    padding: 0 0 0 0;
}

.x-tip-form-invalid {
    background-color: #fff;
    border-color: #fff;
}

.x-tip-form-invalid .x-tool-img {
    background-image: url(./../images/tools/tool-sprites-dark.png);
    background-color: #fff;
}

.x-tip-header-form-invalid .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-tip-header-form-invalid .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-tip-header-form-invalid .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-tip-header-form-invalid .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-tip-header-form-invalid {
    padding: 8px 8px 0 8px;
}

.x-tip-header-title-form-invalid {
    color: #111;
    font-size: 18px;
    font-weight: 600;
}

.x-tip-body-form-invalid {
    padding: 3px 3px 3px 22px;
    color: #111;
    font-size: 12px;
    font-weight: 400;
}

.x-tip-body-form-invalid a {
    color: #111;
}

.x-tip-form-invalid .x-tip-anchor {
    border: 10px solid transparent;
    _border-color: pink;
    _filter: chroma(color=pink);
}

.x-tip-form-invalid .x-tip-anchor-top {
    border-top-width: 0;
    border-bottom: 10px solid #fff;
    top: -10px;
}

.x-tip-form-invalid .x-tip-anchor-bottom {
    border-bottom-width: 0;
    border-top: 10px solid #fff;
    bottom: -10px;
}

.x-tip-form-invalid .x-tip-anchor-left {
    border-left-width: 0;
    border-right: 10px solid #fff;
    left: -10px;
}

.x-tip-form-invalid .x-tip-anchor-right {
    border-right-width: 0;
    border-left: 10px solid #fff;
    right: -10px;
}

.x-tip-body-form-invalid {
    background: 1px 1px no-repeat;
    /*background-image: url(./../images/form/exclamation.png);*/
}

.x-tip-body-form-invalid li {
    margin-bottom: 4px;
}

.x-tip-body-form-invalid li.last {
    margin-bottom: 0;
}

.x-color-picker {
    width: 192px;
    height: 120px;
    background-color: #fff;
    border-color: #fff;
    border-width: 0;
    border-style: solid;
}

.x-color-picker-item {
    width: 24px;
    height: 24px;
    border-width: 1px;
    border-color: #fff;
    border-style: solid;
    background-color: #fff;
    cursor: pointer;
    padding: 2px;
}

a.x-color-picker-item:hover {
    border-color: #8bb8f3;
    background-color: #d9d9d9;
}

.x-color-picker-selected {
    border-color: #8bb8f3;
    background-color: #d9d9d9;
}

.x-color-picker-item-inner {
    line-height: 16px;
    border-color: #d0d0d0;
    border-width: 1px;
    border-style: solid;
}

.x-html-editor-tb .x-btn-icon-el {
    color: #919191;
}

.x-html-editor-tb .x-edit-bold,
.x-menu-item div.x-edit-bold {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-bold:before,
.x-menu-item div.x-edit-bold:before {
    content: '\f032';
}

.x-html-editor-tb .x-edit-italic,
.x-menu-item div.x-edit-italic {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-italic:before,
.x-menu-item div.x-edit-italic:before {
    content: '\f033';
}

.x-html-editor-tb .x-edit-underline,
.x-menu-item div.x-edit-underline {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-underline:before,
.x-menu-item div.x-edit-underline:before {
    content: '\f0cd';
}

.x-html-editor-tb .x-edit-forecolor,
.x-menu-item div.x-edit-forecolor {
    font: 16px/1 ExtJS;
    background: none;
}

.x-html-editor-tb .x-edit-forecolor:before,
.x-menu-item div.x-edit-forecolor:before {
    content: "\e60e";
}

.x-html-editor-tb .x-edit-backcolor,
.x-menu-item div.x-edit-backcolor {
    font: 16px/1 ExtJS;
    background: none;
}

.x-html-editor-tb .x-edit-backcolor:before,
.x-menu-item div.x-edit-backcolor:before {
    content: "\e60d";
}

.x-html-editor-tb .x-edit-justifyleft,
.x-menu-item div.x-edit-justifyleft {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-justifyleft:before,
.x-menu-item div.x-edit-justifyleft:before {
    content: '\f036';
}

.x-html-editor-tb .x-edit-justifycenter,
.x-menu-item div.x-edit-justifycenter {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-justifycenter:before,
.x-menu-item div.x-edit-justifycenter:before {
    content: '\f037';
}

.x-html-editor-tb .x-edit-justifyright,
.x-menu-item div.x-edit-justifyright {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-justifyright:before,
.x-menu-item div.x-edit-justifyright:before {
    content: '\f038';
}

.x-html-editor-tb .x-edit-insertorderedlist,
.x-menu-item div.x-edit-insertorderedlist {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-insertorderedlist:before,
.x-menu-item div.x-edit-insertorderedlist:before {
    content: '\f0cb';
}

.x-html-editor-tb .x-edit-insertunorderedlist,
.x-menu-item div.x-edit-insertunorderedlist {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-insertunorderedlist:before,
.x-menu-item div.x-edit-insertunorderedlist:before {
    content: '\f0ca';
}

.x-html-editor-tb .x-edit-increasefontsize,
.x-menu-item div.x-edit-increasefontsize {
    font: 16px/1 ExtJS;
    background: none;
}

.x-html-editor-tb .x-edit-increasefontsize:before,
.x-menu-item div.x-edit-increasefontsize:before {
    content: "\e610";
}

.x-html-editor-tb .x-edit-decreasefontsize,
.x-menu-item div.x-edit-decreasefontsize {
    font: 16px/1 ExtJS;
    background: none;
}

.x-html-editor-tb .x-edit-decreasefontsize:before,
.x-menu-item div.x-edit-decreasefontsize:before {
    content: "\e60f";
}

.x-html-editor-tb .x-edit-sourceedit,
.x-menu-item div.x-edit-sourceedit {
    font: 16px/1 ExtJS;
    background: none;
}

.x-html-editor-tb .x-edit-sourceedit:before,
.x-menu-item div.x-edit-sourceedit:before {
    content: "\e604";
}

.x-html-editor-tb .x-edit-createlink,
.x-menu-item div.x-edit-createlink {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-createlink:before,
.x-menu-item div.x-edit-createlink:before {
    content: '\f0c1';
}

.x-html-editor-tip .x-tip-bd .x-tip-bd-inner {
    padding: 5px;
    padding-bottom: 1px;
}

.x-html-editor-tb .x-font-select {
    font-size: 13px;
    font-family: inherit;
}

.x-html-editor-wrap textarea {
    font: 400 13px 'Nunito Sans', 'Open Sans', sans-serif;
    background-color: #fff;
    resize: none;
}

.x-htmleditor-iframe {
    background-color: #fff;
}

.x-form-text-default.x-tagfield {
    padding: 0 8px;
}

.x-form-text-default .x-tagfield-input {
    margin: 8px 8px 8px 0;
}

.x-form-text-default .x-tagfield-input-field {
    height: 24px;
    line-height: 24px;
}

.x-form-text-default .x-tagfield-item {
    -webkit-border-radius: 3px 3px 3px 3px;
    -moz-border-radius: 3px 3px 3px 3px;
    -ms-border-radius: 3px 3px 3px 3px;
    -o-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
    background-color: #e0e0e0;
    border: 1px solid #e0e0e0;
    padding: 0 26px 0 8px;
    margin: 8px 8px 8px 0;
    color: #111;
    line-height: 22px;
}

.x-form-text-default .x-tagfield-item:hover {
    background-color: #2196f3;
    border-color: #2196f3;
    color: #fff;
}

.x-form-text-default .x-tagfield-item.x-tagfield-item-selected {
    background-color: #64b5f6;
    border-color: #64b5f6;
    color: #fff;
}

.x-form-text-default .x-tagfield-item.x-rtl {
    padding: 0 8px 0 26px;
}

.x-form-text-default .x-tagfield-item-close {
    width: 12px;
    height: 12px;
    top: 0;
    right: 8px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
    opacity: 0.85;
    font: 12px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #919191;
}

.x-form-text-default .x-tagfield-item-close:before {
    content: '\f00d';
}

.x-form-text-default .x-tagfield-item-close:hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=93)";
    opacity: 0.93;
    color: #fff;
}

.x-form-text-default .x-tagfield-item-close:active {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-form-text-default .x-tagfield-item-close.x-rtl {
    right: auto;
    left: 8px;
}

.x-form-text-default .x-tagfield-item-selected .x-tagfield-item-close {
    color: #fff;
}

.x-form-text-default .x-tagfield-item-selected .x-tagfield-item-close:hover {
    color: #fff;
}

.x-grid-view,
.x-tree-view {
    z-index: 1;
}

.x-theme-row-height-el {
    height: 48px;
}

.x-grid-body {
    background: #fff;
    border-width: 2px 1px 1px;
    border-style: solid;
    border-color: #d0d0d0;
}

.x-grid-empty {
    padding: 16px 8px;
    color: grey;
    background-color: #fff;
    font: 400 13px 'Nunito Sans', 'Open Sans', sans-serif;
}

.x-grid-item {
    color: #111;
    font: 400 13px/16px 'Nunito Sans', 'Open Sans', sans-serif;
    background-color: #fff;
}

.x-grid-item-alt {
    background-color: #fff;
}

.x-grid-item-over {
    color: #111;
    background-color: #eee;
}

.x-grid-item-focused {
    outline: 0;
}

.x-grid-item-focused .x-grid-cell-inner {
    z-index: 1;
}

.x-keyboard-mode .x-grid-item-focused {
    color: #111;
}

.x-keyboard-mode .x-grid-item-focused .x-grid-cell-inner:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    border: 0 solid #2196f3;
}

.x-grid-item-selected {
    color: #111;
    background-color: #e0e0e0;
}

.x-grid-with-row-lines .x-grid-item {
    border-style: solid;
    border-width: 1px 0 0;
    border-color: #e2e2e2;
}

.x-grid-with-row-lines .x-grid-item:first-child {
    border-top-color: #fff;
}

.x-grid-with-row-lines .x-grid-item.x-grid-item-over {
    border-style: solid;
    border-color: #e2e2e2;
}

.x-grid-with-row-lines .x-grid-item-over + .x-grid-item {
    border-top-style: solid;
    border-top-color: #e2e2e2;
}

.x-grid-with-row-lines .x-grid-item.x-grid-item-selected {
    border-style: solid;
    border-color: #d5d5d5;
}

.x-grid-with-row-lines .x-grid-item-selected + .x-grid-item {
    border-top-style: solid;
    border-top-color: #d5d5d5;
}

.x-grid-with-row-lines .x-grid-item:last-child {
    border-bottom-width: 1px;
}

.x-ie8 .x-grid-with-row-lines .x-grid-item {
    border-width: 1px 0;
    margin-top: -1px;
}

.x-ie8 .x-grid-with-row-lines .x-grid-item:first-child {
    margin-top: 0;
}

.x-grid-cell-inner {
    position: relative;
    text-overflow: ellipsis;
    padding: 16px 8px;
}

.x-grid-cell-special {
    border-color: #e2e2e2;
    border-style: solid;
    border-right-width: 1px;
}

.x-rtl.x-grid-cell-special {
    border-right-width: 0;
    border-left-width: 1px;
}

.x-grid-dirty-cell > .x-grid-cell-inner:after {
    content: "\e602";
    font: 14px/1 ExtJS;
    color: #cf4c35;
    position: absolute;
    top: 0;
    left: 0;
}

.x-rtl.x-grid-dirty-cell > .x-grid-cell-inner:after {
    content: "\e603";
    font: 14px/1 ExtJS;
    right: 0;
}

.x-grid-row .x-grid-cell-selected {
    color: #111;
    background-color: #e0e0e0;
}

.x-grid-with-col-lines .x-grid-cell {
    border-style: solid;
    border-color: #e2e2e2;
    border-width: 0 1px 0 0;
}

.x-grid-with-col-lines .x-grid-item-over .x-grid-cell {
    border-color: #e2e2e2;
    border-style: solid;
}

.x-grid-with-col-lines .x-grid-item-selected .x-grid-cell {
    border-color: #d5d5d5;
    border-style: solid;
}

.x-rtl.x-grid-with-col-lines .x-grid-cell {
    border-width: 0 0 0 1px;
}

.x-grid-resize-marker {
    width: 1px;
    background-color: #0f0f0f;
}

.x-grid-drop-indicator {
    position: absolute;
    height: 1px;
    line-height: 0;
    background-color: #77bc71;
    overflow: visible;
    pointer-events: none;
}

.x-grid-drop-indicator .x-grid-drop-indicator-left {
    position: absolute;
    top: -8px;
    left: -12px;
    background-image: url(./../images/grid/dd-insert-arrow-right.png);
    height: 16px;
    width: 16px;
}

.x-grid-drop-indicator .x-grid-drop-indicator-right {
    position: absolute;
    top: -8px;
    right: -11px;
    background-image: url(./../images/grid/dd-insert-arrow-left.png);
    height: 16px;
    width: 16px;
}

.x-col-move-top,
.x-col-move-bottom {
    width: 9px;
    height: 9px;
}

.x-col-move-top {
    background-image: url(./../images/grid/col-move-top.png);
}

.x-col-move-bottom {
    background-image: url(./../images/grid/col-move-bottom.png);
}

.x-grid-header-ct {
    border: 1px solid #d0d0d0;
    border-bottom-color: #fff;
    background-color: #fff;
}

.x-accordion-item .x-grid-header-ct {
    border-width: 0 0 1px !important;
}

.x-grid-header-ct-hidden {
    border-top: 0 !important;
    border-bottom: 0 !important;
}

.x-grid-body {
    border-top-color: #e2e2e2;
}

.x-hmenu-sort-asc {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 16px;
}

.x-hmenu-sort-asc:before {
    content: 'arrow_upward';
}

.x-hmenu-sort-desc {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 16px;
}

.x-hmenu-sort-desc:before {
    content: 'arrow_downward';
}

.x-cols-icon {
    font: 16px/16px ExtJS;
}

.x-cols-icon:before {
    content: "\e601";
}

.x-column-header {
    border-right: 1px solid #e2e2e2;
    color: #111;
    font: 400 13px/16px 'Nunito Sans', 'Open Sans', sans-serif;
    outline: 0;
    background-color: #fff;
}

.x-rtl.x-column-header {
    border-right: 0 none;
    border-left: 1px solid #e2e2e2;
}

.x-group-sub-header {
    background: transparent;
    border-top: 1px solid #e2e2e2;
}

.x-group-sub-header .x-column-header-inner {
    padding: 15px 8px 16px 8px;
}

.x-column-header-inner {
    padding: 16px 8px;
}

.x-column-header-inner-empty {
    text-overflow: clip;
}

.x-keyboard-mode .x-column-header.x-column-header-focus {
    color: #111;
}

.x-keyboard-mode .x-column-header.x-column-header-focus .x-column-header-inner:after {
    content: "";
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 0 solid #fff;
    pointer-events: none;
}

.x-keyboard-mode .x-column-header.x-column-header-focus.x-group-sub-header .x-column-header-inner:before {
    bottom: 0;
}

.x-column-header-over {
    background-image: none;
    background-color: #fff;
}

.x-column-header-sort-ASC,
.x-column-header-sort-DESC {
    background-image: none;
    background-color: #fff;
}

.x-column-header-open {
    background-color: #fff;
}

.x-column-header-open .x-column-header-trigger {
    background-color: #fff;
}

.x-column-header-over .x-column-header-trigger,
.x-column-header-open .x-column-header-trigger {
    width: 24px;
    cursor: pointer;
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #919191;
}

.x-column-header-over .x-column-header-trigger:before,
.x-column-header-open .x-column-header-trigger:before {
    content: '\f0d7';
}

.x-column-header-over .x-column-header-trigger:before,
.x-column-header-open .x-column-header-trigger:before {
    top: 50%;
    margin-top: -8px;
    text-align: center;
    position: relative;
    display: block;
}

.x-rtl.x-column-header-trigger {
    background-position: center center;
}

.x-column-header-align-right .x-column-header-text {
    margin-right: 20px;
}

.x-column-header-align-right .x-rtl.x-column-header-text {
    margin-right: 0;
    margin-left: 20px;
}

.x-column-header-sort-ASC .x-column-header-text-inner,
.x-column-header-sort-DESC .x-column-header-text-inner {
    background-position: right center;
}

.x-column-header-sort-ASC .x-column-header-text-inner:after,
.x-column-header-sort-DESC .x-column-header-text-inner:after {
    display: inline-block;
    text-align: center;
    margin-left: 5px;
    width: 14px;
}

.x-column-header-sort-ASC .x-rtl.x-column-header-text-inner,
.x-column-header-sort-DESC .x-rtl.x-column-header-text-inner {
    background-position: 0 center;
}

.x-column-header-sort-ASC .x-rtl.x-column-header-text-inner:after,
.x-column-header-sort-DESC .x-rtl.x-column-header-text-inner:after {
    margin-left: 0;
    margin-right: 5px;
}

.x-column-header-sort-ASC .x-column-header-text-inner:after {
    content: 'arrow_upward';
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    color: #919191;
}

.x-column-header-sort-DESC .x-column-header-text-inner:after {
    content: 'arrow_downward';
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    color: #919191;
}

.x-no-header-borders .x-column-header {
    border: 0 none;
}

.x-no-header-borders .x-column-header .x-column-header-inner {
    padding-top: 16px;
}

.x-grid-cell-inner-action-col {
    padding: 15px 4px 15px 4px;
}

.x-action-col-cell .x-item-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.3;
}

.x-action-col-icon {
    color: #919191;
    font-size: 18px;
    height: 18px;
    width: 18px;
    cursor: pointer;
}

.x-column-header-checkbox .x-column-header-inner,
.x-grid-checkcolumn-cell-inner {
    padding: 15px 4px 15px 4px;
    text-overflow: clip;
}

.x-column-header-checkbox {
    border-color: #e2e2e2;
}

.x-column-header-checkbox .x-column-header-text {
    overflow: visible;
}

.x-column-header-checkbox .x-column-header-checkbox:after,
.x-grid-checkcolumn:after {
    content: 'check_box_outline_blank';
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    color: rgba(17, 17, 17, 0.54);
}

.x-item-disabled .x-column-header-checkbox .x-column-header-checkbox,
.x-item-disabled .x-grid-checkcolumn {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.3;
}

.x-grid-hd-checker-on .x-column-header-checkbox:after,
.x-grid-checkcolumn-checked:after {
    content: 'check_box';
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    color: rgba(17, 17, 17, 0.54);
}

.x-grid-item-selected .x-selmodel-column .x-grid-checkcolumn:after {
    content: 'check_box';
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    color: rgba(17, 17, 17, 0.54);
}

.x-selmodel-checkbox .x-grid-checkcolumn,
.x-selmodel-column .x-grid-checkcolumn {
    pointer-events: none;
}

.x-grid-cell-row-numberer {
    background-color: #fff;
}

.x-grid-cell-inner-row-numberer {
    padding: 16px 5px 16px 3px;
}

.x-btn-grid-cell-small {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 8px 16px 8px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-btn-grid-cell-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-grid-cell-small {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-grid-cell-small-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-8-16-8-16;
}

.x-btn-grid-cell-small-tl {
    background-position: 0 0;
}

.x-btn-grid-cell-small-tr {
    background-position: right 0;
}

.x-btn-grid-cell-small-bl {
    background-position: 0 0;
}

.x-btn-grid-cell-small-br {
    background-position: right 0;
}

.x-btn-grid-cell-small-ml {
    background-position: 0 top;
}

.x-btn-grid-cell-small-mr {
    background-position: right top;
}

.x-btn-grid-cell-small-tc {
    background-position: 0 0;
}

.x-btn-grid-cell-small-bc {
    background-position: 0 0;
}

.x-btn-grid-cell-small-tr,
.x-btn-grid-cell-small-br,
.x-btn-grid-cell-small-mr {
    padding-right: 0;
}

.x-btn-grid-cell-small-tl,
.x-btn-grid-cell-small-bl,
.x-btn-grid-cell-small-ml {
    padding-left: 0;
}

.x-btn-grid-cell-small-tc {
    height: 0;
}

.x-btn-grid-cell-small-bc {
    height: 0;
}

.x-btn-grid-cell-small-mc {
    padding: 8px 16px 8px 16px;
}

.x-btn-grid-cell-small {
    border-color: #5da8e5;
}

.x-btn-button-grid-cell-small {
    min-height: 16px;
}

.x-ie9m .x-btn-button-grid-cell-small {
    min-height: auto;
    height: 16px;
}

.x-btn-inner-grid-cell-small {
    font: 600 13px/15px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #2196f3;
    padding: 0 5px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-grid-cell-small,
.x-ie9 .x-btn-icon-left > .x-btn-inner-grid-cell-small {
    max-width: calc(100% - 16px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-grid-cell-small,
.x-safari8m .x-btn-icon-left > .x-btn-inner-grid-cell-small {
    max-width: calc(100% - 15px);
}

.x-ie10p .x-btn-inner-grid-cell-small {
    max-width: none;
}

.x-btn-icon-el-grid-cell-small {
    font-size: 16px;
    height: 16px;
    color: #2196f3;
    line-height: 16px;
}

.x-btn-icon-left > .x-btn-icon-el-grid-cell-small,
.x-btn-icon-right > .x-btn-icon-el-grid-cell-small {
    width: 16px;
}

.x-btn-icon-top > .x-btn-icon-el-grid-cell-small,
.x-btn-icon-bottom > .x-btn-icon-el-grid-cell-small {
    min-width: 16px;
}

.x-ie8 .x-btn-icon-el-grid-cell-small.x-btn-glyph {
    color: #2196f3;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-grid-cell-small {
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-grid-cell-small.x-rtl {
    margin-right: 0;
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-grid-cell-small {
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-grid-cell-small.x-rtl {
    margin-left: 0;
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-grid-cell-small {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-grid-cell-small {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-grid-cell-small {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-grid-cell-small {
    margin-right: 5px;
}

.x-btn-arrow-bottom > .x-btn-button-grid-cell-small,
.x-btn-split-bottom > .x-btn-button-grid-cell-small {
    padding-bottom: 8px;
}

.x-btn-wrap-grid-cell-small.x-btn-arrow-right:after {
    width: 8px;
    content: 'arrow_drop_down';
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-grid-cell-small.x-btn-arrow-bottom:after {
    height: 8px;
    content: 'arrow_drop_down';
    font: 16px/8px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-grid-cell-small.x-btn-split-right > .x-btn-button {
    padding-right: 16px;
}

.x-btn-wrap-grid-cell-small.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 16px;
}

.x-btn-wrap-grid-cell-small.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #2196f3;
}

.x-btn-wrap-grid-cell-small.x-btn-split-right:after {
    width: 14px;
    content: 'arrow_drop_down';
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
    padding-left: 16px;
}

.x-btn-wrap-grid-cell-small.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 16px;
}

.x-btn-wrap-grid-cell-small.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-grid-cell-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #2196f3;
}

.x-btn-wrap-grid-cell-small.x-btn-split-bottom:after {
    height: 14px;
    content: 'arrow_drop_down';
    font: 16px/14px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-grid-cell-small {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-grid-cell-small {
    margin-right: 5px;
}

.x-keyboard-mode .x-btn-focus.x-btn-grid-cell-small {
    background-image: none;
    background-color: transparent;
}

.x-btn-grid-cell-small .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 30px;
    pointer-events: none;
}

.x-btn-grid-cell-small .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-btn-over.x-btn-grid-cell-small {
    border-color: #569bd2;
    background-image: none;
    background-color: transparent;
}

.x-btn.x-btn-menu-active.x-btn-grid-cell-small,
.x-btn.x-btn-pressed.x-btn-grid-cell-small {
    border-color: #467eac;
    background-image: none;
    background-color: transparent;
}

.x-btn.x-btn-disabled.x-btn-grid-cell-small {
    background-image: none;
    background-color: transparent;
}

.x-keyboard-mode .x-btn-focus .x-btn-grid-cell-small-mc {
    background-color: transparent;
}

.x-btn-over .x-btn-grid-cell-small-mc {
    background-color: transparent;
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-grid-cell-small-mc {
    background-color: transparent;
}


.x-btn.x-btn-menu-active .x-btn-grid-cell-small-mc,
.x-btn.x-btn-pressed .x-btn-grid-cell-small-mc {
    background-color: transparent;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-grid-cell-small-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-grid-cell-small-mc {
    background-color: transparent;
}

.x-btn.x-btn-disabled .x-btn-grid-cell-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-grid-cell-small {
    background-image: none;
}

.x-btn-disabled.x-btn-grid-cell-small {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=)";
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-first {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-first .x-btn-grid-cell-small-mc {
    padding-right: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-middle {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-middle .x-btn-grid-cell-small-mc {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-last .x-btn-grid-cell-small-mc {
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-first {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-first .x-btn-grid-cell-small-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-middle {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-middle .x-btn-grid-cell-small-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-last .x-btn-grid-cell-small-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-grid-cell-small:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-first:after {
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-last:after {
    right: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-first:after {
    top: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-last:after {
    bottom: 0;
}

.x-button-grid-cell-small-cell > .x-grid-cell-inner {
    padding-top: 8px;
    padding-bottom: 8px;
}

.x-button-grid-cell-small-cell > .x-grid-cell-inner > .x-btn-grid-cell-small {
    vertical-align: top;
}

.x-grid-widgetcolumn-cell-inner {
    text-overflow: clip;
}

.x-grid-group-hd {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #e2e2e2;
    padding: 16px 8px;
    background: #fff;
    cursor: pointer;
}

.x-grid-group-hd-not-collapsible {
    cursor: default;
}

.x-grid-group-hd-collapsible .x-grid-group-title {
    padding: 0 0 0 24px;
}

.x-grid-group-hd-collapsible .x-grid-group-title:before {
    content: '\f146';
    font-size: 14px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    color: #919191;
}

.x-rtl.x-grid-view .x-grid-group-hd-collapsible .x-grid-group-title {
    background-position: right center;
    padding: 0 24px 0 0;
}

.x-grid-group-title {
    color: #111;
    font: 600 13px/15px 'Nunito Sans', 'Open Sans', sans-serif;
}

.x-grid-group-hd-collapsed .x-grid-group-title:before {
    content: '\f0fe';
    font-size: 14px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    color: #919191;
}

.x-group-by-icon {
    font: 16px/16px ExtJS;
}

.x-group-by-icon:before {
    content: "\e607";
}

.x-grid-rowbody {
    font: 400 13px/16px 'Nunito Sans', 'Open Sans', sans-serif;
    padding: 8px;
}

.x-docked-summary {
    border-width: 1px;
    border-color: #d0d0d0;
    border-style: solid;
    background: #fff !important;
}

.x-docked-summary .x-grid-table {
    border: 0 none;
}

.x-grid-row-summary .x-grid-cell,
.x-grid-row-summary .x-grid-rowwrap,
.x-grid-row-summary .x-grid-cell-rowbody {
    border-color: #e2e2e2;
    background-color: #fff !important;
    border-top: 1px solid #e2e2e2;
    font: 400 13px/16px 'Nunito Sans', 'Open Sans', sans-serif;
}

.x-docked-summary .x-grid-item,
.x-docked-summary .x-grid-row-summary .x-grid-cell {
    border-bottom: 0 none;
    border-top: 0 none;
}

.x-docked-summary > :first-child {
    background-color: #fff;
}

.x-grid-row-summary .x-grid-cell-inner-row-expander {
    display: none;
}

.x-menu-default {
    border-style: solid;
    border-width: 1px;
    border-color: #e2e2e2;
}

.x-menu-body-default {
    background: #fff;
    padding: 8px 0;
}

.x-menu-icon-separator-default {
    left: 48px;
    border-left: solid 1px #e0e0e0;
    background-color: #fff;
    width: 1px;
}

.x-rtl > .x-menu-icon-separator {
    left: auto;
    right: 48px;
}

.x-menu-item-default {
    border-width: 0;
    cursor: pointer;
}

.x-menu-item-default.x-menu-item-focus,
.x-menu-item-default.x-menu-item-active {
    background-image: none;
    background-color: #64b5f6;
}

.x-nlg .x-menu-item-default.x-menu-item-focus,
.x-nlg .x-menu-item-default.x-menu-item-active {
    background: #64b5f6 repeat-x left top;
}

.x-menu-item-default.x-menu-item-disabled {
    cursor: default;
}

.x-menu-item-default.x-menu-item-disabled a {
    cursor: default;
}

.x-menu-item-default.x-menu-item-separator {
    height: 1px;
    border-top: solid 1px #e0e0e0;
    background-color: #fff;
    margin: 2px 0;
    padding: 0;
}

.x-menu-item-default.x-menu-item-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-ie9m .x-menu-item-default.x-menu-item-disabled .x-menu-item-icon-ui {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-ie9m .x-menu-item-default.x-menu-item-disabled .x-menu-item-text-default {
    background-color: transparent;
}

.x-menu-item-default .x-form-item-label {
    font-size: 14px;
    color: #111;
}

.x-menu-item-text-default,
.x-menu-item-cmp-default {
    margin: 0 16px 0 16px;
}

.x-menu-item-text-default {
    font: 400 14px 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 31px;
    padding-top: 1px;
    color: #111;
    cursor: pointer;
}

.x-menu-item-text-default.x-menu-item-indent {
    margin-left: 65px;
}

.x-menu-item-text-default.x-menu-item-indent-no-separator {
    margin-left: 48px;
}

.x-menu-item-text-default.x-menu-item-indent-right-icon {
    margin-right: 64px;
}

.x-menu-item-text-default.x-menu-item-indent-right-arrow {
    margin-right: 37px;
}

.x-menu-item-disabled .x-menu-item-text-default {
    cursor: default;
}

.x-rtl.x-menu-item-text-default,
.x-rtl.x-menu-item-cmp-default {
    margin: 0 16px 0 16px;
}

.x-rtl.x-menu-item-text-default.x-menu-item-indent {
    margin-right: 65px;
}

.x-rtl.x-menu-item-text-default.x-menu-item-indent-no-separator {
    margin-right: 48px;
}

.x-rtl.x-menu-item-text-default.x-menu-item-indent-right-icon {
    margin-left: 64px;
}

.x-rtl.x-menu-item-text-default.x-menu-item-indent-right-arrow {
    margin-left: 37px;
}

.x-menu-item-indent-default {
    margin-left: 65px;
}

.x-menu-item-indent-default.x-rtl {
    margin-left: 0;
    margin-right: 65px;
}

.x-menu-item-icon-default {
    width: 16px;
    height: 16px;
    top: 8px;
    left: 16px;
    line-height: 16px;
    font-size: 16px;
    color: #111;
    background-position: center center;
}

.x-menu-item-icon-default.x-rtl {
    left: auto;
    right: 16px;
}

.x-menu-item-focus .x-menu-item-icon-default.x-rtl,
.x-menu-item-active .x-menu-item-icon-default.x-rtl {
    right: 16px;
}

.x-ie8 .x-menu-item-icon-default.x-menu-item-glyph {
    color: #111;
}

.x-menu-item-icon-default.x-menu-item-icon-right {
    width: 16px;
    height: 16px;
    top: 8px;
    right: 16px;
    left: auto;
    background-position: center center;
}

.x-menu-item-icon-default.x-menu-item-icon-right.x-rtl {
    right: auto;
    left: 16px;
}

.x-menu-item-focus .x-menu-item-icon-default.x-menu-item-icon-right.x-rtl,
.x-menu-item-active .x-menu-item-icon-default.x-menu-item-icon-right.x-rtl {
    left: 16px;
}

.x-menu-item-icon-default.x-menu-item-checkbox {
    color: rgba(17, 17, 17, 0.54);
}

.x-menu-item-focus .x-menu-item-icon-default.x-menu-item-checkbox,
.x-menu-item-active .x-menu-item-icon-default.x-menu-item-checkbox {
    color: #111;
}

.x-menu-item-checked .x-menu-item-icon-default.x-menu-item-checkbox {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 16px;
}

.x-menu-item-checked .x-menu-item-icon-default.x-menu-item-checkbox:before {
    content: 'check_box';
}

.x-menu-item-unchecked .x-menu-item-icon-default.x-menu-item-checkbox {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 16px;
}

.x-menu-item-unchecked .x-menu-item-icon-default.x-menu-item-checkbox:before {
    content: 'check_box_outline_blank';
}

.x-menu-item-focus .x-menu-item-icon-default.x-menu-group-icon,
.x-menu-item-active .x-menu-item-icon-default.x-menu-group-icon {
    color: #111;
}

.x-menu-item-checked .x-menu-item-icon-default.x-menu-group-icon {
    font: 18px/16px 'Material Icons', 'Font Awesome 5 Free';
}

.x-menu-item-checked .x-menu-item-icon-default.x-menu-group-icon:before {
    content: '\f00c';
}

.x-menu-item-unchecked .x-menu-item-icon-default.x-menu-group-icon {
    background-image: none;
}

.x-menu-item-unchecked .x-menu-item-icon-default.x-menu-group-icon:before {
    content: '';
}

.x-menu-item-arrow-default {
    width: 16px;
    height: 16px;
    top: 8px;
    right: 0;
    margin: 0 4px 0;
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #919191;
}

.x-menu-item-arrow-default:before {
    content: '\f0da';
}

.x-menu-item-focus .x-menu-item-arrow-default,
.x-menu-item-active .x-menu-item-arrow-default {
    color: #111;
}

.x-menu-item-focus .x-menu-item-arrow-default,
.x-menu-item-active .x-menu-item-arrow-default {
    top: 8px;
    right: 0;
}

.x-menu-item-arrow-default.x-rtl {
    left: 0;
    right: auto;
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-menu-item-arrow-default.x-rtl:before {
    content: '\f0d9';
}

.x-menu-item-focus .x-menu-item-arrow-default.x-rtl,
.x-menu-item-active .x-menu-item-arrow-default.x-rtl {
    left: 0;
}

.x-menu-default-scroller .x-box-scroller-body-horizontal {
    margin-left: 16px;
}

.x-menu-default-scroller.x-rtl .x-box-scroller-body-horizontal {
    margin-left: 0;
    margin-right: 16px;
}

.x-menu-default-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 16px;
}

.x-menu-default-vertical-scroller .x-box-scroller-body-vertical-bottom {
    margin-bottom: 16px;
}

.x-box-scroller-menu-default {
    cursor: pointer;
    color: #919191;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-box-scroller-menu-default.x-box-scroller-hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-box-scroller-menu-default.x-box-scroller-pressed {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    opacity: 0.7;
}

.x-box-scroller-menu-default.x-box-scroller-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    opacity: 0.25;
    cursor: default;
}

.x-box-scroller-menu-default.x-box-scroller-top,
.x-box-scroller-menu-default.x-box-scroller-bottom {
    height: 16px;
    width: 16px;
    left: 50%;
    margin-left: -8px;
}

.x-box-scroller-menu-default.x-box-scroller-top {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 4px;
    font: 16px/16px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-menu-default.x-box-scroller-top:before {
    content: '\f077';
}

.x-box-scroller-menu-default.x-box-scroller-bottom {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 4px;
    font: 16px/16px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-menu-default.x-box-scroller-bottom:before {
    content: '\f078';
}

.x-ie8 .x-box-scroller-menu-default {
    background-color: #fff;
}

.x-menu-default-menubar {
    border-style: solid;
    border-width: 1px;
    border-color: #e2e2e2;
}

.x-menu-body-default-menubar {
    background: #fff;
    padding: 8px 0;
}

.x-menu-icon-separator-default-menubar {
    left: 48px;
    border-left: solid 1px #e0e0e0;
    background-color: #fff;
    width: 1px;
}

.x-rtl > .x-menu-icon-separator {
    left: auto;
    right: 48px;
}

.x-menu-item-default-menubar {
    border-width: 0;
    cursor: pointer;
}

.x-menu-item-default-menubar.x-menu-item-focus,
.x-menu-item-default-menubar.x-menu-item-active {
    background-image: none;
    background-color: #64b5f6;
}

.x-nlg .x-menu-item-default-menubar.x-menu-item-focus,
.x-nlg .x-menu-item-default-menubar.x-menu-item-active {
    background: #64b5f6 repeat-x left top;
}

.x-menu-item-default-menubar.x-menu-item-disabled {
    cursor: default;
}

.x-menu-item-default-menubar.x-menu-item-disabled a {
    cursor: default;
}

.x-menu-item-default-menubar.x-menu-item-separator {
    height: 1px;
    border-top: solid 1px #e0e0e0;
    background-color: #fff;
    margin: 2px 0;
    padding: 0;
}

.x-menu-item-default-menubar.x-menu-item-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-ie9m .x-menu-item-default-menubar.x-menu-item-disabled .x-menu-item-icon-ui {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-ie9m .x-menu-item-default-menubar.x-menu-item-disabled .x-menu-item-text-default-menubar {
    background-color: transparent;
}

.x-menu-item-default-menubar .x-form-item-label {
    font-size: 14px;
    color: #111;
}

.x-menu-item-text-default-menubar,
.x-menu-item-cmp-default-menubar {
    margin: 0 16px 0 16px;
}

.x-menu-item-text-default-menubar {
    font: 400 14px 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 31px;
    padding-top: 1px;
    color: #111;
    cursor: pointer;
}

.x-menu-item-text-default-menubar.x-menu-item-indent {
    margin-left: 65px;
}

.x-menu-item-text-default-menubar.x-menu-item-indent-no-separator {
    margin-left: 48px;
}

.x-menu-item-text-default-menubar.x-menu-item-indent-right-icon {
    margin-right: 64px;
}

.x-menu-item-text-default-menubar.x-menu-item-indent-right-arrow {
    margin-right: 35px;
}

.x-menu-item-disabled .x-menu-item-text-default-menubar {
    cursor: default;
}

.x-rtl.x-menu-item-text-default-menubar,
.x-rtl.x-menu-item-cmp-default-menubar {
    margin: 0 16px 0 16px;
}

.x-rtl.x-menu-item-text-default-menubar.x-menu-item-indent {
    margin-right: 65px;
}

.x-rtl.x-menu-item-text-default-menubar.x-menu-item-indent-no-separator {
    margin-right: 48px;
}

.x-rtl.x-menu-item-text-default-menubar.x-menu-item-indent-right-icon {
    margin-left: 64px;
}

.x-rtl.x-menu-item-text-default-menubar.x-menu-item-indent-right-arrow {
    margin-left: 35px;
}

.x-menu-item-indent-default-menubar {
    margin-left: 65px;
}

.x-menu-item-indent-default-menubar.x-rtl {
    margin-left: 0;
    margin-right: 65px;
}

.x-menu-item-icon-default-menubar {
    width: 16px;
    height: 16px;
    top: 8px;
    left: 16px;
    line-height: 16px;
    font-size: 16px;
    color: #111;
    background-position: center center;
}

.x-menu-item-icon-default-menubar.x-rtl {
    left: auto;
    right: 16px;
}

.x-menu-item-focus .x-menu-item-icon-default-menubar.x-rtl,
.x-menu-item-active .x-menu-item-icon-default-menubar.x-rtl {
    right: 16px;
}

.x-ie8 .x-menu-item-icon-default-menubar.x-menu-item-glyph {
    color: #111;
}

.x-menu-item-icon-default-menubar.x-menu-item-icon-right {
    width: 16px;
    height: 16px;
    top: 8px;
    right: 16px;
    left: auto;
    background-position: center center;
}

.x-menu-item-icon-default-menubar.x-menu-item-icon-right.x-rtl {
    right: auto;
    left: 16px;
}

.x-menu-item-focus .x-menu-item-icon-default-menubar.x-menu-item-icon-right.x-rtl,
.x-menu-item-active .x-menu-item-icon-default-menubar.x-menu-item-icon-right.x-rtl {
    left: 16px;
}

.x-menu-item-icon-default-menubar.x-menu-item-checkbox {
    color: rgba(17, 17, 17, 0.54);
}

.x-menu-item-focus .x-menu-item-icon-default-menubar.x-menu-item-checkbox,
.x-menu-item-active .x-menu-item-icon-default-menubar.x-menu-item-checkbox {
    color: #111;
}

.x-menu-item-checked .x-menu-item-icon-default-menubar.x-menu-item-checkbox {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 16px;
}

.x-menu-item-checked .x-menu-item-icon-default-menubar.x-menu-item-checkbox:before {
    content: 'check_box';
}

.x-menu-item-unchecked .x-menu-item-icon-default-menubar.x-menu-item-checkbox {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 16px;
}

.x-menu-item-unchecked .x-menu-item-icon-default-menubar.x-menu-item-checkbox:before {
    content: 'check_box_outline_blank';
}

.x-menu-item-focus .x-menu-item-icon-default-menubar.x-menu-group-icon,
.x-menu-item-active .x-menu-item-icon-default-menubar.x-menu-group-icon {
    color: #111;
}

.x-menu-item-checked .x-menu-item-icon-default-menubar.x-menu-group-icon {
    font: 18px/16px 'Material Icons', 'Font Awesome 5 Free';
}

.x-menu-item-checked .x-menu-item-icon-default-menubar.x-menu-group-icon:before {
    content: '\f00c';
}

.x-menu-item-unchecked .x-menu-item-icon-default-menubar.x-menu-group-icon {
    background-image: none;
}

.x-menu-item-unchecked .x-menu-item-icon-default-menubar.x-menu-group-icon:before {
    content: '';
}

.x-menu-item-arrow-default-menubar {
    width: 9px;
    height: 6px;
    top: 9px;
    right: 5px;
    margin: 0 4px 0;
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #919191;
}

.x-menu-item-arrow-default-menubar:before {
    content: '\f0d7';
}

.x-menu-item-focus .x-menu-item-arrow-default-menubar,
.x-menu-item-active .x-menu-item-arrow-default-menubar {
    color: #111;
}

.x-menu-item-focus .x-menu-item-arrow-default-menubar,
.x-menu-item-active .x-menu-item-arrow-default-menubar {
    top: 9px;
    right: 5px;
}

.x-menu-item-arrow-default-menubar.x-rtl {
    left: 5px;
    right: auto;
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-menu-item-arrow-default-menubar.x-rtl:before {
    content: '\f0d7';
}

.x-menu-item-focus .x-menu-item-arrow-default-menubar.x-rtl,
.x-menu-item-active .x-menu-item-arrow-default-menubar.x-rtl {
    left: 5px;
}

.x-menu-default-menubar-scroller .x-box-scroller-body-horizontal {
    margin-left: 16px;
}

.x-menu-default-menubar-scroller.x-rtl .x-box-scroller-body-horizontal {
    margin-left: 0;
    margin-right: 16px;
}

.x-menu-default-menubar-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 16px;
}

.x-box-scroller-menu-default-menubar {
    cursor: pointer;
    color: #919191;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-box-scroller-menu-default-menubar.x-box-scroller-hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-box-scroller-menu-default-menubar.x-box-scroller-pressed {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    opacity: 0.7;
}

.x-box-scroller-menu-default-menubar.x-box-scroller-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    opacity: 0.25;
    cursor: default;
}

.x-box-scroller-menu-default-menubar.x-box-scroller-top,
.x-box-scroller-menu-default-menubar.x-box-scroller-bottom {
    height: 16px;
    width: 16px;
    left: 50%;
    margin-left: -8px;
}

.x-box-scroller-menu-default-menubar.x-box-scroller-top {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 4px;
    font: 16px/16px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-menu-default-menubar.x-box-scroller-top:before {
    content: '\f077';
}

.x-box-scroller-menu-default-menubar.x-box-scroller-bottom {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 4px;
    font: 16px/16px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-menu-default-menubar.x-box-scroller-bottom:before {
    content: '\f078';
}

.x-ie8 .x-box-scroller-menu-default-menubar {
    background-color: #fff;
}

.x-grid-filters-filtered-column {
    font-style: italic;
    font-weight: 600;
    text-decoration: underline;
}

.x-grid-filters-icon {
    background-repeat: no-repeat;
    background-position: center center;
    color: #111;
    text-align: center;
}

.x-grid-filters-find .x-form-item-label-inner {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-grid-filters-find .x-form-item-label-inner:before {
    content: '\f002';
}

.x-grid-filters-gt .x-form-item-label-inner {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-grid-filters-gt .x-form-item-label-inner:before {
    content: '\f054';
}

.x-grid-filters-lt .x-form-item-label-inner {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-grid-filters-lt .x-form-item-label-inner:before {
    content: '\f053';
}

.x-grid-filters-eq .x-form-item-label-inner {
    font: 16px/1 ExtJS;
}

.x-grid-filters-eq .x-form-item-label-inner:before {
    content: "\e605";
}

.x-grid-locked .x-grid-inner-locked {
    border-width: 0 2px 0 0;
    border-style: solid;
}

.x-grid-locked .x-rtl.x-grid-inner-locked {
    border-width: 0 0 0 2px;
}

.x-grid-locked-split .x-grid-inner-normal {
    border-width: 0 0 0 2px;
    border-style: solid;
    border-left-color: #e2e2e2;
}

.x-grid-locked-split .x-rtl.x-grid-inner-normal {
    border-width: 0 2px 0 0;
    border-right-color: #e2e2e2;
}

.x-grid-locking-body {
    border-width: 1px;
}

.x-grid-locking-body > .x-splitter {
    background-color: #e1e1e1;
}

.x-grid-locking-body > .x-splitter-active {
    background-color: #b4b4b4;
}

.x-grid-inner-locked {
    border-right-color: #e2e2e2;
}

.x-grid-inner-locked .x-column-header-last,
.x-grid-inner-locked .x-grid-cell-last {
    border-right-width: 0 !important;
}

.x-grid-inner-locked .x-rtl.x-column-header-last {
    border-left-width: 0 !important;
}

.x-rtl.x-grid-inner-locked {
    border-right-color: #2196f3;
    border-left-color: #e2e2e2;
}

.x-rtl.x-grid-inner-locked .x-grid-row .x-column-header-last {
    border-left: 0 none;
}

.x-rtl.x-grid-inner-locked .x-grid-row .x-grid-cell-last {
    border-left: 0 none;
}

.x-hmenu-lock {
    font: 16px/16px 'Material Icons', 'Font Awesome 5 Free';
}

.x-hmenu-lock:before {
    content: '\f023';
}

.x-hmenu-unlock {
    font: 16px/16px 'Material Icons', 'Font Awesome 5 Free';
}

.x-hmenu-unlock:before {
    content: '\f09c';
}

.x-grid-scrollbar-clipper > .x-grid-view,
.x-grid-scrollbar-clipper > .x-tree-view {
    background-color: #fff;
}

.x-grid-scrollbar-clipper-locked,
.x-grid-scrollbar-locked {
    border-width: 0 2px 0 0;
    border-style: solid;
    border-color: #e2e2e2;
}

.x-grid-locked .x-rtl.x-grid-scrollbar-clipper-locked,
.x-grid-locked .x-rtl.x-grid-scrollbar-locked {
    border-width: 0 0 0 2px;
}

.x-grid-scroll-container {
    background-color: #fff;
}

.x-rtl > .x-grid-scroll-container {
    direction: rtl;
}

.x-rtl > .x-grid-scroll-container > .x-grid-scroll-body {
    direction: rtl;
}

.x-grid-editor .x-form-display-field {
    text-overflow: ellipsis;
}

.x-grid-editor .x-form-action-col-field {
    padding: 15px 4px 15px 4px;
}

.x-grid-editor .x-form-text {
    padding-left: 8px;
    padding-right: 8px;
}

.x-tree-cell-editor .x-form-text {
    padding-left: 5px;
    padding-right: 5px;
}

.x-grid-row-editor .x-field {
    margin: 0 3px 0 2px;
}

.x-grid-row-editor .x-form-display-field {
    padding: 16px 6px 16px 6px;
    line-height: 16px;
}

.x-ie9m .x-grid-row-editor .x-form-display-field {
    min-height: 16px;
}

.x-grid-row-editor .x-form-action-col-field {
    padding: 7px 1px 7px 2px;
}

.x-grid-row-editor .x-form-text {
    padding: 5px 4px 4px 5px;
}

.x-gecko .x-grid-row-editor .x-form-text {
    padding-left: 4px;
    padding-right: 3px;
}

.x-grid-row-editor .x-panel-body {
    border-top: 1px solid #d0d0d0 !important;
    border-bottom: 1px solid #d0d0d0 !important;
    padding: 2px 0 2px 0;
    background-color: #f5f5f5;
}

.x-grid-with-col-lines .x-grid-row-editor .x-form-cb {
    margin-right: 1px;
}

.x-grid-with-col-lines .x-grid-row-editor .x-rtl.x-form-cb {
    margin-right: 0;
    margin-left: 1px;
}

.x-grid-row-editor-buttons-default-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 5px;
    -webkit-border-bottom-right-radius: 5px;
    border-bottom-right-radius: 5px;
    -moz-border-radius-bottomleft: 5px;
    -webkit-border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px 5px 5px 5px;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    background-color: #f5f5f5;
}

.x-grid-row-editor-buttons-default-bottom-mc {
    background-color: #f5f5f5;
}

.x-nbr .x-grid-row-editor-buttons-default-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-grid-row-editor-buttons-default-bottom-frameInfo {
    font-family: th-0-5-5-5-0-1-1-1-5-5-5-5;
}

.x-grid-row-editor-buttons-default-bottom-tl {
    background-position: 0 -10px;
}

.x-grid-row-editor-buttons-default-bottom-tr {
    background-position: right -15px;
}

.x-grid-row-editor-buttons-default-bottom-bl {
    background-position: 0 -20px;
}

.x-grid-row-editor-buttons-default-bottom-br {
    background-position: right -25px;
}

.x-grid-row-editor-buttons-default-bottom-ml {
    background-position: 0 top;
}

.x-grid-row-editor-buttons-default-bottom-mr {
    background-position: right top;
}

.x-grid-row-editor-buttons-default-bottom-tc {
    background-position: 0 0;
}

.x-grid-row-editor-buttons-default-bottom-bc {
    background-position: 0 -5px;
}

.x-grid-row-editor-buttons-default-bottom-tr,
.x-grid-row-editor-buttons-default-bottom-br,
.x-grid-row-editor-buttons-default-bottom-mr {
    padding-right: 5px;
}

.x-grid-row-editor-buttons-default-bottom-tl,
.x-grid-row-editor-buttons-default-bottom-bl,
.x-grid-row-editor-buttons-default-bottom-ml {
    padding-left: 5px;
}

.x-grid-row-editor-buttons-default-bottom-tc {
    height: 0;
}

.x-grid-row-editor-buttons-default-bottom-bc {
    height: 5px;
}

.x-grid-row-editor-buttons-default-bottom-tl,
.x-grid-row-editor-buttons-default-bottom-bl,
.x-grid-row-editor-buttons-default-bottom-tr,
.x-grid-row-editor-buttons-default-bottom-br,
.x-grid-row-editor-buttons-default-bottom-tc,
.x-grid-row-editor-buttons-default-bottom-bc,
.x-grid-row-editor-buttons-default-bottom-ml,
.x-grid-row-editor-buttons-default-bottom-mr {
    /*background-image: url(./../images/grid-row-editor-buttons/grid-row-editor-buttons-default-bottom-corners.gif);*/
}

.x-grid-row-editor-buttons-default-bottom-ml,
.x-grid-row-editor-buttons-default-bottom-mr {
    /*background-image: url(./../images/grid-row-editor-buttons/grid-row-editor-buttons-default-bottom-sides.gif);*/
    background-repeat: repeat-y;
}

.x-grid-row-editor-buttons-default-bottom-mc {
    padding: 5px 1px 1px 1px;
}

.x-grid-row-editor-buttons-default-top {
    -moz-border-radius-topleft: 5px;
    -webkit-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    -moz-border-radius-topright: 5px;
    -webkit-border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 5px 5px 5px 5px;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    background-color: #f5f5f5;
}

.x-grid-row-editor-buttons-default-top-mc {
    background-color: #f5f5f5;
}

.x-nbr .x-grid-row-editor-buttons-default-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-grid-row-editor-buttons-default-top-frameInfo {
    font-family: th-5-5-0-5-1-1-0-1-5-5-5-5;
}

.x-grid-row-editor-buttons-default-top-tl {
    background-position: 0 -10px;
}

.x-grid-row-editor-buttons-default-top-tr {
    background-position: right -15px;
}

.x-grid-row-editor-buttons-default-top-bl {
    background-position: 0 -20px;
}

.x-grid-row-editor-buttons-default-top-br {
    background-position: right -25px;
}

.x-grid-row-editor-buttons-default-top-ml {
    background-position: 0 top;
}

.x-grid-row-editor-buttons-default-top-mr {
    background-position: right top;
}

.x-grid-row-editor-buttons-default-top-tc {
    background-position: 0 0;
}

.x-grid-row-editor-buttons-default-top-bc {
    background-position: 0 -5px;
}

.x-grid-row-editor-buttons-default-top-tr,
.x-grid-row-editor-buttons-default-top-br,
.x-grid-row-editor-buttons-default-top-mr {
    padding-right: 5px;
}

.x-grid-row-editor-buttons-default-top-tl,
.x-grid-row-editor-buttons-default-top-bl,
.x-grid-row-editor-buttons-default-top-ml {
    padding-left: 5px;
}

.x-grid-row-editor-buttons-default-top-tc {
    height: 5px;
}

.x-grid-row-editor-buttons-default-top-bc {
    height: 0;
}

.x-grid-row-editor-buttons-default-top-tl,
.x-grid-row-editor-buttons-default-top-bl,
.x-grid-row-editor-buttons-default-top-tr,
.x-grid-row-editor-buttons-default-top-br,
.x-grid-row-editor-buttons-default-top-tc,
.x-grid-row-editor-buttons-default-top-bc,
.x-grid-row-editor-buttons-default-top-ml,
.x-grid-row-editor-buttons-default-top-mr {
    /*background-image: url(./../images/grid-row-editor-buttons/grid-row-editor-buttons-default-top-corners.gif);*/
}

.x-grid-row-editor-buttons-default-top-ml,
.x-grid-row-editor-buttons-default-top-mr {
    /*background-image: url(./../images/grid-row-editor-buttons/grid-row-editor-buttons-default-top-sides.gif);*/
    background-repeat: repeat-y;
}

.x-grid-row-editor-buttons-default-top-mc {
    padding: 1px 1px 5px 1px;
}

.x-grid-row-editor-buttons {
    border-color: #d0d0d0;
}

.x-row-editor-update-button {
    margin-right: 3px;
}

.x-row-editor-cancel-button {
    margin-left: 2px;
}

.x-rtl.x-row-editor-update-button {
    margin-left: 3px;
    margin-right: auto;
}

.x-rtl.x-row-editor-cancel-button {
    margin-right: 2px;
    margin-left: auto;
}

.x-grid-row-editor-errors .x-tip-body {
    padding: 5px;
}

.x-grid-row-editor-errors-item {
    list-style: disc;
    margin-left: 15px;
}

.x-rtl.x-grid-row-editor-errors .x-grid-row-editor-errors-item {
    margin-left: 0;
    margin-right: 15px;
}

.x-grid-cell-inner-row-expander {
    padding: 16px 8px 16px 8px;
}

.x-grid-row-expander {
    width: 16px;
    height: 16px;
    cursor: pointer;
    color: #919191;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
}

.x-grid-row-expander:before {
    content: 'expand_less';
}

.x-grid-row-collapsed .x-grid-row-expander {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
}

.x-grid-row-collapsed .x-grid-row-expander:before {
    content: 'expand_more';
}

.x-ssm-row-numberer-hd {
    cursor: se-resize !important;
}

.x-ssm-row-numberer-cell {
    cursor: e-resize;
}

.x-ssm-column-select .x-column-header {
    cursor: s-resize;
}

.x-ssm-extender-drag-handle {
    height: 7px;
    width: 7px;
    background-color: #2196f3;
}

.x-ssm-extender-mask {
    border: 1px dotted #2196f3;
}

.x-accordion-layout-ct {
    background-color: #fff;
    padding: 16px 8px;
}

.x-accordion-hd .x-panel-header-title {
    color: #111;
    font-weight: 600;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    text-transform: none;
}

.x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus {
    outline: 1px solid #2196f3;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus,
.x-ie10p .x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus,
.x-edge .x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus:after,
.x-ie10p .x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus:after,
.x-edge .x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #2196f3;
    pointer-events: none;
}

.x-accordion-item {
    margin: 0 0 5px;
}

.x-accordion-item .x-accordion-hd {
    background: #f5f5f5;
    border-width: 0;
    border-color: #2196f3;
    padding: 16px 8px;
}

.x-accordion-item .x-accordion-hd-over {
    background-color: #f6f6f6;
}

.x-accordion-item .x-accordion-hd-over .x-tool-tool-el {
    background-color: #f6f6f6;
}

.x-accordion-item .x-accordion-hd-sibling-expanded {
    border-top-color: #2196f3;
    border-top-width: 0;
}

.x-accordion-item .x-accordion-hd-last-collapsed {
    border-bottom-color: #f5f5f5;
}

.x-accordion-item .x-accordion-body {
    border-width: 0;
}

.x-accordion-hd .x-tool-tool-el {
    background-color: #f5f5f5;
    color: #616161;
}

.x-accordion-hd .x-tool-img {
    background-image: url(./../images/tools/tool-sprites-dark.png);
    background-image: none;
}

.x-accordion-hd .x-tool-collapse-top,
.x-accordion-hd .x-tool-collapse-bottom {
    font: 16px/1 'Font Awesome 5 Free';
    background-image: none;
}

.x-accordion-hd .x-tool-collapse-top:before,
.x-accordion-hd .x-tool-collapse-bottom:before {
    content: '\f068';
}

.x-accordion-hd .x-tool-expand-top,
.x-accordion-hd .x-tool-expand-bottom {
    font: 16px/1 'Font Awesome 5 Free';
    background-image: none;
}

.x-accordion-hd .x-tool-expand-top:before,
.x-accordion-hd .x-tool-expand-bottom:before {
    content: '\f067';
}

.x-keyboard-mode .x-accordion-hd .x-tool-focus {
    outline: 1px solid #2196f3;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-accordion-hd .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-accordion-hd .x-tool-focus,
.x-edge .x-keyboard-mode .x-accordion-hd .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-accordion-hd .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-accordion-hd .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-accordion-hd .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #2196f3;
    pointer-events: none;
}

body.x-border-layout-ct,
div.x-border-layout-ct {
    background-color: #e1e1e1;
}

.x-form-layout-wrap {
    border-spacing: 10px;
}

.x-resizable-handle {
    position: absolute;
    z-index: 100;
    font-size: 1px;
    line-height: 10px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    background-color: #ececec;
    color: #919191;
    text-align: center;
}

.x-collapsed .x-resizable-handle {
    display: none;
}

.x-resizable-handle-southeast {
    cursor: se-resize;
}

.x-resizable-handle-southeast:before {
    position: absolute;
    bottom: 0;
    right: 0;
}

.x-resizable-handle-northwest {
    cursor: nw-resize;
}

.x-resizable-handle-northwest:before {
    position: absolute;
    top: 0;
    left: 0;
}

.x-resizable-handle-northeast {
    cursor: ne-resize;
}

.x-resizable-handle-northeast:before {
    position: absolute;
    top: 0;
    right: 0;
}

.x-resizable-handle-southwest {
    cursor: sw-resize;
}

.x-resizable-handle-southwest:before {
    position: absolute;
    bottom: 0;
    left: 0;
}

.x-resizable-handle-east:before,
.x-resizable-handle-west:before {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    width: 100%;
}

.x-resizable-handle-east {
    cursor: e-resize;
    width: 10px;
    right: 0;
    top: 0;
    bottom: 0;
}

.x-resizable-handle-south {
    cursor: s-resize;
    height: 10px;
    left: 0;
    right: 0;
    bottom: 0;
}

.x-resizable-handle-west {
    cursor: w-resize;
    width: 10px;
    left: 0;
    top: 0;
    bottom: 0;
}

.x-resizable-handle-north {
    cursor: n-resize;
    height: 10px;
    left: 0;
    right: 0;
    top: 0;
}

.x-resizable-handle-southeast {
    width: 10px;
    height: 10px;
    right: 0;
    bottom: 0;
    z-index: 101;
}

.x-resizable-handle-northwest {
    width: 10px;
    height: 10px;
    left: 0;
    top: 0;
    z-index: 101;
}

.x-resizable-handle-northeast {
    width: 10px;
    height: 10px;
    right: 0;
    top: 0;
    z-index: 101;
}

.x-resizable-handle-southwest {
    width: 10px;
    height: 10px;
    left: 0;
    bottom: 0;
    z-index: 101;
}

.x-tablet .x-resizable-handle-north,
.x-tablet .x-resizable-handle-south {
    height: 20px;
}

.x-tablet .x-resizable-handle-east,
.x-tablet .x-resizable-handle-west {
    width: 20px;
}

.x-tablet .x-resizable-handle-northwest,
.x-tablet .x-resizable-handle-northeast,
.x-tablet .x-resizable-handle-southwest,
.x-tablet .x-resizable-handle-southeast {
    width: 20px;
    height: 20px;
}

.x-window .x-window-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-window-collapsed .x-window-handle {
    display: none;
}

.x-resizable-proxy {
    border: 1px dashed #3b5a82;
    position: absolute;
    overflow: hidden;
    z-index: 50000;
}

.x-resizable-handle-over,
.x-resizable-pinned .x-resizable-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-resizable-handle-east-over,
.x-resizable-handle-west-over,
.x-resizable-pinned > .x-resizable-handle-east,
.x-resizable-pinned > .x-resizable-handle-west {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
}

.x-resizable-handle-east-over:before,
.x-resizable-handle-west-over:before,
.x-resizable-pinned > .x-resizable-handle-east:before,
.x-resizable-pinned > .x-resizable-handle-west:before {
    content: '\f142';
}

.x-resizable-handle-south-over,
.x-resizable-handle-north-over,
.x-resizable-pinned > .x-resizable-handle-south,
.x-resizable-pinned > .x-resizable-handle-north {
    font: 16px/10px 'Material Icons', 'Font Awesome 5 Free';
}

.x-resizable-handle-south-over:before,
.x-resizable-handle-north-over:before,
.x-resizable-pinned > .x-resizable-handle-south:before,
.x-resizable-pinned > .x-resizable-handle-north:before {
    content: '\f141';
}

.x-resizable-handle-southeast-over,
.x-resizable-pinned > .x-resizable-handle-southeast {
    font: 16px/1 ExtJS;
}

.x-resizable-handle-southeast-over:before,
.x-resizable-pinned > .x-resizable-handle-southeast:before {
    content: "\e60c";
}

.x-resizable-handle-northwest-over,
.x-resizable-pinned > .x-resizable-handle-northwest {
    font: 16px/1 ExtJS;
}

.x-resizable-handle-northwest-over:before,
.x-resizable-pinned > .x-resizable-handle-northwest:before {
    content: "\e609";
}

.x-resizable-handle-northeast-over,
.x-resizable-pinned > .x-resizable-handle-northeast {
    font: 16px/1 ExtJS;
}

.x-resizable-handle-northeast-over:before,
.x-resizable-pinned > .x-resizable-handle-northeast:before {
    content: "\e60a";
}

.x-resizable-handle-southwest-over,
.x-resizable-pinned > .x-resizable-handle-southwest {
    font: 16px/1 ExtJS;
}

.x-resizable-handle-southwest-over:before,
.x-resizable-pinned > .x-resizable-handle-southwest:before {
    content: "\e60b";
}

.x-slider:before {
    content: '';
    position: absolute;
    border-radius: 2px;
    background-color: #e1e1e1;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.x-slider-thumb {
    background-color: #2196f3;
    border-radius: 9px;
}

.x-slider-thumb:before {
    color: #d0d0d0;
}

.x-slider-thumb-over {
    background-color: #bce0fb;
    border-color: #c5cdd4;
}

.x-slider-thumb-over:before {
    color: #d0d0d0;
}

.x-keyboard-mode .x-slider-focus .x-slider-thumb {
    background-color: #2196f3;
    border-color: #2196f3;
}

.x-keyboard-mode .x-slider-focus .x-slider-thumb:before {
    color: #87c1f0;
}

.x-keyboard-mode .x-slider-focus .x-slider-thumb-over {
    background-color: #bce0fb;
}

.x-slider-thumb-drag {
    background-color: #2093ee;
    border-color: #bcc8d1;
}

.x-slider-thumb-drag:before {
    color: #d0d0d0;
}

.x-keyboard-mode .x-slider-focus .x-slider-thumb-drag {
    background-color: #2093ee;
}

.x-slider-horz {
    padding-left: 9px;
    background: no-repeat 0 -18px;
    margin: 7px 0 7px;
}

.x-slider-horz .x-slider-end {
    padding-right: 9px;
    background: no-repeat right -36px;
}

.x-slider-horz .x-slider-inner {
    height: 18px;
}

.x-slider-horz:before {
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
}

.x-slider-horz .x-slider-thumb {
    width: 18px;
    height: 18px;
    margin-left: -9px;
    font: 0/1 ExtJS;
}

.x-slider-horz .x-slider-thumb:before {
    content: "\e612";
}

.x-rtl.x-slider-horz {
    padding-left: 0;
    padding-right: 9px;
}

.x-rtl.x-slider-horz .x-slider-end {
    padding-right: 0;
    padding-left: 9px;
}

.x-rtl.x-slider-horz .x-slider-thumb {
    margin-right: -9px;
}

.x-slider-ct-vert {
    height: 100%;
}

.x-slider-vert {
    padding-top: 10px;
    height: 100%;
}

.x-slider-vert > .x-slider-end {
    height: 100%;
}

.x-slider-vert > .x-slider-end > .x-slider-inner {
    height: 100%;
}

.x-slider-vert:before {
    width: 2px;
    height: 100%;
    left: 10px;
    top: 0;
    margin-left: -1px;
}

.x-slider-vert .x-slider-end {
    padding-bottom: 10px;
    background: no-repeat -20px bottom;
    width: 20px;
}

.x-slider-vert .x-slider-inner {
    width: 20px;
}

.x-slider-vert .x-slider-thumb {
    width: 20px;
    height: 20px;
    margin-bottom: -10px;
    font: 0/1 ExtJS;
}

.x-slider-vert .x-slider-thumb:before {
    content: "\e605";
}

.x-slider-default-cell > .x-grid-cell-inner,
.x-sliderwidget-default-cell > .x-grid-cell-inner {
    padding-top: 8px;
    padding-bottom: 8px;
}

.x-tab-default-top {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 16px 32px 16px 32px;
    border-width: 0 0 5px 0;
    border-style: solid;
    background-color: #2196f3;
}

.x-tab-default-top-mc {
    background-color: #2196f3;
}

.x-nbr .x-tab-default-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tab-default-top-frameInfo {
    font-family: th-0-0-5-0-0-0-5-0-16-32-16-32;
}

.x-tab-default-top-tl {
    background-position: 0 -10px;
}

.x-tab-default-top-tr {
    background-position: right -15px;
}

.x-tab-default-top-bl {
    background-position: 0 -20px;
}

.x-tab-default-top-br {
    background-position: right -25px;
}

.x-tab-default-top-ml {
    background-position: 0 top;
}

.x-tab-default-top-mr {
    background-position: right top;
}

.x-tab-default-top-tc {
    background-position: 0 0;
}

.x-tab-default-top-bc {
    background-position: 0 -5px;
}

.x-tab-default-top-tr,
.x-tab-default-top-br,
.x-tab-default-top-mr {
    padding-right: 0;
}

.x-tab-default-top-tl,
.x-tab-default-top-bl,
.x-tab-default-top-ml {
    padding-left: 0;
}

.x-tab-default-top-tc {
    height: 0;
}

.x-tab-default-top-bc {
    height: 5px;
}

.x-tab-default-top-tl,
.x-tab-default-top-bl,
.x-tab-default-top-tr,
.x-tab-default-top-br,
.x-tab-default-top-tc,
.x-tab-default-top-bc,
.x-tab-default-top-ml,
.x-tab-default-top-mr {
    /*background-image: url(./../images/tab/tab-default-top-corners.gif);*/
}

.x-tab-default-top-ml,
.x-tab-default-top-mr {
    /*background-image: url(./../images/tab/tab-default-top-sides.gif);*/
    background-repeat: repeat-y;
}

.x-tab-default-top-mc {
    padding: 16px 32px 16px 32px;
}

.x-tab-default-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 32px 16px 32px;
    border-width: 5px 0 0 0;
    border-style: solid;
    background-color: #2196f3;
}

.x-tab-default-bottom-mc {
    background-color: #2196f3;
}

.x-nbr .x-tab-default-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tab-default-bottom-frameInfo {
    font-family: th-5-0-0-0-5-0-0-0-16-32-16-32;
}

.x-tab-default-bottom-tl {
    background-position: 0 -10px;
}

.x-tab-default-bottom-tr {
    background-position: right -15px;
}

.x-tab-default-bottom-bl {
    background-position: 0 -20px;
}

.x-tab-default-bottom-br {
    background-position: right -25px;
}

.x-tab-default-bottom-ml {
    background-position: 0 bottom;
}

.x-tab-default-bottom-mr {
    background-position: right bottom;
}

.x-tab-default-bottom-tc {
    background-position: 0 0;
}

.x-tab-default-bottom-bc {
    background-position: 0 -5px;
}

.x-tab-default-bottom-tr,
.x-tab-default-bottom-br,
.x-tab-default-bottom-mr {
    padding-right: 0;
}

.x-tab-default-bottom-tl,
.x-tab-default-bottom-bl,
.x-tab-default-bottom-ml {
    padding-left: 0;
}

.x-tab-default-bottom-tc {
    height: 5px;
}

.x-tab-default-bottom-bc {
    height: 0;
}

.x-tab-default-bottom-tl,
.x-tab-default-bottom-bl,
.x-tab-default-bottom-tr,
.x-tab-default-bottom-br,
.x-tab-default-bottom-tc,
.x-tab-default-bottom-bc,
.x-tab-default-bottom-ml,
.x-tab-default-bottom-mr {
    /*background-image: url(./../images/tab/tab-default-bottom-corners.gif);*/
}

.x-tab-default-bottom-ml,
.x-tab-default-bottom-mr {
    /*background-image: url(./../images/tab/tab-default-bottom-sides.gif);*/
    background-repeat: repeat-y;
}

.x-tab-default-bottom-mc {
    padding: 16px 32px 16px 32px;
}

.x-tab-default-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 32px 16px 32px;
    border-width: 0 5px 0 0;
    border-style: solid;
    background-color: #2196f3;
}

.x-tab-default-left-mc {
    background-color: #2196f3;
}

.x-nbr .x-tab-default-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tab-default-left-frameInfo {
    font-family: th-0-5-0-0-0-5-0-0-16-32-16-32;
}

.x-tab-default-left-tl {
    background-position: 0 -10px;
}

.x-tab-default-left-tr {
    background-position: right -15px;
}

.x-tab-default-left-bl {
    background-position: 0 -20px;
}

.x-tab-default-left-br {
    background-position: right -25px;
}

.x-tab-default-left-ml {
    background-position: 0 top;
}

.x-tab-default-left-mr {
    background-position: right top;
}

.x-tab-default-left-tc {
    background-position: 0 0;
}

.x-tab-default-left-bc {
    background-position: 0 -5px;
}

.x-tab-default-left-tr,
.x-tab-default-left-br,
.x-tab-default-left-mr {
    padding-right: 5px;
}

.x-tab-default-left-tl,
.x-tab-default-left-bl,
.x-tab-default-left-ml {
    padding-left: 0;
}

.x-tab-default-left-tc {
    height: 0;
}

.x-tab-default-left-bc {
    height: 0;
}

.x-tab-default-left-tl,
.x-tab-default-left-bl,
.x-tab-default-left-tr,
.x-tab-default-left-br,
.x-tab-default-left-tc,
.x-tab-default-left-bc,
.x-tab-default-left-ml,
.x-tab-default-left-mr {
    /*background-image: url(./../images/tab/tab-default-left-corners.gif);*/
}

.x-tab-default-left-ml,
.x-tab-default-left-mr {
    /*background-image: url(./../images/tab/tab-default-left-sides.gif);*/
    background-repeat: repeat-y;
}

.x-tab-default-left-mc {
    padding: 16px 32px 16px 32px;
}

.x-tab-default-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 32px 16px 32px;
    border-width: 0 0 0 5px;
    border-style: solid;
    background-color: #2196f3;
}

.x-tab-default-right-mc {
    background-color: #2196f3;
}

.x-nbr .x-tab-default-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tab-default-right-frameInfo {
    font-family: th-0-0-0-5-0-0-0-5-16-32-16-32;
}

.x-tab-default-right-tl {
    background-position: 0 -10px;
}

.x-tab-default-right-tr {
    background-position: right -15px;
}

.x-tab-default-right-bl {
    background-position: 0 -20px;
}

.x-tab-default-right-br {
    background-position: right -25px;
}

.x-tab-default-right-ml {
    background-position: 0 top;
}

.x-tab-default-right-mr {
    background-position: right top;
}

.x-tab-default-right-tc {
    background-position: 0 0;
}

.x-tab-default-right-bc {
    background-position: 0 -5px;
}

.x-tab-default-right-tr,
.x-tab-default-right-br,
.x-tab-default-right-mr {
    padding-right: 0;
}

.x-tab-default-right-tl,
.x-tab-default-right-bl,
.x-tab-default-right-ml {
    padding-left: 5px;
}

.x-tab-default-right-tc {
    height: 0;
}

.x-tab-default-right-bc {
    height: 0;
}

.x-tab-default-right-tl,
.x-tab-default-right-bl,
.x-tab-default-right-tr,
.x-tab-default-right-br,
.x-tab-default-right-tc,
.x-tab-default-right-bc,
.x-tab-default-right-ml,
.x-tab-default-right-mr {
    /*background-image: url(./../images/tab/tab-default-right-corners.gif);*/
}

.x-tab-default-right-ml,
.x-tab-default-right-mr {
    /*background-image: url(./../images/tab/tab-default-right-sides.gif);*/
    background-repeat: repeat-y;
}

.x-tab-default-right-mc {
    padding: 16px 32px 16px 32px;
}

.x-tab-default {
    border-color: #2196f3;
    cursor: pointer;
}

.x-tab-bar-plain .x-tab-default {
    background-color: transparent;
}

.x-tab-bar-plain .x-tab-default {
    border-color: transparent;
}

.x-tab-default-top {
    margin: 0 4px 0 0;
}

.x-tab-default-top.x-rtl {
    margin: 0 0 0 4px;
}

.x-tab-default-top.x-tab-rotate-left {
    margin: 0 0 0 4px;
}

.x-tab-default-top.x-tab-rotate-left.x-rtl {
    margin: 0 4px 0 0;
}

.x-keyboard-mode .x-tab-default-top.x-tab-focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-top.x-tab-focus.x-tab-over {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-top.x-tab-focus.x-tab-active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-tab-default-right {
    margin: 0 0 4px 0;
}

.x-tab-default-right.x-rtl {
    margin: 0 0 4px 0;
}

.x-tab-default-right.x-tab-rotate-right {
    margin: 4px 0 0 0;
}

.x-tab-default-right.x-tab-rotate-right.x-rtl {
    margin: 4px 0 0 0;
}

.x-keyboard-mode .x-tab-default-right.x-tab-focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-right.x-tab-focus.x-tab-over {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-right.x-tab-focus.x-tab-active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-tab-default-bottom {
    margin: 0 4px 0 0;
}

.x-tab-default-bottom.x-rtl {
    margin: 0 0 0 4px;
}

.x-tab-default-bottom.x-tab-rotate-left {
    margin: 0 0 0 4px;
}

.x-tab-default-bottom.x-tab-rotate-left.x-rtl {
    margin: 0 4px 0 0;
}

.x-keyboard-mode .x-tab-default-bottom.x-tab-focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-bottom.x-tab-focus.x-tab-over {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-bottom.x-tab-focus.x-tab-active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-tab-default-left {
    margin: 0 0 4px 0;
}

.x-tab-default-left.x-rtl {
    margin: 0 0 4px 0;
}

.x-tab-default-left.x-tab-rotate-right {
    margin: 4px 0 0 0;
}

.x-tab-default-left.x-tab-rotate-right.x-rtl {
    margin: 4px 0 0 0;
}

.x-keyboard-mode .x-tab-default-left.x-tab-focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-left.x-tab-focus.x-tab-over {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-left.x-tab-focus.x-tab-active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-tab-button-default {
    height: 20px;
}

.x-tab-inner-default {
    font: 600 14px/20px 'Nunito Sans', 'Open Sans', sans-serif;
    color: rgba(255, 255, 255, 0.7);
    max-width: 100%;
}

.x-tab-bar-plain .x-tab-inner-default {
    color: #2196f3;
}

.x-tab-icon-right > .x-tab-inner-default,
.x-tab-icon-left > .x-tab-inner-default {
    max-width: calc(100% - 20px);
}

.x-tab-icon-el-default {
    min-height: 20px;
    background-position: center center;
    font-size: 20px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.7);
}

.x-tab-icon-left > .x-tab-icon-el-default,
.x-tab-icon-right > .x-tab-icon-el-default {
    width: 20px;
}

.x-tab-icon-top > .x-tab-icon-el-default,
.x-tab-icon-bottom > .x-tab-icon-el-default {
    min-width: 20px;
}

.x-tab-bar-plain .x-tab-icon-el-default {
    color: #2196f3;
}

.x-ie8 .x-tab-icon-el-default.x-tab-glyph {
    color: rgba(255, 255, 255, 0.7);
}

.x-tab-text.x-tab-icon-left > .x-tab-icon-el-default {
    margin-right: 6px;
}

.x-tab-text.x-tab-icon-left > .x-tab-icon-el-default.x-rtl {
    margin-right: 0;
    margin-left: 6px;
}

.x-tab-text.x-tab-icon-right > .x-tab-icon-el-default {
    margin-left: 6px;
}

.x-tab-text.x-tab-icon-right > .x-tab-icon-el-default.x-rtl {
    margin-left: 0;
    margin-right: 6px;
}

.x-tab-text.x-tab-icon-top > .x-tab-icon-el-default {
    margin-bottom: 6px;
}

.x-tab-text.x-tab-icon-bottom > .x-tab-icon-el-default {
    margin-top: 6px;
}

.x-keyboard-mode .x-tab-focus.x-tab-default {
    border-color: #2196f3;
    background-color: #2196f3;
    outline: 1px none #2196f3;
    outline-offset: -3px;
}


.x-ie .x-keyboard-mode .x-tab-focus.x-tab-default,
.x-ie10p .x-keyboard-mode .x-tab-focus.x-tab-default,
.x-edge .x-keyboard-mode .x-tab-focus.x-tab-default {
    outline: none;
}

.x-ie .x-keyboard-mode .x-tab-focus.x-tab-default:after,
.x-ie10p .x-keyboard-mode .x-tab-focus.x-tab-default:after,
.x-edge .x-keyboard-mode .x-tab-focus.x-tab-default:after {
    position: absolute;
    content: ' ';
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border: 1px none #2196f3;
    pointer-events: none;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-default {
    background-color: transparent;
    border-color: transparent;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-default .x-tab-inner-default {
    color: #2196f3;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-default .x-tab-icon-el {
    color: #2196f3;
}

.x-tab-over.x-tab-default {
    border-color: transparent;
    background-image: none;
    background-color: rgba(33, 150, 243, 0.08);
}

.x-ie8 .x-tab-over.x-tab-default {
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#142196f3, endColorstr=#142196f3)";
    zoom: 1;
}

.x-ie8 .x-tab-over.x-tab-default.x-tab-rotate-left {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-ie8 .x-tab-over.x-tab-default.x-tab-rotate-right {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-tab-bar-plain .x-tab-over.x-tab-default {
    background-color: transparent;
}

.x-tab-bar-plain .x-tab-over.x-tab-default .x-tab-inner-default {
    color: #2196f3;
}

.x-tab-bar-plain .x-tab-over.x-tab-default .x-tab-icon-el {
    color: #2196f3;
}

.x-keyboard-mode .x-tab-focus.x-tab-over.x-tab-default {
    border-color: transparent;
    background-image: none;
    background-color: rgba(33, 150, 243, 0.08);
}

.x-ie8 .x-keyboard-mode .x-tab-focus.x-tab-over.x-tab-default {
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#142196f3, endColorstr=#142196f3)";
    zoom: 1;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-over.x-tab-default {
    background-color: transparent;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-over.x-tab-default .x-tab-inner-default {
    color: #2196f3;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-over.x-tab-default .x-tab-icon-el {
    color: #2196f3;
}

.x-keyboard-mode.x-ie8 .x-tab-focus.x-tab-over.x-tab-default.x-tab-rotate-left {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-keyboard-mode.x-ie8 .x-tab-focus.x-tab-over.x-tab-default.x-tab-rotate-right {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-tab.x-tab-active.x-tab-default {
    border-color: #f5f5f5;
    background-color: #2196f3;
}

.x-tab-bar-plain .x-tab.x-tab-active.x-tab-default {
    background-color: transparent;
}

.x-tab-bar-plain .x-tab.x-tab-active.x-tab-default {
    border-color: #2196f3;
}

.x-tab.x-tab-active.x-tab-default .x-tab-inner-default {
    color: #fff;
}

.x-tab-bar-plain .x-tab.x-tab-active.x-tab-default .x-tab-inner-default {
    color: #2196f3;
}

.x-tab.x-tab-active.x-tab-default .x-tab-icon-el {
    color: #fff;
}

.x-ie8 .x-tab.x-tab-active.x-tab-default .x-tab-icon-el {
    color: #fff;
}

.x-tab-bar-plain .x-tab.x-tab-active.x-tab-default .x-tab-icon-el {
    color: #2196f3;
}

.x-keyboard-mode .x-tab-focus.x-tab-active.x-tab-default {
    border-color: #f5f5f5;
    background-color: #2196f3;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-active.x-tab-default {
    background-color: transparent;
    border-color: #2196f3;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-active.x-tab-default .x-tab-inner-default {
    color: #2196f3;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-active.x-tab-default .x-tab-icon-el {
    color: #2196f3;
}

.x-tab.x-tab-disabled.x-tab-default {
    border-color: #2196f3;
    background-color: #2196f3;
    cursor: default;
}

.x-tab-bar-plain .x-tab.x-tab-disabled.x-tab-default {
    background-color: transparent;
}

.x-tab-bar-plain .x-tab.x-tab-disabled.x-tab-default {
    border-color: transparent;
}

.x-tab.x-tab-disabled.x-tab-default .x-tab-inner-default {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
    opacity: 0.75;
}

.x-tab-bar-plain .x-tab.x-tab-disabled.x-tab-default .x-tab-inner-default {
    color: #2196f3;
}

.x-tab.x-tab-disabled.x-tab-default .x-tab-icon-el-default {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-tab.x-tab-disabled.x-tab-default .x-tab-icon-el {
    color: rgba(255, 255, 255, 0.7);
    opacity: 0.3;
    filter: none;
}

.x-ie8 .x-tab.x-tab-disabled.x-tab-default .x-tab-icon-el {
    color: rgba(75, 170, 245, 0.9099999999999999);
}

.x-tab-bar-plain .x-tab.x-tab-disabled.x-tab-default .x-tab-icon-el {
    color: #2196f3;
}

.x-nbr .x-tab-default {
    background-image: none;
}

.x-tab-default-tl,
.x-tab-default-bl,
.x-tab-default-tr,
.x-tab-default-br {
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)";
}

.x-tab-default .x-tab-close-btn {
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: rgba(255, 255, 255, 0.7);
}

.x-tab-default .x-tab-close-btn:before {
    content: '\f00d';
}

.x-tab-bar-plain .x-tab-default .x-tab-close-btn {
    color: #919191;
}

.x-tab-default .x-tab-close-btn-over {
    background-position: -16px 0;
}

.x-tab-default .x-tab-close-btn-pressed {
    background-position: -32px 0;
}

.x-keyboard-mode .x-tab-default.x-tab-focus .x-tab-close-btn {
    color: rgba(255, 255, 255, 0.7);
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-default.x-tab-focus .x-tab-close-btn {
    color: #919191;
}

.x-tab-default.x-tab-over .x-tab-close-btn {
    color: rgba(255, 255, 255, 0.7);
}

.x-tab-bar-plain .x-tab-default.x-tab-over .x-tab-close-btn {
    color: #919191;
}

.x-keyboard-mode .x-tab-default.x-tab-focus.x-tab-over .x-tab-close-btn {
    color: rgba(255, 255, 255, 0.7);
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-default.x-tab-focus.x-tab-over .x-tab-close-btn {
    color: #919191;
}

.x-tab-default.x-tab-active .x-tab-close-btn {
    color: #fff;
}

.x-keyboard-mode .x-tab-default.x-tab-focus.x-tab-active .x-tab-close-btn {
    color: #fff;
}

.x-tab-default.x-tab-disabled .x-tab-close-btn {
    color: rgba(255, 255, 255, 0.7);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
    opacity: 0.75;
    background-position: 0 0;
}

.x-tab-bar-plain .x-tab-default.x-tab-disabled .x-tab-close-btn {
    color: #919191;
}

.x-rtl.x-tab-default .x-tab-close-btn {
    right: auto;
    left: 0;
}

.x-tab-closable.x-tab-default .x-tab-button {
    padding-right: 16px;
}

.x-rtl.x-tab-closable.x-tab-default .x-tab-button {
    padding-right: 0;
    padding-left: 16px;
}

.x-tab-bar-default {
    background-color: #2196f3;
}

.x-tab-bar-default-top > .x-tab-bar-body-default {
    padding: 8px 4px 0 8px;
}

.x-tab-bar-default-bottom > .x-tab-bar-body-default {
    padding: 0 4px 8px 8px;
}

.x-tab-bar-default-left > .x-tab-bar-body-default {
    padding: 4px 0 8px 8px;
}

.x-rtl.x-tab-bar-default-left > .x-tab-bar-body-default {
    padding: 4px 8px 8px 0;
}

.x-tab-bar-default-right > .x-tab-bar-body-default {
    padding: 8px 8px 4px 0;
}

.x-rtl.x-tab-bar-default-right > .x-tab-bar-body-default {
    padding: 8px 0 4px 8px;
}

.x-tab-bar-plain.x-tab-bar-default-top > .x-tab-bar-body-default {
    padding: 8px 0 0;
}

.x-tab-bar-plain.x-tab-bar-default-bottom > .x-tab-bar-body-default {
    padding: 0 0 8px 0;
}

.x-tab-bar-plain.x-tab-bar-default-left > .x-tab-bar-body-default {
    padding: 0 0 0 8px;
}

.x-rtl.x-tab-bar-plain.x-tab-bar-default-left > .x-tab-bar-body-default {
    padding: 0 8px 0 0;
}

.x-tab-bar-plain.x-tab-bar-default-right > .x-tab-bar-body-default {
    padding: 0 8px 0 0;
}

.x-rtl.x-tab-bar-plain.x-tab-bar-default-right > .x-tab-bar-body-default {
    padding: 0 0 0 8px;
}

.x-tab-bar-plain.x-tab-bar-default-horizontal {
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-width: 0;
    border-right-width: 0;
}

.x-tab-bar-plain.x-tab-bar-default-vertical {
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-width: 0;
    border-bottom-width: 0;
}

.x-tab-bar-horizontal > .x-tab-bar-body-default {
    min-height: 65px;
}

.x-ie8m .x-tab-bar-horizontal > .x-tab-bar-body-default {
    min-height: 57px;
}

.x-tab-bar-vertical > .x-tab-bar-body-default {
    min-width: 65px;
}

.x-ie8m .x-tab-bar-vertical > .x-tab-bar-body-default {
    min-width: 57px;
}

.x-tab-bar-default-scroller .x-box-scroller-body-horizontal {
    margin-left: 16px;
}

.x-tab-bar-default-scroller.x-rtl .x-box-scroller-body-horizontal {
    margin-left: 0;
    margin-right: 16px;
}

.x-tab-bar-default-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 16px;
}

.x-box-scroller-tab-bar-default {
    cursor: pointer;
    color: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-box-scroller-tab-bar-default.x-box-scroller-hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-box-scroller-tab-bar-default.x-box-scroller-pressed {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    opacity: 0.7;
}

.x-box-scroller-tab-bar-default.x-box-scroller-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    opacity: 0.25;
    cursor: default;
}

.x-box-scroller-tab-bar-default.x-box-scroller-left,
.x-box-scroller-tab-bar-default.x-box-scroller-right {
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
}

.x-box-scroller-tab-bar-default.x-box-scroller-left {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    font: 16px/24px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-tab-bar-default.x-box-scroller-left:before {
    content: '\f053';
}

.x-box-scroller-tab-bar-default.x-box-scroller-right {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    font: 16px/24px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-tab-bar-default.x-box-scroller-right:before {
    content: '\f054';
}

.x-box-scroller-tab-bar-default.x-box-scroller-top,
.x-box-scroller-tab-bar-default.x-box-scroller-bottom {
    height: 24px;
    width: 24px;
    left: 50%;
    margin-left: -12px;
}

.x-box-scroller-tab-bar-default.x-box-scroller-top {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    font: 16px/24px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-tab-bar-default.x-box-scroller-top:before {
    content: '\f077';
}

.x-box-scroller-tab-bar-default.x-box-scroller-bottom {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    font: 16px/24px 'Material Icons', 'Font Awesome 5 Free';
}

.x-box-scroller-tab-bar-default.x-box-scroller-bottom:before {
    content: '\f078';
}

.x-tab-bar-more-icon {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-tab-bar-more-icon:before {
    content: '\f0c9';
}

.x-tab-bar-plain.x-tab-bar-default-scroller .x-box-scroller-body-horizontal {
    margin-left: 24px;
}

.x-tab-bar-plain.x-tab-bar-default-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 16px;
}

.x-tab-bar-plain .x-box-scroller-tab-bar-default {
    color: #919191;
}

.x-tab-bar-default-top .x-box-scroller-tab-bar-default {
    margin-top: -8px;
}

.x-tab-bar-default-right .x-box-scroller-tab-bar-default {
    margin-left: -16px;
}

.x-tab-bar-default-bottom .x-box-scroller-tab-bar-default {
    margin-top: -16px;
}

.x-tab-bar-default-left .x-box-scroller-tab-bar-default {
    margin-left: -8px;
}

.x-box-scroller-tab-bar-default {
    background-color: #2196f3;
}

.x-box-scroller-tab-bar-default .x-ie8 .x-box-scroller-plain {
    background-color: #fff;
}

.x-breadcrumb-btn-default {
    margin: 0 0 0 0;
}

.x-breadcrumb-icon-folder-default {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-breadcrumb-icon-folder-default:before {
    content: '\f07b';
}

.x-btn-menu-active .x-breadcrumb-icon-folder-default {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-menu-active .x-breadcrumb-icon-folder-default:before {
    content: '\f07c';
}

.x-breadcrumb-icon-leaf-default {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-breadcrumb-icon-leaf-default:before {
    content: '\f15b';
}

.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-arrow:after {
    width: 16px;
    background-image: url(./../images/breadcrumb/default-arrow.png);
}

.x-rtl.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-arrow:after {
    background-image: url(./../images/breadcrumb/default-arrow-rtl.png);
}

.x-btn-menu-active.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-arrow:after {
    background-image: url(./../images/breadcrumb/default-arrow-open.png);
}

.x-rtl.x-btn-menu-active.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-arrow:after {
    background-image: url(./../images/breadcrumb/default-arrow-open-rtl.png);
}

.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-split:after {
    width: 16px;
    content: 'keyboard_arrow_right';
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    color: #2196f3;
    background: none !important;
}

.x-rtl.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-split:after {
    content: 'keyboard_arrow_left';
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    background: none !important;
}

.x-btn-menu-active.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-split:after {
    content: 'keyboard_arrow_down';
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    color: #fff;
    background: none !important;
}

.x-breadcrumb-default-scroller .x-box-scroller-body-horizontal {
    margin-left: 32px;
}

.x-breadcrumb-default-scroller.x-rtl .x-box-scroller-body-horizontal {
    margin-left: 0;
    margin-right: 32px;
}

.x-breadcrumb-default-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 24px;
}

.x-box-scroller-breadcrumb-default {
    cursor: pointer;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-box-scroller-breadcrumb-default.x-box-scroller-hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-box-scroller-breadcrumb-default.x-box-scroller-pressed {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-box-scroller-breadcrumb-default.x-box-scroller-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    opacity: 0.25;
    cursor: default;
}

.x-box-scroller-breadcrumb-default.x-box-scroller-left,
.x-box-scroller-breadcrumb-default.x-box-scroller-right {
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
}

.x-box-scroller-breadcrumb-default.x-box-scroller-left {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    background-image: url(./../images/breadcrumb/default-scroll-left.png);
}

.x-box-scroller-breadcrumb-default.x-box-scroller-right {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    background-image: url(./../images/breadcrumb/default-scroll-right.png);
}

.x-tree-expander {
    cursor: pointer;
}

.x-tree-arrows .x-tree-expander {
    font-size: 18px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    color: #9e9e9e;
}

.x-tree-arrows .x-tree-expander:before {
    content: 'chevron_right';
}

.x-tree-arrows .x-tree-expander.x-rtl {
    font-size: 18px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
}

.x-tree-arrows .x-tree-expander.x-rtl:before {
    content: 'chevron_right';
}

.x-tree-arrows .x-grid-tree-node-expanded .x-tree-expander {
    font-size: 18px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    color: #9e9e9e;
}

.x-tree-arrows .x-grid-tree-node-expanded .x-tree-expander:before {
    content: 'expand_more';
}

.x-tree-elbow-plus,
.x-tree-elbow-end-plus {
    font-size: 18px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    color: #9e9e9e;
}

.x-tree-elbow-plus:before,
.x-tree-elbow-end-plus:before {
    content: 'add_box';
}

.x-grid-tree-node-expanded .x-tree-elbow-plus,
.x-grid-tree-node-expanded .x-tree-elbow-end-plus {
    font-size: 18px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    color: #9e9e9e;
}

.x-grid-tree-node-expanded .x-tree-elbow-plus:before,
.x-grid-tree-node-expanded .x-tree-elbow-end-plus:before {
    content: 'indeterminate_check_box';
}

.x-tree-icon {
    width: 16px;
    height: 48px;
    line-height: 48px;
    color: #2196f3;
    font-size: 18px;
}

.x-tree-elbow-img {
    width: 18px;
    height: 48px;
    line-height: 48px;
    margin-right: 4px;
}

.x-rtl.x-tree-elbow-img {
    margin-right: 0;
    margin-left: 4px;
}

.x-tree-icon,
.x-tree-elbow-img,
.x-tree-checkbox {
    margin-top: -16px;
    margin-bottom: -16px;
}

.x-tree-icon-leaf {
    font-size: 18px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    color: #9e9e9e;
}

.x-tree-icon-leaf:before {
    content: 'insert_drive_file';
}

.x-tree-icon-parent {
    font-size: 18px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    color: #9e9e9e;
}

.x-tree-icon-parent:before {
    content: 'folder';
}

.x-tree-icon-parent-expanded {
    font-size: 18px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    color: #9e9e9e;
}

.x-tree-icon-parent-expanded:before {
    content: 'folder_open';
}

.x-tree-icon-custom {
    background-image: none;
}

.x-tree-icon-custom:before {
    content: '';
}

.x-tree-icon-custom.x-rtl {
    background-image: none;
}

.x-tree-checkbox {
    margin-right: 6px;
    top: 15px;
    width: 18px;
    height: 18px;
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    color: rgba(17, 17, 17, 0.54);
}

.x-tree-checkbox:before {
    content: 'check_box_outline_blank';
}

.x-rtl.x-tree-checkbox {
    margin-right: 0;
    margin-left: 6px;
}

.x-tree-checkbox-checked {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
}

.x-tree-checkbox-checked:before {
    content: 'check_box';
}

.x-grid-tree-loading .x-tree-icon {
    background-image: url(./../images/tree/loading.gif);
}

.x-grid-tree-loading .x-rtl.x-tree-icon {
    background-image: url(./../images/tree/loading.gif);
}

.x-tree-node-text {
    padding-left: 6px;
}

.x-rtl.x-tree-node-text {
    padding-left: 0;
    padding-right: 6px;
}

.x-grid-cell-inner-treecolumn {
    padding: 8px;
}

.x-tree-drop-ok-append .x-dd-drop-icon,
.x-tree-drop-ok-above .x-dd-drop-icon,
.x-tree-drop-ok-below .x-dd-drop-icon,
.x-tree-drop-ok-between .x-dd-drop-icon {
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #a4cf6d;
}

.x-tree-drop-ok-append .x-dd-drop-icon:before,
.x-tree-drop-ok-above .x-dd-drop-icon:before,
.x-tree-drop-ok-below .x-dd-drop-icon:before,
.x-tree-drop-ok-between .x-dd-drop-icon:before {
    content: '\f055';
}

.x-tree-ddindicator {
    height: 1px;
    border-width: 1px 0 0;
    border-style: dotted;
    border-color: green;
}

.x-multiselector-remove {
    font-size: 110%;
    color: #d0d0d0;
    cursor: pointer;
}

.x-multiselector-remove .x-grid-cell-inner {
    padding: 16px 4px 16px 4px;
}

.x-grid-item-over .x-multiselector-remove {
    color: red;
}

.x-multiselector-search-input .x-form-text::-ms-clear {
    display: none;
}

.x-toast-icon-information {
    background-image: url(./../images/window/toast/icon16_info.png);
}

.x-toast-icon-error {
    background-image: url(./../images/window/toast/icon16_error.png);
}

.x-toast-window .x-window-body {
    /* Using standard theme */
    padding: 15px 5px 15px 5px;
}

.x-toast-light .x-window-header {
    background-color: #64b5f6;
}

.x-toast-light .x-tool-img {
    background-color: #64b5f6;
}

.x-toast-light {
    background-image: url(./../images/window/toast/fader.png);
}

.x-toast-light .x-window-body {
    padding: 15px 5px 20px 5px;
    background-color: transparent;
    border: 0 solid white;
}

.x-btn-plain-toolbar-small {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 8px 16px 8px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-btn-plain-toolbar-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-plain-toolbar-small {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-plain-toolbar-small-frameInfo {
    font-family: th-2-2-2-2-0-0-0-0-8-16-8-16;
}

.x-btn-plain-toolbar-small-tl {
    background-position: 0 -4px;
}

.x-btn-plain-toolbar-small-tr {
    background-position: right -6px;
}

.x-btn-plain-toolbar-small-bl {
    background-position: 0 -8px;
}

.x-btn-plain-toolbar-small-br {
    background-position: right -10px;
}

.x-btn-plain-toolbar-small-ml {
    background-position: 0 top;
}

.x-btn-plain-toolbar-small-mr {
    background-position: right top;
}

.x-btn-plain-toolbar-small-tc {
    background-position: 0 0;
}

.x-btn-plain-toolbar-small-bc {
    background-position: 0 -2px;
}

.x-btn-plain-toolbar-small-tr,
.x-btn-plain-toolbar-small-br,
.x-btn-plain-toolbar-small-mr {
    padding-right: 2px;
}

.x-btn-plain-toolbar-small-tl,
.x-btn-plain-toolbar-small-bl,
.x-btn-plain-toolbar-small-ml {
    padding-left: 2px;
}

.x-btn-plain-toolbar-small-tc {
    height: 2px;
}

.x-btn-plain-toolbar-small-bc {
    height: 2px;
}

.x-btn-plain-toolbar-small-mc {
    padding: 6px 14px 6px 14px;
}

.x-btn-plain-toolbar-small {
    border-color: transparent;
}

.x-btn-button-plain-toolbar-small {
    min-height: 16px;
}

.x-ie9m .x-btn-button-plain-toolbar-small {
    min-height: auto;
    height: 16px;
}

.x-btn-inner-plain-toolbar-small {
    font: 600 13px/15px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #2196f3;
    padding: 0 5px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-plain-toolbar-small,
.x-ie9 .x-btn-icon-left > .x-btn-inner-plain-toolbar-small {
    max-width: calc(100% - 16px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-plain-toolbar-small,
.x-safari8m .x-btn-icon-left > .x-btn-inner-plain-toolbar-small {
    max-width: calc(100% - 15px);
}

.x-ie10p .x-btn-inner-plain-toolbar-small {
    max-width: none;
}

.x-btn-icon-el-plain-toolbar-small {
    font-size: 16px;
    height: 16px;
    color: #2196f3;
    line-height: 16px;
}

.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-small,
.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-small {
    width: 16px;
}

.x-btn-icon-top > .x-btn-icon-el-plain-toolbar-small,
.x-btn-icon-bottom > .x-btn-icon-el-plain-toolbar-small {
    min-width: 16px;
}

.x-ie8 .x-btn-icon-el-plain-toolbar-small.x-btn-glyph {
    color: #2196f3;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-small {
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-small.x-rtl {
    margin-right: 0;
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-small {
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-small.x-rtl {
    margin-left: 0;
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-plain-toolbar-small {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-plain-toolbar-small {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-plain-toolbar-small {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-small {
    margin-right: 5px;
}

.x-btn-arrow-bottom > .x-btn-button-plain-toolbar-small,
.x-btn-split-bottom > .x-btn-button-plain-toolbar-small {
    padding-bottom: 8px;
}

.x-btn-wrap-plain-toolbar-small.x-btn-arrow-right:after {
    width: 18px;
    content: 'arrow_drop_down';
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-plain-toolbar-small.x-btn-arrow-bottom:after {
    height: 12px;
    content: 'arrow_drop_down';
    font: 16px/12px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button {
    padding-right: 16px;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 16px;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #2196f3;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-right:after {
    width: 16px;
    content: 'arrow_drop_down';
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
    padding-left: 16px;
}

.x-btn-wrap-plain-toolbar-small.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 16px;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #2196f3;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-bottom:after {
    height: 16px;
    content: 'arrow_drop_down';
    font: 16px/16px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-plain-toolbar-small {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-small {
    margin-right: 5px;
}

.x-keyboard-mode .x-btn-focus.x-btn-plain-toolbar-small {
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-keyboard-mode .x-btn-focus.x-btn-plain-toolbar-small .x-btn-inner {
    color: #fff;
}

.x-btn-plain-toolbar-small .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 32px;
    pointer-events: none;
}

.x-btn-plain-toolbar-small .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-plain-toolbar-small.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #2196f3;
}

.x-btn-over.x-btn-plain-toolbar-small {
    border-color: #dbdbdb;
    background-image: none;
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-plain-toolbar-small {
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-plain-toolbar-small .x-btn-inner {
    color: #fff;
}

.x-btn.x-btn-menu-active.x-btn-plain-toolbar-small,
.x-btn.x-btn-pressed.x-btn-plain-toolbar-small {
    border-color: #d2d2d2;
    background-image: none;
    background-color: #2196f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-menu-active.x-btn-plain-toolbar-small .x-btn-inner,
.x-btn.x-btn-pressed.x-btn-plain-toolbar-small .x-btn-inner {
    color: #fff;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-plain-toolbar-small,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-plain-toolbar-small {
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-btn.x-btn-disabled.x-btn-plain-toolbar-small {
    background-image: none;
    background-color: transparent;
}

.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-small-mc {
    background-color: transparent;
}

.x-btn-over .x-btn-plain-toolbar-small-mc {
    background-color: rgba(17, 17, 17, 0.075);
}


.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-small-mc {
    background-color: rgba(17, 17, 17, 0.075);
}
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-small-mc,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-small-mc {
    background-color: #2196f3;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-small-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-small-mc {
    background-color: #2196f3;
}

.x-btn.x-btn-disabled .x-btn-plain-toolbar-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-plain-toolbar-small {
    background-image: none;
}

.x-btn-disabled.x-btn-plain-toolbar-small {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-first {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-first .x-btn-plain-toolbar-small-mc {
    padding-right: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-middle {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-middle .x-btn-plain-toolbar-small-mc {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-last .x-btn-plain-toolbar-small-mc {
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-first {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-first .x-btn-plain-toolbar-small-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-middle {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-middle .x-btn-plain-toolbar-small-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-last .x-btn-plain-toolbar-small-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-plain-toolbar-small:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-first:after {
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-last:after {
    right: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-first:after {
    top: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-last:after {
    bottom: 0;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-plain-toolbar-small:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-plain-toolbar-small:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-nbr .x-segmented-button-item.x-btn.x-btn-menu-active.x-btn-plain-toolbar-small:after,
.x-nbr .x-segmented-button-item.x-btn.x-btn-pressed.x-btn-plain-toolbar-small:after {
    border-width: 0;
    border-color: #51adf6;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-plain-toolbar-small:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-plain-toolbar-small:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-button-plain-toolbar-small-cell > .x-grid-cell-inner {
    padding-top: 8px;
    padding-bottom: 8px;
}

.x-button-plain-toolbar-small-cell > .x-grid-cell-inner > .x-btn-plain-toolbar-small {
    vertical-align: top;
}

.x-btn-plain-toolbar-medium {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 8px 16px 8px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-btn-plain-toolbar-medium-mc {
    background-color: transparent;
}

.x-nbr .x-btn-plain-toolbar-medium {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-plain-toolbar-medium-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-8-16-8-16;
}

.x-btn-plain-toolbar-medium-tl {
    background-position: 0 0;
}

.x-btn-plain-toolbar-medium-tr {
    background-position: right 0;
}

.x-btn-plain-toolbar-medium-bl {
    background-position: 0 0;
}

.x-btn-plain-toolbar-medium-br {
    background-position: right 0;
}

.x-btn-plain-toolbar-medium-ml {
    background-position: 0 top;
}

.x-btn-plain-toolbar-medium-mr {
    background-position: right top;
}

.x-btn-plain-toolbar-medium-tc {
    background-position: 0 0;
}

.x-btn-plain-toolbar-medium-bc {
    background-position: 0 0;
}

.x-btn-plain-toolbar-medium-tr,
.x-btn-plain-toolbar-medium-br,
.x-btn-plain-toolbar-medium-mr {
    padding-right: 0;
}

.x-btn-plain-toolbar-medium-tl,
.x-btn-plain-toolbar-medium-bl,
.x-btn-plain-toolbar-medium-ml {
    padding-left: 0;
}

.x-btn-plain-toolbar-medium-tc {
    height: 0;
}

.x-btn-plain-toolbar-medium-bc {
    height: 0;
}

.x-btn-plain-toolbar-medium-mc {
    padding: 8px 16px 8px 16px;
}

.x-btn-plain-toolbar-medium {
    border-color: transparent;
}

.x-btn-button-plain-toolbar-medium {
    min-height: 20px;
}

.x-ie9m .x-btn-button-plain-toolbar-medium {
    min-height: auto;
    height: 20px;
}

.x-btn-inner-plain-toolbar-medium {
    font: 600 14px/16px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #2196f3;
    padding: 0 8px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-plain-toolbar-medium,
.x-ie9 .x-btn-icon-left > .x-btn-inner-plain-toolbar-medium {
    max-width: calc(100% - 20px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-plain-toolbar-medium,
.x-safari8m .x-btn-icon-left > .x-btn-inner-plain-toolbar-medium {
    max-width: calc(100% - 19px);
}

.x-ie10p .x-btn-inner-plain-toolbar-medium {
    max-width: none;
}

.x-btn-icon-el-plain-toolbar-medium {
    font-size: 20px;
    height: 20px;
    color: #2196f3;
    line-height: 20px;
}

.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-medium,
.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-medium {
    width: 20px;
}

.x-btn-icon-top > .x-btn-icon-el-plain-toolbar-medium,
.x-btn-icon-bottom > .x-btn-icon-el-plain-toolbar-medium {
    min-width: 20px;
}

.x-ie8 .x-btn-icon-el-plain-toolbar-medium.x-btn-glyph {
    color: #2196f3;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-medium {
    margin-right: 0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-medium.x-rtl {
    margin-right: 0;
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-medium {
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-medium.x-rtl {
    margin-left: 0;
    margin-right: 0;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-plain-toolbar-medium {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-plain-toolbar-medium {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-plain-toolbar-medium {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-medium {
    margin-right: 8px;
}

.x-btn-arrow-bottom > .x-btn-button-plain-toolbar-medium,
.x-btn-split-bottom > .x-btn-button-plain-toolbar-medium {
    padding-bottom: 8px;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-arrow-right:after {
    width: 20px;
    content: 'arrow_drop_down';
    font: 20px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-arrow-bottom:after {
    height: 14px;
    content: 'arrow_drop_down';
    font: 20px/14px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-right > .x-btn-button {
    padding-right: 16px;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 16px;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #2196f3;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-right:after {
    width: 24px;
    content: 'arrow_drop_down';
    font: 20px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
    padding-left: 16px;
}

.x-btn-wrap-plain-toolbar-medium.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 16px;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #2196f3;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-bottom:after {
    height: 24px;
    content: 'arrow_drop_down';
    font: 20px/24px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-plain-toolbar-medium {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-medium {
    margin-right: 8px;
}

.x-keyboard-mode .x-btn-focus.x-btn-plain-toolbar-medium {
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-keyboard-mode .x-btn-focus.x-btn-plain-toolbar-medium .x-btn-inner {
    color: #fff;
}

.x-btn-plain-toolbar-medium .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 40px;
    pointer-events: none;
}

.x-btn-plain-toolbar-medium .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-plain-toolbar-medium.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #2196f3;
}

.x-btn-over.x-btn-plain-toolbar-medium {
    border-color: #dbdbdb;
    background-image: none;
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-plain-toolbar-medium {
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-plain-toolbar-medium .x-btn-inner {
    color: #fff;
}

.x-btn.x-btn-menu-active.x-btn-plain-toolbar-medium,
.x-btn.x-btn-pressed.x-btn-plain-toolbar-medium {
    border-color: #d2d2d2;
    background-image: none;
    background-color: #2196f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-menu-active.x-btn-plain-toolbar-medium .x-btn-inner,
.x-btn.x-btn-pressed.x-btn-plain-toolbar-medium .x-btn-inner {
    color: #fff;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-plain-toolbar-medium,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-plain-toolbar-medium {
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-btn.x-btn-disabled.x-btn-plain-toolbar-medium {
    background-image: none;
    background-color: transparent;
}

.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-medium-mc {
    background-color: transparent;
}

.x-btn-over .x-btn-plain-toolbar-medium-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-medium-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-btn.x-btn-menu-active .x-btn-plain-toolbar-medium-mc,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-medium-mc {
    background-color: #2196f3;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-medium-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-medium-mc {
    background-color: #2196f3;
}

.x-btn.x-btn-disabled .x-btn-plain-toolbar-medium-mc {
    background-color: transparent;
}

.x-nbr .x-btn-plain-toolbar-medium {
    background-image: none;
}

.x-btn-disabled.x-btn-plain-toolbar-medium {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-first {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-first .x-btn-plain-toolbar-medium-mc {
    padding-right: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-middle {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-middle .x-btn-plain-toolbar-medium-mc {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-last .x-btn-plain-toolbar-medium-mc {
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-first {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-first .x-btn-plain-toolbar-medium-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-middle {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-middle .x-btn-plain-toolbar-medium-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-last .x-btn-plain-toolbar-medium-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-plain-toolbar-medium:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-first:after {
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-last:after {
    right: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-first:after {
    top: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-last:after {
    bottom: 0;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-plain-toolbar-medium:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-plain-toolbar-medium:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-nbr .x-segmented-button-item.x-btn.x-btn-menu-active.x-btn-plain-toolbar-medium:after,
.x-nbr .x-segmented-button-item.x-btn.x-btn-pressed.x-btn-plain-toolbar-medium:after {
    border-width: 0;
    border-color: #51adf6;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-plain-toolbar-medium:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-plain-toolbar-medium:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-button-plain-toolbar-medium-cell > .x-grid-cell-inner {
    padding-top: 6px;
    padding-bottom: 6px;
}

.x-button-plain-toolbar-medium-cell > .x-grid-cell-inner > .x-btn-plain-toolbar-medium {
    vertical-align: top;
}

.x-btn-plain-toolbar-large {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 8px 16px 8px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-btn-plain-toolbar-large-mc {
    background-color: transparent;
}

.x-nbr .x-btn-plain-toolbar-large {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-plain-toolbar-large-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-8-16-8-16;
}

.x-btn-plain-toolbar-large-tl {
    background-position: 0 0;
}

.x-btn-plain-toolbar-large-tr {
    background-position: right 0;
}

.x-btn-plain-toolbar-large-bl {
    background-position: 0 0;
}

.x-btn-plain-toolbar-large-br {
    background-position: right 0;
}

.x-btn-plain-toolbar-large-ml {
    background-position: 0 top;
}

.x-btn-plain-toolbar-large-mr {
    background-position: right top;
}

.x-btn-plain-toolbar-large-tc {
    background-position: 0 0;
}

.x-btn-plain-toolbar-large-bc {
    background-position: 0 0;
}

.x-btn-plain-toolbar-large-tr,
.x-btn-plain-toolbar-large-br,
.x-btn-plain-toolbar-large-mr {
    padding-right: 0;
}

.x-btn-plain-toolbar-large-tl,
.x-btn-plain-toolbar-large-bl,
.x-btn-plain-toolbar-large-ml {
    padding-left: 0;
}

.x-btn-plain-toolbar-large-tc {
    height: 0;
}

.x-btn-plain-toolbar-large-bc {
    height: 0;
}

.x-btn-plain-toolbar-large-mc {
    padding: 8px 16px 8px 16px;
}

.x-btn-plain-toolbar-large {
    border-color: transparent;
}

.x-btn-button-plain-toolbar-large {
    min-height: 24px;
}

.x-ie9m .x-btn-button-plain-toolbar-large {
    min-height: auto;
    height: 24px;
}

.x-btn-inner-plain-toolbar-large {
    font: 600 16px/20px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #2196f3;
    padding: 0 10px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-plain-toolbar-large,
.x-ie9 .x-btn-icon-left > .x-btn-inner-plain-toolbar-large {
    max-width: calc(100% - 24px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-plain-toolbar-large,
.x-safari8m .x-btn-icon-left > .x-btn-inner-plain-toolbar-large {
    max-width: calc(100% - 23px);
}

.x-ie10p .x-btn-inner-plain-toolbar-large {
    max-width: none;
}

.x-btn-icon-el-plain-toolbar-large {
    font-size: 24px;
    height: 24px;
    color: #2196f3;
    line-height: 24px;
}

.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-large,
.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-large {
    width: 24px;
}

.x-btn-icon-top > .x-btn-icon-el-plain-toolbar-large,
.x-btn-icon-bottom > .x-btn-icon-el-plain-toolbar-large {
    min-width: 24px;
}

.x-ie8 .x-btn-icon-el-plain-toolbar-large.x-btn-glyph {
    color: #2196f3;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-large {
    margin-right: 0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-large.x-rtl {
    margin-right: 0;
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-large {
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-large.x-rtl {
    margin-left: 0;
    margin-right: 0;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-plain-toolbar-large {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-plain-toolbar-large {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-plain-toolbar-large {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-large {
    margin-right: 10px;
}

.x-btn-arrow-bottom > .x-btn-button-plain-toolbar-large,
.x-btn-split-bottom > .x-btn-button-plain-toolbar-large {
    padding-bottom: 8px;
}

.x-btn-wrap-plain-toolbar-large.x-btn-arrow-right:after {
    width: 22px;
    content: 'arrow_drop_down';
    font: 24px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-plain-toolbar-large.x-btn-arrow-bottom:after {
    height: 16px;
    content: 'arrow_drop_down';
    font: 24px/16px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-right > .x-btn-button {
    padding-right: 16px;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 16px;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #2196f3;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-right:after {
    width: 32px;
    content: 'arrow_drop_down';
    font: 24px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
    padding-left: 16px;
}

.x-btn-wrap-plain-toolbar-large.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 16px;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #2196f3;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-bottom:after {
    height: 32px;
    content: 'arrow_drop_down';
    font: 24px/32px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-plain-toolbar-large {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-large {
    margin-right: 10px;
}

.x-keyboard-mode .x-btn-focus.x-btn-plain-toolbar-large {
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-keyboard-mode .x-btn-focus.x-btn-plain-toolbar-large .x-btn-inner {
    color: #fff;
}

.x-btn-plain-toolbar-large .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 48px;
    pointer-events: none;
}

.x-btn-plain-toolbar-large .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-plain-toolbar-large.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #2196f3;
}

.x-btn-over.x-btn-plain-toolbar-large {
    border-color: #dbdbdb;
    background-image: none;
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-plain-toolbar-large {
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-plain-toolbar-large .x-btn-inner {
    color: #fff;
}

.x-btn.x-btn-menu-active.x-btn-plain-toolbar-large,
.x-btn.x-btn-pressed.x-btn-plain-toolbar-large {
    border-color: #d2d2d2;
    background-image: none;
    background-color: #2196f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-menu-active.x-btn-plain-toolbar-large .x-btn-inner,
.x-btn.x-btn-pressed.x-btn-plain-toolbar-large .x-btn-inner {
    color: #fff;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-plain-toolbar-large,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-plain-toolbar-large {
    -webkit-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    -moz-box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
    box-shadow: #2196f3 0 1px 0 0 inset, #2196f3 0 -1px 0 0 inset, #2196f3 -1px 0 0 0 inset, #2196f3 1px 0 0 0 inset;
}

.x-btn.x-btn-disabled.x-btn-plain-toolbar-large {
    background-image: none;
    background-color: transparent;
}

.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-large-mc {
    background-color: transparent;
}

.x-btn-over .x-btn-plain-toolbar-large-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-large-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-btn.x-btn-menu-active .x-btn-plain-toolbar-large-mc,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-large-mc {
    background-color: #2196f3;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-large-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-large-mc {
    background-color: #2196f3;
}

.x-btn.x-btn-disabled .x-btn-plain-toolbar-large-mc {
    background-color: transparent;
}

.x-nbr .x-btn-plain-toolbar-large {
    background-image: none;
}

.x-btn-disabled.x-btn-plain-toolbar-large {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-first {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-first .x-btn-plain-toolbar-large-mc {
    padding-right: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-middle {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-middle .x-btn-plain-toolbar-large-mc {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-last .x-btn-plain-toolbar-large-mc {
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-first {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-first .x-btn-plain-toolbar-large-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-middle {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-middle .x-btn-plain-toolbar-large-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-last .x-btn-plain-toolbar-large-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-plain-toolbar-large:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-first:after {
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-last:after {
    right: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-first:after {
    top: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-last:after {
    bottom: 0;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-plain-toolbar-large:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-plain-toolbar-large:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-nbr .x-segmented-button-item.x-btn.x-btn-menu-active.x-btn-plain-toolbar-large:after,
.x-nbr .x-segmented-button-item.x-btn.x-btn-pressed.x-btn-plain-toolbar-large:after {
    border-width: 0;
    border-color: #51adf6;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-plain-toolbar-large:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-plain-toolbar-large:after {
    border-width: 1px;
    border-color: #2196f3;
}

.x-button-plain-toolbar-large-cell > .x-grid-cell-inner {
    padding-top: 4px;
    padding-bottom: 4px;
}

.x-button-plain-toolbar-large-cell > .x-grid-cell-inner > .x-btn-plain-toolbar-large {
    vertical-align: top;
}

.x-btn-plain-toolbar-small-disabled .x-btn-icon-el,
.x-btn-plain-toolbar-medium-disabled .x-btn-icon-el,
.x-btn-plain-toolbar-large-disabled .x-btn-icon-el {
    background-color: #f5f5f5;
}

.x-ie8 .x-btn-plain-toolbar-small-disabled .x-btn-icon-el,
.x-ie8 .x-btn-plain-toolbar-medium-disabled .x-btn-icon-el,
.x-ie8 .x-btn-plain-toolbar-large-disabled .x-btn-icon-el {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-panel-light {
    border-color: #d0d0d0;
    padding: 0;
}

.x-panel-light.x-masked {
    border-color: #e8e8e8;
}

.x-panel-header-light {
    font-size: 18px;
    border: 1px solid #d0d0d0;
}

.x-panel-header-light .x-tool-tool-el {
    color: #919191;
}

.x-panel-header-light .x-tool-tool-el {
    background-color: #fff;
}

.x-panel-header-light-horizontal {
    padding: 16px;
}

.x-panel-header-light-horizontal .x-panel-header-light-tab-bar {
    margin-top: -16px;
    margin-bottom: -16px;
}

.x-panel-header-light-horizontal.x-header-noborder {
    padding: 17px 17px 16px 17px;
}

.x-panel-header-light-horizontal.x-header-noborder .x-panel-header-light-tab-bar {
    margin-top: -17px;
    margin-bottom: -16px;
}

.x-panel-header-light-vertical {
    padding: 16px 16px 16px 16px;
}

.x-panel-header-light-vertical .x-panel-header-light-tab-bar {
    margin-right: -16px;
    margin-left: -16px;
}

.x-panel-header-light-vertical.x-header-noborder {
    padding: 17px 17px 17px 16px;
}

.x-panel-header-light-vertical.x-header-noborder .x-panel-header-light-tab-bar {
    margin-right: -17px;
    margin-left: -16px;
}

.x-rtl.x-panel-header-light-vertical {
    padding: 16px 16px 16px 16px;
}

.x-rtl.x-panel-header-light-vertical .x-panel-header-light-tab-bar {
    margin-left: -16px;
    margin-right: -16px;
}

.x-rtl.x-panel-header-light-vertical.x-header-noborder {
    padding: 17px 16px 17px 17px;
}

.x-rtl.x-panel-header-light-vertical.x-header-noborder .x-panel-header-light-tab-bar {
    margin-left: -17px;
    margin-right: -16px;
}

.x-panel-header-title-light {
    color: rgba(17, 17, 17, 0.54);
    font-size: 18px;
    font-weight: 600;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 22px;
}

.x-keyboard-mode .x-panel-header-title-light.x-title-focus {
    outline: 1px solid #2196f3;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-title-light.x-title-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-title-light.x-title-focus,
.x-edge .x-keyboard-mode .x-panel-header-title-light.x-title-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-title-light.x-title-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-title-light.x-title-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-title-light.x-title-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #2196f3;
    pointer-events: none;
}

.x-panel-header-title-light > .x-title-text-light {
    text-transform: none;
    padding: 0;
}

.x-panel-header-title-light > .x-title-text-light:after {
    font-family: ext-watermark;
    font-size: 40.5px;
    content: 'd';
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(208, 208, 208, 0.8160000000000001);
}

.x-panel-header-title-light > .x-title-text-light.x-rtl:after {
    right: auto;
    left: 0;
}

.x-panel-header-title-light > .x-title-icon-wrap-light.x-title-icon-top {
    height: 24px;
    padding-bottom: 8px;
}

.x-panel-header-title-light > .x-title-icon-wrap-light.x-title-icon-right {
    width: 24px;
    padding-left: 8px;
}

.x-panel-header-title-light > .x-title-icon-wrap-light.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 8px;
}

.x-panel-header-title-light > .x-title-icon-wrap-light.x-title-icon-bottom {
    height: 24px;
    padding-top: 8px;
}

.x-panel-header-title-light > .x-title-icon-wrap-light.x-title-icon-left {
    width: 24px;
    padding-right: 8px;
}

.x-panel-header-title-light > .x-title-icon-wrap-light.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-panel-header-title-light > .x-title-icon-wrap-light > .x-title-icon-light {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: rgba(17, 17, 17, 0.54);
    background-position: center center;
}

.x-ie8 .x-panel-header-title-light > .x-title-icon-wrap-light > .x-title-icon-light.x-title-glyph {
    color: rgba(17, 17, 17, 0.54);
}

.x-panel-body-light {
    background: #fff;
    border-color: #d0d0d0;
    color: #111;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    border-width: 1px;
    border-style: solid;
}

.x-panel-header-light {
    background-image: none;
    background-color: #fff;
}

.x-panel-header-light-vertical {
    background-image: none;
    background-color: #fff;
}

.x-rtl.x-panel-header-light-vertical {
    background-image: none;
    background-color: #fff;
}

.x-panel .x-panel-header-light-collapsed-border-top {
    border-bottom-width: 1px !important;
}

.x-panel .x-panel-header-light-collapsed-border-right {
    border-left-width: 1px !important;
}

.x-panel .x-panel-header-light-collapsed-border-bottom {
    border-top-width: 1px !important;
}

.x-panel .x-panel-header-light-collapsed-border-left {
    border-right-width: 1px !important;
}

.x-panel-header-light-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-panel-header-light-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-panel-header-light-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-panel-header-light-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-panel-header-light-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-light-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-light-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-panel-header-light-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-panel-header-light .x-tool-focus {
    outline: 1px solid #2196f3;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-light .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-light .x-tool-focus,
.x-edge .x-keyboard-mode .x-panel-header-light .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-light .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-light .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-light .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #2196f3;
    pointer-events: none;
}

.x-rtl.x-panel-header-light-collapsed-border-right {
    border-right-width: 1px !important;
}

.x-rtl.x-panel-header-light-collapsed-border-left {
    border-left-width: 1px !important;
}

.x-panel-light-resizable .x-panel-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-panel-light-outer-border-l {
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-b {
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
}

.x-panel-light-outer-border-bl {
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-r {
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
}

.x-panel-light-outer-border-rl {
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-rb {
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
}

.x-panel-light-outer-border-rbl {
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-t {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
}

.x-panel-light-outer-border-tl {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-tb {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
}

.x-panel-light-outer-border-tbl {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-tr {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
}

.x-panel-light-outer-border-trl {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-trb {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
}

.x-panel-light-outer-border-trbl {
    border-color: #d0d0d0 !important;
    border-width: 1px !important;
}

.x-panel-light-framed {
    border-color: #d0d0d0;
    padding: 0;
}

.x-panel-light-framed.x-masked {
    border-color: #e8e8e8;
}

.x-panel-header-light-framed {
    font-size: 18px;
    border: 1px solid #d0d0d0;
}

.x-panel-header-light-framed .x-tool-tool-el {
    color: #919191;
}

.x-panel-header-light-framed .x-tool-tool-el {
    background-color: #fff;
}

.x-panel-header-light-framed-horizontal {
    padding: 16px 16px 15px 16px;
}

.x-panel-header-light-framed-horizontal .x-panel-header-light-framed-tab-bar {
    margin-top: -16px;
    margin-bottom: -15px;
}

.x-panel-header-light-framed-horizontal.x-header-noborder {
    padding: 17px 17px 15px 17px;
}

.x-panel-header-light-framed-horizontal.x-header-noborder .x-panel-header-light-framed-tab-bar {
    margin-top: -17px;
    margin-bottom: -15px;
}

.x-panel-header-light-framed-vertical {
    padding: 16px 16px 16px 15px;
}

.x-panel-header-light-framed-vertical .x-panel-header-light-framed-tab-bar {
    margin-right: -16px;
    margin-left: -15px;
}

.x-panel-header-light-framed-vertical.x-header-noborder {
    padding: 17px 17px 17px 15px;
}

.x-panel-header-light-framed-vertical.x-header-noborder .x-panel-header-light-framed-tab-bar {
    margin-right: -17px;
    margin-left: -15px;
}

.x-rtl.x-panel-header-light-framed-vertical {
    padding: 16px 15px 16px 16px;
}

.x-rtl.x-panel-header-light-framed-vertical .x-panel-header-light-framed-tab-bar {
    margin-left: -16px;
    margin-right: -15px;
}

.x-rtl.x-panel-header-light-framed-vertical.x-header-noborder {
    padding: 17px 15px 17px 17px;
}

.x-rtl.x-panel-header-light-framed-vertical.x-header-noborder .x-panel-header-light-framed-tab-bar {
    margin-left: -17px;
    margin-right: -15px;
}

.x-panel-header-title-light-framed {
    color: rgba(17, 17, 17, 0.54);
    font-size: 18px;
    font-weight: 600;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 22px;
}

.x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus {
    outline: 1px solid #2196f3;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus,
.x-edge .x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #2196f3;
    pointer-events: none;
}

.x-panel-header-title-light-framed > .x-title-text-light-framed {
    text-transform: none;
    padding: 0;
}

.x-panel-header-title-light-framed > .x-title-text-light-framed:after {
    font-family: ext-watermark;
    font-size: 39.75px;
    content: 'd';
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(208, 208, 208, 0.8160000000000001);
}

.x-panel-header-title-light-framed > .x-title-text-light-framed.x-rtl:after {
    right: auto;
    left: 0;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed.x-title-icon-top {
    height: 24px;
    padding-bottom: 8px;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed.x-title-icon-right {
    width: 24px;
    padding-left: 8px;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 8px;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed.x-title-icon-bottom {
    height: 24px;
    padding-top: 8px;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed.x-title-icon-left {
    width: 24px;
    padding-right: 8px;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed > .x-title-icon-light-framed {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: rgba(17, 17, 17, 0.54);
    background-position: center center;
}

.x-ie8 .x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed > .x-title-icon-light-framed.x-title-glyph {
    color: rgba(17, 17, 17, 0.54);
}

.x-panel-body-light-framed {
    background: #fff;
    border-color: #d0d0d0;
    color: #111;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    border-width: 1px;
    border-style: solid;
}

.x-panel-light-framed {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 0 0 0;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-panel-light-framed-mc {
    background-color: #fff;
}

.x-nbr .x-panel-light-framed {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-light-framed-frameInfo {
    font-family: dh-1-1-1-1-1-1-1-1-0px-0px-0px-0px;
}

.x-panel-light-framed-tl {
    background-position: 0 -2px;
}

.x-panel-light-framed-tr {
    background-position: right -3px;
}

.x-panel-light-framed-bl {
    background-position: 0 -4px;
}

.x-panel-light-framed-br {
    background-position: right -5px;
}

.x-panel-light-framed-ml {
    background-position: 0 top;
}

.x-panel-light-framed-mr {
    background-position: right top;
}

.x-panel-light-framed-tc {
    background-position: 0 0;
}

.x-panel-light-framed-bc {
    background-position: 0 -1px;
}

.x-panel-light-framed-tr,
.x-panel-light-framed-br,
.x-panel-light-framed-mr {
    padding-right: 1px;
}

.x-panel-light-framed-tl,
.x-panel-light-framed-bl,
.x-panel-light-framed-ml {
    padding-left: 1px;
}

.x-panel-light-framed-tc {
    height: 1px;
}

.x-panel-light-framed-bc {
    height: 1px;
}
.x-panel-light-framed-mc {
    padding: 0 0 0 0;
}

.x-panel-header-light-framed-top {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 15px 16px;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    background-color: #fff;
}

.x-panel-header-light-framed-top-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-top-frameInfo {
    font-family: dh-1-1-0-1-1-1-0-1-16-16-15-16;
}

.x-panel-header-light-framed-top-tl {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-top-tr {
    background-position: right -3px;
}

.x-panel-header-light-framed-top-bl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-top-br {
    background-position: right -5px;
}

.x-panel-header-light-framed-top-ml {
    background-position: 0 top;
}

.x-panel-header-light-framed-top-mr {
    background-position: right top;
}

.x-panel-header-light-framed-top-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-top-bc {
    background-position: 0 -1px;
}

.x-panel-header-light-framed-top-tr,
.x-panel-header-light-framed-top-br,
.x-panel-header-light-framed-top-mr {
    padding-right: 1px;
}

.x-panel-header-light-framed-top-tl,
.x-panel-header-light-framed-top-bl,
.x-panel-header-light-framed-top-ml {
    padding-left: 1px;
}

.x-panel-header-light-framed-top-tc {
    height: 1px;
}

.x-panel-header-light-framed-top-bc {
    height: 0;
}

.x-panel-header-light-framed-top-mc {
    padding: 16px 16px 15px 16px;
}

.x-panel-header-light-framed-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 16px 15px;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    background-color: #fff;
}

.x-rtl.x-panel-header-light-framed-right {
    background-image: none;
    background-color: #fff;
}

.x-panel-header-light-framed-right-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-right-frameInfo {
    font-family: dh-1-1-1-0-1-1-1-0-16-16-16-15;
}

.x-panel-header-light-framed-right-tl {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-right-tr {
    background-position: right -3px;
}

.x-panel-header-light-framed-right-bl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-right-br {
    background-position: right -5px;
}

.x-panel-header-light-framed-right-ml {
    background-position: right 0;
}

.x-panel-header-light-framed-right-mr {
    background-position: right 0;
}

.x-panel-header-light-framed-right-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-right-bc {
    background-position: 0 -1px;
}

.x-panel-header-light-framed-right-tr,
.x-panel-header-light-framed-right-br,
.x-panel-header-light-framed-right-mr {
    padding-right: 1px;
}

.x-panel-header-light-framed-right-tl,
.x-panel-header-light-framed-right-bl,
.x-panel-header-light-framed-right-ml {
    padding-left: 0;
}

.x-panel-header-light-framed-right-tc {
    height: 1px;
}

.x-panel-header-light-framed-right-bc {
    height: 1px;
}

.x-panel-header-light-framed-right-mc {
    padding: 16px 16px 16px 15px;
}

.x-panel-header-light-framed-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 15px 16px;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    background-color: #fff;
}

.x-panel-header-light-framed-bottom-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-bottom-frameInfo {
    font-family: dh-0-1-1-1-0-1-1-1-16-16-15-16;
}

.x-panel-header-light-framed-bottom-tl {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-bottom-tr {
    background-position: right -3px;
}

.x-panel-header-light-framed-bottom-bl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-bottom-br {
    background-position: right -5px;
}

.x-panel-header-light-framed-bottom-ml {
    background-position: 0 bottom;
}

.x-panel-header-light-framed-bottom-mr {
    background-position: right bottom;
}

.x-panel-header-light-framed-bottom-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-bottom-bc {
    background-position: 0 -1px;
}

.x-panel-header-light-framed-bottom-tr,
.x-panel-header-light-framed-bottom-br,
.x-panel-header-light-framed-bottom-mr {
    padding-right: 1px;
}

.x-panel-header-light-framed-bottom-tl,
.x-panel-header-light-framed-bottom-bl,
.x-panel-header-light-framed-bottom-ml {
    padding-left: 1px;
}

.x-panel-header-light-framed-bottom-tc {
    height: 0;
}

.x-panel-header-light-framed-bottom-bc {
    height: 1px;
}

.x-panel-header-light-framed-bottom-mc {
    padding: 16px 16px 15px 16px;
}

.x-panel-header-light-framed-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 16px 15px;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    background-color: #fff;
}

.x-rtl.x-panel-header-light-framed-left {
    background-image: none;
    background-color: #fff;
}

.x-panel-header-light-framed-left-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-left-frameInfo {
    font-family: dh-1-0-1-1-1-0-1-1-16-16-16-15;
}

.x-panel-header-light-framed-left-tl {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-left-tr {
    background-position: right -3px;
}

.x-panel-header-light-framed-left-bl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-left-br {
    background-position: right -5px;
}

.x-panel-header-light-framed-left-ml {
    background-position: left 0;
}

.x-panel-header-light-framed-left-mr {
    background-position: left 0;
}

.x-panel-header-light-framed-left-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-left-bc {
    background-position: 0 -1px;
}

.x-panel-header-light-framed-left-tr,
.x-panel-header-light-framed-left-br,
.x-panel-header-light-framed-left-mr {
    padding-right: 0;
}

.x-panel-header-light-framed-left-tl,
.x-panel-header-light-framed-left-bl,
.x-panel-header-light-framed-left-ml {
    padding-left: 1px;
}

.x-panel-header-light-framed-left-tc {
    height: 1px;
}

.x-panel-header-light-framed-left-bc {
    height: 1px;
}

.x-panel-header-light-framed-left-mc {
    padding: 16px 16px 16px 15px;
}

.x-panel-header-light-framed-collapsed-top {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 15px 16px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-panel-header-light-framed-collapsed-top-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-collapsed-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-collapsed-top-frameInfo {
    font-family: dh-1-1-1-1-1-1-1-1-16-16-15-16;
}

.x-panel-header-light-framed-collapsed-top-tl {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-collapsed-top-tr {
    background-position: right -3px;
}

.x-panel-header-light-framed-collapsed-top-bl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-collapsed-top-br {
    background-position: right -5px;
}

.x-panel-header-light-framed-collapsed-top-ml {
    background-position: 0 top;
}

.x-panel-header-light-framed-collapsed-top-mr {
    background-position: right top;
}

.x-panel-header-light-framed-collapsed-top-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-collapsed-top-bc {
    background-position: 0 -1px;
}

.x-panel-header-light-framed-collapsed-top-tr,
.x-panel-header-light-framed-collapsed-top-br,
.x-panel-header-light-framed-collapsed-top-mr {
    padding-right: 1px;
}

.x-panel-header-light-framed-collapsed-top-tl,
.x-panel-header-light-framed-collapsed-top-bl,
.x-panel-header-light-framed-collapsed-top-ml {
    padding-left: 1px;
}

.x-panel-header-light-framed-collapsed-top-tc {
    height: 1px;
}

.x-panel-header-light-framed-collapsed-top-bc {
    height: 1px;
}

.x-panel-header-light-framed-collapsed-top-mc {
    padding: 16px 16px 15px 16px;
}

.x-panel-header-light-framed-collapsed-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 16px 15px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-rtl.x-panel-header-light-framed-collapsed-right {
    background-image: none;
    background-color: #fff;
}

.x-panel-header-light-framed-collapsed-right-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-collapsed-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-collapsed-right-frameInfo {
    font-family: dh-1-1-1-1-1-1-1-1-16-16-16-15;
}

.x-panel-header-light-framed-collapsed-right-tl {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-collapsed-right-tr {
    background-position: right -3px;
}

.x-panel-header-light-framed-collapsed-right-bl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-collapsed-right-br {
    background-position: right -5px;
}

.x-panel-header-light-framed-collapsed-right-ml {
    background-position: right 0;
}

.x-panel-header-light-framed-collapsed-right-mr {
    background-position: right 0;
}

.x-panel-header-light-framed-collapsed-right-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-collapsed-right-bc {
    background-position: 0 -1px;
}

.x-panel-header-light-framed-collapsed-right-tr,
.x-panel-header-light-framed-collapsed-right-br,
.x-panel-header-light-framed-collapsed-right-mr {
    padding-right: 1px;
}

.x-panel-header-light-framed-collapsed-right-tl,
.x-panel-header-light-framed-collapsed-right-bl,
.x-panel-header-light-framed-collapsed-right-ml {
    padding-left: 1px;
}

.x-panel-header-light-framed-collapsed-right-tc {
    height: 1px;
}

.x-panel-header-light-framed-collapsed-right-bc {
    height: 1px;
}
.x-panel-header-light-framed-collapsed-right-mc {
    padding: 16px 16px 16px 15px;
}

.x-panel-header-light-framed-collapsed-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 15px 16px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-panel-header-light-framed-collapsed-bottom-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-collapsed-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-collapsed-bottom-frameInfo {
    font-family: dh-1-1-1-1-1-1-1-1-16-16-15-16;
}

.x-panel-header-light-framed-collapsed-bottom-tl {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-collapsed-bottom-tr {
    background-position: right -3px;
}

.x-panel-header-light-framed-collapsed-bottom-bl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-collapsed-bottom-br {
    background-position: right -5px;
}

.x-panel-header-light-framed-collapsed-bottom-ml {
    background-position: 0 bottom;
}

.x-panel-header-light-framed-collapsed-bottom-mr {
    background-position: right bottom;
}

.x-panel-header-light-framed-collapsed-bottom-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-collapsed-bottom-bc {
    background-position: 0 -1px;
}

.x-panel-header-light-framed-collapsed-bottom-tr,
.x-panel-header-light-framed-collapsed-bottom-br,
.x-panel-header-light-framed-collapsed-bottom-mr {
    padding-right: 1px;
}

.x-panel-header-light-framed-collapsed-bottom-tl,
.x-panel-header-light-framed-collapsed-bottom-bl,
.x-panel-header-light-framed-collapsed-bottom-ml {
    padding-left: 1px;
}

.x-panel-header-light-framed-collapsed-bottom-tc {
    height: 1px;
}

.x-panel-header-light-framed-collapsed-bottom-bc {
    height: 1px;
}

.x-panel-header-light-framed-collapsed-bottom-mc {
    padding: 16px 16px 15px 16px;
}

.x-panel-header-light-framed-collapsed-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 16px 16px 16px 15px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-rtl.x-panel-header-light-framed-collapsed-left {
    background-image: none;
    background-color: #fff;
}

.x-panel-header-light-framed-collapsed-left-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-collapsed-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-collapsed-left-frameInfo {
    font-family: dh-1-1-1-1-1-1-1-1-16-16-16-15;
}

.x-panel-header-light-framed-collapsed-left-tl {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-collapsed-left-tr {
    background-position: right -3px;
}

.x-panel-header-light-framed-collapsed-left-bl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-collapsed-left-br {
    background-position: right -5px;
}

.x-panel-header-light-framed-collapsed-left-ml {
    background-position: left 0;
}

.x-panel-header-light-framed-collapsed-left-mr {
    background-position: left 0;
}

.x-panel-header-light-framed-collapsed-left-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-collapsed-left-bc {
    background-position: 0 -1px;
}

.x-panel-header-light-framed-collapsed-left-tr,
.x-panel-header-light-framed-collapsed-left-br,
.x-panel-header-light-framed-collapsed-left-mr {
    padding-right: 1px;
}

.x-panel-header-light-framed-collapsed-left-tl,
.x-panel-header-light-framed-collapsed-left-bl,
.x-panel-header-light-framed-collapsed-left-ml {
    padding-left: 1px;
}

.x-panel-header-light-framed-collapsed-left-tc {
    height: 1px;
}

.x-panel-header-light-framed-collapsed-left-bc {
    height: 1px;
}

.x-panel-header-light-framed-collapsed-left-mc {
    padding: 16px 16px 16px 15px;
}

.x-panel .x-panel-header-light-framed-top {
    border-bottom-width: 1px !important;
}

.x-panel .x-panel-header-light-framed-right {
    border-left-width: 1px !important;
}

.x-panel .x-panel-header-light-framed-bottom {
    border-top-width: 1px !important;
}

.x-panel .x-panel-header-light-framed-left {
    border-right-width: 1px !important;
}

.x-nbr .x-panel-header-light-framed-collapsed-top {
    border-bottom-width: 0 !important;
}

.x-nbr .x-panel-header-light-framed-collapsed-right {
    border-left-width: 0 !important;
}

.x-nbr .x-panel-header-light-framed-collapsed-bottom {
    border-top-width: 0 !important;
}

.x-nbr .x-panel-header-light-framed-collapsed-left {
    border-right-width: 0 !important;
}

.x-panel-header-light-framed-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-panel-header-light-framed-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-panel-header-light-framed-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-panel-header-light-framed-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-panel-header-light-framed-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-light-framed-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-light-framed-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-panel-header-light-framed-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-panel-header-light-framed .x-tool-focus {
    outline: 1px solid #2196f3;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-light-framed .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-light-framed .x-tool-focus,
.x-edge .x-keyboard-mode .x-panel-header-light-framed .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-light-framed .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-light-framed .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-light-framed .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #2196f3;
    pointer-events: none;
}

.x-rtl.x-panel-header-light-framed-collapsed-border-right {
    border-right-width: 1px !important;
}

.x-rtl.x-panel-header-light-framed-collapsed-border-left {
    border-left-width: 1px !important;
}

.x-panel-light-framed-resizable .x-panel-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-panel-light-framed-outer-border-l {
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-framed-outer-border-b {
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
}

.x-panel-light-framed-outer-border-bl {
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-framed-outer-border-r {
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
}

.x-panel-light-framed-outer-border-rl {
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-framed-outer-border-rb {
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
}

.x-panel-light-framed-outer-border-rbl {
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-framed-outer-border-t {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
}

.x-panel-light-framed-outer-border-tl {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-framed-outer-border-tb {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
}

.x-panel-light-framed-outer-border-tbl {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-framed-outer-border-tr {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
}

.x-panel-light-framed-outer-border-trl {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-framed-outer-border-trb {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
}

.x-panel-light-framed-outer-border-trbl {
    border-color: #d0d0d0 !important;
    border-width: 1px !important;
}

.x-form-file-wrap .x-form-trigger-wrap {
    border: 0;
}

.x-form-file-wrap .x-form-trigger-wrap .x-form-text {
    border: 1px solid;
    border-color: #d0d0d0;
    height: 32px;
}

.x-form-file-wrap .x-form-trigger-wrap .x-form-text.x-form-invalid-field {
    border-color: #cf4c35;
}

.x-html-editor-container {
    border: 1px solid;
    border-color: #d0d0d0;
}

.x-grid-header-ct {
    border: 1px solid #e2e2e2;
}

.x-column-header-trigger {
    background-color: #fff;
    border-left: 1px solid #e2e2e2;
}

.x-rtl.x-column-header-trigger {
    border-right: 1px solid #e2e2e2;
    border-left: 0;
}

.x-column-header-last {
    border-right-width: 0;
}

.x-column-header-last .x-column-header-over .x-column-header-trigger {
    border-right: 1px solid #e2e2e2;
}

.x-rtl.x-column-header-last {
    border-left-width: 0;
}

.x-rtl.x-column-header-last .x-column-header-over .x-column-header-trigger {
    border-left: 1px solid #e2e2e2;
}

.x-resizable-handle {
    background-repeat: no-repeat;
}

.x-resizable-handle-east-over,
.x-resizable-handle-west-over {
    background-position: center;
}

.x-resizable-handle-south-over,
.x-resizable-handle-north-over {
    background-position: center;
}

.x-resizable-handle-southeast-over {
    background-position: -2px -2px;
}

.x-resizable-handle-northwest-over {
    background-position: 2px 2px;
}

.x-resizable-handle-northeast-over {
    background-position: -2px 2px;
}

.x-resizable-handle-southwest-over {
    background-position: 2px -2px;
}

.x-resizable-pinned .x-resizable-handle-east,
.x-resizable-pinned .x-resizable-handle-west {
    background-position: center;
}

.x-resizable-pinned .x-resizable-handle-south,
.x-resizable-pinned .x-resizable-handle-north {
    background-position: center;
}

.x-resizable-pinned .x-resizable-handle-southeast {
    background-position: -2px -2px;
}

.x-resizable-pinned .x-resizable-handle-northwest {
    background-position: 2px 2px;
}

.x-resizable-pinned .x-resizable-handle-northeast {
    background-position: -2px 2px;
}

.x-resizable-pinned .x-resizable-handle-southwest {
    background-position: 2px -2px;
}

.fa-spin {
    animation: fa-spin 2s infinite linear;
}

.fa-pulse {
    animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {

    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.fa-border {
    border: solid 0.08em #eee;
    border-radius: 0.1em;
    padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
    float: left;
}

.fa-pull-right {
    float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
    margin-right: 0.3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
    margin-left: 0.3em;
}

.fa,
.fas,
.far,
.fal,
.fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.fa-fw {
    text-align: center;
    width: 1.25em;
}

.fa-500px:before {
    content: '\f26e' !important;
}

.fa-accessible-icon:before {
    content: '\f368' !important;
}

.fa-accusoft:before {
    content: '\f369' !important;
}

.fa-acquisitions-incorporated:before {
    content: '\f6af' !important;
}

.fa-ad:before {
    content: '\f641' !important;
}

.fa-address-book:before {
    content: '\f2b9' !important;
}

.fa-address-card:before {
    content: '\f2bb' !important;
}

.fa-adjust:before {
    content: '\f042' !important;
}

.fa-adn:before {
    content: '\f170' !important;
}

.fa-adobe:before {
    content: '\f778' !important;
}

.fa-adversal:before {
    content: '\f36a' !important;
}

.fa-affiliatetheme:before {
    content: '\f36b' !important;
}

.fa-air-freshener:before {
    content: '\f5d0' !important;
}

.fa-algolia:before {
    content: '\f36c' !important;
}

.fa-align-center:before {
    content: '\f037' !important;
}

.fa-align-justify:before {
    content: '\f039' !important;
}

.fa-align-left:before {
    content: '\f036' !important;
}

.fa-align-right:before {
    content: '\f038' !important;
}

.fa-alipay:before {
    content: '\f642' !important;
}

.fa-allergies:before {
    content: '\f461' !important;
}

.fa-amazon:before {
    content: '\f270' !important;
}

.fa-amazon-pay:before {
    content: '\f42c' !important;
}

.fa-ambulance:before {
    content: '\f0f9' !important;
}

.fa-american-sign-language-interpreting:before {
    content: '\f2a3' !important;
}

.fa-amilia:before {
    content: '\f36d' !important;
}

.fa-anchor:before {
    content: '\f13d' !important;
}

.fa-android:before {
    content: '\f17b' !important;
}

.fa-angellist:before {
    content: '\f209' !important;
}

.fa-angle-double-down:before {
    content: '\f103' !important;
}

.fa-angle-double-left:before {
    content: '\f100' !important;
}

.fa-angle-double-right:before {
    content: '\f101' !important;
}

.fa-angle-double-up:before {
    content: '\f102' !important;
}

.fa-angle-down:before {
    content: '\f107' !important;
}

.fa-angle-left:before {
    content: '\f104' !important;
}

.fa-angle-right:before {
    content: '\f105' !important;
}

.fa-angle-up:before {
    content: '\f106' !important;
}

.fa-angry:before {
    content: '\f556' !important;
}

.fa-angrycreative:before {
    content: '\f36e' !important;
}

.fa-angular:before {
    content: '\f420' !important;
}

.fa-ankh:before {
    content: '\f644' !important;
}

.fa-app-store:before {
    content: '\f36f' !important;
}

.fa-app-store-ios:before {
    content: '\f370' !important;
}

.fa-apper:before {
    content: '\f371' !important;
}

.fa-apple:before {
    content: '\f179' !important;
}

.fa-apple-alt:before {
    content: '\f5d1' !important;
}

.fa-apple-pay:before {
    content: '\f415' !important;
}

.fa-archive:before {
    content: '\f187' !important;
}

.fa-archway:before {
    content: '\f557' !important;
}

.fa-arrow-alt-circle-down:before {
    content: '\f358' !important;
}

.fa-arrow-alt-circle-left:before {
    content: '\f359' !important;
}

.fa-arrow-alt-circle-right:before {
    content: '\f35a' !important;
}

.fa-arrow-alt-circle-up:before {
    content: '\f35b' !important;
}

.fa-arrow-circle-down:before {
    content: '\f0ab' !important;
}

.fa-arrow-circle-left:before {
    content: '\f0a8' !important;
}

.fa-arrow-circle-right:before {
    content: '\f0a9' !important;
}

.fa-arrow-circle-up:before {
    content: '\f0aa' !important;
}

.fa-arrow-down:before {
    content: '\f063' !important;
}

.fa-arrow-left:before {
    content: '\f060' !important;
}

.fa-arrow-right:before {
    content: '\f061' !important;
}

.fa-arrow-up:before {
    content: '\f062' !important;
}

.fa-arrows-alt:before {
    content: '\f31e' !important;
}

.fa-arrows-alt-h:before {
    content: '\f337' !important;
}

.fa-arrows-alt-v:before {
    content: '\f338' !important;
}

.fa-artstation:before {
    content: '\f77a' !important;
}

.fa-assistive-listening-systems:before {
    content: '\f2a2' !important;
}

.fa-asterisk:before {
    content: '\f069' !important;
}

.fa-asymmetrik:before {
    content: '\f372' !important;
}

.fa-at:before {
    content: '\f1fa' !important;
}

.fa-atlas:before {
    content: '\f558' !important;
}

.fa-atlassian:before {
    content: '\f77b' !important;
}

.fa-atom:before {
    content: '\f5d2' !important;
}

.fa-audible:before {
    content: '\f373' !important;
}

.fa-audio-description:before {
    content: '\f29e' !important;
}

.fa-autoprefixer:before {
    content: '\f41c' !important;
}

.fa-avianex:before {
    content: '\f374' !important;
}

.fa-aviato:before {
    content: '\f421' !important;
}

.fa-award:before {
    content: '\f559' !important;
}

.fa-aws:before {
    content: '\f375' !important;
}

.fa-baby:before {
    content: '\f77c' !important;
}

.fa-baby-carriage:before {
    content: '\f77d' !important;
}

.fa-backspace:before {
    content: '\f55a' !important;
}

.fa-backward:before {
    content: '\f04a' !important;
}

.fa-balance-scale:before {
    content: '\f24e' !important;
}

.fa-ban:before {
    content: '\f05e' !important;
}

.fa-band-aid:before {
    content: '\f462' !important;
}

.fa-bandcamp:before {
    content: '\f2d5' !important;
}

.fa-barcode:before {
    content: '\f02a' !important;
}

.fa-bars:before {
    content: '\f0c9' !important;
}

.fa-baseball-ball:before {
    content: '\f433' !important;
}

.fa-basketball-ball:before {
    content: '\f434' !important;
}

.fa-bath:before {
    content: '\f2cd' !important;
}

.fa-battery-empty:before {
    content: '\f244' !important;
}

.fa-battery-full:before {
    content: '\f240' !important;
}

.fa-battery-half:before {
    content: '\f242' !important;
}

.fa-battery-quarter:before {
    content: '\f243' !important;
}

.fa-battery-three-quarters:before {
    content: '\f241' !important;
}

.fa-bed:before {
    content: '\f236' !important;
}

.fa-beer:before {
    content: '\f0fc' !important;
}

.fa-behance:before {
    content: '\f1b4' !important;
}

.fa-behance-square:before {
    content: '\f1b5' !important;
}

.fa-bell:before {
    content: '\f0f3' !important;
}

.fa-bell-slash:before {
    content: '\f1f6' !important;
}

.fa-bezier-curve:before {
    content: '\f55b' !important;
}

.fa-bible:before {
    content: '\f647' !important;
}

.fa-bicycle:before {
    content: '\f206' !important;
}

.fa-bimobject:before {
    content: '\f378' !important;
}

.fa-binoculars:before {
    content: '\f1e5' !important;
}

.fa-biohazard:before {
    content: '\f780' !important;
}

.fa-birthday-cake:before {
    content: '\f1fd' !important;
}

.fa-bitbucket:before {
    content: '\f171' !important;
}

.fa-bitcoin:before {
    content: '\f15a' !important;
}

.fa-bity:before {
    content: '\f37a' !important;
}

.fa-black-tie:before {
    content: '\f27e' !important;
}

.fa-blackberry:before {
    content: '\f37b' !important;
}

.fa-blender:before {
    content: '\f517' !important;
}

.fa-blender-phone:before {
    content: '\f6b6' !important;
}

.fa-blind:before {
    content: '\f29d' !important;
}

.fa-blog:before {
    content: '\f781' !important;
}

.fa-blogger:before {
    content: '\f37c' !important;
}

.fa-blogger-b:before {
    content: '\f37d' !important;
}

.fa-bluetooth:before {
    content: '\f293' !important;
}

.fa-bluetooth-b:before {
    content: '\f294' !important;
}

.fa-bold:before {
    content: '\f032' !important;
}

.fa-bolt:before {
    content: '\f0e7' !important;
}

.fa-bomb:before {
    content: '\f1e2' !important;
}

.fa-bone:before {
    content: '\f5d7' !important;
}

.fa-bong:before {
    content: '\f55c' !important;
}

.fa-book:before {
    content: '\f02d' !important;
}

.fa-book-dead:before {
    content: '\f6b7' !important;
}

.fa-book-open:before {
    content: '\f518' !important;
}

.fa-book-reader:before {
    content: '\f5da' !important;
}

.fa-bookmark:before {
    content: '\f02e' !important;
}

.fa-bowling-ball:before {
    content: '\f436' !important;
}

.fa-box:before {
    content: '\f466' !important;
}

.fa-box-open:before {
    content: '\f49e' !important;
}

.fa-boxes:before {
    content: '\f468' !important;
}

.fa-braille:before {
    content: '\f2a1' !important;
}

.fa-brain:before {
    content: '\f5dc' !important;
}

.fa-briefcase:before {
    content: '\f0b1' !important;
}

.fa-briefcase-medical:before {
    content: '\f469' !important;
}

.fa-broadcast-tower:before {
    content: '\f519' !important;
}

.fa-broom:before {
    content: '\f51a' !important;
}

.fa-brush:before {
    content: '\f55d' !important;
}

.fa-btc:before {
    content: '\f15a' !important;
}

.fa-bug:before {
    content: '\f188' !important;
}

.fa-building:before {
    content: '\f1ad' !important;
}

.fa-bullhorn:before {
    content: '\f0a1' !important;
}

.fa-bullseye:before {
    content: '\f140' !important;
}

.fa-burn:before {
    content: '\f46a' !important;
}

.fa-buromobelexperte:before {
    content: '\f37f' !important;
}

.fa-bus:before {
    content: '\f207' !important;
}

.fa-bus-alt:before {
    content: '\f55e' !important;
}

.fa-business-time:before {
    content: '\f64a' !important;
}

.fa-buysellads:before {
    content: '\f20d' !important;
}

.fa-calculator:before {
    content: '\f1ec' !important;
}

.fa-calendar:before {
    content: '\f073' !important;
}

.fa-calendar-alt:before {
    content: '\f073' !important;
}

.fa-calendar-check:before {
    content: '\f274' !important;
}

.fa-calendar-day:before {
    content: '\f783' !important;
}

.fa-calendar-minus:before {
    content: '\f272' !important;
}

.fa-calendar-plus:before {
    content: '\f271' !important;
}

.fa-calendar-times:before {
    content: '\f273' !important;
}

.fa-calendar-week:before {
    content: '\f784' !important;
}

.fa-camera:before {
    content: '\f030' !important;
}

.fa-camera-retro:before {
    content: '\f083' !important;
}

.fa-campground:before {
    content: '\f6bb' !important;
}

.fa-canadian-maple-leaf:before {
    content: '\f785' !important;
}

.fa-candy-cane:before {
    content: '\f786' !important;
}

.fa-cannabis:before {
    content: '\f55f' !important;
}

.fa-capsules:before {
    content: '\f46b' !important;
}

.fa-car:before {
    content: '\f1b9' !important;
}

.fa-car-alt:before {
    content: '\f5de' !important;
}

.fa-car-battery:before {
    content: '\f5df' !important;
}

.fa-car-crash:before {
    content: '\f5e1' !important;
}

.fa-car-side:before {
    content: '\f5e4' !important;
}

.fa-caret-down:before {
    content: '\f0d7' !important;
}

.fa-caret-left:before {
    content: '\f0d9' !important;
}

.fa-caret-right:before {
    content: '\f0da' !important;
}

.fa-caret-square-down:before {
    content: '\f150' !important;
}

.fa-caret-square-left:before {
    content: '\f191' !important;
}

.fa-caret-square-right:before {
    content: '\f152' !important;
}

.fa-caret-square-up:before {
    content: '\f151' !important;
}

.fa-caret-up:before {
    content: '\f0d8' !important;
}

.fa-carrot:before {
    content: '\f787' !important;
}

.fa-cart-arrow-down:before {
    content: '\f218' !important;
}

.fa-cart-plus:before {
    content: '\f217' !important;
}

.fa-cash-register:before {
    content: '\f788' !important;
}

.fa-cat:before {
    content: '\f6be' !important;
}

.fa-cc-amazon-pay:before {
    content: '\f42d' !important;
}

.fa-cc-amex:before {
    content: '\f1f3' !important;
}

.fa-cc-apple-pay:before {
    content: '\f416' !important;
}

.fa-cc-diners-club:before {
    content: '\f24c' !important;
}

.fa-cc-discover:before {
    content: '\f1f2' !important;
}

.fa-cc-jcb:before {
    content: '\f24b' !important;
}

.fa-cc-mastercard:before {
    content: '\f1f1' !important;
}

.fa-cc-paypal:before {
    content: '\f1f4' !important;
}

.fa-cc-stripe:before {
    content: '\f1f5' !important;
}

.fa-cc-visa:before {
    content: '\f1f0' !important;
}

.fa-centercode:before {
    content: '\f380' !important;
}

.fa-centos:before {
    content: '\f789' !important;
}

.fa-certificate:before {
    content: '\f0a3' !important;
}

.fa-chair:before {
    content: '\f6c0' !important;
}

.fa-chalkboard:before {
    content: '\f51b' !important;
}

.fa-chalkboard-teacher:before {
    content: '\f51c' !important;
}

.fa-charging-station:before {
    content: '\f5e7' !important;
}

.fa-chart-area:before {
    content: '\f1fe' !important;
}

.fa-chart-bar:before {
    content: '\f080' !important;
}

.fa-chart-line:before {
    content: '\f201' !important;
}

.fa-chart-pie:before {
    content: '\f200' !important;
}

.fa-check:before {
    content: '\f00c' !important;
}

.fa-check-circle:before {
    content: '\f058' !important;
}

.fa-check-double:before {
    content: '\f560' !important;
}

.fa-check-square:before {
    content: '\f14a' !important;
}

.fa-chess:before {
    content: '\f439' !important;
}

.fa-chess-bishop:before {
    content: '\f43a' !important;
}

.fa-chess-board:before {
    content: '\f43c' !important;
}

.fa-chess-king:before {
    content: '\f43f' !important;
}

.fa-chess-knight:before {
    content: '\f441' !important;
}

.fa-chess-pawn:before {
    content: '\f443' !important;
}

.fa-chess-queen:before {
    content: '\f445' !important;
}

.fa-chess-rook:before {
    content: '\f447' !important;
}

.fa-chevron-circle-down:before {
    content: '\f13a' !important;
}

.fa-chevron-circle-left:before {
    content: '\f137' !important;
}

.fa-chevron-circle-right:before {
    content: '\f138' !important;
}

.fa-chevron-circle-up:before {
    content: '\f139' !important;
}

.fa-chevron-down:before {
    content: '\f078' !important;
}

.fa-chevron-left:before {
    content: '\f053' !important;
}

.fa-chevron-right:before {
    content: '\f054' !important;
}

.fa-chevron-up:before {
    content: '\f077' !important;
}

.fa-child:before {
    content: '\f1ae' !important;
}

.fa-chrome:before {
    content: '\f268' !important;
}

.fa-church:before {
    content: '\f51d' !important;
}

.fa-circle:before {
    content: '\f111' !important;
}

.fa-circle-notch:before {
    content: '\f1ce' !important;
}

.fa-city:before {
    content: '\f64f' !important;
}

.fa-clipboard:before {
    content: '\f328' !important;
}

.fa-clipboard-check:before {
    content: '\f46c' !important;
}

.fa-clipboard-list:before {
    content: '\f46d' !important;
}

.fa-clock:before {
    content: '\f017' !important;
}

.fa-clone:before {
    content: '\f24d' !important;
}

.fa-closed-captioning:before {
    content: '\f20a' !important;
}

.fa-cloud:before {
    content: '\f0c2' !important;
}

.fa-cloud-download-alt:before {
    content: '\f381' !important;
}

.fa-cloud-meatball:before {
    content: '\f73b' !important;
}

.fa-cloud-moon:before {
    content: '\f6c3' !important;
}

.fa-cloud-moon-rain:before {
    content: '\f73c' !important;
}

.fa-cloud-rain:before {
    content: '\f73d' !important;
}

.fa-cloud-showers-heavy:before {
    content: '\f740' !important;
}

.fa-cloud-sun:before {
    content: '\f6c4' !important;
}

.fa-cloud-sun-rain:before {
    content: '\f743' !important;
}

.fa-cloud-upload-alt:before {
    content: '\f382' !important;
}

.fa-cloudscale:before {
    content: '\f383' !important;
}

.fa-cloudsmith:before {
    content: '\f384' !important;
}

.fa-cloudversify:before {
    content: '\f385' !important;
}

.fa-cocktail:before {
    content: '\f561' !important;
}

.fa-code:before {
    content: '\f121' !important;
}

.fa-code-branch:before {
    content: '\f126' !important;
}

.fa-codepen:before {
    content: '\f1cb' !important;
}

.fa-codiepie:before {
    content: '\f284' !important;
}

.fa-coffee:before {
    content: '\f0f4' !important;
}

.fa-cog:before {
    content: '\f013' !important;
}

.fa-cogs:before {
    content: '\f085' !important;
}

.fa-coins:before {
    content: '\f51e' !important;
}

.fa-columns:before {
    content: '\f0db' !important;
}

.fa-comment:before {
    content: '\f075' !important;
}

.fa-comment-alt:before {
    content: '\f27a' !important;
}

.fa-comment-dollar:before {
    content: '\f651' !important;
}

.fa-comment-dots:before {
    content: '\f4ad' !important;
}

.fa-comment-slash:before {
    content: '\f4b3' !important;
}

.fa-comments:before {
    content: '\f086' !important;
}

.fa-comments-dollar:before {
    content: '\f653' !important;
}

.fa-compact-disc:before {
    content: '\f51f' !important;
}

.fa-compass:before {
    content: '\f14e' !important;
}

.fa-compress:before {
    content: '\f066' !important;
}

.fa-compress-arrows-alt:before {
    content: '\f78c' !important;
}

.fa-concierge-bell:before {
    content: '\f562' !important;
}

.fa-confluence:before {
    content: '\f78d' !important;
}

.fa-connectdevelop:before {
    content: '\f20e' !important;
}

.fa-contao:before {
    content: '\f26d' !important;
}

.fa-cookie:before {
    content: '\f563' !important;
}

.fa-cookie-bite:before {
    content: '\f564' !important;
}

.fa-copy:before {
    content: '\f0c5' !important;
}

.fa-copyright:before {
    content: '\f1f9' !important;
}

.fa-couch:before {
    content: '\f4b8' !important;
}

.fa-cpanel:before {
    content: '\f388' !important;
}

.fa-creative-commons:before {
    content: '\f25e' !important;
}

.fa-creative-commons-by:before {
    content: '\f4e7' !important;
}

.fa-creative-commons-nc:before {
    content: '\f4e8' !important;
}

.fa-creative-commons-nc-eu:before {
    content: '\f4e9' !important;
}

.fa-creative-commons-nc-jp:before {
    content: '\f4ea' !important;
}

.fa-creative-commons-nd:before {
    content: '\f4eb' !important;
}

.fa-creative-commons-pd:before {
    content: '\f4ec' !important;
}

.fa-creative-commons-pd-alt:before {
    content: '\f4ed' !important;
}

.fa-creative-commons-remix:before {
    content: '\f4ee' !important;
}

.fa-creative-commons-sa:before {
    content: '\f4ef' !important;
}

.fa-creative-commons-sampling:before {
    content: '\f4f0' !important;
}

.fa-creative-commons-sampling-plus:before {
    content: '\f4f1' !important;
}

.fa-creative-commons-share:before {
    content: '\f4f2' !important;
}

.fa-creative-commons-zero:before {
    content: '\f4f3' !important;
}

.fa-credit-card:before {
    content: '\f09d' !important;
}

.fa-critical-role:before {
    content: '\f6c9' !important;
}

.fa-crop:before {
    content: '\f125' !important;
}

.fa-crop-alt:before {
    content: '\f565' !important;
}

.fa-cross:before {
    content: '\f654' !important;
}

.fa-crosshairs:before {
    content: '\f05b' !important;
}

.fa-crow:before {
    content: '\f520' !important;
}

.fa-crown:before {
    content: '\f521' !important;
}

.fa-css3:before {
    content: '\f13c' !important;
}

.fa-css3-alt:before {
    content: '\f38b' !important;
}

.fa-cube:before {
    content: '\f1b2' !important;
}

.fa-cubes:before {
    content: '\f1b3' !important;
}

.fa-cut:before {
    content: '\f0c4' !important;
}

.fa-cuttlefish:before {
    content: '\f38c' !important;
}

.fa-d-and-d:before {
    content: '\f38d' !important;
}

.fa-d-and-d-beyond:before {
    content: '\f6ca' !important;
}

.fa-dashcube:before {
    content: '\f210' !important;
}

.fa-database:before {
    content: '\f1c0' !important;
}

.fa-deaf:before {
    content: '\f2a4' !important;
}

.fa-delicious:before {
    content: '\f1a5' !important;
}

.fa-democrat:before {
    content: '\f747' !important;
}

.fa-deploydog:before {
    content: '\f38e' !important;
}

.fa-deskpro:before {
    content: '\f38f' !important;
}

.fa-desktop:before {
    content: '\f108' !important;
}

.fa-dev:before {
    content: '\f6cc' !important;
}

.fa-deviantart:before {
    content: '\f1bd' !important;
}

.fa-dharmachakra:before {
    content: '\f655' !important;
}

.fa-dhl:before {
    content: '\f790' !important;
}

.fa-diagnoses:before {
    content: '\f470' !important;
}

.fa-diaspora:before {
    content: '\f791' !important;
}

.fa-dice:before {
    content: '\f522' !important;
}

.fa-dice-d20:before {
    content: '\f6cf' !important;
}

.fa-dice-d6:before {
    content: '\f6d1' !important;
}

.fa-dice-five:before {
    content: '\f523' !important;
}

.fa-dice-four:before {
    content: '\f524' !important;
}

.fa-dice-one:before {
    content: '\f525' !important;
}

.fa-dice-six:before {
    content: '\f526' !important;
}

.fa-dice-three:before {
    content: '\f527' !important;
}

.fa-dice-two:before {
    content: '\f528' !important;
}

.fa-digg:before {
    content: '\f1a6' !important;
}

.fa-digital-ocean:before {
    content: '\f391' !important;
}

.fa-digital-tachograph:before {
    content: '\f566' !important;
}

.fa-directions:before {
    content: '\f5eb' !important;
}

.fa-discord:before {
    content: '\f392' !important;
}

.fa-discourse:before {
    content: '\f393' !important;
}

.fa-divide:before {
    content: '\f529' !important;
}

.fa-dizzy:before {
    content: '\f567' !important;
}

.fa-dna:before {
    content: '\f471' !important;
}

.fa-dochub:before {
    content: '\f394' !important;
}

.fa-docker:before {
    content: '\f395' !important;
}

.fa-dog:before {
    content: '\f6d3' !important;
}

.fa-dollar-sign:before {
    content: '\f155' !important;
}

.fa-dolly:before {
    content: '\f472' !important;
}

.fa-dolly-flatbed:before {
    content: '\f474' !important;
}

.fa-donate:before {
    content: '\f4b9' !important;
}

.fa-door-closed:before {
    content: '\f52a' !important;
}

.fa-door-open:before {
    content: '\f52b' !important;
}

.fa-dot-circle:before {
    content: '\f192' !important;
}

.fa-dove:before {
    content: '\f4ba' !important;
}

.fa-download:before {
    content: '\f019' !important;
}

.fa-draft2digital:before {
    content: '\f396' !important;
}

.fa-drafting-compass:before {
    content: '\f568' !important;
}

.fa-dragon:before {
    content: '\f6d5' !important;
}

.fa-draw-polygon:before {
    content: '\f5ee' !important;
}

.fa-dribbble:before {
    content: '\f17d' !important;
}

.fa-dribbble-square:before {
    content: '\f397' !important;
}

.fa-dropbox:before {
    content: '\f16b' !important;
}

.fa-drum:before {
    content: '\f569' !important;
}

.fa-drum-steelpan:before {
    content: '\f56a' !important;
}

.fa-drumstick-bite:before {
    content: '\f6d7' !important;
}

.fa-drupal:before {
    content: '\f1a9' !important;
}

.fa-dumbbell:before {
    content: '\f44b' !important;
}

.fa-dumpster:before {
    content: '\f793' !important;
}

.fa-dumpster-fire:before {
    content: '\f794' !important;
}

.fa-dungeon:before {
    content: '\f6d9' !important;
}

.fa-dyalog:before {
    content: '\f399' !important;
}

.fa-earlybirds:before {
    content: '\f39a' !important;
}

.fa-ebay:before {
    content: '\f4f4' !important;
}

.fa-edge:before {
    content: '\f282' !important;
}

.fa-edit:before {
    content: '\f044' !important;
}

.fa-eject:before {
    content: '\f052' !important;
}

.fa-elementor:before {
    content: '\f430' !important;
}

.fa-ellipsis-h:before {
    content: '\f141' !important;
}

.fa-ellipsis-v:before {
    content: '\f142' !important;
}

.fa-ello:before {
    content: '\f5f1' !important;
}

.fa-ember:before {
    content: '\f423' !important;
}

.fa-empire:before {
    content: '\f1d1' !important;
}

.fa-envelope:before {
    content: '\f0e0' !important;
}

.fa-envelope-open:before {
    content: '\f2b6' !important;
}

.fa-envelope-open-text:before {
    content: '\f658' !important;
}

.fa-envelope-square:before {
    content: '\f199' !important;
}

.fa-envira:before {
    content: '\f299' !important;
}

.fa-equals:before {
    content: '\f52c' !important;
}

.fa-eraser:before {
    content: '\f12d' !important;
}

.fa-erlang:before {
    content: '\f39d' !important;
}

.fa-ethereum:before {
    content: '\f42e' !important;
}

.fa-ethernet:before {
    content: '\f796' !important;
}

.fa-etsy:before {
    content: '\f2d7' !important;
}

.fa-euro-sign:before {
    content: '\f153' !important;
}

.fa-exchange-alt:before {
    content: '\f362' !important;
}

.fa-exclamation:before {
    content: '\f12a' !important;
}

.fa-exclamation-circle:before {
    content: '\f06a' !important;
}

.fa-exclamation-triangle:before {
    content: '\f071' !important;
}

.fa-expand:before {
    content: '\f065' !important;
}

.fa-expand-arrows-alt:before {
    content: '\f31e' !important;
}

.fa-expeditedssl:before {
    content: '\f23e' !important;
}

.fa-external-link-alt:before {
    content: '\f35d' !important;
}

.fa-external-link-square-alt:before {
    content: '\f360' !important;
}

.fa-eye:before {
    content: '\f06e' !important;
}

.fa-eye-dropper:before {
    content: '\f1fb' !important;
}

.fa-eye-slash:before {
    content: '\f070' !important;
}

.fa-facebook:before {
    content: '\f39e' !important;
}

.fa-facebook-f:before {
    content: '\f39e' !important;
}

.fa-facebook-messenger:before {
    content: '\f39f' !important;
}

.fa-facebook-square:before {
    content: '\f082' !important;
}

.fa-fantasy-flight-games:before {
    content: '\f6dc' !important;
}

.fa-fast-backward:before {
    content: '\f049' !important;
}

.fa-fast-forward:before {
    content: '\f050' !important;
}

.fa-fax:before {
    content: '\f1ac' !important;
}

.fa-feather:before {
    content: '\f52d' !important;
}

.fa-feather-alt:before {
    content: '\f56b' !important;
}

.fa-fedex:before {
    content: '\f797' !important;
}

.fa-fedora:before {
    content: '\f798' !important;
}

.fa-female:before {
    content: '\f182' !important;
}

.fa-fighter-jet:before {
    content: '\f0fb' !important;
}

.fa-figma:before {
    content: '\f799' !important;
}

.fa-file:before {
    content: '\f15b' !important;
}

.fa-file-alt:before {
    content: '\f15c' !important;
}

.fa-file-archive:before {
    content: '\f1c6' !important;
}

.fa-file-audio:before {
    content: '\f1c7' !important;
}

.fa-file-code:before {
    content: '\f1c9' !important;
}

.fa-file-contract:before {
    content: '\f56c' !important;
}

.fa-file-csv:before {
    content: '\f6dd' !important;
}

.fa-file-download:before {
    content: '\f56d' !important;
}

.fa-file-excel:before {
    content: '\f1c3' !important;
}

.fa-file-export:before {
    content: '\f56e' !important;
}

.fa-file-image:before {
    content: '\f1c5' !important;
}

.fa-file-import:before {
    content: '\f56f' !important;
}

.fa-file-invoice:before {
    content: '\f570' !important;
}

.fa-file-invoice-dollar:before {
    content: '\f571' !important;
}

.fa-file-medical:before {
    content: '\f477' !important;
}

.fa-file-medical-alt:before {
    content: '\f478' !important;
}

.fa-file-pdf:before {
    content: '\f1c1' !important;
}

.fa-file-powerpoint:before {
    content: '\f1c4' !important;
}

.fa-file-prescription:before {
    content: '\f572' !important;
}

.fa-file-signature:before {
    content: '\f573' !important;
}

.fa-file-upload:before {
    content: '\f574' !important;
}

.fa-file-video:before {
    content: '\f1c8' !important;
}

.fa-file-word:before {
    content: '\f1c2' !important;
}

.fa-fill:before {
    content: '\f575' !important;
}

.fa-fill-drip:before {
    content: '\f576' !important;
}

.fa-film:before {
    content: '\f008' !important;
}

.fa-filter:before {
    content: '\f0b0' !important;
}

.fa-fingerprint:before {
    content: '\f577' !important;
}

.fa-fire:before {
    content: '\f06d' !important;
}

.fa-fire-alt:before {
    content: '\f7e4' !important;
}

.fa-fire-extinguisher:before {
    content: '\f134' !important;
}

.fa-firefox:before {
    content: '\f269' !important;
}

.fa-first-aid:before {
    content: '\f479' !important;
}

.fa-first-order:before {
    content: '\f2b0' !important;
}

.fa-first-order-alt:before {
    content: '\f50a' !important;
}

.fa-firstdraft:before {
    content: '\f3a1' !important;
}

.fa-fish:before {
    content: '\f578' !important;
}

.fa-fist-raised:before {
    content: '\f6de' !important;
}

.fa-flag:before {
    content: '\f024' !important;
}

.fa-flag-checkered:before {
    content: '\f11e' !important;
}

.fa-flag-usa:before {
    content: '\f74d' !important;
}

.fa-flask:before {
    content: '\f0c3' !important;
}

.fa-flickr:before {
    content: '\f16e' !important;
}

.fa-flipboard:before {
    content: '\f44d' !important;
}

.fa-flushed:before {
    content: '\f579' !important;
}

.fa-fly:before {
    content: '\f417' !important;
}

.fa-folder:before {
    content: '\f07b' !important;
}

.fa-folder-minus:before {
    content: '\f65d' !important;
}

.fa-folder-open:before {
    content: '\f07c' !important;
}

.fa-folder-plus:before {
    content: '\f65e' !important;
}

.fa-font:before {
    content: '\f031' !important;
}

.fa-font-awesome:before {
    content: '\f2b4' !important;
}

.fa-font-awesome-alt:before {
    content: '\f35c' !important;
}

.fa-font-awesome-flag:before {
    content: '\f425' !important;
}

.fa-font-awesome-logo-full:before {
    content: '\f4e6' !important;
}

.fa-fonticons:before {
    content: '\f280' !important;
}

.fa-fonticons-fi:before {
    content: '\f3a2' !important;
}

.fa-football-ball:before {
    content: '\f44e' !important;
}

.fa-fort-awesome:before {
    content: '\f286' !important;
}

.fa-fort-awesome-alt:before {
    content: '\f3a3' !important;
}

.fa-forumbee:before {
    content: '\f211' !important;
}

.fa-forward:before {
    content: '\f04e' !important;
}

.fa-foursquare:before {
    content: '\f180' !important;
}

.fa-free-code-camp:before {
    content: '\f2c5' !important;
}

.fa-freebsd:before {
    content: '\f3a4' !important;
}

.fa-frog:before {
    content: '\f52e' !important;
}

.fa-frown:before {
    content: '\f119' !important;
}

.fa-frown-open:before {
    content: '\f57a' !important;
}

.fa-fulcrum:before {
    content: '\f50b' !important;
}

.fa-funnel-dollar:before {
    content: '\f662' !important;
}

.fa-futbol:before {
    content: '\f1e3' !important;
}

.fa-galactic-republic:before {
    content: '\f50c' !important;
}

.fa-galactic-senate:before {
    content: '\f50d' !important;
}

.fa-gamepad:before {
    content: '\f11b' !important;
}

.fa-gas-pump:before {
    content: '\f52f' !important;
}

.fa-gavel:before {
    content: '\f0e3' !important;
}

.fa-gem:before {
    content: '\f3a5' !important;
}

.fa-genderless:before {
    content: '\f22d' !important;
}

.fa-get-pocket:before {
    content: '\f265' !important;
}

.fa-gg:before {
    content: '\f260' !important;
}

.fa-gg-circle:before {
    content: '\f261' !important;
}

.fa-ghost:before {
    content: '\f6e2' !important;
}

.fa-gift:before {
    content: '\f06b' !important;
}

.fa-gifts:before {
    content: '\f79c' !important;
}

.fa-git:before {
    content: '\f1d3' !important;
}

.fa-git-square:before {
    content: '\f1d2' !important;
}

.fa-github:before {
    content: '\f09b' !important;
}

.fa-github-alt:before {
    content: '\f113' !important;
}

.fa-github-square:before {
    content: '\f092' !important;
}

.fa-gitkraken:before {
    content: '\f3a6' !important;
}

.fa-gitlab:before {
    content: '\f296' !important;
}

.fa-gitter:before {
    content: '\f426' !important;
}

.fa-glass-cheers:before {
    content: '\f79f' !important;
}

.fa-glass-martini:before {
    content: '\f000' !important;
}

.fa-glass-martini-alt:before {
    content: '\f57b' !important;
}

.fa-glass-whiskey:before {
    content: '\f7a0' !important;
}

.fa-glasses:before {
    content: '\f530' !important;
}

.fa-glide:before {
    content: '\f2a5' !important;
}

.fa-glide-g:before {
    content: '\f2a6' !important;
}

.fa-globe:before {
    content: '\f0ac' !important;
}

.fa-globe-africa:before {
    content: '\f57c' !important;
}

.fa-globe-americas:before {
    content: '\f57d' !important;
}

.fa-globe-asia:before {
    content: '\f57e' !important;
}

.fa-globe-europe:before {
    content: '\f7a2' !important;
}

.fa-gofore:before {
    content: '\f3a7' !important;
}

.fa-golf-ball:before {
    content: '\f450' !important;
}

.fa-goodreads:before {
    content: '\f3a8' !important;
}

.fa-goodreads-g:before {
    content: '\f3a9' !important;
}

.fa-google:before {
    content: '\f1a0' !important;
}

.fa-google-drive:before {
    content: '\f3aa' !important;
}

.fa-google-play:before {
    content: '\f3ab' !important;
}

.fa-google-plus:before {
    content: '\f0d5' !important;
}

.fa-google-plus-g:before {
    content: '\f0d5' !important;
}

.fa-google-plus-square:before {
    content: '\f0d4' !important;
}

.fa-google-wallet:before {
    content: '\f1ee' !important;
}

.fa-gopuram:before {
    content: '\f664' !important;
}

.fa-graduation-cap:before {
    content: '\f19d' !important;
}

.fa-gratipay:before {
    content: '\f184' !important;
}

.fa-grav:before {
    content: '\f2d6' !important;
}

.fa-greater-than:before {
    content: '\f531' !important;
}

.fa-greater-than-equal:before {
    content: '\f532' !important;
}

.fa-grimace:before {
    content: '\f57f' !important;
}

.fa-grin:before {
    content: '\f580' !important;
}

.fa-grin-alt:before {
    content: '\f581' !important;
}

.fa-grin-beam:before {
    content: '\f582' !important;
}

.fa-grin-beam-sweat:before {
    content: '\f583' !important;
}

.fa-grin-hearts:before {
    content: '\f584' !important;
}

.fa-grin-squint:before {
    content: '\f585' !important;
}

.fa-grin-squint-tears:before {
    content: '\f586' !important;
}

.fa-grin-stars:before {
    content: '\f587' !important;
}

.fa-grin-tears:before {
    content: '\f588' !important;
}

.fa-grin-tongue:before {
    content: '\f589' !important;
}

.fa-grin-tongue-squint:before {
    content: '\f58a' !important;
}

.fa-grin-tongue-wink:before {
    content: '\f58b' !important;
}

.fa-grin-wink:before {
    content: '\f58c' !important;
}

.fa-grip-horizontal:before {
    content: '\f58d' !important;
}

.fa-grip-lines:before {
    content: '\f7a4' !important;
}

.fa-grip-lines-vertical:before {
    content: '\f7a5' !important;
}

.fa-grip-vertical:before {
    content: '\f58e' !important;
}

.fa-gripfire:before {
    content: '\f3ac' !important;
}

.fa-grunt:before {
    content: '\f3ad' !important;
}

.fa-guitar:before {
    content: '\f7a6' !important;
}

.fa-gulp:before {
    content: '\f3ae' !important;
}

.fa-h-square:before {
    content: '\f0fd' !important;
}

.fa-hacker-news:before {
    content: '\f1d4' !important;
}

.fa-hacker-news-square:before {
    content: '\f3af' !important;
}

.fa-hackerrank:before {
    content: '\f5f7' !important;
}

.fa-hammer:before {
    content: '\f6e3' !important;
}

.fa-hamsa:before {
    content: '\f665' !important;
}

.fa-hand-holding:before {
    content: '\f4bd' !important;
}

.fa-hand-holding-heart:before {
    content: '\f4be' !important;
}

.fa-hand-holding-usd:before {
    content: '\f4c0' !important;
}

.fa-hand-lizard:before {
    content: '\f258' !important;
}

.fa-hand-paper:before {
    content: '\f256' !important;
}

.fa-hand-peace:before {
    content: '\f25b' !important;
}

.fa-hand-point-down:before {
    content: '\f0a7' !important;
}

.fa-hand-point-left:before {
    content: '\f0a5' !important;
}

.fa-hand-point-right:before {
    content: '\f0a4' !important;
}

.fa-hand-point-up:before {
    content: '\f0a6' !important;
}

.fa-hand-pointer:before {
    content: '\f25a' !important;
}

.fa-hand-rock:before {
    content: '\f255' !important;
}

.fa-hand-scissors:before {
    content: '\f257' !important;
}

.fa-hand-spock:before {
    content: '\f259' !important;
}

.fa-hands:before {
    content: '\f4c2' !important;
}

.fa-hands-helping:before {
    content: '\f4c4' !important;
}

.fa-handshake:before {
    content: '\f2b5' !important;
}

.fa-hanukiah:before {
    content: '\f6e6' !important;
}

.fa-hashtag:before {
    content: '\f292' !important;
}

.fa-hat-wizard:before {
    content: '\f6e8' !important;
}

.fa-haykal:before {
    content: '\f666' !important;
}

.fa-hdd:before {
    content: '\f0a0' !important;
}

.fa-heading:before {
    content: '\f1dc' !important;
}

.fa-headphones:before {
    content: '\f025' !important;
}

.fa-headphones-alt:before {
    content: '\f58f' !important;
}

.fa-headset:before {
    content: '\f590' !important;
}

.fa-heart:before {
    content: '\f004' !important;
}

.fa-heart-broken:before {
    content: '\f7a9' !important;
}

.fa-heartbeat:before {
    content: '\f21e' !important;
}

.fa-helicopter:before {
    content: '\f533' !important;
}

.fa-highlighter:before {
    content: '\f591' !important;
}

.fa-hiking:before {
    content: '\f6ec' !important;
}

.fa-hippo:before {
    content: '\f6ed' !important;
}

.fa-hips:before {
    content: '\f452' !important;
}

.fa-hire-a-helper:before {
    content: '\f3b0' !important;
}

.fa-history:before {
    content: '\f1da' !important;
}

.fa-hockey-puck:before {
    content: '\f453' !important;
}

.fa-holly-berry:before {
    content: '\f7aa' !important;
}

.fa-home:before {
    content: '\f015' !important;
}

.fa-hooli:before {
    content: '\f427' !important;
}

.fa-hornbill:before {
    content: '\f592' !important;
}

.fa-horse:before {
    content: '\f6f0' !important;
}

.fa-horse-head:before {
    content: '\f7ab' !important;
}

.fa-hospital:before {
    content: '\f0f8' !important;
}

.fa-hospital-alt:before {
    content: '\f47d' !important;
}

.fa-hospital-symbol:before {
    content: '\f47e' !important;
}

.fa-hot-tub:before {
    content: '\f593' !important;
}

.fa-hotel:before {
    content: '\f236' !important;
}

.fa-hotjar:before {
    content: '\f3b1' !important;
}

.fa-hourglass:before {
    content: '\f254' !important;
}

.fa-hourglass-end:before {
    content: '\f253' !important;
}

.fa-hourglass-half:before {
    content: '\f252' !important;
}

.fa-hourglass-start:before {
    content: '\f251' !important;
}

.fa-house-damage:before {
    content: '\f6f1' !important;
}

.fa-houzz:before {
    content: '\f27c' !important;
}

.fa-hryvnia:before {
    content: '\f6f2' !important;
}

.fa-html5:before {
    content: '\f13b' !important;
}

.fa-hubspot:before {
    content: '\f3b2' !important;
}

.fa-i-cursor:before {
    content: '\f246' !important;
}

.fa-icicles:before {
    content: '\f7ad' !important;
}

.fa-id-badge:before {
    content: '\f2c1' !important;
}

.fa-id-card:before {
    content: '\f2c2' !important;
}

.fa-id-card-alt:before {
    content: '\f47f' !important;
}

.fa-igloo:before {
    content: '\f7ae' !important;
}

.fa-image:before {
    content: '\f03e' !important;
}

.fa-images:before {
    content: '\f302' !important;
}

.fa-imdb:before {
    content: '\f2d8' !important;
}

.fa-inbox:before {
    content: '\f01c' !important;
}

.fa-indent:before {
    content: '\f03c' !important;
}

.fa-industry:before {
    content: '\f275' !important;
}

.fa-infinity:before {
    content: '\f534' !important;
}

.fa-info:before {
    content: '\f129' !important;
}

.fa-info-circle:before {
    content: '\f05a' !important;
}

.fa-instagram:before {
    content: '\f16d' !important;
}

.fa-intercom:before {
    content: '\f7af' !important;
}

.fa-internet-explorer:before {
    content: '\f26b' !important;
}

.fa-invision:before {
    content: '\f7b0' !important;
}

.fa-ioxhost:before {
    content: '\f208' !important;
}

.fa-italic:before {
    content: '\f033' !important;
}

.fa-itunes:before {
    content: '\f3b4' !important;
}

.fa-itunes-note:before {
    content: '\f3b5' !important;
}

.fa-java:before {
    content: '\f4e4' !important;
}

.fa-jedi:before {
    content: '\f669' !important;
}

.fa-jedi-order:before {
    content: '\f50e' !important;
}

.fa-jenkins:before {
    content: '\f3b6' !important;
}

.fa-jira:before {
    content: '\f7b1' !important;
}

.fa-joget:before {
    content: '\f3b7' !important;
}

.fa-joint:before {
    content: '\f595' !important;
}

.fa-joomla:before {
    content: '\f1aa' !important;
}

.fa-journal-whills:before {
    content: '\f66a' !important;
}

.fa-js:before {
    content: '\f3b8' !important;
}

.fa-js-square:before {
    content: '\f3b9' !important;
}

.fa-jsfiddle:before {
    content: '\f1cc' !important;
}

.fa-kaaba:before {
    content: '\f66b' !important;
}

.fa-kaggle:before {
    content: '\f5fa' !important;
}

.fa-key:before {
    content: '\f084' !important;
}

.fa-keybase:before {
    content: '\f4f5' !important;
}

.fa-keyboard:before {
    content: '\f11c' !important;
}

.fa-keycdn:before {
    content: '\f3ba' !important;
}

.fa-khanda:before {
    content: '\f66d' !important;
}

.fa-kickstarter:before {
    content: '\f3bb' !important;
}

.fa-kickstarter-k:before {
    content: '\f3bc' !important;
}

.fa-kiss:before {
    content: '\f596' !important;
}

.fa-kiss-beam:before {
    content: '\f597' !important;
}

.fa-kiss-wink-heart:before {
    content: '\f598' !important;
}

.fa-kiwi-bird:before {
    content: '\f535' !important;
}

.fa-korvue:before {
    content: '\f42f' !important;
}

.fa-landmark:before {
    content: '\f66f' !important;
}

.fa-language:before {
    content: '\f1ab' !important;
}

.fa-laptop:before {
    content: '\f109' !important;
}

.fa-laptop-code:before {
    content: '\f5fc' !important;
}

.fa-laravel:before {
    content: '\f3bd' !important;
}

.fa-lastfm:before {
    content: '\f202' !important;
}

.fa-lastfm-square:before {
    content: '\f203' !important;
}

.fa-laugh:before {
    content: '\f599' !important;
}

.fa-laugh-beam:before {
    content: '\f59a' !important;
}

.fa-laugh-squint:before {
    content: '\f59b' !important;
}

.fa-laugh-wink:before {
    content: '\f59c' !important;
}

.fa-layer-group:before {
    content: '\f5fd' !important;
}

.fa-leaf:before {
    content: '\f06c' !important;
}

.fa-leanpub:before {
    content: '\f212' !important;
}

.fa-lemon:before {
    content: '\f094' !important;
}

.fa-less:before {
    content: '\f41d' !important;
}

.fa-less-than:before {
    content: '\f536' !important;
}

.fa-less-than-equal:before {
    content: '\f537' !important;
}

.fa-level-down-alt:before {
    content: '\f3be' !important;
}

.fa-level-up-alt:before {
    content: '\f3bf' !important;
}

.fa-life-ring:before {
    content: '\f1cd' !important;
}

.fa-lightbulb:before {
    content: '\f0eb' !important;
}

.fa-line:before {
    content: '\f3c0' !important;
}

.fa-link:before {
    content: '\f0c1' !important;
}

.fa-linkedin:before {
    content: '\f0e1' !important;
}

.fa-linkedin-in:before {
    content: '\f0e1' !important;
}

.fa-linode:before {
    content: '\f2b8' !important;
}

.fa-linux:before {
    content: '\f17c' !important;
}

.fa-lira-sign:before {
    content: '\f195' !important;
}

.fa-list:before {
    content: '\f03a' !important;
}

.fa-list-alt:before {
    content: '\f022' !important;
}

.fa-list-ol:before {
    content: '\f0cb' !important;
}

.fa-list-ul:before {
    content: '\f0ca' !important;
}

.fa-location-arrow:before {
    content: '\f124' !important;
}

.fa-lock:before {
    content: '\f023' !important;
}

.fa-lock-open:before {
    content: '\f3c1' !important;
}

.fa-long-arrow-alt-down:before {
    content: '\f309' !important;
}

.fa-long-arrow-alt-left:before {
    content: '\f30a' !important;
}

.fa-long-arrow-alt-right:before {
    content: '\f30b' !important;
}

.fa-long-arrow-alt-up:before {
    content: '\f30c' !important;
}

.fa-low-vision:before {
    content: '\f2a8' !important;
}

.fa-luggage-cart:before {
    content: '\f59d' !important;
}

.fa-lyft:before {
    content: '\f3c3' !important;
}

.fa-magento:before {
    content: '\f3c4' !important;
}

.fa-magic:before {
    content: '\f0d0' !important;
}

.fa-magnet:before {
    content: '\f076' !important;
}

.fa-mail-bulk:before {
    content: '\f674' !important;
}

.fa-mailchimp:before {
    content: '\f59e' !important;
}

.fa-male:before {
    content: '\f183' !important;
}

.fa-mandalorian:before {
    content: '\f50f' !important;
}

.fa-map:before {
    content: '\f279' !important;
}

.fa-map-marked:before {
    content: '\f59f' !important;
}

.fa-map-marked-alt:before {
    content: '\f5a0' !important;
}

.fa-map-marker:before {
    content: '\f3c5' !important;
}

.fa-map-marker-alt:before {
    content: '\f3c5' !important;
}

.fa-map-pin:before {
    content: '\f276' !important;
}

.fa-map-signs:before {
    content: '\f277' !important;
}

.fa-markdown:before {
    content: '\f60f' !important;
}

.fa-marker:before {
    content: '\f5a1' !important;
}

.fa-mars:before {
    content: '\f222' !important;
}

.fa-mars-double:before {
    content: '\f227' !important;
}

.fa-mars-stroke:before {
    content: '\f229' !important;
}

.fa-mars-stroke-h:before {
    content: '\f22b' !important;
}

.fa-mars-stroke-v:before {
    content: '\f22a' !important;
}

.fa-mask:before {
    content: '\f6fa' !important;
}

.fa-mastodon:before {
    content: '\f4f6' !important;
}

.fa-maxcdn:before {
    content: '\f136' !important;
}

.fa-medal:before {
    content: '\f5a2' !important;
}

.fa-medapps:before {
    content: '\f3c6' !important;
}

.fa-medium:before {
    content: '\f23a' !important;
}

.fa-medium-m:before {
    content: '\f3c7' !important;
}

.fa-medkit:before {
    content: '\f0fa' !important;
}

.fa-medrt:before {
    content: '\f3c8' !important;
}

.fa-meetup:before {
    content: '\f2e0' !important;
}

.fa-megaport:before {
    content: '\f5a3' !important;
}

.fa-meh:before {
    content: '\f11a' !important;
}

.fa-meh-blank:before {
    content: '\f5a4' !important;
}

.fa-meh-rolling-eyes:before {
    content: '\f5a5' !important;
}

.fa-memory:before {
    content: '\f538' !important;
}

.fa-mendeley:before {
    content: '\f7b3' !important;
}

.fa-menorah:before {
    content: '\f676' !important;
}

.fa-mercury:before {
    content: '\f223' !important;
}

.fa-meteor:before {
    content: '\f753' !important;
}

.fa-microchip:before {
    content: '\f2db' !important;
}

.fa-microphone:before {
    content: '\f130' !important;
}

.fa-microphone-alt:before {
    content: '\f3c9' !important;
}

.fa-microphone-alt-slash:before {
    content: '\f539' !important;
}

.fa-microphone-slash:before {
    content: '\f131' !important;
}

.fa-microscope:before {
    content: '\f610' !important;
}

.fa-microsoft:before {
    content: '\f3ca' !important;
}

.fa-minus:before {
    content: '\f068' !important;
}

.fa-minus-circle:before {
    content: '\f056' !important;
}

.fa-minus-square:before {
    content: '\f146' !important;
}

.fa-mitten:before {
    content: '\f7b5' !important;
}

.fa-mix:before {
    content: '\f3cb' !important;
}

.fa-mixcloud:before {
    content: '\f289' !important;
}

.fa-mizuni:before {
    content: '\f3cc' !important;
}

.fa-mobile:before {
    content: '\f3cd' !important;
}

.fa-mobile-alt:before {
    content: '\f3cd' !important;
}

.fa-modx:before {
    content: '\f285' !important;
}

.fa-monero:before {
    content: '\f3d0' !important;
}

.fa-money-bill:before {
    content: '\f0d6' !important;
}

.fa-money-bill-alt:before {
    content: '\f3d1' !important;
}

.fa-money-bill-wave:before {
    content: '\f53a' !important;
}

.fa-money-bill-wave-alt:before {
    content: '\f53b' !important;
}

.fa-money-check:before {
    content: '\f53c' !important;
}

.fa-money-check-alt:before {
    content: '\f53d' !important;
}

.fa-monument:before {
    content: '\f5a6' !important;
}

.fa-moon:before {
    content: '\f186' !important;
}

.fa-mortar-pestle:before {
    content: '\f5a7' !important;
}

.fa-mosque:before {
    content: '\f678' !important;
}

.fa-motorcycle:before {
    content: '\f21c' !important;
}

.fa-mountain:before {
    content: '\f6fc' !important;
}

.fa-mouse-pointer:before {
    content: '\f245' !important;
}

.fa-mug-hot:before {
    content: '\f7b6' !important;
}

.fa-music:before {
    content: '\f001' !important;
}

.fa-napster:before {
    content: '\f3d2' !important;
}

.fa-neos:before {
    content: '\f612' !important;
}

.fa-network-wired:before {
    content: '\f6ff' !important;
}

.fa-neuter:before {
    content: '\f22c' !important;
}

.fa-newspaper:before {
    content: '\f1ea' !important;
}

.fa-nimblr:before {
    content: '\f5a8' !important;
}

.fa-nintendo-switch:before {
    content: '\f418' !important;
}

.fa-node:before {
    content: '\f419' !important;
}

.fa-node-js:before {
    content: '\f3d3' !important;
}

.fa-not-equal:before {
    content: '\f53e' !important;
}

.fa-notes-medical:before {
    content: '\f481' !important;
}

.fa-npm:before {
    content: '\f3d4' !important;
}

.fa-ns8:before {
    content: '\f3d5' !important;
}

.fa-nutritionix:before {
    content: '\f3d6' !important;
}

.fa-object-group:before {
    content: '\f247' !important;
}

.fa-object-ungroup:before {
    content: '\f248' !important;
}

.fa-odnoklassniki:before {
    content: '\f263' !important;
}

.fa-odnoklassniki-square:before {
    content: '\f264' !important;
}

.fa-oil-can:before {
    content: '\f613' !important;
}

.fa-old-republic:before {
    content: '\f510' !important;
}

.fa-om:before {
    content: '\f679' !important;
}

.fa-opencart:before {
    content: '\f23d' !important;
}

.fa-openid:before {
    content: '\f19b' !important;
}

.fa-opera:before {
    content: '\f26a' !important;
}

.fa-optin-monster:before {
    content: '\f23c' !important;
}

.fa-osi:before {
    content: '\f41a' !important;
}

.fa-otter:before {
    content: '\f700' !important;
}

.fa-outdent:before {
    content: '\f03b' !important;
}

.fa-page4:before {
    content: '\f3d7' !important;
}

.fa-pagelines:before {
    content: '\f18c' !important;
}

.fa-paint-brush:before {
    content: '\f1fc' !important;
}

.fa-paint-roller:before {
    content: '\f5aa' !important;
}

.fa-palette:before {
    content: '\f53f' !important;
}

.fa-palfed:before {
    content: '\f3d8' !important;
}

.fa-pallet:before {
    content: '\f482' !important;
}

.fa-paper-plane:before {
    content: '\f1d8' !important;
}

.fa-paperclip:before {
    content: '\f0c6' !important;
}

.fa-parachute-box:before {
    content: '\f4cd' !important;
}

.fa-paragraph:before {
    content: '\f1dd' !important;
}

.fa-parking:before {
    content: '\f540' !important;
}

.fa-passport:before {
    content: '\f5ab' !important;
}

.fa-pastafarianism:before {
    content: '\f67b' !important;
}

.fa-paste:before {
    content: '\f328' !important;
}

.fa-patreon:before {
    content: '\f3d9' !important;
}

.fa-pause:before {
    content: '\f04c' !important;
}

.fa-pause-circle:before {
    content: '\f28b' !important;
}

.fa-paw:before {
    content: '\f1b0' !important;
}

.fa-paypal:before {
    content: '\f1ed' !important;
}

.fa-peace:before {
    content: '\f67c' !important;
}

.fa-pen:before {
    content: '\f304' !important;
}

.fa-pen-alt:before {
    content: '\f305' !important;
}

.fa-pen-fancy:before {
    content: '\f5ac' !important;
}

.fa-pen-nib:before {
    content: '\f5ad' !important;
}

.fa-pen-square:before {
    content: '\f14b' !important;
}

.fa-pencil-alt:before {
    content: '\f303' !important;
}

.fa-pencil-ruler:before {
    content: '\f5ae' !important;
}

.fa-penny-arcade:before {
    content: '\f704' !important;
}

.fa-people-carry:before {
    content: '\f4ce' !important;
}

.fa-percent:before {
    content: '\f295' !important;
}

.fa-percentage:before {
    content: '\f541' !important;
}

.fa-periscope:before {
    content: '\f3da' !important;
}

.fa-person-booth:before {
    content: '\f756' !important;
}

.fa-phabricator:before {
    content: '\f3db' !important;
}

.fa-phoenix-framework:before {
    content: '\f3dc' !important;
}

.fa-phoenix-squadron:before {
    content: '\f511' !important;
}

.fa-phone:before {
    content: '\f095' !important;
}

.fa-phone-slash:before {
    content: '\f3dd' !important;
}

.fa-phone-square:before {
    content: '\f098' !important;
}

.fa-phone-volume:before {
    content: '\f2a0' !important;
}

.fa-php:before {
    content: '\f457' !important;
}

.fa-pied-piper:before {
    content: '\f2ae' !important;
}

.fa-pied-piper-alt:before {
    content: '\f1a8' !important;
}

.fa-pied-piper-hat:before {
    content: '\f4e5' !important;
}

.fa-pied-piper-pp:before {
    content: '\f1a7' !important;
}

.fa-piggy-bank:before {
    content: '\f4d3' !important;
}

.fa-pills:before {
    content: '\f484' !important;
}

.fa-pinterest:before {
    content: '\f0d2' !important;
}

.fa-pinterest-p:before {
    content: '\f231' !important;
}

.fa-pinterest-square:before {
    content: '\f0d3' !important;
}

.fa-place-of-worship:before {
    content: '\f67f' !important;
}

.fa-plane:before {
    content: '\f072' !important;
}

.fa-plane-arrival:before {
    content: '\f5af' !important;
}

.fa-plane-departure:before {
    content: '\f5b0' !important;
}

.fa-play:before {
    content: '\f04b' !important;
}

.fa-play-circle:before {
    content: '\f144' !important;
}

.fa-playstation:before {
    content: '\f3df' !important;
}

.fa-plug:before {
    content: '\f1e6' !important;
}

.fa-plus:before {
    content: '\f067' !important;
}

.fa-plus-circle:before {
    content: '\f055' !important;
}

.fa-plus-square:before {
    content: '\f0fe' !important;
}

.fa-podcast:before {
    content: '\f2ce' !important;
}

.fa-poll:before {
    content: '\f681' !important;
}

.fa-poll-h:before {
    content: '\f682' !important;
}

.fa-poo:before {
    content: '\f2fe' !important;
}

.fa-poo-storm:before {
    content: '\f75a' !important;
}

.fa-poop:before {
    content: '\f619' !important;
}

.fa-portrait:before {
    content: '\f3e0' !important;
}

.fa-pound-sign:before {
    content: '\f154' !important;
}

.fa-power-off:before {
    content: '\f011' !important;
}

.fa-pray:before {
    content: '\f683' !important;
}

.fa-praying-hands:before {
    content: '\f684' !important;
}

.fa-prescription:before {
    content: '\f5b1' !important;
}

.fa-prescription-bottle:before {
    content: '\f485' !important;
}

.fa-prescription-bottle-alt:before {
    content: '\f486' !important;
}

.fa-print:before {
    content: '\f02f' !important;
}

.fa-procedures:before {
    content: '\f487' !important;
}

.fa-product-hunt:before {
    content: '\f288' !important;
}

.fa-project-diagram:before {
    content: '\f542' !important;
}

.fa-pushed:before {
    content: '\f3e1' !important;
}

.fa-puzzle-piece:before {
    content: '\f12e' !important;
}

.fa-python:before {
    content: '\f3e2' !important;
}

.fa-qq:before {
    content: '\f1d6' !important;
}

.fa-qrcode:before {
    content: '\f029' !important;
}

.fa-question:before {
    content: '\f128' !important;
}

.fa-question-circle:before {
    content: '\f059' !important;
}

.fa-quidditch:before {
    content: '\f458' !important;
}

.fa-quinscape:before {
    content: '\f459' !important;
}

.fa-quora:before {
    content: '\f2c4' !important;
}

.fa-quote-left:before {
    content: '\f10d' !important;
}

.fa-quote-right:before {
    content: '\f10e' !important;
}

.fa-quran:before {
    content: '\f687' !important;
}

.fa-r-project:before {
    content: '\f4f7' !important;
}

.fa-radiation:before {
    content: '\f7b9' !important;
}

.fa-radiation-alt:before {
    content: '\f7ba' !important;
}

.fa-rainbow:before {
    content: '\f75b' !important;
}

.fa-random:before {
    content: '\f074' !important;
}

.fa-raspberry-pi:before {
    content: '\f7bb' !important;
}

.fa-ravelry:before {
    content: '\f2d9' !important;
}

.fa-react:before {
    content: '\f41b' !important;
}

.fa-reacteurope:before {
    content: '\f75d' !important;
}

.fa-readme:before {
    content: '\f4d5' !important;
}

.fa-rebel:before {
    content: '\f1d0' !important;
}

.fa-receipt:before {
    content: '\f543' !important;
}

.fa-recycle:before {
    content: '\f1b8' !important;
}

.fa-red-river:before {
    content: '\f3e3' !important;
}

.fa-reddit:before {
    content: '\f1a1' !important;
}

.fa-reddit-alien:before {
    content: '\f281' !important;
}

.fa-reddit-square:before {
    content: '\f1a2' !important;
}

.fa-redhat:before {
    content: '\f7bc' !important;
}

.fa-redo:before {
    content: '\f01e' !important;
}

.fa-redo-alt:before {
    content: '\f2f9' !important;
}

.fa-registered:before {
    content: '\f25d' !important;
}

.fa-renren:before {
    content: '\f18b' !important;
}

.fa-reply:before {
    content: '\f3e5' !important;
}

.fa-reply-all:before {
    content: '\f122' !important;
}

.fa-replyd:before {
    content: '\f3e6' !important;
}

.fa-republican:before {
    content: '\f75e' !important;
}

.fa-researchgate:before {
    content: '\f4f8' !important;
}

.fa-resolving:before {
    content: '\f3e7' !important;
}

.fa-restroom:before {
    content: '\f7bd' !important;
}

.fa-retweet:before {
    content: '\f079' !important;
}

.fa-rev:before {
    content: '\f5b2' !important;
}

.fa-ribbon:before {
    content: '\f4d6' !important;
}

.fa-ring:before {
    content: '\f70b' !important;
}

.fa-road:before {
    content: '\f018' !important;
}

.fa-robot:before {
    content: '\f544' !important;
}

.fa-rocket:before {
    content: '\f135' !important;
}

.fa-rocketchat:before {
    content: '\f3e8' !important;
}

.fa-rockrms:before {
    content: '\f3e9' !important;
}

.fa-route:before {
    content: '\f4d7' !important;
}

.fa-rss:before {
    content: '\f09e' !important;
}

.fa-rss-square:before {
    content: '\f143' !important;
}

.fa-ruble-sign:before {
    content: '\f158' !important;
}

.fa-ruler:before {
    content: '\f545' !important;
}

.fa-ruler-combined:before {
    content: '\f546' !important;
}

.fa-ruler-horizontal:before {
    content: '\f547' !important;
}

.fa-ruler-vertical:before {
    content: '\f548' !important;
}

.fa-running:before {
    content: '\f70c' !important;
}

.fa-rupee-sign:before {
    content: '\f156' !important;
}

.fa-sad-cry:before {
    content: '\f5b3' !important;
}

.fa-sad-tear:before {
    content: '\f5b4' !important;
}

.fa-safari:before {
    content: '\f267' !important;
}

.fa-sass:before {
    content: '\f41e' !important;
}

.fa-satellite:before {
    content: '\f7bf' !important;
}

.fa-satellite-dish:before {
    content: '\f7c0' !important;
}

.fa-save:before {
    content: '\f0c7' !important;
}

.fa-schlix:before {
    content: '\f3ea' !important;
}

.fa-school:before {
    content: '\f549' !important;
}

.fa-screwdriver:before {
    content: '\f54a' !important;
}

.fa-scribd:before {
    content: '\f28a' !important;
}

.fa-scroll:before {
    content: '\f70e' !important;
}

.fa-sd-card:before {
    content: '\f7c2' !important;
}

.fa-search:before {
    content: '\f002' !important;
}

.fa-search-dollar:before {
    content: '\f688' !important;
}

.fa-search-location:before {
    content: '\f689' !important;
}

.fa-search-minus:before {
    content: '\f010' !important;
}

.fa-search-plus:before {
    content: '\f00e' !important;
}

.fa-searchengin:before {
    content: '\f3eb' !important;
}

.fa-seedling:before {
    content: '\f4d8' !important;
}

.fa-sellcast:before {
    content: '\f2da' !important;
}

.fa-sellsy:before {
    content: '\f213' !important;
}

.fa-server:before {
    content: '\f233' !important;
}

.fa-servicestack:before {
    content: '\f3ec' !important;
}

.fa-shapes:before {
    content: '\f61f' !important;
}

.fa-share:before {
    content: '\f064' !important;
}

.fa-share-alt:before {
    content: '\f1e0' !important;
}

.fa-share-alt-square:before {
    content: '\f1e1' !important;
}

.fa-share-square:before {
    content: '\f14d' !important;
}

.fa-shekel-sign:before {
    content: '\f20b' !important;
}

.fa-shield-alt:before {
    content: '\f3ed' !important;
}

.fa-ship:before {
    content: '\f21a' !important;
}

.fa-shipping-fast:before {
    content: '\f48b' !important;
}

.fa-shirtsinbulk:before {
    content: '\f214' !important;
}

.fa-shoe-prints:before {
    content: '\f54b' !important;
}

.fa-shopping-bag:before {
    content: '\f290' !important;
}

.fa-shopping-basket:before {
    content: '\f291' !important;
}

.fa-shopping-cart:before {
    content: '\f07a' !important;
}

.fa-shopware:before {
    content: '\f5b5' !important;
}

.fa-shower:before {
    content: '\f2cc' !important;
}

.fa-shuttle-van:before {
    content: '\f5b6' !important;
}

.fa-sign:before {
    content: '\f4d9' !important;
}

.fa-sign-in-alt:before {
    content: '\f2f6' !important;
}

.fa-sign-language:before {
    content: '\f2a7' !important;
}

.fa-sign-out-alt:before {
    content: '\f2f5' !important;
}

.fa-signal:before {
    content: '\f012' !important;
}

.fa-signature:before {
    content: '\f5b7' !important;
}

.fa-sim-card:before {
    content: '\f7c4' !important;
}

.fa-simplybuilt:before {
    content: '\f215' !important;
}

.fa-sistrix:before {
    content: '\f3ee' !important;
}

.fa-sitemap:before {
    content: '\f0e8' !important;
}

.fa-sith:before {
    content: '\f512' !important;
}

.fa-skating:before {
    content: '\f7c5' !important;
}

.fa-sketch:before {
    content: '\f7c6' !important;
}

.fa-skiing:before {
    content: '\f7c9' !important;
}

.fa-skiing-nordic:before {
    content: '\f7ca' !important;
}

.fa-skull:before {
    content: '\f54c' !important;
}

.fa-skull-crossbones:before {
    content: '\f714' !important;
}

.fa-skyatlas:before {
    content: '\f216' !important;
}

.fa-skype:before {
    content: '\f17e' !important;
}

.fa-slack:before {
    content: '\f198' !important;
}

.fa-slack-hash:before {
    content: '\f3ef' !important;
}

.fa-slash:before {
    content: '\f715' !important;
}

.fa-sleigh:before {
    content: '\f7cc' !important;
}

.fa-sliders-h:before {
    content: '\f1de' !important;
}

.fa-slideshare:before {
    content: '\f1e7' !important;
}

.fa-smile:before {
    content: '\f118' !important;
}

.fa-smile-beam:before {
    content: '\f5b8' !important;
}

.fa-smile-wink:before {
    content: '\f4da' !important;
}

.fa-smog:before {
    content: '\f75f' !important;
}

.fa-smoking:before {
    content: '\f48d' !important;
}

.fa-smoking-ban:before {
    content: '\f54d' !important;
}

.fa-sms:before {
    content: '\f7cd' !important;
}

.fa-snapchat:before {
    content: '\f2ab' !important;
}

.fa-snapchat-ghost:before {
    content: '\f2ac' !important;
}

.fa-snapchat-square:before {
    content: '\f2ad' !important;
}

.fa-snowboarding:before {
    content: '\f7ce' !important;
}

.fa-snowflake:before {
    content: '\f2dc' !important;
}

.fa-snowman:before {
    content: '\f7d0' !important;
}

.fa-snowplow:before {
    content: '\f7d2' !important;
}

.fa-socks:before {
    content: '\f696' !important;
}

.fa-solar-panel:before {
    content: '\f5ba' !important;
}

.fa-sort:before {
    content: '\f0dc' !important;
}

.fa-sort-alpha-down:before {
    content: '\f15d' !important;
}

.fa-sort-alpha-up:before {
    content: '\f15e' !important;
}

.fa-sort-amount-down:before {
    content: '\f160' !important;
}

.fa-sort-amount-up:before {
    content: '\f161' !important;
}

.fa-sort-down:before {
    content: '\f0dd' !important;
}

.fa-sort-numeric-down:before {
    content: '\f162' !important;
}

.fa-sort-numeric-up:before {
    content: '\f163' !important;
}

.fa-sort-up:before {
    content: '\f0de' !important;
}

.fa-soundcloud:before {
    content: '\f1be' !important;
}

.fa-sourcetree:before {
    content: '\f7d3' !important;
}

.fa-spa:before {
    content: '\f5bb' !important;
}

.fa-space-shuttle:before {
    content: '\f197' !important;
}

.fa-speakap:before {
    content: '\f3f3' !important;
}

.fa-spider:before {
    content: '\f717' !important;
}

.fa-spinner:before {
    content: '\f110' !important;
}

.fa-splotch:before {
    content: '\f5bc' !important;
}

.fa-spotify:before {
    content: '\f1bc' !important;
}

.fa-spray-can:before {
    content: '\f5bd' !important;
}

.fa-square:before {
    content: '\f0c8' !important;
}

.fa-square-full:before {
    content: '\f45c' !important;
}

.fa-square-root-alt:before {
    content: '\f698' !important;
}

.fa-squarespace:before {
    content: '\f5be' !important;
}

.fa-stack-exchange:before {
    content: '\f18d' !important;
}

.fa-stack-overflow:before {
    content: '\f16c' !important;
}

.fa-stamp:before {
    content: '\f5bf' !important;
}

.fa-star:before {
    content: '\f005' !important;
}

.fa-star-and-crescent:before {
    content: '\f699' !important;
}

.fa-star-half:before {
    content: '\f089' !important;
}

.fa-star-half-alt:before {
    content: '\f5c0' !important;
}

.fa-star-of-david:before {
    content: '\f69a' !important;
}

.fa-star-of-life:before {
    content: '\f621' !important;
}

.fa-staylinked:before {
    content: '\f3f5' !important;
}

.fa-steam:before {
    content: '\f1b6' !important;
}

.fa-steam-square:before {
    content: '\f1b7' !important;
}

.fa-steam-symbol:before {
    content: '\f3f6' !important;
}

.fa-step-backward:before {
    content: '\f048' !important;
}

.fa-step-forward:before {
    content: '\f051' !important;
}

.fa-stethoscope:before {
    content: '\f0f1' !important;
}

.fa-sticker-mule:before {
    content: '\f3f7' !important;
}

.fa-sticky-note:before {
    content: '\f249' !important;
}

.fa-stop:before {
    content: '\f04d' !important;
}

.fa-stop-circle:before {
    content: '\f28d' !important;
}

.fa-stopwatch:before {
    content: '\f2f2' !important;
}

.fa-store:before {
    content: '\f54e' !important;
}

.fa-store-alt:before {
    content: '\f54f' !important;
}

.fa-strava:before {
    content: '\f428' !important;
}

.fa-stream:before {
    content: '\f550' !important;
}

.fa-street-view:before {
    content: '\f21d' !important;
}

.fa-strikethrough:before {
    content: '\f0cc' !important;
}

.fa-stripe:before {
    content: '\f429' !important;
}

.fa-stripe-s:before {
    content: '\f42a' !important;
}

.fa-stroopwafel:before {
    content: '\f551' !important;
}

.fa-studiovinari:before {
    content: '\f3f8' !important;
}

.fa-stumbleupon:before {
    content: '\f1a4' !important;
}

.fa-stumbleupon-circle:before {
    content: '\f1a3' !important;
}

.fa-subscript:before {
    content: '\f12c' !important;
}

.fa-subway:before {
    content: '\f239' !important;
}

.fa-suitcase:before {
    content: '\f0f2' !important;
}

.fa-suitcase-rolling:before {
    content: '\f5c1' !important;
}

.fa-sun:before {
    content: '\f185' !important;
}

.fa-superpowers:before {
    content: '\f2dd' !important;
}

.fa-superscript:before {
    content: '\f12b' !important;
}

.fa-supple:before {
    content: '\f3f9' !important;
}

.fa-surprise:before {
    content: '\f5c2' !important;
}

.fa-suse:before {
    content: '\f7d6' !important;
}

.fa-swatchbook:before {
    content: '\f5c3' !important;
}

.fa-swimmer:before {
    content: '\f5c4' !important;
}

.fa-swimming-pool:before {
    content: '\f5c5' !important;
}

.fa-synagogue:before {
    content: '\f69b' !important;
}

.fa-sync:before {
    content: '\f021' !important;
}

.fa-sync-alt:before {
    content: '\f2f1' !important;
}

.fa-syringe:before {
    content: '\f48e' !important;
}

.fa-table:before {
    content: '\f0ce' !important;
}

.fa-table-tennis:before {
    content: '\f45d' !important;
}

.fa-tablet:before {
    content: '\f3fa' !important;
}

.fa-tablet-alt:before {
    content: '\f3fa' !important;
}

.fa-tablets:before {
    content: '\f490' !important;
}

.fa-tachometer-alt:before {
    content: '\f3fd' !important;
}

.fa-tag:before {
    content: '\f02b' !important;
}

.fa-tags:before {
    content: '\f02c' !important;
}

.fa-tape:before {
    content: '\f4db' !important;
}

.fa-tasks:before {
    content: '\f0ae' !important;
}

.fa-taxi:before {
    content: '\f1ba' !important;
}

.fa-teamspeak:before {
    content: '\f4f9' !important;
}

.fa-teeth:before {
    content: '\f62e' !important;
}

.fa-teeth-open:before {
    content: '\f62f' !important;
}

.fa-telegram:before {
    content: '\f2c6' !important;
}

.fa-telegram-plane:before {
    content: '\f3fe' !important;
}

.fa-temperature-high:before {
    content: '\f769' !important;
}

.fa-temperature-low:before {
    content: '\f76b' !important;
}

.fa-tencent-weibo:before {
    content: '\f1d5' !important;
}

.fa-tenge:before {
    content: '\f7d7' !important;
}

.fa-terminal:before {
    content: '\f120' !important;
}

.fa-text-height:before {
    content: '\f034' !important;
}

.fa-text-width:before {
    content: '\f035' !important;
}

.fa-th:before {
    content: '\f00a' !important;
}

.fa-th-large:before {
    content: '\f009' !important;
}

.fa-th-list:before {
    content: '\f00b' !important;
}

.fa-the-red-yeti:before {
    content: '\f69d' !important;
}

.fa-theater-masks:before {
    content: '\f630' !important;
}

.fa-themeco:before {
    content: '\f5c6' !important;
}

.fa-themeisle:before {
    content: '\f2b2' !important;
}

.fa-thermometer:before {
    content: '\f2c7' !important;
}

.fa-thermometer-empty:before {
    content: '\f2cb' !important;
}

.fa-thermometer-full:before {
    content: '\f2c7' !important;
}

.fa-thermometer-half:before {
    content: '\f2c9' !important;
}

.fa-thermometer-quarter:before {
    content: '\f2ca' !important;
}

.fa-thermometer-three-quarters:before {
    content: '\f2c8' !important;
}

.fa-think-peaks:before {
    content: '\f731' !important;
}

.fa-thumbs-down:before {
    content: '\f165' !important;
}

.fa-thumbs-up:before {
    content: '\f164' !important;
}

.fa-thumbtack:before {
    content: '\f08d' !important;
}

.fa-ticket-alt:before {
    content: '\f3ff' !important;
}

.fa-times:before {
    content: '\f00d' !important;
}

.fa-times-circle:before {
    content: '\f057' !important;
}

.fa-tint:before {
    content: '\f043' !important;
}

.fa-tint-slash:before {
    content: '\f5c7' !important;
}

.fa-tired:before {
    content: '\f5c8' !important;
}

.fa-toggle-off:before {
    content: '\f204' !important;
}

.fa-toggle-on:before {
    content: '\f205' !important;
}

.fa-toilet:before {
    content: '\f7d8' !important;
}

.fa-toilet-paper:before {
    content: '\f71e' !important;
}

.fa-toolbox:before {
    content: '\f552' !important;
}

.fa-tools:before {
    content: '\f7d9' !important;
}

.fa-tooth:before {
    content: '\f5c9' !important;
}

.fa-torah:before {
    content: '\f6a0' !important;
}

.fa-torii-gate:before {
    content: '\f6a1' !important;
}

.fa-tractor:before {
    content: '\f722' !important;
}

.fa-trade-federation:before {
    content: '\f513' !important;
}

.fa-trademark:before {
    content: '\f25c' !important;
}

.fa-traffic-light:before {
    content: '\f637' !important;
}

.fa-train:before {
    content: '\f238' !important;
}

.fa-tram:before {
    content: '\f7da' !important;
}

.fa-transgender:before {
    content: '\f224' !important;
}

.fa-transgender-alt:before {
    content: '\f225' !important;
}

.fa-trash:before {
    content: '\f2ed' !important;
}

.fa-trash-alt:before {
    content: '\f2ed' !important;
}

.fa-tree:before {
    content: '\f1bb' !important;
}

.fa-trello:before {
    content: '\f181' !important;
}

.fa-tripadvisor:before {
    content: '\f262' !important;
}

.fa-trophy:before {
    content: '\f091' !important;
}

.fa-truck:before {
    content: '\f0d1' !important;
}

.fa-truck-loading:before {
    content: '\f4de' !important;
}

.fa-truck-monster:before {
    content: '\f63b' !important;
}

.fa-truck-moving:before {
    content: '\f4df' !important;
}

.fa-truck-pickup:before {
    content: '\f63c' !important;
}

.fa-tshirt:before {
    content: '\f553' !important;
}

.fa-tty:before {
    content: '\f1e4' !important;
}

.fa-tumblr:before {
    content: '\f173' !important;
}

.fa-tumblr-square:before {
    content: '\f174' !important;
}

.fa-tv:before {
    content: '\f26c' !important;
}

.fa-twitch:before {
    content: '\f1e8' !important;
}

.fa-twitter:before {
    content: '\f099' !important;
}

.fa-twitter-square:before {
    content: '\f081' !important;
}

.fa-typo3:before {
    content: '\f42b' !important;
}

.fa-uber:before {
    content: '\f402' !important;
}

.fa-ubuntu:before {
    content: '\f7df' !important;
}

.fa-uikit:before {
    content: '\f403' !important;
}

.fa-umbrella:before {
    content: '\f0e9' !important;
}

.fa-umbrella-beach:before {
    content: '\f5ca' !important;
}

.fa-underline:before {
    content: '\f0cd' !important;
}

.fa-undo:before {
    content: '\f0e2' !important;
}

.fa-undo-alt:before {
    content: '\f2ea' !important;
}

.fa-uniregistry:before {
    content: '\f404' !important;
}

.fa-universal-access:before {
    content: '\f29a' !important;
}

.fa-university:before {
    content: '\f19c' !important;
}

.fa-unlink:before {
    content: '\f127' !important;
}

.fa-unlock:before {
    content: '\f09c' !important;
}

.fa-unlock-alt:before {
    content: '\f13e' !important;
}

.fa-untappd:before {
    content: '\f405' !important;
}

.fa-upload:before {
    content: '\f093' !important;
}

.fa-ups:before {
    content: '\f7e0' !important;
}

.fa-usb:before {
    content: '\f287' !important;
}

.fa-user:before {
    content: '\f007' !important;
}

.fa-user-alt:before {
    content: '\f406' !important;
}

.fa-user-alt-slash:before {
    content: '\f4fa' !important;
}

.fa-user-astronaut:before {
    content: '\f4fb' !important;
}

.fa-user-check:before {
    content: '\f4fc' !important;
}

.fa-user-circle:before {
    content: '\f2bd' !important;
}

.fa-user-clock:before {
    content: '\f4fd' !important;
}

.fa-user-cog:before {
    content: '\f4fe' !important;
}

.fa-user-edit:before {
    content: '\f4ff' !important;
}

.fa-user-friends:before {
    content: '\f500' !important;
}

.fa-user-graduate:before {
    content: '\f501' !important;
}

.fa-user-injured:before {
    content: '\f728' !important;
}

.fa-user-lock:before {
    content: '\f502' !important;
}

.fa-user-md:before {
    content: '\f0f0' !important;
}

.fa-user-minus:before {
    content: '\f503' !important;
}

.fa-user-ninja:before {
    content: '\f504' !important;
}

.fa-user-plus:before {
    content: '\f234' !important;
}

.fa-user-secret:before {
    content: '\f21b' !important;
}

.fa-user-shield:before {
    content: '\f505' !important;
}

.fa-user-slash:before {
    content: '\f506' !important;
}

.fa-user-tag:before {
    content: '\f507' !important;
}

.fa-user-tie:before {
    content: '\f508' !important;
}

.fa-user-times:before {
    content: '\f235' !important;
}

.fa-users:before {
    content: '\f0c0' !important;
}

.fa-users-cog:before {
    content: '\f509' !important;
}

.fa-usps:before {
    content: '\f7e1' !important;
}

.fa-ussunnah:before {
    content: '\f407' !important;
}

.fa-utensil-spoon:before {
    content: '\f2e5' !important;
}

.fa-utensils:before {
    content: '\f2e7' !important;
}

.fa-vaadin:before {
    content: '\f408' !important;
}

.fa-vector-square:before {
    content: '\f5cb' !important;
}

.fa-venus:before {
    content: '\f221' !important;
}

.fa-venus-double:before {
    content: '\f226' !important;
}

.fa-venus-mars:before {
    content: '\f228' !important;
}

.fa-viacoin:before {
    content: '\f237' !important;
}

.fa-viadeo:before {
    content: '\f2a9' !important;
}

.fa-viadeo-square:before {
    content: '\f2aa' !important;
}

.fa-vial:before {
    content: '\f492' !important;
}

.fa-vials:before {
    content: '\f493' !important;
}

.fa-viber:before {
    content: '\f409' !important;
}

.fa-video:before {
    content: '\f03d' !important;
}

.fa-video-slash:before {
    content: '\f4e2' !important;
}

.fa-vihara:before {
    content: '\f6a7' !important;
}

.fa-vimeo:before {
    content: '\f27d' !important;
}

.fa-vimeo-square:before {
    content: '\f194' !important;
}

.fa-vimeo-v:before {
    content: '\f27d' !important;
}

.fa-vine:before {
    content: '\f1ca' !important;
}

.fa-vk:before {
    content: '\f189' !important;
}

.fa-vnv:before {
    content: '\f40b' !important;
}

.fa-volleyball-ball:before {
    content: '\f45f' !important;
}

.fa-volume-down:before {
    content: '\f027' !important;
}

.fa-volume-mute:before {
    content: '\f6a9' !important;
}

.fa-volume-off:before {
    content: '\f026' !important;
}

.fa-volume-up:before {
    content: '\f028' !important;
}

.fa-vote-yea:before {
    content: '\f772' !important;
}

.fa-vr-cardboard:before {
    content: '\f729' !important;
}

.fa-vuejs:before {
    content: '\f41f' !important;
}

.fa-walking:before {
    content: '\f554' !important;
}

.fa-wallet:before {
    content: '\f555' !important;
}

.fa-warehouse:before {
    content: '\f494' !important;
}

.fa-water:before {
    content: '\f773' !important;
}

.fa-weebly:before {
    content: '\f5cc' !important;
}

.fa-weibo:before {
    content: '\f18a' !important;
}

.fa-weight:before {
    content: '\f496' !important;
}

.fa-weight-hanging:before {
    content: '\f5cd' !important;
}

.fa-weixin:before {
    content: '\f1d7' !important;
}

.fa-whatsapp:before {
    content: '\f232' !important;
}

.fa-whatsapp-square:before {
    content: '\f40c' !important;
}

.fa-wheelchair:before {
    content: '\f193' !important;
}

.fa-whmcs:before {
    content: '\f40d' !important;
}

.fa-wifi:before {
    content: '\f1eb' !important;
}

.fa-wikipedia-w:before {
    content: '\f266' !important;
}

.fa-wind:before {
    content: '\f72e' !important;
}

.fa-window-close:before {
    content: '\f410' !important;
}

.fa-window-maximize:before {
    content: '\f2d0' !important;
}

.fa-window-minimize:before {
    content: '\f2d1' !important;
}

.fa-window-restore:before {
    content: '\f2d2' !important;
}

.fa-windows:before {
    content: '\f17a' !important;
}

.fa-wine-bottle:before {
    content: '\f72f' !important;
}

.fa-wine-glass:before {
    content: '\f4e3' !important;
}

.fa-wine-glass-alt:before {
    content: '\f5ce' !important;
}

.fa-wix:before {
    content: '\f5cf' !important;
}

.fa-wizards-of-the-coast:before {
    content: '\f730' !important;
}

.fa-wolf-pack-battalion:before {
    content: '\f514' !important;
}

.fa-won-sign:before {
    content: '\f159' !important;
}

.fa-wordpress:before {
    content: '\f19a' !important;
}

.fa-wordpress-simple:before {
    content: '\f411' !important;
}

.fa-wpbeginner:before {
    content: '\f297' !important;
}

.fa-wpexplorer:before {
    content: '\f2de' !important;
}

.fa-wpforms:before {
    content: '\f298' !important;
}

.fa-wpressr:before {
    content: '\f3e4' !important;
}

.fa-wrench:before {
    content: '\f0ad' !important;
}

.fa-x-ray:before {
    content: '\f497' !important;
}

.fa-xbox:before {
    content: '\f412' !important;
}

.fa-xing:before {
    content: '\f168' !important;
}

.fa-xing-square:before {
    content: '\f169' !important;
}

.fa-y-combinator:before {
    content: '\f23b' !important;
}

.fa-yahoo:before {
    content: '\f19e' !important;
}

.fa-yandex:before {
    content: '\f413' !important;
}

.fa-yandex-international:before {
    content: '\f414' !important;
}

.fa-yarn:before {
    content: '\f7e3' !important;
}

.fa-yelp:before {
    content: '\f1e9' !important;
}

.fa-yen-sign:before {
    content: '\f157' !important;
}

.fa-yin-yang:before {
    content: '\f6ad' !important;
}

.fa-yoast:before {
    content: '\f2b1' !important;
}

.fa-youtube:before {
    content: '\f167' !important;
}

.fa-youtube-square:before {
    content: '\f167' !important;
}

.fa-zhihu:before {
    content: '\f63f' !important;
}

.fa-lg {
    font-size: 1.33333em;
    line-height: 0.75em;
    vertical-align: -0.0667em;
}

.fa-xs {
    font-size: 0.75em;
}

.fa-sm {
    font-size: 0.875em;
}

.fa-1x {
    font-size: 1em;
}

.fa-2x {
    font-size: 2em;
}

.fa-3x {
    font-size: 3em;
}

.fa-4x {
    font-size: 4em;
}

.fa-5x {
    font-size: 5em;
}

.fa-6x {
    font-size: 6em;
}

.fa-7x {
    font-size: 7em;
}

.fa-8x {
    font-size: 8em;
}

.fa-9x {
    font-size: 9em;
}

.fa-10x {
    font-size: 10em;
}

.fa-ul {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0;
}

.fa-ul > li {
    position: relative;
}

.fa-li {
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit;
}

.fa-rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    transform: rotate(90deg);
}

.fa-rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    transform: rotate(180deg);
}

.fa-rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    transform: rotate(270deg);
}

.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    transform: scale(-1, 1);
}

.fa-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    transform: scale(1, -1);
}

.fa-flip-horizontal.fa-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
    filter: none;
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.fa-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
}

.fa-stack-1x {
    line-height: inherit;
}

.fa-stack-2x {
    font-size: 2em;
}

.fa-inverse {
    color: #fff;
}

@font-face {
    font-family: 'Font Awesome 5 Brands';
    font-style: normal;
    font-weight: normal;
    src: url('./../fonts/font-awesome/fa-brands-400.eot');
    src: url('./../fonts/font-awesome/fa-brands-400.eot?#iefix') format('embedded-opentype'), url('./../fonts/font-awesome/fa-brands-400.woff2') format('woff2'), url('./../fonts/font-awesome/fa-brands-400.woff') format('woff'), url('./../fonts/font-awesome/fa-brands-400.ttf') format('truetype'), url('./../fonts/font-awesome/fa-brands-400.svg#fontawesome') format('svg');
}

.fab {
    font-family: 'Font Awesome 5 Brands';
}

@font-face {
    font-family: 'Font Awesome 5 Free Regular';
    font-style: normal;
    font-weight: 400;
    src: url('./../fonts/font-awesome/fa-regular-400.eot');
    src: url('./../fonts/font-awesome/fa-regular-400.eot?#iefix') format('embedded-opentype'), url('./../fonts/font-awesome/fa-regular-400.woff2') format('woff2'), url('./../fonts/font-awesome/fa-regular-400.woff') format('woff'), url('./../fonts/font-awesome/fa-regular-400.ttf') format('truetype'), url('./../fonts/font-awesome/fa-regular-400.svg#fontawesome') format('svg');
}

.far {
    font-family: 'Font Awesome 5 Free Regular';
    font-weight: 400;
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    src: url('./../fonts/font-awesome/fa-solid-900.eot');
    src: url('./../fonts/font-awesome/fa-solid-900.eot?#iefix') format('embedded-opentype'), url('./../fonts/font-awesome/fa-solid-900.woff2') format('woff2'), url('./../fonts/font-awesome/fa-solid-900.woff') format('woff'), url('./../fonts/font-awesome/fa-solid-900.ttf') format('truetype'), url('./../fonts/font-awesome/fa-solid-900.svg#fontawesome') format('svg');
}

.fa,
.fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.x-fa:before,
.x-fas:before {
    font-family: 'Font Awesome 5 Free' !important;
}

.x-far:before {
    font-family: 'Font Awesome 5 Free Regular' !important;
}

.x-fab:before {
    font-family: 'Font Awesome 5 Brands' !important;
}

.ext {
    font-family: ExtJS;
}

.ext-sencha:before {
    content: "\e61e";
}

.ext-checkbox-checked:before {
    content: "\e613";
}

.ext-checkbox-unchecked:before {
    content: "\e614";
}

.ext-chevron-left:before {
    content: "\e615";
}

.ext-chevron-right:before {
    content: "\e616";
}

.ext-double-chevron-left:before {
    content: "\e617";
}

.ext-double-chevron-right:before {
    content: "\e618";
}

.ext-sort-down:before {
    content: "\e619";
}

.ext-sort-up:before {
    content: "\e61a";
}

.ext-spinner-down:before {
    content: "\e61b";
}

.ext-spinner-up:before {
    content: "\e61c";
}

.ext-square-edit:before {
    content: "\e61d";
}

.ext-equals-vertical:before {
    content: "\e612";
}

.ext-expand:before {
    content: "\e600";
}

.ext-columns:before {
    content: "\e601";
}

.ext-dirty:before {
    content: "\e602";
}

.ext-dirty-rtl:before {
    content: "\e603";
}

.ext-edit-html:before {
    content: "\e604";
}

.ext-equals:before {
    content: "\e605";
}

.ext-collapse:before {
    content: "\e606";
}

.ext-group-by:before {
    content: "\e607";
}

.ext-minimize:before {
    content: "\e608";
}

.ext-nw-handle:before {
    content: "\e609";
}

.ext-ne-handle:before {
    content: "\e60a";
}

.ext-sw-handle:before {
    content: "\e60b";
}

.ext-se-handle:before {
    content: "\e60c";
}

.ext-text-background-color:before {
    content: "\e60d";
}

.ext-text-color:before {
    content: "\e60e";
}

.ext-text-decrease:before {
    content: "\e60f";
}

.ext-text-increase:before {
    content: "\e610";
}

.ext-unpin:before {
    content: "\e611";
}

.x-fieldset {
    border-top: 1px solid #cacaca !important;
    background: #f6f6f6;
    position: static;
}

.x-fieldset .x-fieldset-body {
    position: relative;
}

.x-fieldset.x-fieldset-collapsed {
    background: none;
}

.x-menu-item-active .x-menu-item-text {
    font-weight: 600;
}

.x-tab-close-btn:before {
    display: block;
    width: 16px;
    line-height: 16px;
    text-align: center;
}

.x-breadcrumb-btn > .x-btn-arrow-right:after {
    content: '';
}

.x-toast {
    -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0 2px 2px;
    -moz-box-shadow: rgba(0, 0, 0, 0.5) 0 2px 2px;
    box-shadow: rgba(0, 0, 0, 0.5) 0 2px 2px;
}

:root {
    --dark-mode: false;
    --base-color: #2196f3;
    --base-highlight-color: #64b5f6;
    --base-light-color: #bbdefb;
    --base-dark-color: #1976d2;
    --base-pressed-color: #6ab8f7;
    --base-focused-color: #42a5f5;
    --base-invisible-color: rgba(33, 150, 243, 0);
    --base-foreground-color: #fff;
    --accent-color: #9e9e9e;
    --accent-light-color: #f5f5f5;
    --accent-dark-color: #616161;
    --accent-pressed-color: #c4c4c4;
    --accent-invisible-color: rgba(158, 158, 158, 0);
    --accent-foreground-color: #222;
    --confirm-color: #7cb342;
    --confirm-pressed-color: #a2cc75;
    --alert-color: #c62828;
    --alert-pressed-color: #de5c5c;
    --color: #111;
    --reverse-color: #fff;
    --highlight-color: rgba(17, 17, 17, 0.54);
    --disabled-color: rgba(17, 17, 17, 0.38);
    --reverse-disabled-color: rgba(255, 255, 255, 0.38);
    --divider-color: #e2e2e2;
    --selected-background-color: #e0e0e0;
    --hovered-background-color: #eee;
    --header-background-color: #2196f3;
    --faded-color: #e1e1e1;
    --background-color: #fafafa;
    --alt-background-color: #f5f5f5;
    --reverse-background-color: #303030;
    --reverse-alt-background-color: #3a3a3a;
    --overlay-color: rgba(0, 0, 0, 0.03);
    --content-padding: 16px;
    --listitem-selected-background-color: export;
}

.md-icon-3d-rotation::before {
    content: '3d_rotation';
}

.md-icon-accessibility::before {
    content: 'accessibility';
}

.md-icon-accessible::before {
    content: 'accessible';
}

.md-icon-account-balance::before {
    content: 'account_balance';
}

.md-icon-account-balance-wallet::before {
    content: 'account_balance_wallet';
}

.md-icon-account-box::before {
    content: 'account_box';
}

.md-icon-account-circle::before {
    content: 'account_circle';
}

.md-icon-add-shopping-cart::before {
    content: 'add_shopping_cart';
}

.md-icon-alarm::before {
    content: 'alarm';
}

.md-icon-alarm-add::before {
    content: 'alarm_add';
}

.md-icon-alarm-off::before {
    content: 'alarm_off';
}

.md-icon-alarm-on::before {
    content: 'alarm_on';
}

.md-icon-all-out::before {
    content: 'all_out';
}

.md-icon-android::before {
    content: 'android';
}

.md-icon-announcement::before {
    content: 'announcement';
}

.md-icon-aspect-ratio::before {
    content: 'aspect_ratio';
}

.md-icon-assessment::before {
    content: 'assessment';
}

.md-icon-assignment::before {
    content: 'assignment';
}

.md-icon-assignment-ind::before {
    content: 'assignment_ind';
}

.md-icon-assignment-late::before {
    content: 'assignment_late';
}

.md-icon-assignment-return::before {
    content: 'assignment_return';
}

.md-icon-assignment-returned::before {
    content: 'assignment_returned';
}

.md-icon-assignment-turned-in::before {
    content: 'assignment_turned_in';
}

.md-icon-autorenew::before {
    content: 'autorenew';
}

.md-icon-backup::before {
    content: 'backup';
}

.md-icon-book::before {
    content: 'book';
}

.md-icon-bookmark::before {
    content: 'bookmark';
}

.md-icon-bookmark-border::before {
    content: 'bookmark_border';
}

.md-icon-bug-report::before {
    content: 'bug_report';
}

.md-icon-build::before {
    content: 'build';
}

.md-icon-cached::before {
    content: 'cached';
}

.md-icon-camera-enhance::before {
    content: 'camera_enhance';
}

.md-icon-card-giftcard::before {
    content: 'card_giftcard';
}

.md-icon-card-membership::before {
    content: 'card_membership';
}

.md-icon-card-travel::before {
    content: 'card_travel';
}

.md-icon-change-history::before {
    content: 'change_history';
}

.md-icon-check-circle::before {
    content: 'check_circle';
}

.md-icon-chrome-reader-mode::before {
    content: 'chrome_reader_mode';
}

.md-icon-class::before {
    content: 'class';
}

.md-icon-code::before {
    content: 'code';
}

.md-icon-compare-arrows::before {
    content: 'compare_arrows';
}

.md-icon-copyright::before {
    content: 'copyright';
}

.md-icon-credit-card::before {
    content: 'credit_card';
}

.md-icon-dashboard::before {
    content: 'dashboard';
}

.md-icon-date-range::before {
    content: 'date_range';
}

.md-icon-delete::before {
    content: 'delete';
}

.md-icon-delete-forever::before {
    content: 'delete_forever';
}

.md-icon-description::before {
    content: 'description';
}

.md-icon-dns::before {
    content: 'dns';
}

.md-icon-done::before {
    content: 'done';
}

.md-icon-done-all::before {
    content: 'done_all';
}

.md-icon-donut-large::before {
    content: 'donut_large';
}

.md-icon-donut-small::before {
    content: 'donut_small';
}

.md-icon-eject::before {
    content: 'eject';
}

.md-icon-euro-symbol::before {
    content: 'euro_symbol';
}

.md-icon-event::before {
    content: 'event';
}

.md-icon-event-seat::before {
    content: 'event_seat';
}

.md-icon-exit-to-app::before {
    content: 'exit_to_app';
}

.md-icon-explore::before {
    content: 'explore';
}

.md-icon-extension::before {
    content: 'extension';
}

.md-icon-face::before {
    content: 'face';
}

.md-icon-favorite::before {
    content: 'favorite';
}

.md-icon-favorite-border::before {
    content: 'favorite_border';
}

.md-icon-feedback::before {
    content: 'feedback';
}

.md-icon-find-in-page::before {
    content: 'find_in_page';
}

.md-icon-find-replace::before {
    content: 'find_replace';
}

.md-icon-fingerprint::before {
    content: 'fingerprint';
}

.md-icon-flight-land::before {
    content: 'flight_land';
}

.md-icon-flight-takeoff::before {
    content: 'flight_takeoff';
}

.md-icon-flip-to-back::before {
    content: 'flip_to_back';
}

.md-icon-flip-to-front::before {
    content: 'flip_to_front';
}

.md-icon-g-translate::before {
    content: 'g_translate';
}

.md-icon-gavel::before {
    content: 'gavel';
}

.md-icon-get-app::before {
    content: 'get_app';
}

.md-icon-gif::before {
    content: 'gif';
}

.md-icon-grade::before {
    content: 'grade';
}

.md-icon-group-work::before {
    content: 'group_work';
}

.md-icon-help::before {
    content: 'help';
}

.md-icon-help-outline::before {
    content: 'help_outline';
}

.md-icon-highlight-off::before {
    content: 'highlight_off';
}

.md-icon-history::before {
    content: 'history';
}

.md-icon-home::before {
    content: 'home';
}

.md-icon-hourglass-empty::before {
    content: 'hourglass_empty';
}

.md-icon-hourglass-full::before {
    content: 'hourglass_full';
}

.md-icon-http::before {
    content: 'http';
}

.md-icon-https::before {
    content: 'https';
}

.md-icon-important-devices::before {
    content: 'important_devices';
}

.md-icon-info::before {
    content: 'info';
}

.md-icon-info-outline::before {
    content: 'info_outline';
}

.md-icon-input::before {
    content: 'input';
}

.md-icon-invert-colors::before {
    content: 'invert_colors';
}

.md-icon-label::before {
    content: 'label';
}

.md-icon-label-outline::before {
    content: 'label_outline';
}

.md-icon-language::before {
    content: 'language';
}

.md-icon-launch::before {
    content: 'launch';
}

.md-icon-lightbulb-outline::before {
    content: 'lightbulb_outline';
}

.md-icon-line-style::before {
    content: 'line_style';
}

.md-icon-line-weight::before {
    content: 'line_weight';
}

.md-icon-list::before {
    content: 'list';
}

.md-icon-lock::before {
    content: 'lock';
}

.md-icon-lock-open::before {
    content: 'lock_open';
}

.md-icon-lock-outline::before {
    content: 'lock_outline';
}

.md-icon-loyalty::before {
    content: 'loyalty';
}

.md-icon-markunread-mailbox::before {
    content: 'markunread_mailbox';
}

.md-icon-motorcycle::before {
    content: 'motorcycle';
}

.md-icon-note-add::before {
    content: 'note_add';
}

.md-icon-offline-pin::before {
    content: 'offline_pin';
}

.md-icon-opacity::before {
    content: 'opacity';
}

.md-icon-open-in-browser::before {
    content: 'open_in_browser';
}

.md-icon-open-in-new::before {
    content: 'open_in_new';
}

.md-icon-open-with::before {
    content: 'open_with';
}

.md-icon-pageview::before {
    content: 'pageview';
}

.md-icon-pan-tool::before {
    content: 'pan_tool';
}

.md-icon-payment::before {
    content: 'payment';
}

.md-icon-perm-camera-mic::before {
    content: 'perm_camera_mic';
}

.md-icon-perm-contact-calendar::before {
    content: 'perm_contact_calendar';
}

.md-icon-perm-data-setting::before {
    content: 'perm_data_setting';
}

.md-icon-perm-device-information::before {
    content: 'perm_device_information';
}

.md-icon-perm-identity::before {
    content: 'perm_identity';
}

.md-icon-perm-media::before {
    content: 'perm_media';
}

.md-icon-perm-phone-msg::before {
    content: 'perm_phone_msg';
}

.md-icon-perm-scan-wifi::before {
    content: 'perm_scan_wifi';
}

.md-icon-pets::before {
    content: 'pets';
}

.md-icon-picture-in-picture::before {
    content: 'picture_in_picture';
}

.md-icon-picture-in-picture-alt::before {
    content: 'picture_in_picture_alt';
}

.md-icon-play-for-work::before {
    content: 'play_for_work';
}

.md-icon-polymer::before {
    content: 'polymer';
}

.md-icon-power-settings-new::before {
    content: 'power_settings_new';
}

.md-icon-pregnant-woman::before {
    content: 'pregnant_woman';
}

.md-icon-print::before {
    content: 'print';
}

.md-icon-query-builder::before {
    content: 'query_builder';
}

.md-icon-question-answer::before {
    content: 'question_answer';
}

.md-icon-receipt::before {
    content: 'receipt';
}

.md-icon-record-voice-over::before {
    content: 'record_voice_over';
}

.md-icon-redeem::before {
    content: 'redeem';
}

.md-icon-remove-shopping-cart::before {
    content: 'remove_shopping_cart';
}

.md-icon-reorder::before {
    content: 'reorder';
}

.md-icon-report-problem::before {
    content: 'report_problem';
}

.md-icon-restore::before {
    content: 'restore';
}

.md-icon-restore-page::before {
    content: 'restore_page';
}

.md-icon-room::before {
    content: 'room';
}

.md-icon-rounded-corner::before {
    content: 'rounded_corner';
}

.md-icon-rowing::before {
    content: 'rowing';
}

.md-icon-schedule::before {
    content: 'schedule';
}

.md-icon-search::before {
    content: 'search';
}

.md-icon-settings::before {
    content: 'settings';
}

.md-icon-settings-applications::before {
    content: 'settings_applications';
}

.md-icon-settings-backup-restore::before {
    content: 'settings_backup_restore';
}

.md-icon-settings-bluetooth::before {
    content: 'settings_bluetooth';
}

.md-icon-settings-brightness::before {
    content: 'settings_brightness';
}

.md-icon-settings-cell::before {
    content: 'settings_cell';
}

.md-icon-settings-ethernet::before {
    content: 'settings_ethernet';
}

.md-icon-settings-input-antenna::before {
    content: 'settings_input_antenna';
}

.md-icon-settings-input-component::before {
    content: 'settings_input_component';
}

.md-icon-settings-input-composite::before {
    content: 'settings_input_composite';
}

.md-icon-settings-input-hdmi::before {
    content: 'settings_input_hdmi';
}

.md-icon-settings-input-svideo::before {
    content: 'settings_input_svideo';
}

.md-icon-settings-overscan::before {
    content: 'settings_overscan';
}

.md-icon-settings-phone::before {
    content: 'settings_phone';
}

.md-icon-settings-power::before {
    content: 'settings_power';
}

.md-icon-settings-remote::before {
    content: 'settings_remote';
}

.md-icon-settings-voice::before {
    content: 'settings_voice';
}

.md-icon-shop::before {
    content: 'shop';
}

.md-icon-shop-two::before {
    content: 'shop_two';
}

.md-icon-shopping-basket::before {
    content: 'shopping_basket';
}

.md-icon-shopping-cart::before {
    content: 'shopping_cart';
}

.md-icon-speaker-notes::before {
    content: 'speaker_notes';
}

.md-icon-speaker-notes-off::before {
    content: 'speaker_notes_off';
}

.md-icon-spellcheck::before {
    content: 'spellcheck';
}

.md-icon-star-rate::before {
    content: 'star_rate';
}

.md-icon-stars::before {
    content: 'stars';
}

.md-icon-store::before {
    content: 'store';
}

.md-icon-subject::before {
    content: 'subject';
}

.md-icon-supervisor-account::before {
    content: 'supervisor_account';
}

.md-icon-swap-horiz::before {
    content: 'swap_horiz';
}

.md-icon-swap-vert::before {
    content: 'swap_vert';
}

.md-icon-swap-vertical-circle::before {
    content: 'swap_vertical_circle';
}

.md-icon-system-update-alt::before {
    content: 'system_update_alt';
}

.md-icon-tab::before {
    content: 'tab';
}

.md-icon-tab-unselected::before {
    content: 'tab_unselected';
}

.md-icon-theaters::before {
    content: 'theaters';
}

.md-icon-thumb-down::before {
    content: 'thumb_down';
}

.md-icon-thumb-up::before {
    content: 'thumb_up';
}

.md-icon-thumbs-up-down::before {
    content: 'thumbs_up_down';
}

.md-icon-timeline::before {
    content: 'timeline';
}

.md-icon-toc::before {
    content: 'toc';
}

.md-icon-today::before {
    content: 'today';
}

.md-icon-toll::before {
    content: 'toll';
}

.md-icon-touch-app::before {
    content: 'touch_app';
}

.md-icon-track-changes::before {
    content: 'track_changes';
}

.md-icon-translate::before {
    content: 'translate';
}

.md-icon-trending-down::before {
    content: 'trending_down';
}

.md-icon-trending-flat::before {
    content: 'trending_flat';
}

.md-icon-trending-up::before {
    content: 'trending_up';
}

.md-icon-turned-in::before {
    content: 'turned_in';
}

.md-icon-turned-in-not::before {
    content: 'turned_in_not';
}

.md-icon-update::before {
    content: 'update';
}

.md-icon-verified-user::before {
    content: 'verified_user';
}

.md-icon-view-agenda::before {
    content: 'view_agenda';
}

.md-icon-view-array::before {
    content: 'view_array';
}

.md-icon-view-carousel::before {
    content: 'view_carousel';
}

.md-icon-view-column::before {
    content: 'view_column';
}

.md-icon-view-day::before {
    content: 'view_day';
}

.md-icon-view-headline::before {
    content: 'view_headline';
}

.md-icon-view-list::before {
    content: 'view_list';
}

.md-icon-view-module::before {
    content: 'view_module';
}

.md-icon-view-quilt::before {
    content: 'view_quilt';
}

.md-icon-view-stream::before {
    content: 'view_stream';
}

.md-icon-view-week::before {
    content: 'view_week';
}

.md-icon-visibility::before {
    content: 'visibility';
}

.md-icon-visibility-off::before {
    content: 'visibility_off';
}

.md-icon-watch-later::before {
    content: 'watch_later';
}

.md-icon-work::before {
    content: 'work';
}

.md-icon-youtube-searched-for::before {
    content: 'youtube_searched_for';
}

.md-icon-zoom-in::before {
    content: 'zoom_in';
}

.md-icon-zoom-out::before {
    content: 'zoom_out';
}

.md-icon-add-alert::before {
    content: 'add_alert';
}

.md-icon-error::before {
    content: 'error';
}

.md-icon-error-outline::before {
    content: 'error_outline';
}

.md-icon-warning::before {
    content: 'warning';
}

.md-icon-add-to-queue::before {
    content: 'add_to_queue';
}

.md-icon-airplay::before {
    content: 'airplay';
}

.md-icon-album::before {
    content: 'album';
}

.md-icon-art-track::before {
    content: 'art_track';
}

.md-icon-av-timer::before {
    content: 'av_timer';
}

.md-icon-branding-watermark::before {
    content: 'branding_watermark';
}

.md-icon-call-to-action::before {
    content: 'call_to_action';
}

.md-icon-closed-caption::before {
    content: 'closed_caption';
}

.md-icon-equalizer::before {
    content: 'equalizer';
}

.md-icon-explicit::before {
    content: 'explicit';
}

.md-icon-fast-forward::before {
    content: 'fast_forward';
}

.md-icon-fast-rewind::before {
    content: 'fast_rewind';
}

.md-icon-featured-play-list::before {
    content: 'featured_play_list';
}

.md-icon-featured-video::before {
    content: 'featured_video';
}

.md-icon-fiber-dvr::before {
    content: 'fiber_dvr';
}

.md-icon-fiber-manual-record::before {
    content: 'fiber_manual_record';
}

.md-icon-fiber-new::before {
    content: 'fiber_new';
}

.md-icon-fiber-pin::before {
    content: 'fiber_pin';
}

.md-icon-fiber-smart-record::before {
    content: 'fiber_smart_record';
}

.md-icon-forward-10::before {
    content: 'forward_10';
}

.md-icon-forward-30::before {
    content: 'forward_30';
}

.md-icon-forward-5::before {
    content: 'forward_5';
}

.md-icon-games::before {
    content: 'games';
}

.md-icon-hd::before {
    content: 'hd';
}

.md-icon-hearing::before {
    content: 'hearing';
}

.md-icon-high-quality::before {
    content: 'high_quality';
}

.md-icon-library-add::before {
    content: 'library_add';
}

.md-icon-library-books::before {
    content: 'library_books';
}

.md-icon-library-music::before {
    content: 'library_music';
}

.md-icon-loop::before {
    content: 'loop';
}

.md-icon-mic::before {
    content: 'mic';
}

.md-icon-mic-none::before {
    content: 'mic_none';
}

.md-icon-mic-off::before {
    content: 'mic_off';
}

.md-icon-movie::before {
    content: 'movie';
}

.md-icon-music-video::before {
    content: 'music_video';
}

.md-icon-new-releases::before {
    content: 'new_releases';
}

.md-icon-not-interested::before {
    content: 'not_interested';
}

.md-icon-note::before {
    content: 'note';
}

.md-icon-pause::before {
    content: 'pause';
}

.md-icon-pause-circle-filled::before {
    content: 'pause_circle_filled';
}

.md-icon-pause-circle-outline::before {
    content: 'pause_circle_outline';
}

.md-icon-play-arrow::before {
    content: 'play_arrow';
}

.md-icon-play-circle-filled::before {
    content: 'play_circle_filled';
}

.md-icon-play-circle-outline::before {
    content: 'play_circle_outline';
}

.md-icon-playlist-add::before {
    content: 'playlist_add';
}

.md-icon-playlist-add-check::before {
    content: 'playlist_add_check';
}

.md-icon-playlist-play::before {
    content: 'playlist_play';
}

.md-icon-queue::before {
    content: 'queue';
}

.md-icon-queue-music::before {
    content: 'queue_music';
}

.md-icon-queue-play-next::before {
    content: 'queue_play_next';
}

.md-icon-radio::before {
    content: 'radio';
}

.md-icon-recent-actors::before {
    content: 'recent_actors';
}

.md-icon-remove-from-queue::before {
    content: 'remove_from_queue';
}

.md-icon-repeat::before {
    content: 'repeat';
}

.md-icon-repeat-one::before {
    content: 'repeat_one';
}

.md-icon-replay::before {
    content: 'replay';
}

.md-icon-replay-10::before {
    content: 'replay_10';
}

.md-icon-replay-30::before {
    content: 'replay_30';
}

.md-icon-replay-5::before {
    content: 'replay_5';
}

.md-icon-shuffle::before {
    content: 'shuffle';
}

.md-icon-skip-next::before {
    content: 'skip_next';
}

.md-icon-skip-previous::before {
    content: 'skip_previous';
}

.md-icon-slow-motion-video::before {
    content: 'slow_motion_video';
}

.md-icon-snooze::before {
    content: 'snooze';
}

.md-icon-sort-by-alpha::before {
    content: 'sort_by_alpha';
}

.md-icon-stop::before {
    content: 'stop';
}

.md-icon-subscriptions::before {
    content: 'subscriptions';
}

.md-icon-subtitles::before {
    content: 'subtitles';
}

.md-icon-surround-sound::before {
    content: 'surround_sound';
}

.md-icon-video-call::before {
    content: 'video_call';
}

.md-icon-video-label::before {
    content: 'video_label';
}

.md-icon-video-library::before {
    content: 'video_library';
}

.md-icon-videocam::before {
    content: 'videocam';
}

.md-icon-videocam-off::before {
    content: 'videocam_off';
}

.md-icon-volume-down::before {
    content: 'volume_down';
}

.md-icon-volume-mute::before {
    content: 'volume_mute';
}

.md-icon-volume-off::before {
    content: 'volume_off';
}

.md-icon-volume-up::before {
    content: 'volume_up';
}

.md-icon-web::before {
    content: 'web';
}

.md-icon-web-asset::before {
    content: 'web_asset';
}

.md-icon-business::before {
    content: 'business';
}

.md-icon-call::before {
    content: 'call';
}

.md-icon-call-end::before {
    content: 'call_end';
}

.md-icon-call-made::before {
    content: 'call_made';
}

.md-icon-call-merge::before {
    content: 'call_merge';
}

.md-icon-call-missed::before {
    content: 'call_missed';
}

.md-icon-call-missed-outgoing::before {
    content: 'call_missed_outgoing';
}

.md-icon-call-received::before {
    content: 'call_received';
}

.md-icon-call-split::before {
    content: 'call_split';
}

.md-icon-chat::before {
    content: 'chat';
}

.md-icon-chat-bubble::before {
    content: 'chat_bubble';
}

.md-icon-chat-bubble-outline::before {
    content: 'chat_bubble_outline';
}

.md-icon-clear-all::before {
    content: 'clear_all';
}

.md-icon-comment::before {
    content: 'comment';
}

.md-icon-contact-mail::before {
    content: 'contact_mail';
}

.md-icon-contact-phone::before {
    content: 'contact_phone';
}

.md-icon-contacts::before {
    content: 'contacts';
}

.md-icon-dialer-sip::before {
    content: 'dialer_sip';
}

.md-icon-dialpad::before {
    content: 'dialpad';
}

.md-icon-email::before {
    content: 'email';
}

.md-icon-forum::before {
    content: 'forum';
}

.md-icon-import-contacts::before {
    content: 'import_contacts';
}

.md-icon-import-export::before {
    content: 'import_export';
}

.md-icon-invert-colors-off::before {
    content: 'invert_colors_off';
}

.md-icon-live-help::before {
    content: 'live_help';
}

.md-icon-location-off::before {
    content: 'location_off';
}

.md-icon-location-on::before {
    content: 'location_on';
}

.md-icon-mail-outline::before {
    content: 'mail_outline';
}

.md-icon-message::before {
    content: 'message';
}

.md-icon-no-sim::before {
    content: 'no_sim';
}

.md-icon-phone::before {
    content: 'phone';
}

.md-icon-phonelink-erase::before {
    content: 'phonelink_erase';
}

.md-icon-phonelink-lock::before {
    content: 'phonelink_lock';
}

.md-icon-phonelink-ring::before {
    content: 'phonelink_ring';
}

.md-icon-phonelink-setup::before {
    content: 'phonelink_setup';
}

.md-icon-portable-wifi-off::before {
    content: 'portable_wifi_off';
}

.md-icon-present-to-all::before {
    content: 'present_to_all';
}

.md-icon-ring-volume::before {
    content: 'ring_volume';
}

.md-icon-rss-feed::before {
    content: 'rss_feed';
}

.md-icon-screen-share::before {
    content: 'screen_share';
}

.md-icon-speaker-phone::before {
    content: 'speaker_phone';
}

.md-icon-stay-current-landscape::before {
    content: 'stay_current_landscape';
}

.md-icon-stay-current-portrait::before {
    content: 'stay_current_portrait';
}

.md-icon-stay-primary-landscape::before {
    content: 'stay_primary_landscape';
}

.md-icon-stay-primary-portrait::before {
    content: 'stay_primary_portrait';
}

.md-icon-stop-screen-share::before {
    content: 'stop_screen_share';
}

.md-icon-swap-calls::before {
    content: 'swap_calls';
}

.md-icon-textsms::before {
    content: 'textsms';
}

.md-icon-voicemail::before {
    content: 'voicemail';
}

.md-icon-vpn-key::before {
    content: 'vpn_key';
}

.md-icon-add::before {
    content: 'add';
}

.md-icon-add-box::before {
    content: 'add_box';
}

.md-icon-add-circle::before {
    content: 'add_circle';
}

.md-icon-add-circle-outline::before {
    content: 'add_circle_outline';
}

.md-icon-archive::before {
    content: 'archive';
}

.md-icon-backspace::before {
    content: 'backspace';
}

.md-icon-block::before {
    content: 'block';
}

.md-icon-clear::before {
    content: 'clear';
}

.md-icon-content-copy::before {
    content: 'content_copy';
}

.md-icon-content-cut::before {
    content: 'content_cut';
}

.md-icon-content-paste::before {
    content: 'content_paste';
}

.md-icon-create::before {
    content: 'create';
}

.md-icon-delete-sweep::before {
    content: 'delete_sweep';
}

.md-icon-drafts::before {
    content: 'drafts';
}

.md-icon-filter-list::before {
    content: 'filter_list';
}

.md-icon-flag::before {
    content: 'flag';
}

.md-icon-font-download::before {
    content: 'font_download';
}

.md-icon-forward::before {
    content: 'forward';
}

.md-icon-gesture::before {
    content: 'gesture';
}

.md-icon-inbox::before {
    content: 'inbox';
}

.md-icon-link::before {
    content: 'link';
}

.md-icon-low-priority::before {
    content: 'low_priority';
}

.md-icon-mail::before {
    content: 'mail';
}

.md-icon-markunread::before {
    content: 'markunread';
}

.md-icon-move-to-inbox::before {
    content: 'move_to_inbox';
}

.md-icon-next-week::before {
    content: 'next_week';
}

.md-icon-redo::before {
    content: 'redo';
}

.md-icon-remove::before {
    content: 'remove';
}

.md-icon-remove-circle::before {
    content: 'remove_circle';
}

.md-icon-remove-circle-outline::before {
    content: 'remove_circle_outline';
}

.md-icon-reply::before {
    content: 'reply';
}

.md-icon-reply-all::before {
    content: 'reply_all';
}

.md-icon-report::before {
    content: 'report';
}

.md-icon-save::before {
    content: 'save';
}

.md-icon-select-all::before {
    content: 'select_all';
}

.md-icon-send::before {
    content: 'send';
}

.md-icon-sort::before {
    content: 'sort';
}

.md-icon-text-format::before {
    content: 'text_format';
}

.md-icon-unarchive::before {
    content: 'unarchive';
}

.md-icon-undo::before {
    content: 'undo';
}

.md-icon-weekend::before {
    content: 'weekend';
}

.md-icon-access-alarm::before {
    content: 'access_alarm';
}

.md-icon-access-alarms::before {
    content: 'access_alarms';
}

.md-icon-access-time::before {
    content: 'access_time';
}

.md-icon-add-alarm::before {
    content: 'add_alarm';
}

.md-icon-airplanemode-active::before {
    content: 'airplanemode_active';
}

.md-icon-airplanemode-inactive::before {
    content: 'airplanemode_inactive';
}

.md-icon-battery-alert::before {
    content: 'battery_alert';
}

.md-icon-battery-charging-full::before {
    content: 'battery_charging_full';
}

.md-icon-battery-full::before {
    content: 'battery_full';
}

.md-icon-battery-std::before {
    content: 'battery_std';
}

.md-icon-battery-unknown::before {
    content: 'battery_unknown';
}

.md-icon-bluetooth::before {
    content: 'bluetooth';
}

.md-icon-bluetooth-connected::before {
    content: 'bluetooth_connected';
}

.md-icon-bluetooth-disabled::before {
    content: 'bluetooth_disabled';
}

.md-icon-bluetooth-searching::before {
    content: 'bluetooth_searching';
}

.md-icon-brightness-auto::before {
    content: 'brightness_auto';
}

.md-icon-brightness-high::before {
    content: 'brightness_high';
}

.md-icon-brightness-low::before {
    content: 'brightness_low';
}

.md-icon-brightness-medium::before {
    content: 'brightness_medium';
}

.md-icon-data-usage::before {
    content: 'data_usage';
}

.md-icon-developer-mode::before {
    content: 'developer_mode';
}

.md-icon-devices::before {
    content: 'devices';
}

.md-icon-dvr::before {
    content: 'dvr';
}

.md-icon-gps-fixed::before {
    content: 'gps_fixed';
}

.md-icon-gps-not-fixed::before {
    content: 'gps_not_fixed';
}

.md-icon-gps-off::before {
    content: 'gps_off';
}

.md-icon-graphic-eq::before {
    content: 'graphic_eq';
}

.md-icon-location-disabled::before {
    content: 'location_disabled';
}

.md-icon-location-searching::before {
    content: 'location_searching';
}

.md-icon-network-cell::before {
    content: 'network_cell';
}

.md-icon-network-wifi::before {
    content: 'network_wifi';
}

.md-icon-nfc::before {
    content: 'nfc';
}

.md-icon-screen-lock-landscape::before {
    content: 'screen_lock_landscape';
}

.md-icon-screen-lock-portrait::before {
    content: 'screen_lock_portrait';
}

.md-icon-screen-lock-rotation::before {
    content: 'screen_lock_rotation';
}

.md-icon-screen-rotation::before {
    content: 'screen_rotation';
}

.md-icon-sd-storage::before {
    content: 'sd_storage';
}

.md-icon-settings-system-daydream::before {
    content: 'settings_system_daydream';
}

.md-icon-signal-cellular-4-bar::before {
    content: 'signal_cellular_4_bar';
}

.md-icon-signal-cellular-connected-no-internet-4-bar::before {
    content: 'signal_cellular_connected_no_internet_4_bar';
}

.md-icon-signal-cellular-no-sim::before {
    content: 'signal_cellular_no_sim';
}

.md-icon-signal-cellular-null::before {
    content: 'signal_cellular_null';
}

.md-icon-signal-cellular-off::before {
    content: 'signal_cellular_off';
}

.md-icon-signal-wifi-4-bar::before {
    content: 'signal_wifi_4_bar';
}

.md-icon-signal-wifi-4-bar-lock::before {
    content: 'signal_wifi_4_bar_lock';
}

.md-icon-signal-wifi-off::before {
    content: 'signal_wifi_off';
}

.md-icon-storage::before {
    content: 'storage';
}

.md-icon-usb::before {
    content: 'usb';
}

.md-icon-wallpaper::before {
    content: 'wallpaper';
}

.md-icon-widgets::before {
    content: 'widgets';
}

.md-icon-wifi-lock::before {
    content: 'wifi_lock';
}

.md-icon-wifi-tethering::before {
    content: 'wifi_tethering';
}

.md-icon-attach-file::before {
    content: 'attach_file';
}

.md-icon-attach-money::before {
    content: 'attach_money';
}

.md-icon-border-all::before {
    content: 'border_all';
}

.md-icon-border-bottom::before {
    content: 'border_bottom';
}

.md-icon-border-clear::before {
    content: 'border_clear';
}

.md-icon-border-color::before {
    content: 'border_color';
}

.md-icon-border-horizontal::before {
    content: 'border_horizontal';
}

.md-icon-border-inner::before {
    content: 'border_inner';
}

.md-icon-border-left::before {
    content: 'border_left';
}

.md-icon-border-outer::before {
    content: 'border_outer';
}

.md-icon-border-right::before {
    content: 'border_right';
}

.md-icon-border-style::before {
    content: 'border_style';
}

.md-icon-border-top::before {
    content: 'border_top';
}

.md-icon-border-vertical::before {
    content: 'border_vertical';
}

.md-icon-bubble-chart::before {
    content: 'bubble_chart';
}

.md-icon-drag-handle::before {
    content: 'drag_handle';
}

.md-icon-format-align-center::before {
    content: 'format_align_center';
}

.md-icon-format-align-justify::before {
    content: 'format_align_justify';
}

.md-icon-format-align-left::before {
    content: 'format_align_left';
}

.md-icon-format-align-right::before {
    content: 'format_align_right';
}

.md-icon-format-bold::before {
    content: 'format_bold';
}

.md-icon-format-clear::before {
    content: 'format_clear';
}

.md-icon-format-color-fill::before {
    content: 'format_color_fill';
}

.md-icon-format-color-reset::before {
    content: 'format_color_reset';
}

.md-icon-format-color-text::before {
    content: 'format_color_text';
}

.md-icon-format-indent-decrease::before {
    content: 'format_indent_decrease';
}

.md-icon-format-indent-increase::before {
    content: 'format_indent_increase';
}

.md-icon-format-italic::before {
    content: 'format_italic';
}

.md-icon-format-line-spacing::before {
    content: 'format_line_spacing';
}

.md-icon-format-list-bulleted::before {
    content: 'format_list_bulleted';
}

.md-icon-format-list-numbered::before {
    content: 'format_list_numbered';
}

.md-icon-format-paint::before {
    content: 'format_paint';
}

.md-icon-format-quote::before {
    content: 'format_quote';
}

.md-icon-format-shapes::before {
    content: 'format_shapes';
}

.md-icon-format-size::before {
    content: 'format_size';
}

.md-icon-format-strikethrough::before {
    content: 'format_strikethrough';
}

.md-icon-format-textdirection-l-to-r::before {
    content: 'format_textdirection_l_to_r';
}

.md-icon-format-textdirection-r-to-l::before {
    content: 'format_textdirection_r_to_l';
}

.md-icon-format-underlined::before {
    content: 'format_underlined';
}

.md-icon-functions::before {
    content: 'functions';
}

.md-icon-highlight::before {
    content: 'highlight';
}

.md-icon-insert-chart::before {
    content: 'insert_chart';
}

.md-icon-insert-comment::before {
    content: 'insert_comment';
}

.md-icon-insert-drive-file::before {
    content: 'insert_drive_file';
}

.md-icon-insert-emoticon::before {
    content: 'insert_emoticon';
}

.md-icon-insert-invitation::before {
    content: 'insert_invitation';
}

.md-icon-insert-link::before {
    content: 'insert_link';
}

.md-icon-insert-photo::before {
    content: 'insert_photo';
}

.md-icon-linear-scale::before {
    content: 'linear_scale';
}

.md-icon-merge-type::before {
    content: 'merge_type';
}

.md-icon-mode-comment::before {
    content: 'mode_comment';
}

.md-icon-mode-edit::before {
    content: 'mode_edit';
}

.md-icon-monetization-on::before {
    content: 'monetization_on';
}

.md-icon-money-off::before {
    content: 'money_off';
}

.md-icon-multiline-chart::before {
    content: 'multiline_chart';
}

.md-icon-pie-chart::before {
    content: 'pie_chart';
}

.md-icon-pie-chart-outlined::before {
    content: 'pie_chart_outlined';
}

.md-icon-publish::before {
    content: 'publish';
}

.md-icon-short-text::before {
    content: 'short_text';
}

.md-icon-show-chart::before {
    content: 'show_chart';
}

.md-icon-space-bar::before {
    content: 'space_bar';
}

.md-icon-strikethrough-s::before {
    content: 'strikethrough_s';
}

.md-icon-text-fields::before {
    content: 'text_fields';
}

.md-icon-title::before {
    content: 'title';
}

.md-icon-vertical-align-bottom::before {
    content: 'vertical_align_bottom';
}

.md-icon-vertical-align-center::before {
    content: 'vertical_align_center';
}

.md-icon-vertical-align-top::before {
    content: 'vertical_align_top';
}

.md-icon-wrap-text::before {
    content: 'wrap_text';
}

.md-icon-attachment::before {
    content: 'attachment';
}

.md-icon-cloud::before {
    content: 'cloud';
}

.md-icon-cloud-circle::before {
    content: 'cloud_circle';
}

.md-icon-cloud-done::before {
    content: 'cloud_done';
}

.md-icon-cloud-download::before {
    content: 'cloud_download';
}

.md-icon-cloud-off::before {
    content: 'cloud_off';
}

.md-icon-cloud-queue::before {
    content: 'cloud_queue';
}

.md-icon-cloud-upload::before {
    content: 'cloud_upload';
}

.md-icon-create-new-folder::before {
    content: 'create_new_folder';
}

.md-icon-file-download::before {
    content: 'file_download';
}

.md-icon-file-upload::before {
    content: 'file_upload';
}

.md-icon-folder::before {
    content: 'folder';
}

.md-icon-folder-open::before {
    content: 'folder_open';
}

.md-icon-folder-shared::before {
    content: 'folder_shared';
}

.md-icon-cast::before {
    content: 'cast';
}

.md-icon-cast-connected::before {
    content: 'cast_connected';
}

.md-icon-computer::before {
    content: 'computer';
}

.md-icon-desktop-mac::before {
    content: 'desktop_mac';
}

.md-icon-desktop-windows::before {
    content: 'desktop_windows';
}

.md-icon-developer-board::before {
    content: 'developer_board';
}

.md-icon-device-hub::before {
    content: 'device_hub';
}

.md-icon-devices-other::before {
    content: 'devices_other';
}

.md-icon-dock::before {
    content: 'dock';
}

.md-icon-gamepad::before {
    content: 'gamepad';
}

.md-icon-headset::before {
    content: 'headset';
}

.md-icon-headset-mic::before {
    content: 'headset_mic';
}

.md-icon-keyboard::before {
    content: 'keyboard';
}

.md-icon-keyboard-arrow-down::before {
    content: 'keyboard_arrow_down';
}

.md-icon-keyboard-arrow-left::before {
    content: 'keyboard_arrow_left';
}

.md-icon-keyboard-arrow-right::before {
    content: 'keyboard_arrow_right';
}

.md-icon-keyboard-arrow-up::before {
    content: 'keyboard_arrow_up';
}

.md-icon-keyboard-backspace::before {
    content: 'keyboard_backspace';
}

.md-icon-keyboard-capslock::before {
    content: 'keyboard_capslock';
}

.md-icon-keyboard-hide::before {
    content: 'keyboard_hide';
}

.md-icon-keyboard-return::before {
    content: 'keyboard_return';
}

.md-icon-keyboard-tab::before {
    content: 'keyboard_tab';
}

.md-icon-keyboard-voice::before {
    content: 'keyboard_voice';
}

.md-icon-laptop::before {
    content: 'laptop';
}

.md-icon-laptop-chromebook::before {
    content: 'laptop_chromebook';
}

.md-icon-laptop-mac::before {
    content: 'laptop_mac';
}

.md-icon-laptop-windows::before {
    content: 'laptop_windows';
}

.md-icon-memory::before {
    content: 'memory';
}

.md-icon-mouse::before {
    content: 'mouse';
}

.md-icon-phone-android::before {
    content: 'phone_android';
}

.md-icon-phone-iphone::before {
    content: 'phone_iphone';
}

.md-icon-phonelink::before {
    content: 'phonelink';
}

.md-icon-phonelink-off::before {
    content: 'phonelink_off';
}

.md-icon-power-input::before {
    content: 'power_input';
}

.md-icon-router::before {
    content: 'router';
}

.md-icon-scanner::before {
    content: 'scanner';
}

.md-icon-security::before {
    content: 'security';
}

.md-icon-sim-card::before {
    content: 'sim_card';
}

.md-icon-smartphone::before {
    content: 'smartphone';
}

.md-icon-speaker::before {
    content: 'speaker';
}

.md-icon-speaker-group::before {
    content: 'speaker_group';
}

.md-icon-tablet::before {
    content: 'tablet';
}

.md-icon-tablet-android::before {
    content: 'tablet_android';
}

.md-icon-tablet-mac::before {
    content: 'tablet_mac';
}

.md-icon-toys::before {
    content: 'toys';
}

.md-icon-tv::before {
    content: 'tv';
}

.md-icon-videogame-asset::before {
    content: 'videogame_asset';
}

.md-icon-watch::before {
    content: 'watch';
}

.md-icon-add-a-photo::before {
    content: 'add_a_photo';
}

.md-icon-add-to-photos::before {
    content: 'add_to_photos';
}

.md-icon-adjust::before {
    content: 'adjust';
}

.md-icon-assistant::before {
    content: 'assistant';
}

.md-icon-assistant-photo::before {
    content: 'assistant_photo';
}

.md-icon-audiotrack::before {
    content: 'audiotrack';
}

.md-icon-blur-circular::before {
    content: 'blur_circular';
}

.md-icon-blur-linear::before {
    content: 'blur_linear';
}

.md-icon-blur-off::before {
    content: 'blur_off';
}

.md-icon-blur-on::before {
    content: 'blur_on';
}

.md-icon-brightness-1::before {
    content: 'brightness_1';
}

.md-icon-brightness-2::before {
    content: 'brightness_2';
}

.md-icon-brightness-3::before {
    content: 'brightness_3';
}

.md-icon-brightness-4::before {
    content: 'brightness_4';
}

.md-icon-brightness-5::before {
    content: 'brightness_5';
}

.md-icon-brightness-6::before {
    content: 'brightness_6';
}

.md-icon-brightness-7::before {
    content: 'brightness_7';
}

.md-icon-broken-image::before {
    content: 'broken_image';
}

.md-icon-brush::before {
    content: 'brush';
}

.md-icon-burst-mode::before {
    content: 'burst_mode';
}

.md-icon-camera::before {
    content: 'camera';
}

.md-icon-camera-alt::before {
    content: 'camera_alt';
}

.md-icon-camera-front::before {
    content: 'camera_front';
}

.md-icon-camera-rear::before {
    content: 'camera_rear';
}

.md-icon-camera-roll::before {
    content: 'camera_roll';
}

.md-icon-center-focus-strong::before {
    content: 'center_focus_strong';
}

.md-icon-center-focus-weak::before {
    content: 'center_focus_weak';
}

.md-icon-collections::before {
    content: 'collections';
}

.md-icon-collections-bookmark::before {
    content: 'collections_bookmark';
}

.md-icon-color-lens::before {
    content: 'color_lens';
}

.md-icon-colorize::before {
    content: 'colorize';
}

.md-icon-compare::before {
    content: 'compare';
}

.md-icon-control-point::before {
    content: 'control_point';
}

.md-icon-control-point-duplicate::before {
    content: 'control_point_duplicate';
}

.md-icon-crop::before {
    content: 'crop';
}

.md-icon-crop-16-9::before {
    content: 'crop_16_9';
}

.md-icon-crop-3-2::before {
    content: 'crop_3_2';
}

.md-icon-crop-5-4::before {
    content: 'crop_5_4';
}

.md-icon-crop-7-5::before {
    content: 'crop_7_5';
}

.md-icon-crop-din::before {
    content: 'crop_din';
}

.md-icon-crop-free::before {
    content: 'crop_free';
}

.md-icon-crop-landscape::before {
    content: 'crop_landscape';
}

.md-icon-crop-original::before {
    content: 'crop_original';
}

.md-icon-crop-portrait::before {
    content: 'crop_portrait';
}

.md-icon-crop-rotate::before {
    content: 'crop_rotate';
}

.md-icon-crop-square::before {
    content: 'crop_square';
}

.md-icon-dehaze::before {
    content: 'dehaze';
}

.md-icon-details::before {
    content: 'details';
}

.md-icon-edit::before {
    content: 'edit';
}

.md-icon-exposure::before {
    content: 'exposure';
}

.md-icon-exposure-neg-1::before {
    content: 'exposure_neg_1';
}

.md-icon-exposure-neg-2::before {
    content: 'exposure_neg_2';
}

.md-icon-exposure-plus-1::before {
    content: 'exposure_plus_1';
}

.md-icon-exposure-plus-2::before {
    content: 'exposure_plus_2';
}

.md-icon-exposure-zero::before {
    content: 'exposure_zero';
}

.md-icon-filter::before {
    content: 'filter';
}

.md-icon-filter-1::before {
    content: 'filter_1';
}

.md-icon-filter-2::before {
    content: 'filter_2';
}

.md-icon-filter-3::before {
    content: 'filter_3';
}

.md-icon-filter-4::before {
    content: 'filter_4';
}

.md-icon-filter-5::before {
    content: 'filter_5';
}

.md-icon-filter-6::before {
    content: 'filter_6';
}

.md-icon-filter-7::before {
    content: 'filter_7';
}

.md-icon-filter-8::before {
    content: 'filter_8';
}

.md-icon-filter-9::before {
    content: 'filter_9';
}

.md-icon-filter-9-plus::before {
    content: 'filter_9_plus';
}

.md-icon-filter-b-and-w::before {
    content: 'filter_b_and_w';
}

.md-icon-filter-center-focus::before {
    content: 'filter_center_focus';
}

.md-icon-filter-drama::before {
    content: 'filter_drama';
}

.md-icon-filter-frames::before {
    content: 'filter_frames';
}

.md-icon-filter-hdr::before {
    content: 'filter_hdr';
}

.md-icon-filter-none::before {
    content: 'filter_none';
}

.md-icon-filter-tilt-shift::before {
    content: 'filter_tilt_shift';
}

.md-icon-filter-vintage::before {
    content: 'filter_vintage';
}

.md-icon-flare::before {
    content: 'flare';
}

.md-icon-flash-auto::before {
    content: 'flash_auto';
}

.md-icon-flash-off::before {
    content: 'flash_off';
}

.md-icon-flash-on::before {
    content: 'flash_on';
}

.md-icon-flip::before {
    content: 'flip';
}

.md-icon-gradient::before {
    content: 'gradient';
}

.md-icon-grain::before {
    content: 'grain';
}

.md-icon-grid-off::before {
    content: 'grid_off';
}

.md-icon-grid-on::before {
    content: 'grid_on';
}

.md-icon-hdr-off::before {
    content: 'hdr_off';
}

.md-icon-hdr-on::before {
    content: 'hdr_on';
}

.md-icon-hdr-strong::before {
    content: 'hdr_strong';
}

.md-icon-hdr-weak::before {
    content: 'hdr_weak';
}

.md-icon-healing::before {
    content: 'healing';
}

.md-icon-image::before {
    content: 'image';
}

.md-icon-image-aspect-ratio::before {
    content: 'image_aspect_ratio';
}

.md-icon-iso::before {
    content: 'iso';
}

.md-icon-landscape::before {
    content: 'landscape';
}

.md-icon-leak-add::before {
    content: 'leak_add';
}

.md-icon-leak-remove::before {
    content: 'leak_remove';
}

.md-icon-lens::before {
    content: 'lens';
}

.md-icon-linked-camera::before {
    content: 'linked_camera';
}

.md-icon-looks::before {
    content: 'looks';
}

.md-icon-looks-3::before {
    content: 'looks_3';
}

.md-icon-looks-4::before {
    content: 'looks_4';
}

.md-icon-looks-5::before {
    content: 'looks_5';
}

.md-icon-looks-6::before {
    content: 'looks_6';
}

.md-icon-looks-one::before {
    content: 'looks_one';
}

.md-icon-looks-two::before {
    content: 'looks_two';
}

.md-icon-loupe::before {
    content: 'loupe';
}

.md-icon-monochrome-photos::before {
    content: 'monochrome_photos';
}

.md-icon-movie-creation::before {
    content: 'movie_creation';
}

.md-icon-movie-filter::before {
    content: 'movie_filter';
}

.md-icon-music-note::before {
    content: 'music_note';
}

.md-icon-nature::before {
    content: 'nature';
}

.md-icon-nature-people::before {
    content: 'nature_people';
}

.md-icon-navigate-before::before {
    content: 'navigate_before';
}

.md-icon-navigate-next::before {
    content: 'navigate_next';
}

.md-icon-palette::before {
    content: 'palette';
}

.md-icon-panorama::before {
    content: 'panorama';
}

.md-icon-panorama-fish-eye::before {
    content: 'panorama_fish_eye';
}

.md-icon-panorama-horizontal::before {
    content: 'panorama_horizontal';
}

.md-icon-panorama-vertical::before {
    content: 'panorama_vertical';
}

.md-icon-panorama-wide-angle::before {
    content: 'panorama_wide_angle';
}

.md-icon-photo::before {
    content: 'photo';
}

.md-icon-photo-album::before {
    content: 'photo_album';
}

.md-icon-photo-camera::before {
    content: 'photo_camera';
}

.md-icon-photo-filter::before {
    content: 'photo_filter';
}

.md-icon-photo-library::before {
    content: 'photo_library';
}

.md-icon-photo-size-select-actual::before {
    content: 'photo_size_select_actual';
}

.md-icon-photo-size-select-large::before {
    content: 'photo_size_select_large';
}

.md-icon-photo-size-select-small::before {
    content: 'photo_size_select_small';
}

.md-icon-picture-as-pdf::before {
    content: 'picture_as_pdf';
}

.md-icon-portrait::before {
    content: 'portrait';
}

.md-icon-remove-red-eye::before {
    content: 'remove_red_eye';
}

.md-icon-rotate-90-degrees-ccw::before {
    content: 'rotate_90_degrees_ccw';
}

.md-icon-rotate-left::before {
    content: 'rotate_left';
}

.md-icon-rotate-right::before {
    content: 'rotate_right';
}

.md-icon-slideshow::before {
    content: 'slideshow';
}

.md-icon-straighten::before {
    content: 'straighten';
}

.md-icon-style::before {
    content: 'style';
}

.md-icon-switch-camera::before {
    content: 'switch_camera';
}

.md-icon-switch-video::before {
    content: 'switch_video';
}

.md-icon-tag-faces::before {
    content: 'tag_faces';
}

.md-icon-texture::before {
    content: 'texture';
}

.md-icon-timelapse::before {
    content: 'timelapse';
}

.md-icon-timer::before {
    content: 'timer';
}

.md-icon-timer-10::before {
    content: 'timer_10';
}

.md-icon-timer-3::before {
    content: 'timer_3';
}

.md-icon-timer-off::before {
    content: 'timer_off';
}

.md-icon-tonality::before {
    content: 'tonality';
}

.md-icon-transform::before {
    content: 'transform';
}

.md-icon-tune::before {
    content: 'tune';
}

.md-icon-view-comfy::before {
    content: 'view_comfy';
}

.md-icon-view-compact::before {
    content: 'view_compact';
}

.md-icon-vignette::before {
    content: 'vignette';
}

.md-icon-wb-auto::before {
    content: 'wb_auto';
}

.md-icon-wb-cloudy::before {
    content: 'wb_cloudy';
}

.md-icon-wb-incandescent::before {
    content: 'wb_incandescent';
}

.md-icon-wb-iridescent::before {
    content: 'wb_iridescent';
}

.md-icon-wb-sunny::before {
    content: 'wb_sunny';
}

.md-icon-add-location::before {
    content: 'add_location';
}

.md-icon-beenhere::before {
    content: 'beenhere';
}

.md-icon-directions::before {
    content: 'directions';
}

.md-icon-directions-bike::before {
    content: 'directions_bike';
}

.md-icon-directions-boat::before {
    content: 'directions_boat';
}

.md-icon-directions-bus::before {
    content: 'directions_bus';
}

.md-icon-directions-car::before {
    content: 'directions_car';
}

.md-icon-directions-railway::before {
    content: 'directions_railway';
}

.md-icon-directions-run::before {
    content: 'directions_run';
}

.md-icon-directions-subway::before {
    content: 'directions_subway';
}

.md-icon-directions-transit::before {
    content: 'directions_transit';
}

.md-icon-directions-walk::before {
    content: 'directions_walk';
}

.md-icon-edit-location::before {
    content: 'edit_location';
}

.md-icon-ev-station::before {
    content: 'ev_station';
}

.md-icon-flight::before {
    content: 'flight';
}

.md-icon-hotel::before {
    content: 'hotel';
}

.md-icon-layers::before {
    content: 'layers';
}

.md-icon-layers-clear::before {
    content: 'layers_clear';
}

.md-icon-local-activity::before {
    content: 'local_activity';
}

.md-icon-local-airport::before {
    content: 'local_airport';
}

.md-icon-local-atm::before {
    content: 'local_atm';
}

.md-icon-local-bar::before {
    content: 'local_bar';
}

.md-icon-local-cafe::before {
    content: 'local_cafe';
}

.md-icon-local-car-wash::before {
    content: 'local_car_wash';
}

.md-icon-local-convenience-store::before {
    content: 'local_convenience_store';
}

.md-icon-local-dining::before {
    content: 'local_dining';
}

.md-icon-local-drink::before {
    content: 'local_drink';
}

.md-icon-local-florist::before {
    content: 'local_florist';
}

.md-icon-local-gas-station::before {
    content: 'local_gas_station';
}

.md-icon-local-grocery-store::before {
    content: 'local_grocery_store';
}

.md-icon-local-hospital::before {
    content: 'local_hospital';
}

.md-icon-local-hotel::before {
    content: 'local_hotel';
}

.md-icon-local-laundry-service::before {
    content: 'local_laundry_service';
}

.md-icon-local-library::before {
    content: 'local_library';
}

.md-icon-local-mall::before {
    content: 'local_mall';
}

.md-icon-local-movies::before {
    content: 'local_movies';
}

.md-icon-local-offer::before {
    content: 'local_offer';
}

.md-icon-local-parking::before {
    content: 'local_parking';
}

.md-icon-local-pharmacy::before {
    content: 'local_pharmacy';
}

.md-icon-local-phone::before {
    content: 'local_phone';
}

.md-icon-local-pizza::before {
    content: 'local_pizza';
}

.md-icon-local-play::before {
    content: 'local_play';
}

.md-icon-local-post-office::before {
    content: 'local_post_office';
}

.md-icon-local-printshop::before {
    content: 'local_printshop';
}

.md-icon-local-see::before {
    content: 'local_see';
}

.md-icon-local-shipping::before {
    content: 'local_shipping';
}

.md-icon-local-taxi::before {
    content: 'local_taxi';
}

.md-icon-map::before {
    content: 'map';
}

.md-icon-my-location::before {
    content: 'my_location';
}

.md-icon-navigation::before {
    content: 'navigation';
}

.md-icon-near-me::before {
    content: 'near_me';
}

.md-icon-person-pin::before {
    content: 'person_pin';
}

.md-icon-person-pin-circle::before {
    content: 'person_pin_circle';
}

.md-icon-pin-drop::before {
    content: 'pin_drop';
}

.md-icon-place::before {
    content: 'place';
}

.md-icon-rate-review::before {
    content: 'rate_review';
}

.md-icon-restaurant::before {
    content: 'restaurant';
}

.md-icon-restaurant-menu::before {
    content: 'restaurant_menu';
}

.md-icon-satellite::before {
    content: 'satellite';
}

.md-icon-store-mall-directory::before {
    content: 'store_mall_directory';
}

.md-icon-streetview::before {
    content: 'streetview';
}

.md-icon-subway::before {
    content: 'subway';
}

.md-icon-terrain::before {
    content: 'terrain';
}

.md-icon-traffic::before {
    content: 'traffic';
}

.md-icon-train::before {
    content: 'train';
}

.md-icon-tram::before {
    content: 'tram';
}

.md-icon-transfer-within-a-station::before {
    content: 'transfer_within_a_station';
}

.md-icon-zoom-out-map::before {
    content: 'zoom_out_map';
}

.md-icon-apps::before {
    content: 'apps';
}

.md-icon-arrow-back::before {
    content: 'arrow_back';
}

.md-icon-arrow-downward::before {
    content: 'arrow_downward';
}

.md-icon-arrow-drop-down::before {
    content: 'arrow_drop_down';
}

.md-icon-arrow-drop-down-circle::before {
    content: 'arrow_drop_down_circle';
}

.md-icon-arrow-drop-up::before {
    content: 'arrow_drop_up';
}

.md-icon-arrow-forward::before {
    content: 'arrow_forward';
}

.md-icon-arrow-upward::before {
    content: 'arrow_upward';
}

.md-icon-cancel::before {
    content: 'cancel';
}

.md-icon-check::before {
    content: 'check';
}

.md-icon-chevron-left::before {
    content: 'chevron_left';
}

.md-icon-chevron-right::before {
    content: 'chevron_right';
}

.md-icon-close::before {
    content: 'close';
}

.md-icon-expand-less::before {
    content: 'expand_less';
}

.md-icon-expand-more::before {
    content: 'expand_more';
}

.md-icon-first-page::before {
    content: 'first_page';
}

.md-icon-fullscreen::before {
    content: 'fullscreen';
}

.md-icon-fullscreen-exit::before {
    content: 'fullscreen_exit';
}

.md-icon-last-page::before {
    content: 'last_page';
}

.md-icon-menu::before {
    content: 'menu';
}

.md-icon-more-horiz::before {
    content: 'more_horiz';
}

.md-icon-more-vert::before {
    content: 'more_vert';
}

.md-icon-refresh::before {
    content: 'refresh';
}

.md-icon-subdirectory-arrow-left::before {
    content: 'subdirectory_arrow_left';
}

.md-icon-subdirectory-arrow-right::before {
    content: 'subdirectory_arrow_right';
}

.md-icon-adb::before {
    content: 'adb';
}

.md-icon-airline-seat-flat::before {
    content: 'airline_seat_flat';
}

.md-icon-airline-seat-flat-angled::before {
    content: 'airline_seat_flat_angled';
}

.md-icon-airline-seat-individual-suite::before {
    content: 'airline_seat_individual_suite';
}

.md-icon-airline-seat-legroom-extra::before {
    content: 'airline_seat_legroom_extra';
}

.md-icon-airline-seat-legroom-normal::before {
    content: 'airline_seat_legroom_normal';
}

.md-icon-airline-seat-legroom-reduced::before {
    content: 'airline_seat_legroom_reduced';
}

.md-icon-airline-seat-recline-extra::before {
    content: 'airline_seat_recline_extra';
}

.md-icon-airline-seat-recline-normal::before {
    content: 'airline_seat_recline_normal';
}

.md-icon-bluetooth-audio::before {
    content: 'bluetooth_audio';
}

.md-icon-confirmation-number::before {
    content: 'confirmation_number';
}

.md-icon-disc-full::before {
    content: 'disc_full';
}

.md-icon-do-not-disturb::before {
    content: 'do_not_disturb';
}

.md-icon-do-not-disturb-alt::before {
    content: 'do_not_disturb_alt';
}

.md-icon-do-not-disturb-off::before {
    content: 'do_not_disturb_off';
}

.md-icon-do-not-disturb-on::before {
    content: 'do_not_disturb_on';
}

.md-icon-drive-eta::before {
    content: 'drive_eta';
}

.md-icon-enhanced-encryption::before {
    content: 'enhanced_encryption';
}

.md-icon-event-available::before {
    content: 'event_available';
}

.md-icon-event-busy::before {
    content: 'event_busy';
}

.md-icon-event-note::before {
    content: 'event_note';
}

.md-icon-folder-special::before {
    content: 'folder_special';
}

.md-icon-live-tv::before {
    content: 'live_tv';
}

.md-icon-mms::before {
    content: 'mms';
}

.md-icon-more::before {
    content: 'more';
}

.md-icon-network-check::before {
    content: 'network_check';
}

.md-icon-network-locked::before {
    content: 'network_locked';
}

.md-icon-no-encryption::before {
    content: 'no_encryption';
}

.md-icon-ondemand-video::before {
    content: 'ondemand_video';
}

.md-icon-personal-video::before {
    content: 'personal_video';
}

.md-icon-phone-bluetooth-speaker::before {
    content: 'phone_bluetooth_speaker';
}

.md-icon-phone-forwarded::before {
    content: 'phone_forwarded';
}

.md-icon-phone-in-talk::before {
    content: 'phone_in_talk';
}

.md-icon-phone-locked::before {
    content: 'phone_locked';
}

.md-icon-phone-missed::before {
    content: 'phone_missed';
}

.md-icon-phone-paused::before {
    content: 'phone_paused';
}

.md-icon-power::before {
    content: 'power';
}

.md-icon-priority-high::before {
    content: 'priority_high';
}

.md-icon-sd-card::before {
    content: 'sd_card';
}

.md-icon-sim-card-alert::before {
    content: 'sim_card_alert';
}

.md-icon-sms::before {
    content: 'sms';
}

.md-icon-sms-failed::before {
    content: 'sms_failed';
}

.md-icon-sync::before {
    content: 'sync';
}

.md-icon-sync-disabled::before {
    content: 'sync_disabled';
}

.md-icon-sync-problem::before {
    content: 'sync_problem';
}

.md-icon-system-update::before {
    content: 'system_update';
}

.md-icon-tap-and-play::before {
    content: 'tap_and_play';
}

.md-icon-time-to-leave::before {
    content: 'time_to_leave';
}

.md-icon-vibration::before {
    content: 'vibration';
}

.md-icon-voice-chat::before {
    content: 'voice_chat';
}

.md-icon-vpn-lock::before {
    content: 'vpn_lock';
}

.md-icon-wc::before {
    content: 'wc';
}

.md-icon-wifi::before {
    content: 'wifi';
}

.md-icon-ac-unit::before {
    content: 'ac_unit';
}

.md-icon-airport-shuttle::before {
    content: 'airport_shuttle';
}

.md-icon-all-inclusive::before {
    content: 'all_inclusive';
}

.md-icon-beach-access::before {
    content: 'beach_access';
}

.md-icon-business-center::before {
    content: 'business_center';
}

.md-icon-casino::before {
    content: 'casino';
}

.md-icon-child-care::before {
    content: 'child_care';
}

.md-icon-child-friendly::before {
    content: 'child_friendly';
}

.md-icon-fitness-center::before {
    content: 'fitness_center';
}

.md-icon-free-breakfast::before {
    content: 'free_breakfast';
}

.md-icon-golf-course::before {
    content: 'golf_course';
}

.md-icon-hot-tub::before {
    content: 'hot_tub';
}

.md-icon-kitchen::before {
    content: 'kitchen';
}

.md-icon-pool::before {
    content: 'pool';
}

.md-icon-room-service::before {
    content: 'room_service';
}

.md-icon-rv-hookup::before {
    content: 'rv_hookup';
}

.md-icon-smoke-free::before {
    content: 'smoke_free';
}

.md-icon-smoking-rooms::before {
    content: 'smoking_rooms';
}

.md-icon-spa::before {
    content: 'spa';
}

.md-icon-cake::before {
    content: 'cake';
}

.md-icon-domain::before {
    content: 'domain';
}

.md-icon-group::before {
    content: 'group';
}

.md-icon-group-add::before {
    content: 'group_add';
}

.md-icon-location-city::before {
    content: 'location_city';
}

.md-icon-mood::before {
    content: 'mood';
}

.md-icon-mood-bad::before {
    content: 'mood_bad';
}

.md-icon-notifications::before {
    content: 'notifications';
}

.md-icon-notifications-active::before {
    content: 'notifications_active';
}

.md-icon-notifications-none::before {
    content: 'notifications_none';
}

.md-icon-notifications-off::before {
    content: 'notifications_off';
}

.md-icon-notifications-paused::before {
    content: 'notifications_paused';
}

.md-icon-pages::before {
    content: 'pages';
}

.md-icon-party-mode::before {
    content: 'party_mode';
}

.md-icon-people::before {
    content: 'people';
}

.md-icon-people-outline::before {
    content: 'people_outline';
}

.md-icon-person::before {
    content: 'person';
}

.md-icon-person-add::before {
    content: 'person_add';
}

.md-icon-person-outline::before {
    content: 'person_outline';
}

.md-icon-plus-one::before {
    content: 'plus_one';
}

.md-icon-poll::before {
    content: 'poll';
}

.md-icon-public::before {
    content: 'public';
}

.md-icon-school::before {
    content: 'school';
}

.md-icon-sentiment-dissatisfied::before {
    content: 'sentiment_dissatisfied';
}

.md-icon-sentiment-neutral::before {
    content: 'sentiment_neutral';
}

.md-icon-sentiment-satisfied::before {
    content: 'sentiment_satisfied';
}

.md-icon-sentiment-very-dissatisfied::before {
    content: 'sentiment_very_dissatisfied';
}

.md-icon-sentiment-very-satisfied::before {
    content: 'sentiment_very_satisfied';
}

.md-icon-share::before {
    content: 'share';
}

.md-icon-whatshot::before {
    content: 'whatshot';
}

.md-icon-check-box::before {
    content: 'check_box';
}

.md-icon-check-box-outline-blank::before {
    content: 'check_box_outline_blank';
}

.md-icon-indeterminate-check-box::before {
    content: 'indeterminate_check_box';
}

.md-icon-radio-button-checked::before {
    content: 'radio_button_checked';
}

.md-icon-radio-button-unchecked::before {
    content: 'radio_button_unchecked';
}

.md-icon-star::before {
    content: 'star';
}

.md-icon-star-border::before {
    content: 'star_border';
}

.md-icon-star-half::before {
    content: 'star_half';
}

html,
body {
    font-family: 'Roboto', sans-serif;
    color: #111;
    /* Support for IE */
    font-feature-settings: 'liga';
}

.x-btn-inner {
    text-transform: uppercase;
}

.x-btn-raised {
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.12), 2px 2px 5px 0 rgba(0, 0, 0, 0.08);
}

.x-btn-pressed .x-btn-icon-el,
.x-btn-pressed .x-btn-arrow:after,
.x-btn-menu-active .x-btn-icon-el,
.x-btn-menu-active .x-btn-arrow:after,
.x-btn-focus .x-btn-icon-el,
.x-btn-focus .x-btn-arrow:after {
    color: #fff;
}

.x-btn-button-left .x-btn-icon-el {
    text-align: left;
    padding: 0 8px;
}

.x-btn-button-right .x-btn-icon-el {
    text-align: right;
    padding: 0 8px;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-toolbar-small,
.x-keyboard-mode .x-btn-focus.x-btn-plain-toolbar-small {
    background-color: #42a5f5;
}

.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-small.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-small.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-small.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-small.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-small.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-small.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-medium.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-medium.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-medium.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-medium.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-medium.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-medium.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-large.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-large.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-large.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-large.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-large.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-large.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-small.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-small.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-small.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-small.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-small.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-small.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-medium.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-medium.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-medium.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-large.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-large.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-large.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-large.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-large.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-large.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-small.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-small.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-small.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-small.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-small.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-small.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-medium.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-medium.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-medium.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-medium.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-medium.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-medium.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-large.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-large.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-large.x-btn-split:after,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-large.x-btn-icon-el,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-large.x-btn-inner,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-large.x-btn-split:after {
    color: rgba(0, 0, 0, 0.26);
}

.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-small .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-small .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-small .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-small .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-small .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-small .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-medium .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-medium .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-medium .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-medium .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-medium .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-medium .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-large .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-large .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-icon-el-default-large .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-large .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-large .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-small .x-btn-wrap-default-large .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-small .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-small .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-small .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-small .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-small .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-small .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-medium .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-medium .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-medium .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-large .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-large .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-icon-el-default-large .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-large .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-large .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-medium .x-btn-wrap-default-large .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-small .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-small .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-small .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-small .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-small .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-small .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-medium .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-medium .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-medium .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-medium .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-medium .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-medium .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-large .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-large .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-icon-el-default-large .x-btn-inner-default-large,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-large .x-btn-inner-default-small,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-large .x-btn-inner-default-medium,
.x-btn-disabled.x-split-button.x-btn-default-large .x-btn-wrap-default-large .x-btn-inner-default-large {
    color: rgba(0, 0, 0, 0.26);
}

.x-btn-disabled .x-btn-icon-el,
.x-btn-disabled .x-btn-inner,
.x-btn-disabled .x-btn-arrow:after {
    color: rgba(0, 0, 0, 0.26);
}

.x-btn-disabled.x-btn-default-toolbar-small,
.x-btn-disabled.x-btn-plain-toolbar-small {
    opacity: 1;
}

.x-btn-disabled .x-btn-split-bottom .x-btn-button:after {
    border-bottom: 1px solid rgba(0, 0, 0, 0.26);
}

.x-btn-disabled .x-btn-split-right .x-btn-button:after {
    border-right: 1px solid rgba(0, 0, 0, 0.26);
}

.x-form-file-wrap .x-form-trigger .x-form-trigger-over {
    cursor: initial;
}

.x-form-file-wrap .x-form-trigger-cmp .x-form-file-btn {
    background: #2196f3;
}

.x-form-file-wrap .x-form-trigger-cmp .x-form-file-btn .x-btn-icon-el,
.x-form-file-wrap .x-form-trigger-cmp .x-form-file-btn .x-btn-inner {
    color: #fff;
}

.x-keyboard-mode .x-breadcrumb-btn.x-btn-pressed .x-btn-icon-el,
.x-keyboard-mode .x-breadcrumb-btn.x-btn-pressed .x-btn-arrow:after,
.x-keyboard-mode .x-breadcrumb-btn.x-btn-pressed .x-btn-split:after,
.x-keyboard-mode .x-breadcrumb-btn.x-btn-menu-active .x-btn-icon-el,
.x-keyboard-mode .x-breadcrumb-btn.x-btn-menu-active .x-btn-arrow:after,
.x-keyboard-mode .x-breadcrumb-btn.x-btn-menu-active .x-btn-split:after,
.x-keyboard-mode .x-breadcrumb-btn.x-btn-focus .x-btn-icon-el,
.x-keyboard-mode .x-breadcrumb-btn.x-btn-focus .x-btn-arrow:after,
.x-keyboard-mode .x-breadcrumb-btn.x-btn-focus .x-btn-split:after,
.x-keyboard-mode .x-breadcrumb-btn.x-arrow-focus .x-btn-icon-el,
.x-keyboard-mode .x-breadcrumb-btn.x-arrow-focus .x-btn-arrow:after,
.x-keyboard-mode .x-breadcrumb-btn.x-arrow-focus .x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-btn-plain-toolbar-small.x-arrow-focus {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-btn-plain-toolbar-small.x-arrow-focus .x-btn-inner {
    color: #fff;
}

.x-keyboard-mode .x-btn-plain-toolbar-small.x-arrow-focus .x-btn-arrow-el {
    border: none;
}

.x-form-layout-wrap[id*=segmented] .x-form-item-label,
.x-container[id*=vertical-segmented] .x-form-item-label {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

.x-segmented-button .x-segmented-button-item,
.x-segmented-button .x-split-button.x-btn-default-small,
.x-segmented-button .x-split-button.x-btn-default-small.x-btn-menu-active {
    background-color: #2196f3;
}

.x-segmented-button .x-segmented-button-item .x-btn-icon-el,
.x-segmented-button .x-segmented-button-item .x-btn-arrow-right:after,
.x-segmented-button .x-segmented-button-item .x-btn-split-right:after,
.x-segmented-button .x-segmented-button-item .x-btn-inner,
.x-segmented-button .x-split-button.x-btn-default-small .x-btn-icon-el,
.x-segmented-button .x-split-button.x-btn-default-small .x-btn-arrow-right:after,
.x-segmented-button .x-split-button.x-btn-default-small .x-btn-split-right:after,
.x-segmented-button .x-split-button.x-btn-default-small .x-btn-inner,
.x-segmented-button .x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-icon-el,
.x-segmented-button .x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-arrow-right:after,
.x-segmented-button .x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-split-right:after,
.x-segmented-button .x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-inner {
    color: #fff;
}

.x-segmented-button.x-item-disabled .x-segmented-button-item,
.x-segmented-button.x-item-disabled .x-split-button.x-btn-default-small,
.x-segmented-button.x-item-disabled .x-split-button.x-btn-default-small.x-btn-menu-active {
    background-color: transparent;
}

.x-segmented-button.x-item-disabled .x-segmented-button-item .x-btn-icon-el,
.x-segmented-button.x-item-disabled .x-segmented-button-item .x-btn-arrow-right:after,
.x-segmented-button.x-item-disabled .x-segmented-button-item .x-btn-split-right:after,
.x-segmented-button.x-item-disabled .x-segmented-button-item .x-btn-inner,
.x-segmented-button.x-item-disabled .x-split-button.x-btn-default-small .x-btn-icon-el,
.x-segmented-button.x-item-disabled .x-split-button.x-btn-default-small .x-btn-arrow-right:after,
.x-segmented-button.x-item-disabled .x-split-button.x-btn-default-small .x-btn-split-right:after,
.x-segmented-button.x-item-disabled .x-split-button.x-btn-default-small .x-btn-inner,
.x-segmented-button.x-item-disabled .x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-icon-el,
.x-segmented-button.x-item-disabled .x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-arrow-right:after,
.x-segmented-button.x-item-disabled .x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-split-right:after,
.x-segmented-button.x-item-disabled .x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-inner {
    color: rgba(0, 0, 0, 0.26);
}

.x-segmented-button .x-btn-over.x-btn-default-small {
    background-color: #1976d2;
}

.x-segmented-button .x-btn-focus {
    background-color: #42a5f5;
}

.x-segmented-button .x-btn-pressed {
    background-color: #64b5f6;
}

.x-split-button .x-btn-wrap.x-btn-split-right:before {
    position: absolute;
    content: ' ';
    top: 0;
    left: 0;
    bottom: 0;
}

.x-split-button .x-btn-wrap.x-btn-split-right + .x-btn-arrow-el {
    top: 0;
    width: 0;
    height: 100%;
}

.x-split-button .x-btn-wrap.x-btn-split-right + .x-btn-arrow-el:before {
    content: ' ';
    position: absolute;
    display: block;
    height: 100%;
    top: 0;
}

.x-split-button .x-btn-wrap.x-btn-split-bottom:before {
    position: absolute;
    content: ' ';
    top: 0;
    left: 0;
    right: 0;
}

.x-split-button .x-btn-wrap.x-btn-split-bottom + .x-btn-arrow-el {
    top: 0;
    left: 0;
    right: auto;
    width: 0;
    height: 0;
    clip: rect(0, 0, 0, 0);
}

.x-split-button .x-btn-wrap.x-btn-split-right:after {
    z-index: 1;
}

.x-split-button.x-btn-default-small {
    background-color: transparent;
}

.x-split-button.x-btn-default-small.x-btn-pressed,
.x-split-button.x-btn-default-small.x-btn-menu-active {
    background-color: transparent;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus {
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus .x-btn-arrow-el {
    border-width: 0;
}

.x-split-button.x-btn-default-small .x-btn-wrap-default-small.x-btn-split-right:before {
    right: 33px;
    background-color: transparent;
}

.x-split-button.x-btn-default-small .x-btn-wrap-default-small.x-btn-split-right + .x-btn-arrow-el {
    right: 32px;
}

.x-split-button.x-btn-default-small .x-btn-wrap-default-small.x-btn-split-right + .x-btn-arrow-el:before {
    right: -32px;
    width: 32px;
}

.x-split-button.x-btn-default-small .x-btn-wrap-default-small.x-btn-split-right .x-btn-button:before {
    width: 32px;
    right: -32px;
}

.x-split-button.x-btn-default-small .x-btn-wrap-default-small.x-btn-split-bottom:before {
    bottom: 17px;
    background-color: transparent;
}

.x-split-button.x-btn-default-small .x-btn-wrap-default-small.x-btn-split-bottom:after {
    background-color: transparent;
    margin-left: -16px;
    margin-right: -16px;
}

.x-ie10p .x-split-button.x-btn-default-small .x-btn-wrap-default-small .x-btn-button.x-btn-icon-top,
.x-ie10p .x-split-button.x-btn-default-small .x-btn-wrap-default-small .x-btn-button.x-btn-icon-bottom {
    overflow: visible;
}

.x-split-button.x-btn-default-small.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-small.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-small.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-small .x-btn-wrap-default-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-small.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-small .x-btn-wrap-default-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-wrap-default-small.x-btn-split:before {
    background-color: transparent;
}

.x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-wrap-default-small .x-btn-wrap-default-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-wrap-default-small .x-btn-wrap-default-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-small.x-btn-pressed .x-btn-wrap-default-small.x-btn-split:before {
    background-color: #0c7ce6;
}

.x-split-button.x-btn-default-small.x-btn-pressed .x-btn-wrap-default-small .x-btn-icon-el-default-small {
    color: #fff;
}

.x-split-button.x-btn-default-small.x-btn-pressed .x-btn-wrap-default-small .x-btn-inner-default-small {
    color: #fff;
}

.x-split-button.x-btn-default-small.x-btn-pressed .x-btn-wrap-default-small .x-btn-wrap-default-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-small.x-btn-pressed .x-btn-wrap-default-small .x-btn-wrap-default-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-small.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-small .x-btn-icon-el-default-small {
    color: #fff;
}

.x-split-button.x-btn-default-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-small .x-btn-inner-default-small {
    color: #fff;
}

.x-split-button.x-btn-default-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-small .x-btn-wrap-default-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-small .x-btn-wrap-default-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus .x-btn-wrap-default-small.x-btn-split:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus .x-btn-wrap-default-small .x-btn-icon-el-default-small {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus .x-btn-wrap-default-small .x-btn-inner-default-small {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus .x-btn-wrap-default-small .x-btn-wrap-default-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus .x-btn-wrap-default-small .x-btn-wrap-default-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus.x-btn-pressed .x-btn-wrap-default-small.x-btn-split:before {
    background-color: #6ab8f7;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus.x-btn-pressed .x-btn-wrap-default-small .x-btn-icon-el-default-small {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus.x-btn-pressed .x-btn-wrap-default-small .x-btn-inner-default-small {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus.x-btn-pressed .x-btn-wrap-default-small .x-btn-wrap-default-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus.x-btn-pressed .x-btn-wrap-default-small .x-btn-wrap-default-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-small.x-btn-split:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-split-button.x-btn-default-small.x-btn-over.x-arrow-over .x-btn-wrap-default-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-small.x-btn-over.x-arrow-over .x-btn-wrap-default-small.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-wrap-default-small.x-btn-split:after,
.x-split-button.x-btn-default-small.x-arrow-pressed .x-btn-wrap-default-small.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-wrap-default-small.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-default-small.x-arrow-pressed .x-btn-wrap-default-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #0c7ce6;
}

.x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-wrap-default-small.x-btn-split-bottom:after,
.x-split-button.x-btn-default-small.x-arrow-pressed .x-btn-wrap-default-small.x-btn-split-bottom:after {
    background-color: #0c7ce6;
}

.x-split-button.x-btn-default-small.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-small.x-btn-split:after,
.x-split-button.x-btn-default-small.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-small.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-default-small.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-small.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-default-small.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-small.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-small.x-btn-split-bottom:after,
.x-split-button.x-btn-default-small.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-small.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-wrap-default-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-split-button.x-btn-default-small.x-btn-menu-active .x-btn-wrap-default-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus .x-btn-wrap-default-small.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus .x-btn-wrap-default-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus .x-btn-wrap-default-small.x-btn-split-bottom:after {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-small.x-btn-split:after,
.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-small.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-small.x-btn-split-right + .x-btn-arrow-el:before,
.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #6ab8f7;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-small.x-btn-split-bottom:after,
.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-small.x-btn-split-bottom:after {
    background-color: #6ab8f7;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus.x-btn-pressed .x-btn-wrap-default-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus.x-btn-pressed .x-btn-wrap-default-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-small.x-btn-split-right + .x-btn-arrow-el:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-default-small.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-small.x-btn-split-bottom:after {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-split-button.x-btn-default-medium {
    background-color: transparent;
}

.x-split-button.x-btn-default-medium.x-btn-pressed,
.x-split-button.x-btn-default-medium.x-btn-menu-active {
    background-color: transparent;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus {
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus .x-btn-arrow-el {
    border-width: 0;
}

.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium.x-btn-split-right:before {
    right: 41px;
    background-color: transparent;
}

.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium.x-btn-split-right + .x-btn-arrow-el {
    right: 40px;
}

.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium.x-btn-split-right + .x-btn-arrow-el:before {
    right: -40px;
    width: 40px;
}

.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium.x-btn-split-right .x-btn-button:before {
    width: 40px;
    right: -40px;
}

.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium.x-btn-split-bottom:before {
    bottom: 25px;
    background-color: transparent;
}

.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium.x-btn-split-bottom:after {
    background-color: transparent;
    margin-left: -16px;
    margin-right: -16px;
}

.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium.x-btn-split:after {
    color: #2196f3;
}

.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium .x-btn-inner-default-medium {
    color: #2196f3;
}

.x-split-button.x-btn-default-medium .x-btn-wrap-default-medium .x-btn-icon-el-default-medium {
    color: #2196f3;
}

.x-ie10p .x-split-button.x-btn-default-medium .x-btn-wrap-default-medium .x-btn-button.x-btn-icon-top,
.x-ie10p .x-split-button.x-btn-default-medium .x-btn-wrap-default-medium .x-btn-button.x-btn-icon-bottom {
    overflow: visible;
}

.x-split-button.x-btn-default-medium.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-medium.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-medium.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-medium .x-btn-icon-el-default-medium {
    color: #2196f3;
}

.x-split-button.x-btn-default-medium.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-medium .x-btn-inner-default-medium {
    color: #2196f3;
}

.x-split-button.x-btn-default-medium.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-medium .x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-medium.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-medium .x-btn-wrap-default-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-medium.x-btn-menu-active .x-btn-wrap-default-medium.x-btn-split:before {
    background-color: transparent;
}

.x-split-button.x-btn-default-medium.x-btn-menu-active .x-btn-wrap-default-medium .x-btn-icon-el-default-medium {
    color: #2196f3;
}

.x-split-button.x-btn-default-medium.x-btn-menu-active .x-btn-wrap-default-medium .x-btn-inner-default-medium {
    color: #2196f3;
}

.x-split-button.x-btn-default-medium.x-btn-menu-active .x-btn-wrap-default-medium .x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-medium.x-btn-menu-active .x-btn-wrap-default-medium .x-btn-wrap-default-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-medium.x-btn-pressed .x-btn-wrap-default-medium.x-btn-split:before {
    background-color: #0c7ce6;
}

.x-split-button.x-btn-default-medium.x-btn-pressed .x-btn-wrap-default-medium .x-btn-icon-el-default-medium {
    color: #fff;
}

.x-split-button.x-btn-default-medium.x-btn-pressed .x-btn-wrap-default-medium .x-btn-inner-default-medium {
    color: #fff;
}

.x-split-button.x-btn-default-medium.x-btn-pressed .x-btn-wrap-default-medium .x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-medium.x-btn-pressed .x-btn-wrap-default-medium .x-btn-wrap-default-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-medium.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-medium.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-medium.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-medium .x-btn-icon-el-default-medium {
    color: #fff;
}

.x-split-button.x-btn-default-medium.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-medium .x-btn-inner-default-medium {
    color: #fff;
}

.x-split-button.x-btn-default-medium.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-medium .x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-medium.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-medium .x-btn-wrap-default-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus .x-btn-wrap-default-medium.x-btn-split:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus .x-btn-wrap-default-medium .x-btn-icon-el-default-medium {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus .x-btn-wrap-default-medium .x-btn-inner-default-medium {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus .x-btn-wrap-default-medium .x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus .x-btn-wrap-default-medium .x-btn-wrap-default-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus.x-btn-pressed .x-btn-wrap-default-medium.x-btn-split:before {
    background-color: #6ab8f7;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus.x-btn-pressed .x-btn-wrap-default-medium .x-btn-icon-el-default-medium {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus.x-btn-pressed .x-btn-wrap-default-medium .x-btn-inner-default-medium {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus.x-btn-pressed .x-btn-wrap-default-medium .x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus.x-btn-pressed .x-btn-wrap-default-medium .x-btn-wrap-default-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-medium.x-btn-split:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-split-button.x-btn-default-medium.x-btn-over.x-arrow-over .x-btn-wrap-default-medium.x-btn-split:after {
    color: #2196f3;
}

.x-split-button.x-btn-default-medium.x-btn-over.x-arrow-over .x-btn-wrap-default-medium.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-medium.x-btn-over.x-arrow-over .x-btn-wrap-default-medium.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-medium.x-btn-menu-active .x-btn-wrap-default-medium.x-btn-split:after,
.x-split-button.x-btn-default-medium.x-arrow-pressed .x-btn-wrap-default-medium.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-default-medium.x-btn-menu-active .x-btn-wrap-default-medium.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-default-medium.x-arrow-pressed .x-btn-wrap-default-medium.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #0c7ce6;
}

.x-split-button.x-btn-default-medium.x-btn-menu-active .x-btn-wrap-default-medium.x-btn-split-bottom:after,
.x-split-button.x-btn-default-medium.x-arrow-pressed .x-btn-wrap-default-medium.x-btn-split-bottom:after {
    background-color: #0c7ce6;
}

.x-split-button.x-btn-default-medium.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-medium.x-btn-split:after,
.x-split-button.x-btn-default-medium.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-medium.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-default-medium.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-medium.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-default-medium.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-medium.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-medium.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-medium.x-btn-split-bottom:after,
.x-split-button.x-btn-default-medium.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-medium.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-medium.x-btn-menu-active .x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-split-button.x-btn-default-medium.x-btn-menu-active .x-btn-wrap-default-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus .x-btn-wrap-default-medium.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus .x-btn-wrap-default-medium.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus .x-btn-wrap-default-medium.x-btn-split-bottom:after {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-medium.x-btn-split:after,
.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-medium.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-medium.x-btn-split-right + .x-btn-arrow-el:before,
.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-medium.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #6ab8f7;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-medium.x-btn-split-bottom:after,
.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-medium.x-btn-split-bottom:after {
    background-color: #6ab8f7;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus.x-btn-pressed .x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus.x-btn-pressed .x-btn-wrap-default-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-medium.x-btn-split-right + .x-btn-arrow-el:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-default-medium.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-medium.x-btn-split-bottom:after {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-split-button.x-btn-default-large {
    background-color: transparent;
}

.x-split-button.x-btn-default-large.x-btn-pressed,
.x-split-button.x-btn-default-large.x-btn-menu-active {
    background-color: transparent;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus {
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus .x-btn-arrow-el {
    border-width: 0;
}

.x-split-button.x-btn-default-large .x-btn-wrap-default-large.x-btn-split-right:before {
    right: 49px;
    background-color: transparent;
}

.x-split-button.x-btn-default-large .x-btn-wrap-default-large.x-btn-split-right + .x-btn-arrow-el {
    right: 48px;
}

.x-split-button.x-btn-default-large .x-btn-wrap-default-large.x-btn-split-right + .x-btn-arrow-el:before {
    right: -48px;
    width: 48px;
}

.x-split-button.x-btn-default-large .x-btn-wrap-default-large.x-btn-split-right .x-btn-button:before {
    width: 48px;
    right: -48px;
}

.x-split-button.x-btn-default-large .x-btn-wrap-default-large.x-btn-split-bottom:before {
    bottom: 33px;
    background-color: transparent;
}

.x-split-button.x-btn-default-large .x-btn-wrap-default-large.x-btn-split-bottom:after {
    background-color: transparent;
    margin-left: -16px;
    margin-right: -16px;
}

.x-split-button.x-btn-default-large .x-btn-wrap-default-large.x-btn-split:after {
    color: #2196f3;
}

.x-split-button.x-btn-default-large .x-btn-wrap-default-large .x-btn-inner-default-large {
    color: #2196f3;
}

.x-split-button.x-btn-default-large .x-btn-wrap-default-large .x-btn-icon-el-default-large {
    color: #2196f3;
}

.x-ie10p .x-split-button.x-btn-default-large .x-btn-wrap-default-large .x-btn-button.x-btn-icon-top,
.x-ie10p .x-split-button.x-btn-default-large .x-btn-wrap-default-large .x-btn-button.x-btn-icon-bottom {
    overflow: visible;
}

.x-split-button.x-btn-default-large.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-large.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-large.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-large .x-btn-icon-el-default-large {
    color: #2196f3;
}

.x-split-button.x-btn-default-large.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-large .x-btn-inner-default-large {
    color: #2196f3;
}

.x-split-button.x-btn-default-large.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-large .x-btn-wrap-default-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-large.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-large .x-btn-wrap-default-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-large.x-btn-menu-active .x-btn-wrap-default-large.x-btn-split:before {
    background-color: transparent;
}

.x-split-button.x-btn-default-large.x-btn-menu-active .x-btn-wrap-default-large .x-btn-icon-el-default-large {
    color: #2196f3;
}

.x-split-button.x-btn-default-large.x-btn-menu-active .x-btn-wrap-default-large .x-btn-inner-default-large {
    color: #2196f3;
}

.x-split-button.x-btn-default-large.x-btn-menu-active .x-btn-wrap-default-large .x-btn-wrap-default-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-large.x-btn-menu-active .x-btn-wrap-default-large .x-btn-wrap-default-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-large.x-btn-pressed .x-btn-wrap-default-large.x-btn-split:before {
    background-color: #0c7ce6;
}

.x-split-button.x-btn-default-large.x-btn-pressed .x-btn-wrap-default-large .x-btn-icon-el-default-large {
    color: #fff;
}

.x-split-button.x-btn-default-large.x-btn-pressed .x-btn-wrap-default-large .x-btn-inner-default-large {
    color: #fff;
}

.x-split-button.x-btn-default-large.x-btn-pressed .x-btn-wrap-default-large .x-btn-wrap-default-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-large.x-btn-pressed .x-btn-wrap-default-large .x-btn-wrap-default-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-large.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-large.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-large.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-large .x-btn-icon-el-default-large {
    color: #fff;
}

.x-split-button.x-btn-default-large.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-large .x-btn-inner-default-large {
    color: #fff;
}

.x-split-button.x-btn-default-large.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-large .x-btn-wrap-default-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-large.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-large .x-btn-wrap-default-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus .x-btn-wrap-default-large.x-btn-split:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus .x-btn-wrap-default-large .x-btn-icon-el-default-large {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus .x-btn-wrap-default-large .x-btn-inner-default-large {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus .x-btn-wrap-default-large .x-btn-wrap-default-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus .x-btn-wrap-default-large .x-btn-wrap-default-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus.x-btn-pressed .x-btn-wrap-default-large.x-btn-split:before {
    background-color: #6ab8f7;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus.x-btn-pressed .x-btn-wrap-default-large .x-btn-icon-el-default-large {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus.x-btn-pressed .x-btn-wrap-default-large .x-btn-inner-default-large {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus.x-btn-pressed .x-btn-wrap-default-large .x-btn-wrap-default-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus.x-btn-pressed .x-btn-wrap-default-large .x-btn-wrap-default-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-large.x-btn-split:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-split-button.x-btn-default-large.x-btn-over.x-arrow-over .x-btn-wrap-default-large.x-btn-split:after {
    color: #2196f3;
}

.x-split-button.x-btn-default-large.x-btn-over.x-arrow-over .x-btn-wrap-default-large.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-large.x-btn-over.x-arrow-over .x-btn-wrap-default-large.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-large.x-btn-menu-active .x-btn-wrap-default-large.x-btn-split:after,
.x-split-button.x-btn-default-large.x-arrow-pressed .x-btn-wrap-default-large.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-default-large.x-btn-menu-active .x-btn-wrap-default-large.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-default-large.x-arrow-pressed .x-btn-wrap-default-large.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #0c7ce6;
}

.x-split-button.x-btn-default-large.x-btn-menu-active .x-btn-wrap-default-large.x-btn-split-bottom:after,
.x-split-button.x-btn-default-large.x-arrow-pressed .x-btn-wrap-default-large.x-btn-split-bottom:after {
    background-color: #0c7ce6;
}

.x-split-button.x-btn-default-large.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-large.x-btn-split:after,
.x-split-button.x-btn-default-large.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-large.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-default-large.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-large.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-default-large.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-large.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-large.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-large.x-btn-split-bottom:after,
.x-split-button.x-btn-default-large.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-large.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-large.x-btn-menu-active .x-btn-wrap-default-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-split-button.x-btn-default-large.x-btn-menu-active .x-btn-wrap-default-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus .x-btn-wrap-default-large.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus .x-btn-wrap-default-large.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus .x-btn-wrap-default-large.x-btn-split-bottom:after {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-large.x-btn-split:after,
.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-large.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-large.x-btn-split-right + .x-btn-arrow-el:before,
.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-large.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #6ab8f7;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-large.x-btn-split-bottom:after,
.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-large.x-btn-split-bottom:after {
    background-color: #6ab8f7;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus.x-btn-pressed .x-btn-wrap-default-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus.x-btn-pressed .x-btn-wrap-default-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-large.x-btn-split-right + .x-btn-arrow-el:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-default-large.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-large.x-btn-split-bottom:after {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-split-button.x-btn-default-toolbar-small {
    background-color: transparent;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-pressed,
.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active {
    background-color: transparent;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus {
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus .x-btn-arrow-el {
    border-width: 0;
}

.x-split-button.x-btn-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-right:before {
    right: 33px;
    background-color: transparent;
}

.x-split-button.x-btn-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-right + .x-btn-arrow-el {
    right: 32px;
}

.x-split-button.x-btn-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    right: -32px;
    width: 32px;
}

.x-split-button.x-btn-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-right .x-btn-button:before {
    width: 32px;
    right: -32px;
}

.x-split-button.x-btn-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-bottom:before {
    bottom: 17px;
    background-color: transparent;
}

.x-split-button.x-btn-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-bottom:after {
    background-color: transparent;
    margin-left: -16px;
    margin-right: -16px;
}

.x-split-button.x-btn-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split:after {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-small .x-btn-wrap-default-toolbar-small .x-btn-inner-default-toolbar-small {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-small .x-btn-wrap-default-toolbar-small .x-btn-icon-el-default-toolbar-small {
    color: #2196f3;
}

.x-ie10p .x-split-button.x-btn-default-toolbar-small .x-btn-wrap-default-toolbar-small .x-btn-button.x-btn-icon-top,
.x-ie10p .x-split-button.x-btn-default-toolbar-small .x-btn-wrap-default-toolbar-small .x-btn-button.x-btn-icon-bottom {
    overflow: visible;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-small.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-small.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-small .x-btn-icon-el-default-toolbar-small {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-small .x-btn-inner-default-toolbar-small {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-toolbar-small.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active .x-btn-wrap-default-toolbar-small.x-btn-split:before {
    background-color: transparent;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active .x-btn-wrap-default-toolbar-small .x-btn-icon-el-default-toolbar-small {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active .x-btn-wrap-default-toolbar-small .x-btn-inner-default-toolbar-small {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active .x-btn-wrap-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active .x-btn-wrap-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-toolbar-small.x-btn-pressed .x-btn-wrap-default-toolbar-small.x-btn-split:before {
    background-color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-pressed .x-btn-wrap-default-toolbar-small .x-btn-icon-el-default-toolbar-small {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-pressed .x-btn-wrap-default-toolbar-small .x-btn-inner-default-toolbar-small {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-pressed .x-btn-wrap-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-toolbar-small.x-btn-pressed .x-btn-wrap-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-toolbar-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-small.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-small .x-btn-icon-el-default-toolbar-small {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-small .x-btn-inner-default-toolbar-small {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-toolbar-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus .x-btn-wrap-default-toolbar-small.x-btn-split:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus .x-btn-wrap-default-toolbar-small .x-btn-icon-el-default-toolbar-small {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus .x-btn-wrap-default-toolbar-small .x-btn-inner-default-toolbar-small {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus .x-btn-wrap-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus .x-btn-wrap-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-small.x-btn-split:before {
    background-color: #2196f3;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-small .x-btn-icon-el-default-toolbar-small {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-small .x-btn-inner-default-toolbar-small {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-small .x-btn-wrap-default-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-small.x-btn-split:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-small.x-btn-split:after {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-small.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-small.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active .x-btn-wrap-default-toolbar-small.x-btn-split:after,
.x-split-button.x-btn-default-toolbar-small.x-arrow-pressed .x-btn-wrap-default-toolbar-small.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active .x-btn-wrap-default-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-default-toolbar-small.x-arrow-pressed .x-btn-wrap-default-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active .x-btn-wrap-default-toolbar-small.x-btn-split-bottom:after,
.x-split-button.x-btn-default-toolbar-small.x-arrow-pressed .x-btn-wrap-default-toolbar-small.x-btn-split-bottom:after {
    background-color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-small.x-btn-split:after,
.x-split-button.x-btn-default-toolbar-small.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-small.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-default-toolbar-small.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-small.x-btn-split-bottom:after,
.x-split-button.x-btn-default-toolbar-small.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-small.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active .x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-split-button.x-btn-default-toolbar-small.x-btn-menu-active .x-btn-wrap-default-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus .x-btn-wrap-default-toolbar-small.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus .x-btn-wrap-default-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus .x-btn-wrap-default-toolbar-small.x-btn-split-bottom:after {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-toolbar-small.x-btn-split:after,
.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-toolbar-small.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before,
.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #2196f3;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-toolbar-small.x-btn-split-bottom:after,
.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-toolbar-small.x-btn-split-bottom:after {
    background-color: #2196f3;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus.x-btn-pressed .x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus.x-btn-pressed .x-btn-wrap-default-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-small.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-small.x-btn-split-bottom:after {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-split-button.x-btn-plain-toolbar-small {
    background-color: transparent;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-pressed,
.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active {
    background-color: transparent;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus {
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus .x-btn-arrow-el {
    border-width: 0;
}

.x-split-button.x-btn-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-right:before {
    right: 33px;
    background-color: transparent;
}

.x-split-button.x-btn-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-right + .x-btn-arrow-el {
    right: 32px;
}

.x-split-button.x-btn-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    right: -32px;
    width: 32px;
}

.x-split-button.x-btn-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-right .x-btn-button:before {
    width: 32px;
    right: -32px;
}

.x-split-button.x-btn-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom:before {
    bottom: 17px;
    background-color: transparent;
}

.x-split-button.x-btn-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom:after {
    background-color: transparent;
    margin-left: -16px;
    margin-right: -16px;
}

.x-split-button.x-btn-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split:after {
    color: #2196f3;
}

.x-split-button.x-btn-plain-toolbar-small .x-btn-wrap-plain-toolbar-small .x-btn-inner-plain-toolbar-small {
    color: #2196f3;
}

.x-split-button.x-btn-plain-toolbar-small .x-btn-wrap-plain-toolbar-small .x-btn-icon-el-plain-toolbar-small {
    color: #2196f3;
}

.x-ie10p .x-split-button.x-btn-plain-toolbar-small .x-btn-wrap-plain-toolbar-small .x-btn-button.x-btn-icon-top,
.x-ie10p .x-split-button.x-btn-plain-toolbar-small .x-btn-wrap-plain-toolbar-small .x-btn-button.x-btn-icon-bottom {
    overflow: visible;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-over:not(.x-arrow-over) .x-btn-wrap-plain-toolbar-small.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-over:not(.x-arrow-over) .x-btn-wrap-plain-toolbar-small .x-btn-icon-el-plain-toolbar-small {
    color: #2196f3;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-over:not(.x-arrow-over) .x-btn-wrap-plain-toolbar-small .x-btn-inner-plain-toolbar-small {
    color: #2196f3;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-over:not(.x-arrow-over) .x-btn-wrap-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-over:not(.x-arrow-over) .x-btn-wrap-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active .x-btn-wrap-plain-toolbar-small.x-btn-split:before {
    background-color: transparent;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active .x-btn-wrap-plain-toolbar-small .x-btn-icon-el-plain-toolbar-small {
    color: #2196f3;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active .x-btn-wrap-plain-toolbar-small .x-btn-inner-plain-toolbar-small {
    color: #2196f3;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active .x-btn-wrap-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active .x-btn-wrap-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-pressed .x-btn-wrap-plain-toolbar-small.x-btn-split:before {
    background-color: #2196f3;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-pressed .x-btn-wrap-plain-toolbar-small .x-btn-icon-el-plain-toolbar-small {
    color: #fff;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-pressed .x-btn-wrap-plain-toolbar-small .x-btn-inner-plain-toolbar-small {
    color: #fff;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-pressed .x-btn-wrap-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-pressed .x-btn-wrap-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-plain-toolbar-small.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-plain-toolbar-small .x-btn-icon-el-plain-toolbar-small {
    color: #fff;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-plain-toolbar-small .x-btn-inner-plain-toolbar-small {
    color: #fff;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus .x-btn-wrap-plain-toolbar-small.x-btn-split:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus .x-btn-wrap-plain-toolbar-small .x-btn-icon-el-plain-toolbar-small {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus .x-btn-wrap-plain-toolbar-small .x-btn-inner-plain-toolbar-small {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus .x-btn-wrap-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus .x-btn-wrap-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus.x-btn-pressed .x-btn-wrap-plain-toolbar-small.x-btn-split:before {
    background-color: #2196f3;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus.x-btn-pressed .x-btn-wrap-plain-toolbar-small .x-btn-icon-el-plain-toolbar-small {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus.x-btn-pressed .x-btn-wrap-plain-toolbar-small .x-btn-inner-plain-toolbar-small {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus.x-btn-pressed .x-btn-wrap-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus.x-btn-pressed .x-btn-wrap-plain-toolbar-small .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-plain-toolbar-small.x-btn-split:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-over.x-arrow-over .x-btn-wrap-plain-toolbar-small.x-btn-split:after {
    color: #2196f3;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-over.x-arrow-over .x-btn-wrap-plain-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-over.x-arrow-over .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active .x-btn-wrap-plain-toolbar-small.x-btn-split:after,
.x-split-button.x-btn-plain-toolbar-small.x-arrow-pressed .x-btn-wrap-plain-toolbar-small.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active .x-btn-wrap-plain-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-plain-toolbar-small.x-arrow-pressed .x-btn-wrap-plain-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #2196f3;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom:after,
.x-split-button.x-btn-plain-toolbar-small.x-arrow-pressed .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom:after {
    background-color: #2196f3;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-plain-toolbar-small.x-btn-split:after,
.x-split-button.x-btn-plain-toolbar-small.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-plain-toolbar-small.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-plain-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-plain-toolbar-small.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-plain-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom:after,
.x-split-button.x-btn-plain-toolbar-small.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active .x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-split-button.x-btn-plain-toolbar-small.x-btn-menu-active .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus .x-btn-wrap-plain-toolbar-small.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus .x-btn-wrap-plain-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom:after {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus.x-btn-menu-active .x-btn-wrap-plain-toolbar-small.x-btn-split:after,
.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus.x-arrow-pressed .x-btn-wrap-plain-toolbar-small.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus.x-btn-menu-active .x-btn-wrap-plain-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before,
.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus.x-arrow-pressed .x-btn-wrap-plain-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #2196f3;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus.x-btn-menu-active .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom:after,
.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus.x-arrow-pressed .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom:after {
    background-color: #2196f3;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus.x-btn-pressed .x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus.x-btn-pressed .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-plain-toolbar-small.x-btn-split-right + .x-btn-arrow-el:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-plain-toolbar-small.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-plain-toolbar-small.x-btn-split-bottom:after {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-split-button.x-btn-default-toolbar-medium {
    background-color: transparent;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-pressed,
.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active {
    background-color: transparent;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus {
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus .x-btn-arrow-el {
    border-width: 0;
}

.x-split-button.x-btn-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-right:before {
    right: 41px;
    background-color: transparent;
}

.x-split-button.x-btn-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-right + .x-btn-arrow-el {
    right: 40px;
}

.x-split-button.x-btn-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-right + .x-btn-arrow-el:before {
    right: -40px;
    width: 40px;
}

.x-split-button.x-btn-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-right .x-btn-button:before {
    width: 40px;
    right: -40px;
}

.x-split-button.x-btn-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom:before {
    bottom: 25px;
    background-color: transparent;
}

.x-split-button.x-btn-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom:after {
    background-color: transparent;
    margin-left: -16px;
    margin-right: -16px;
}

.x-split-button.x-btn-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split:after {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-medium .x-btn-wrap-default-toolbar-medium .x-btn-inner-default-toolbar-medium {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-medium .x-btn-wrap-default-toolbar-medium .x-btn-icon-el-default-toolbar-medium {
    color: #2196f3;
}

.x-ie10p .x-split-button.x-btn-default-toolbar-medium .x-btn-wrap-default-toolbar-medium .x-btn-button.x-btn-icon-top,
.x-ie10p .x-split-button.x-btn-default-toolbar-medium .x-btn-wrap-default-toolbar-medium .x-btn-button.x-btn-icon-bottom {
    overflow: visible;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-medium.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-medium .x-btn-icon-el-default-toolbar-medium {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-medium .x-btn-inner-default-toolbar-medium {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active .x-btn-wrap-default-toolbar-medium.x-btn-split:before {
    background-color: transparent;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active .x-btn-wrap-default-toolbar-medium .x-btn-icon-el-default-toolbar-medium {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active .x-btn-wrap-default-toolbar-medium .x-btn-inner-default-toolbar-medium {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active .x-btn-wrap-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active .x-btn-wrap-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-pressed .x-btn-wrap-default-toolbar-medium.x-btn-split:before {
    background-color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-pressed .x-btn-wrap-default-toolbar-medium .x-btn-icon-el-default-toolbar-medium {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-pressed .x-btn-wrap-default-toolbar-medium .x-btn-inner-default-toolbar-medium {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-pressed .x-btn-wrap-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-pressed .x-btn-wrap-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-medium.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-medium .x-btn-icon-el-default-toolbar-medium {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-medium .x-btn-inner-default-toolbar-medium {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus .x-btn-wrap-default-toolbar-medium.x-btn-split:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus .x-btn-wrap-default-toolbar-medium .x-btn-icon-el-default-toolbar-medium {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus .x-btn-wrap-default-toolbar-medium .x-btn-inner-default-toolbar-medium {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus .x-btn-wrap-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus .x-btn-wrap-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-medium.x-btn-split:before {
    background-color: #2196f3;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-medium .x-btn-icon-el-default-toolbar-medium {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-medium .x-btn-inner-default-toolbar-medium {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-medium .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-medium.x-btn-split:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-medium.x-btn-split:after {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-medium.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active .x-btn-wrap-default-toolbar-medium.x-btn-split:after,
.x-split-button.x-btn-default-toolbar-medium.x-arrow-pressed .x-btn-wrap-default-toolbar-medium.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active .x-btn-wrap-default-toolbar-medium.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-default-toolbar-medium.x-arrow-pressed .x-btn-wrap-default-toolbar-medium.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom:after,
.x-split-button.x-btn-default-toolbar-medium.x-arrow-pressed .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom:after {
    background-color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-medium.x-btn-split:after,
.x-split-button.x-btn-default-toolbar-medium.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-medium.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-medium.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-default-toolbar-medium.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-medium.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom:after,
.x-split-button.x-btn-default-toolbar-medium.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active .x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-split-button.x-btn-default-toolbar-medium.x-btn-menu-active .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus .x-btn-wrap-default-toolbar-medium.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus .x-btn-wrap-default-toolbar-medium.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom:after {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-toolbar-medium.x-btn-split:after,
.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-toolbar-medium.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-toolbar-medium.x-btn-split-right + .x-btn-arrow-el:before,
.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-toolbar-medium.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #2196f3;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom:after,
.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom:after {
    background-color: #2196f3;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus.x-btn-pressed .x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus.x-btn-pressed .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-medium.x-btn-split-right + .x-btn-arrow-el:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-medium.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-medium.x-btn-split-bottom:after {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-split-button.x-btn-default-toolbar-large {
    background-color: transparent;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-pressed,
.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active {
    background-color: transparent;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus {
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus .x-btn-arrow-el {
    border-width: 0;
}

.x-split-button.x-btn-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-right:before {
    right: 49px;
    background-color: transparent;
}

.x-split-button.x-btn-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-right + .x-btn-arrow-el {
    right: 48px;
}

.x-split-button.x-btn-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-right + .x-btn-arrow-el:before {
    right: -48px;
    width: 48px;
}

.x-split-button.x-btn-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-right .x-btn-button:before {
    width: 48px;
    right: -48px;
}

.x-split-button.x-btn-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-bottom:before {
    bottom: 33px;
    background-color: transparent;
}

.x-split-button.x-btn-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-bottom:after {
    background-color: transparent;
    margin-left: -16px;
    margin-right: -16px;
}

.x-split-button.x-btn-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split:after {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-large .x-btn-wrap-default-toolbar-large .x-btn-inner-default-toolbar-large {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-large .x-btn-wrap-default-toolbar-large .x-btn-icon-el-default-toolbar-large {
    color: #2196f3;
}

.x-ie10p .x-split-button.x-btn-default-toolbar-large .x-btn-wrap-default-toolbar-large .x-btn-button.x-btn-icon-top,
.x-ie10p .x-split-button.x-btn-default-toolbar-large .x-btn-wrap-default-toolbar-large .x-btn-button.x-btn-icon-bottom {
    overflow: visible;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-large.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-large.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-large .x-btn-icon-el-default-toolbar-large {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-large .x-btn-inner-default-toolbar-large {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-toolbar-large.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active .x-btn-wrap-default-toolbar-large.x-btn-split:before {
    background-color: transparent;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active .x-btn-wrap-default-toolbar-large .x-btn-icon-el-default-toolbar-large {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active .x-btn-wrap-default-toolbar-large .x-btn-inner-default-toolbar-large {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active .x-btn-wrap-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active .x-btn-wrap-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-split-button.x-btn-default-toolbar-large.x-btn-pressed .x-btn-wrap-default-toolbar-large.x-btn-split:before {
    background-color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-pressed .x-btn-wrap-default-toolbar-large .x-btn-icon-el-default-toolbar-large {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-pressed .x-btn-wrap-default-toolbar-large .x-btn-inner-default-toolbar-large {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-pressed .x-btn-wrap-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-toolbar-large.x-btn-pressed .x-btn-wrap-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-toolbar-large.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-large.x-btn-split:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-large.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-large .x-btn-icon-el-default-toolbar-large {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-large .x-btn-inner-default-toolbar-large {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: #fff;
    content: '';
}

.x-split-button.x-btn-default-toolbar-large.x-btn-pressed.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #fff;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus .x-btn-wrap-default-toolbar-large.x-btn-split:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus .x-btn-wrap-default-toolbar-large .x-btn-icon-el-default-toolbar-large {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus .x-btn-wrap-default-toolbar-large .x-btn-inner-default-toolbar-large {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus .x-btn-wrap-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus .x-btn-wrap-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-large.x-btn-split:before {
    background-color: #2196f3;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-large .x-btn-icon-el-default-toolbar-large {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-large .x-btn-inner-default-toolbar-large {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus.x-btn-pressed .x-btn-wrap-default-toolbar-large .x-btn-wrap-default-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: #2196f3;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-btn-focus.x-btn-over:not(.x-arrow-over) .x-btn-wrap-default-toolbar-large.x-btn-split:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-large.x-btn-split:after {
    color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-large.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-large.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-large.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active .x-btn-wrap-default-toolbar-large.x-btn-split:after,
.x-split-button.x-btn-default-toolbar-large.x-arrow-pressed .x-btn-wrap-default-toolbar-large.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active .x-btn-wrap-default-toolbar-large.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-default-toolbar-large.x-arrow-pressed .x-btn-wrap-default-toolbar-large.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active .x-btn-wrap-default-toolbar-large.x-btn-split-bottom:after,
.x-split-button.x-btn-default-toolbar-large.x-arrow-pressed .x-btn-wrap-default-toolbar-large.x-btn-split-bottom:after {
    background-color: #2196f3;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-large.x-btn-split:after,
.x-split-button.x-btn-default-toolbar-large.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-large.x-btn-split:after {
    color: #fff;
}

.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-large.x-btn-split-right + .x-btn-arrow-el:before,
.x-split-button.x-btn-default-toolbar-large.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-large.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-large.x-btn-split-bottom:after,
.x-split-button.x-btn-default-toolbar-large.x-arrow-pressed.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-large.x-btn-split-bottom:after {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active .x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-split-button.x-btn-default-toolbar-large.x-btn-menu-active .x-btn-wrap-default-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus .x-btn-wrap-default-toolbar-large.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus .x-btn-wrap-default-toolbar-large.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus .x-btn-wrap-default-toolbar-large.x-btn-split-bottom:after {
    background-color: #42a5f5;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-toolbar-large.x-btn-split:after,
.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-toolbar-large.x-btn-split:after {
    color: #fff;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-toolbar-large.x-btn-split-right + .x-btn-arrow-el:before,
.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-toolbar-large.x-btn-split-right + .x-btn-arrow-el:before {
    background-color: #2196f3;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus.x-btn-menu-active .x-btn-wrap-default-toolbar-large.x-btn-split-bottom:after,
.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus.x-arrow-pressed .x-btn-wrap-default-toolbar-large.x-btn-split-bottom:after {
    background-color: #2196f3;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus.x-btn-pressed .x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus.x-btn-pressed .x-btn-wrap-default-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom-color: transparent;
    content: '';
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-large.x-btn-split-right + .x-btn-arrow-el:before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-split-button.x-btn-default-toolbar-large.x-arrow-focus.x-btn-over.x-arrow-over .x-btn-wrap-default-toolbar-large.x-btn-split-bottom:after {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-tip-header .x-tool-img {
    color: #111;
}

.x-toolbar.x-toolbar-docked-top,
.x-toolbar.x-toolbar-docked-left,
.x-toolbar.x-toolbar-docked-right {
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.12), 2px 2px 5px 0 rgba(0, 0, 0, 0.08);
}

.x-toolbar-default-docked-bottom {
    background-color: #fafafa;
}

.x-toolbar-default-docked-bottom .x-form-text-field-body-default {
    min-width: 50px;
    max-width: none;
}

.x-docked {
    z-index: 4;
}

.x-window {
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.12), 2px 2px 5px 0 rgba(0, 0, 0, 0.08);
}

.x-window .x-window-header .x-tool-close {
    color: #2196f3;
}

.x-form-item-no-label .x-form-empty-field::-webkit-input-placeholder,
.x-form-item-no-label .x-form-empty-field::-moz-placeholder,
.x-form-item-no-label .x-form-empty-field::-ms-input-placeholder {
    opacity: 1;
}

.x-form-empty-field::-webkit-input-placeholder,
.x-form-empty-field::-moz-placeholder,
.x-form-empty-field::-ms-input-placeholder,
.x-form-empty-field::-moz-placeholder {
    /* Chrome/Opera/Safari/Firefox 19+/IE 10+/Firefox 18- */
    /* Chrome/Opera/Safari/Firefox 19+/IE 10+/Firefox 18- */
    opacity: 0;
}

.x-form-empty-field::focus::-webkit-input-placeholder,
.x-form-empty-field::focus::-moz-placeholder,
.x-form-empty-field::focus::-ms-input-placeholder {
    opacity: 1;
    transition: opacity 0.4s;
}

.x-form-type-text .x-form-item-label,
.x-form-type-password .x-form-item-label {
    margin-top: 4px;
    position: relative;
    height: 16px;
    line-height: 0;
}

.x-form-type-text .x-form-trigger-default,
.x-form-type-password .x-form-trigger-default {
    border-bottom: 1px solid #9e9e9e;
}

.x-form-type-text .x-form-text-default,
.x-form-type-password .x-form-text-default {
    font: 400 13px/16px 'Nunito Sans', 'Open Sans', sans-serif;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    outline: none;
    width: 100%;
    padding: 0;
    box-shadow: none;
    box-sizing: content-box;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.x-form-type-text .x-form-file-wrap .x-form-trigger-wrap .x-form-text,
.x-form-type-password .x-form-file-wrap .x-form-trigger-wrap .x-form-text {
    height: 32px;
    border: none;
    border-bottom: 1px solid #9e9e9e;
}

.x-form-type-text .x-form-trigger-wrap-default,
.x-form-type-password .x-form-trigger-wrap-default {
    border: none;
}

.x-form-type-text .x-form-trigger-wrap-invalid .x-form-text-default,
.x-form-type-password .x-form-trigger-wrap-invalid .x-form-text-default {
    -webkit-transition-duration: 0.7s;
    -moz-transition-duration: 0.7s;
    -o-transition-duration: 0.7s;
    transition-duration: 0.7s;
    border-bottom: 1px solid #c62828;
}

.x-webkit .x-form-type-text .x-form-text,
.x-webkit .x-form-type-password .x-form-text {
    height: calc(100% - 21px);
}

.x-form-type-text .x-form-type-text,
.x-form-type-password .x-form-type-text {
    position: relative;
    margin-top: 17px;
}

.x-colorpicker-field input {
    padding-left: 32px !important;
}

.x-form-readonly .x-form-item-label,
.x-hbox-form-item .x-form-item-label,
.x-form-fieldcontainer .x-form-item-label,
.x-colorpicker-field .x-form-item-label,
.x-form-item[id*=slider] .x-form-item-label,
.x-field-focus .x-form-item-label {
    -webkit-transform: translateY(8px);
    -moz-transform: translateY(8px);
    -ms-transform: translateY(8px);
    -o-transform: translateY(8px);
    transform: translateY(8px);
    font-size: 12px;
}

.x-form-type-checkbox .x-form-item-label,
.x-form-type-radio .x-form-item-label,
.x-form-checkboxgroup .x-form-item-label {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    font-size: 12px;
}

.x-form-item-label.x-form-item-label-right,
.x-form-item-label.x-form-item-label-left {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

.x-field-focus .x-form-item-label.x-form-item-label-right,
.x-field-focus .x-form-item-label.x-form-item-label-left {
    font-size: 13px;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

.x-form-item-label {
    -webkit-transition: -webkit-all ease-out;
    -webkit-transition-delay: 0s;
    -moz-transition: -moz-all ease-out;
    -o-transition: -o-all ease-out;
    transition: all 0s ease-out 0s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transform: translateY(32px);
    -moz-transform: translateY(32px);
    -ms-transform: translateY(32px);
    -o-transform: translateY(32px);
    transform: translateY(32px);
}

.x-field-focus .x-form-item-label,
.not-empty .x-form-item-label {
    font-size: 12px;
    -webkit-transform: translateY(8px);
    -moz-transform: translateY(8px);
    -ms-transform: translateY(8px);
    -o-transform: translateY(8px);
    transform: translateY(8px);
}

.x-field-focus .x-form-item-label {
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    color: #2196f3;
}

.x-field-focus .x-form-item-label {
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.x-field-focus .x-form-text-default,
.x-field-focus .x-form-trigger-default {
    -webkit-transition-duration: 0.7s;
    -moz-transition-duration: 0.7s;
    -o-transition-duration: 0.7s;
    transition-duration: 0.7s;
    border-bottom: 1px solid #2196f3;
}

.x-form-item-label-default.x-form-item-label-top > .x-form-item-label-inner {
    padding: 0;
}

.x-form-item-label-default {
    padding: 0;
    vertical-align: middle;
}

.x-form-text-field-body-default {
    min-width: 150px;
    max-width: none;
}

.x-form-item-body {
    display: block;
}

.x-form-checkbox,
.x-tree-checkbox,
.x-grid-checkcolumn,
.x-column-header-checkbox {
    font-size: 18px;
}

.x-form-cb-checked .x-form-checkbox,
.x-form-cb-checked .x-form-radio {
    color: #2196f3;
}

.x-fieldset {
    background: #fff;
}

.x-form-radio {
    font-size: 18px;
}

.x-keyboard-mode .x-view-item-focused {
    outline: 1px solid #2196f3;
    outline-offset: -1px;
}

.x-datepicker {
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.12), 2px 2px 5px 0 rgba(0, 0, 0, 0.08);
}

.x-datepicker .x-datepicker-disabled .x-datepicker-date {
    border-radius: 50%;
}

.x-datepicker .x-datepicker-selected .x-datepicker-date {
    border-radius: 50%;
    padding: 0 15px 0 13px;
}

.x-datepicker .x-datepicker-prevday {
    visibility: hidden;
}

.x-datepicker .x-datepicker-column-header {
    color: #919191;
}

.x-tip {
    -webkit-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.18), 2px 2px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.18), 2px 2px 5px 0 rgba(0, 0, 0, 0.12);
}

.x-tagfield-item:hover .x-tagfield-item-close {
    color: #fff;
}

.x-grid-cell {
    position: relative;
}

.x-grid-item {
    font: 400 13px/16px 'Nunito Sans', 'Open Sans', sans-serif;
}

.x-column-header-sort-ASC .x-column-header-text-inner:after,
.x-column-header-sort-DESC .x-column-header-text-inner:after {
    vertical-align: bottom;
}

.x-grid-item-selected .x-grid-checkcolumn:after,
.x-grid-cell-selected .x-grid-checkcolumn:after {
    color: #2196f3;
}

.x-grid-checkcolumn-checked:after {
    color: #2196f3;
}

.x-menu-item-arrow {
    top: 8px;
    right: 5px;
}

.x-menu-item-focus .x-menu-item-text,
.x-menu-item-active .x-menu-item-text {
    font-weight: 400;
}

.x-menu-item-focus .x-menu-item-arrow-default,
.x-menu-item-active .x-menu-item-arrow-default {
    top: 8px;
    right: 5px;
}

.x-menu-item-checked .x-menu-item-icon-default.x-menu-item-checkbox {
    color: #2196f3;
}

.x-grid-editor {
    background-color: #fff;
}

.x-grid-editor .x-form-item-body {
    padding: 9px;
}

.x-grid-editor .x-form-type-text.x-form-readonly .x-form-item-body {
    border-bottom: 0;
    padding: 0 9px;
}

.x-grid-editor .x-form-type-text .x-form-item-body {
    border-bottom: 2px solid #2196f3;
}

.x-grid-editor .x-form-type-text .x-form-item-body .x-form-text,
.x-grid-editor .x-form-type-text .x-form-item-body .x-form-trigger {
    border-bottom: none;
}

.x-grid-row-editor .x-panel-body {
    background: #fff;
}

.x-grid-row-editor-wrap,
.x-grid-row-editor-buttons {
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.12), 2px 2px 5px 0 rgba(0, 0, 0, 0.08);
}

.x-grid-row-editor-wrap .x-form-text-field-body-default,
.x-grid-row-editor-buttons .x-form-text-field-body-default {
    min-width: 70px;
    max-width: none;
}

.x-grid-row-expander {
    font-size: 18px;
}

.x-item-disabled {
    opacity: 0.8;
}

.x-item-disabled .x-slider-thumb {
    background-color: #e1e1e1;
}

.x-tab-default {
    text-transform: uppercase;
}

.x-keyboard-mode .x-tab-focus.x-tab-active.x-tab-default {
    background-color: #2196f3;
}

.x-tree-checkbox-checked {
    color: #2196f3;
}

.x-multiselector-search-input {
    background: #fff;
    padding: 8px;
    border: 1px solid #2196f3;
}

.x-surface,
.x-surface-canvas {
    position: absolute;
}

.x-draw-container {
    position: relative;
    cursor: default;
}

.x-legend {
    background: #fff;
    outline: none;
}

.x-legend-horizontal {
    overflow-x: auto;
    overflow-y: hidden;
}

.x-legend-horizontal .x-legend-item {
    display: inline-block;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.x-legend-horizontal .x-legend-item:first-child {
    border-left: 1px solid #ccc;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.x-legend-horizontal .x-legend-item:last-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.x-legend-horizontal.x-rtl .x-legend-item:first-child {
    border-right: 1px solid #ccc;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.x-legend-horizontal.x-rtl .x-legend-item:last-child {
    border-right: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.x-legend-vertical {
    overflow-x: hidden;
    overflow-y: auto;
}

.x-legend-vertical .x-legend-item {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.x-legend-vertical .x-legend-item:first-child {
    border-top: 1px solid #ccc;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.x-legend-vertical .x-legend-item:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.x-legend-item-inactive {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.3;
}

.x-legend-item {
    padding: 0.8em 0.6em 0.8em 1.8em;
    color: #333;
    background: rgba(255, 255, 255, 0);
    max-width: 16em;
    min-width: 0;
    font-size: 13px;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 13px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.x-rtl > * > .x-legend-item {
    padding: 0.8em 1.8em 0.8em 1em;
}

.x-legend-item-marker {
    position: absolute;
    width: 0.8em;
    height: 0.8em;
    -webkit-box-shadow: rgba(255, 255, 255, 0.3) 0 1px 0, rgba(0, 0, 0, 0.4) 0 1px 0 inset;
    -moz-box-shadow: rgba(255, 255, 255, 0.3) 0 1px 0, rgba(0, 0, 0, 0.4) 0 1px 0 inset;
    box-shadow: rgba(255, 255, 255, 0.3) 0 1px 0, rgba(0, 0, 0, 0.4) 0 1px 0 inset;
    left: 0.7em;
    top: 0.85em;
}

.x-rtl > * > * > .x-legend-item-marker {
    right: 0.7em;
}

.x-legend-inner {
    display: table;
    text-align: center;
    padding: 10px;
}

.x-legend-horizontal .x-legend-inner {
    min-width: 100%;
}

.x-legend-vertical .x-legend-inner {
    min-height: 100%;
}

.x-legend-container {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
    line-height: 0;
    background: #fff;
    -webkit-box-shadow: rgba(255, 255, 255, 0.6) 0 1px 1px;
    -moz-box-shadow: rgba(255, 255, 255, 0.6) 0 1px 1px;
    box-shadow: rgba(255, 255, 255, 0.6) 0 1px 1px;
}

.x-chart-image {
    width: 100%;
    height: auto;
}

#poweredby {
    /*!
* Ext JS Library 4.0
* Copyright(c) 2006-2011 Sencha Inc.
* licensing@sencha.com
* http://www.sencha.com/license
*/
    position: absolute;
    bottom: 40px;
    right: 20px;
    z-index: 15000;
    /* IE 5-7 */
    filter: opacity=70;
    /* Netscape */
    -moz-opacity: 0.7;
    /* Safari 1.x */
    -khtml-opacity: 0.7;
    /* Good browsers */
    opacity: 0.7;
}

#poweredby div {
    position: relative;
    width: 150px;
    height: 25px;
    background-image: url(./../images/powered-by-ext-js.png);
    background-repeat: no-repeat;
}

.x-ie6 #poweredby div,
.x-ie7 #poweredby div,
.x-ie8 #poweredby div {
    /* The simple background image PNG does not work in IE6-8, but does in IE9 */
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/powered-by-ext-js.png', sizingMethod='scale');
}

.ux-start-button-icon {
    /*----------------------------------------------------
    Start Button
*/
    background-image: url(./../images/taskbar/sencha.gif) !important;
    background-color: transparent;
}

.ux-start-button .x-btn-arrow {
    background-image: none;
}

.ux-taskbar {
    padding-left: 4px !important;
}

.ux-desktop-black .ux-start-button {
    /*
    Start Button (Black)
*/
    height: 28px;
    padding: 0;
    display: block;
}

.ux-desktop-black .ux-start-button em {
    margin-top: 5px;
}

.ux-desktop-black .ux-start-button .x-btn-inner {
    color: black;
    font-size: 11px;
    font-weight: bold;
}

.ux-desktop-black .ux-start-button {
    background-image: -moz-linear-gradient(center top, #e4f3ff, #d9edff 48%, #c2d8f2 52%, #c6dcf6);
    border-radius: 8px 8px 8px 8px;
    border-color: #81a4d0;
}

.ux-start-menu-toolbar .x-btn-inner {
    /*----------------------------------------------------
    Start Menu
*/
    text-align: left;
}

.ux-start-menu-toolbar {
    padding: 4px !important;
}

div.ux-taskbar div.x-toolbar {
    background: transparent none;
    border: 0 none;
    padding: 0;
}

.ux-desktop-windowbar .x-btn-inner,
.x-nbr .ux-desktop-windowbar .x-btn-icon-text-left button {
    text-align: left !important;
}

.ux-desktop-black .ux-taskbar {
    background: transparent url('./../images/taskbar/black/taskbar-start-panel-bg.gif') repeat-x left top;
    border-color: transparent;
}

.ux-desktop-black .ux-taskbar .x-splitter {
    background: transparent url(./../images/taskbar/black/taskbar-split-h.gif) no-repeat scroll 0 -2px;
}

.ux-desktop-black .ux-taskbar .x-toolbar-text {
    color: white !important;
}

.ux-desktop-black .ux-taskbar .x-btn-default-toolbar-small-over {
    background-color: yellow;
}

.ux-desktop-black .ux-taskbar .x-btn-inner {
    color: white;
}

.ux-desktop-black .ux-taskbar .x-btn-pressed .x-btn-inner,
.ux-taskbar .x-btn-over .x-btn-inner {
    color: black;
}

.ux-desktop-trayclock {
    text-align: center;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
}

.ux-wallpaper {
    background-color: #3d71b8;
}

.ux-wallpaper-tiled {
    background-repeat: repeat;
}

.ux-desktop-shortcut {
    cursor: pointer;
    text-align: center;
    padding: 8px;
    margin: 8px;
    width: 64px;
}

.ux-desktop-shortcut-icon {
    width: 48px;
    height: 48px;
    background-color: transparent;
    background-repeat: no-repeat;
}

.ux-desktop-shortcut-text {
    font: normal 10px tahoma, arial, verdana, sans-serif;
    text-decoration: none;
    padding-top: 5px;
    color: white;
}

.x-view-over .ux-desktop-shortcut-text {
    text-decoration: underline;
}

.accordion-shortcut {
    background-image: url(./../images/im48x48.png);
}

.cpu-shortcut {
    background-image: url(./../images/statusLarge.png);
}

.grid-shortcut {
    background-image: url(./../images/grid48x48.png);
}

.notepad-shortcut {
    background-image: url(./../images/notepadLarge.png);
    width: 46px;
    height: 46px;
}

.x-ie6 .accordion-shortcut {
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/im48x48.png', sizingMethod='scale');
}

.x-ie6 .cpu-shortcut {
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/statusLarge.png', sizingMethod='scale');
}

.x-ie6 .grid-shortcut {
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/grid48x48.png', sizingMethod='scale');
}

.x-ie6 .notepad-shortcut {
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/notepadLarge.png', sizingMethod='scale');
}

.video {
    background-image: url(./../images/gears.gif);
}

.bogus {
    background-image: url(./../images/bogus.png) !important;
}

.notepad {
    background-image: url(./../images/bogus.png) !important;
}

.logout {
    background-image: url(./../images/logout.gif) !important;
}

.settings {
    background-image: url(./../images/gears.gif) !important;
}

.icon-grid {
    background-image: url(./../images/grid.png) !important;
}

.accordion {
    background-image: url(./../images/accordian.gif) !important;
}

.tabs {
    background-image: url(./../images/tabs.gif) !important;
}

#poweredby {
    position: absolute;
    bottom: 40px;
    right: 20px;
    z-index: 15000;
    filter: opacity=70;
    -moz-opacity: 0.7;
    -khtml-opacity: 0.7;
    opacity: 0.7;
}

#poweredby div {
    position: relative;
    width: 150px;
    height: 25px;
    background-image: url(./../images/powered-by-ext-js.png);
    background-repeat: no-repeat;
}

.x-ie6 #poweredby div,
.x-ie7 #poweredby div,
.x-ie8 #poweredby div {
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/powered-by-ext-js.png', sizingMethod='scale');
}

.ux-start-button-icon {
    background-image: url(./../images/taskbar/sencha.gif) !important;
    background-color: transparent;
}

.ux-start-button .x-btn-arrow {
    background-image: none;
}

.ux-taskbar {
    padding-left: 4px !important;
}

.ux-desktop-black .ux-start-button {
    height: 28px;
    padding: 0;
    display: block;
}

.ux-desktop-black .ux-start-button em {
    margin-top: 5px;
}

.ux-desktop-black .ux-start-button .x-btn-inner {
    color: black;
    font-size: 11px;
    font-weight: bold;
}

.ux-desktop-black .ux-start-button {
    background-image: -moz-linear-gradient(center top, #e4f3ff, #d9edff 48%, #c2d8f2 52%, #c6dcf6);
    border-radius: 8px 8px 8px 8px;
    border-color: #81a4d0;
}

.ux-start-menu-toolbar .x-btn-inner {
    text-align: left;
}

.ux-start-menu-toolbar {
    padding: 4px !important;
}

div.ux-taskbar div.x-toolbar {
    background: transparent none;
    border: 0 none;
    padding: 0;
}

.ux-desktop-windowbar .x-btn-inner,
.x-nbr .ux-desktop-windowbar .x-btn-icon-text-left button {
    text-align: left !important;
}

.ux-desktop-black .ux-taskbar {
    background: transparent url('./../images/taskbar/black/taskbar-start-panel-bg.gif') repeat-x left top;
    border-color: transparent;
}

.ux-desktop-black .ux-taskbar .x-splitter {
    background: transparent url(./../images/taskbar/black/taskbar-split-h.gif) no-repeat scroll 0 -2px;
}

.ux-desktop-black .ux-taskbar .x-toolbar-text {
    color: white !important;
}

.ux-desktop-black .ux-taskbar .x-btn-default-toolbar-small-over {
    background-color: yellow;
}

.ux-desktop-black .ux-taskbar .x-btn-inner {
    color: white;
}

.ux-desktop-black .ux-taskbar .x-btn-pressed .x-btn-inner,
.ux-taskbar .x-btn-over .x-btn-inner {
    color: black;
}

.ux-desktop-trayclock {
    text-align: center;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
}

.ux-wallpaper {
    background-color: #3d71b8;
}

.ux-wallpaper-tiled {
    background-repeat: repeat;
}

.ux-desktop-shortcut {
    cursor: pointer;
    text-align: center;
    padding: 8px;
    margin: 8px;
    width: 64px;
}

.ux-desktop-shortcut-icon {
    width: 48px;
    height: 48px;
    background-color: transparent;
    background-repeat: no-repeat;
}

.ux-desktop-shortcut-text {
    font: normal 10px tahoma, arial, verdana, sans-serif;
    text-decoration: none;
    padding-top: 5px;
    color: white;
}

.x-view-over .ux-desktop-shortcut-text {
    text-decoration: underline;
}

.accordion-shortcut {
    background-image: url(./../images/im48x48.png);
}

.cpu-shortcut {
    background-image: url(./../images/statusLarge.png);
}

.grid-shortcut {
    background-image: url(./../images/grid48x48.png);
}

.notepad-shortcut {
    background-image: url(./../images/notepadLarge.png);
    width: 46px;
    height: 46px;
}

.x-ie6 .accordion-shortcut {
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/im48x48.png', sizingMethod='scale');
}

.x-ie6 .cpu-shortcut {
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/statusLarge.png', sizingMethod='scale');
}

.x-ie6 .grid-shortcut {
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/grid48x48.png', sizingMethod='scale');
}

.x-ie6 .notepad-shortcut {
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/notepadLarge.png', sizingMethod='scale');
}

.video {
    background-image: url(./../images/gears.gif);
}

.bogus {
    background-image: url(./../images/bogus.png) !important;
}

.notepad {
    background-image: url(./../images/bogus.png) !important;
}

.logout {
    background-image: url(./../images/logout.gif) !important;
}

.settings {
    background-image: url(./../images/gears.gif) !important;
}

.icon-grid {
    background-image: url(./../images/grid.png) !important;
}

.accordion {
    background-image: url(./../images/accordian.gif) !important;
}

.tabs {
    background-image: url(./../images/tabs.gif) !important;
}


.ux-taskbar {
    background-color: #08528d;
}

.ux-taskbar .x-btn-inner {
    color: #fff;
}

.editactionbtn {
    width: 30px;
}

.x-panel-authlogin {
    background-color: #fff;
}

.loginview.x-body {
    background: url('./../images/abstract_bg3.jpg');
    background-size: auto;
}

.loginview .x-panel {
    border-radius: 5px;
}

.centerview {
    border-left: 0;
    border-top: 0;
    padding: 0;
    margin: 15px;
    color: #2196f3;
    font-size: 24px;
    background-color: white;
}

.detailview {
    color: #111;
    background-color: #bbdefb;
    padding: 15px;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.4);
}

.footerview {
    color: #111;
    background-color: #2196f3;
    border-top: 1px solid #0c7cd5;
    box-shadow: 2px -2px 8px 0 rgba(0, 0, 0, 0.4);
}

.headerview {
    font-size: 28px;
    border-bottom: 1px solid #0c7cd5;
    color: white;
    background-color: #2196f3;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.4);
}

.navview {
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.4);
}

.bottomview {
    color: white;
    background-color: #0960a5;
}

.topview {
    color: white;
    font-size: 13px;
    background-color: #064475;
}

.imgAll {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 104%;
    background-position: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 8px 10px -6px black;
    -moz-box-shadow: 0 8px 10px -6px black;
    box-shadow: 0 8px 10px -6px black;
}

.imgSmall {
    width: 35px;
    height: 35px;
}

.imgBig {
    width: 100px;
    height: 100px;
}

.x-treelist-nav {
    background-color: #0960a5;
    background-position: 24px 0%;
    padding: 0 0 0 0;
}

.x-treelist-nav .x-treelist-toolstrip {
    background-color: #2196f3;
}

.x-treelist-nav .x-treelist-item-selected.x-treelist-item-tool {
    color: #fff;
    background-color: #51adf6;
}

.x-treelist-nav .x-treelist-item-selected > .x-treelist-row {
    background-color: #39a1f4;
}

.x-treelist-nav .x-treelist-item-tool {
    padding-left: 10px;
    padding-right: 10px;
}

.x-treelist-nav .x-treelist-item-tool-floated:after {
    height: 35px;
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 4px;
    background-color: #82c4f8;
}

.x-treelist-nav .x-treelist-item-icon:before,
.x-treelist-nav .x-treelist-item-tool:before,
.x-treelist-nav .x-treelist-item-expander {
    line-height: 35px;
}

.x-treelist-nav .x-treelist-item-icon,
.x-treelist-nav .x-treelist-item-tool,
.x-treelist-nav .x-treelist-item-expander {
    text-align: center;
    background-repeat: no-repeat;
    background-position: 0 center;
}

.x-treelist-nav .x-treelist-item-loading .x-treelist-item-icon {
    background-image: url(./../images/tree/loading.gif);
    color: transparent;
}

.x-treelist-nav .x-treelist-item-icon,
.x-treelist-nav .x-treelist-item-tool {
    color: white;
    font-size: 13px;
    width: 24px;
}

.x-treelist-nav .x-treelist-item-tool {
    width: 44px;
}

.x-treelist-nav .x-treelist-item-expander {
    color: white;
    font-size: 13px;
    width: 24px;
}

.x-treelist-nav .x-treelist-item-text {
    color: white;
    margin-left: 25px;
    margin-right: 24px;
    font-size: 13px;
    line-height: 35px;
    text-overflow: initial;
}

.x-treelist-nav .x-treelist-row {
    padding-left: 10px;
    padding-right: 10px;
}

.x-treelist-nav .x-treelist-row-over:before,
.x-treelist-nav .x-treelist-item-selected > .x-treelist-row:before {
    content: " ";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
}

.x-treelist-nav .x-treelist-row-over:before {
    background-color: #82c4f8;
}

.x-treelist-nav .x-treelist-item-selected > .x-treelist-row-over:before {
    background-color: #0b74c7;
}

.x-treelist-nav .x-treelist-item-selected > .x-treelist-row:before {
    background-color: #51adf6;
}

.x-treelist-nav .x-treelist-item-floated .x-treelist-container {
    width: auto;
}

.x-treelist-nav .x-treelist-item-floated > .x-treelist-row {
    background-color: #2196f3;
}

.x-treelist-nav .x-treelist-item-floated > .x-treelist-container {
    margin-left: -10px;
}

.x-big .x-treelist-nav .x-treelist-item-floated > .x-treelist-container {
    margin-left: -10px;
}

.x-treelist-nav .x-treelist-item-floated > * > * > .x-treelist-item-text {
    margin-left: 0;
}

.x-treelist-nav .x-treelist-item-floated > * .x-treelist-row {
    cursor: pointer;
    padding-left: 0;
}

.x-treelist-nav .x-treelist-item-floated .x-treelist-row:before {
    width: 0;
}

.x-treelist-nav .x-treelist-item-floated > .x-treelist-row-over {
    background-color: #2196f3;
}

.x-treelist-nav .x-treelist-item-floated > .x-treelist-row-over > * > .x-treelist-item-text {
    color: white;
}

.x-treelist-nav .x-treelist-item-expanded {
    background-color: transparent;
}

.x-treelist-nav .x-treelist-item-expanded .x-treelist-item {
    background-color: #2196f3;
}

.x-treelist-nav .x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-icon {
    color: #fff;
}

.x-treelist-nav .x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-text {
    color: #fff;
}

.x-treelist-nav .x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-expander {
    color: #fff;
}

.x-treelist-nav .x-treelist-row-over {
    background-color: #51adf6;
}

.x-treelist-nav .x-treelist-row-over > * > .x-treelist-item-icon {
    color: #fff;
}

.x-treelist-nav .x-treelist-row-over > * > .x-treelist-item-text {
    color: #fff;
}

.x-treelist-nav .x-treelist-row-over > * > .x-treelist-item-expander {
    color: #fff;
}

.x-treelist-nav .x-treelist-expander-first .x-treelist-item-icon {
    left: 24px;
}

.x-treelist-nav .x-treelist-expander-first .x-treelist-item-text {
    margin-left: 49px;
    margin-right: 0;
}

.x-treelist-nav .x-treelist-expander-first .x-treelist-item-hide-icon > * > * > .x-treelist-item-text {
    margin-left: 24.5px;
}

.x-treelist-nav .x-treelist-item-hide-icon > * > * > .x-treelist-item-text {
    margin-left: 0.5px;
}

.x-window-oneportal {
    border-color: #0a68b4;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
    border-radius: 1px;
    margin: 5px 5%;
}

.x-window-oneportal {
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
    border-radius: 1px;
    padding: 0 0 0 0;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-window-oneportal-mc {
    background-color: #fff;
}

.x-nbr .x-window-oneportal {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-oneportal-frameInfo {
    font-family: dh-1-1-1-1-1-1-1-1-0px-0px-0px-0px;
}

.x-window-oneportal-tl {
    background-position: 0 -2px;
}

.x-window-oneportal-tr {
    background-position: right -3px;
}

.x-window-oneportal-bl {
    background-position: 0 -4px;
}

.x-window-oneportal-br {
    background-position: right -5px;
}

.x-window-oneportal-ml {
    background-position: 0 top;
}

.x-window-oneportal-mr {
    background-position: right top;
}

.x-window-oneportal-tc {
    background-position: 0 0;
}

.x-window-oneportal-bc {
    background-position: 0 -1px;
}

.x-window-oneportal-tr,
.x-window-oneportal-br,
.x-window-oneportal-mr {
    padding-right: 1px;
}

.x-window-oneportal-tl,
.x-window-oneportal-bl,
.x-window-oneportal-ml {
    padding-left: 1px;
}

.x-window-oneportal-tc {
    height: 1px;
}

.x-window-oneportal-bc {
    height: 1px;
}

.x-window-oneportal-mc {
    padding: 0 0 0 0;
}

.x-window-body-oneportal {
    border-color: #0a68b4;
    border-width: 1px;
    border-style: solid;
    background: #fff;
    color: #000;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
}

.x-window-header-oneportal {
    font-size: 14px;
    border-color: #0a68b4;
    background-color: #0a6ebd;
}

.x-window-header-oneportal .x-tool-img {
    background-color: #0a6ebd;
}

.x-window-header-oneportal-horizontal .x-window-header-oneportal-tab-bar {
    margin-top: -10px;
    margin-bottom: -10px;
}

.x-window-header-oneportal-vertical .x-window-header-oneportal-tab-bar {
    margin-right: -10px;
    margin-left: -10px;
}

.x-rtl.x-window-header-oneportal-vertical .x-window-header-oneportal-tab-bar {
    margin-left: -10px;
    margin-right: -10px;
}

.x-window-header-title-oneportal {
    color: white;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 22px;
}

.x-window-header-title-oneportal > .x-title-text-oneportal {
    padding: 0;
    text-transform: none;
}

.x-window-header-title-oneportal > .x-title-text-oneportal:after {
    font-family: ext-watermark;
    font-size: 31.5px;
    content: 'd';
    position: absolute;
    top: 0;
    right: 0;
    color: #6ca8d8;
}

.x-window-header-title-oneportal > .x-title-text-oneportal.x-rtl:after {
    right: auto;
    left: 0;
}

.x-window-header-title-oneportal > .x-title-icon-wrap-oneportal.x-title-icon-top {
    height: 24px;
    padding-bottom: 8px;
}

.x-window-header-title-oneportal > .x-title-icon-wrap-oneportal.x-title-icon-right {
    width: 24px;
    padding-left: 8px;
}

.x-window-header-title-oneportal > .x-title-icon-wrap-oneportal.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 8px;
}

.x-window-header-title-oneportal > .x-title-icon-wrap-oneportal.x-title-icon-bottom {
    height: 24px;
    padding-top: 8px;
}

.x-window-header-title-oneportal > .x-title-icon-wrap-oneportal.x-title-icon-left {
    width: 24px;
    padding-right: 8px;
}

.x-window-header-title-oneportal > .x-title-icon-wrap-oneportal.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-window-header-title-oneportal > .x-title-icon-wrap-oneportal > .x-title-icon-oneportal {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: white;
    background-position: center center;
}

.x-ie8 .x-window-header-title-oneportal > .x-title-icon-wrap-oneportal > .x-title-icon-oneportal.x-title-glyph {
    color: #fff;
}

.x-window-header-oneportal-top {
    -moz-border-radius-topleft: 1px;
    -webkit-border-top-left-radius: 1px;
    border-top-left-radius: 1px;
    -moz-border-radius-topright: 1px;
    -webkit-border-top-right-radius: 1px;
    border-top-right-radius: 1px;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 10px 10px 10px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: #0a6ebd;
}

.x-window-header-oneportal-top-mc {
    background-color: #0a6ebd;
}

.x-nbr .x-window-header-oneportal-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-oneportal-top-frameInfo {
    font-family: dh-1-1-0-1-0-0-0-0-10-10-10-10;
}

.x-window-header-oneportal-top-tl {
    background-position: 0 -2px;
}

.x-window-header-oneportal-top-tr {
    background-position: right -3px;
}

.x-window-header-oneportal-top-bl {
    background-position: 0 -4px;
}

.x-window-header-oneportal-top-br {
    background-position: right -5px;
}

.x-window-header-oneportal-top-ml {
    background-position: 0 top;
}

.x-window-header-oneportal-top-mr {
    background-position: right top;
}

.x-window-header-oneportal-top-tc {
    background-position: 0 0;
}

.x-window-header-oneportal-top-bc {
    background-position: 0 -1px;
}

.x-window-header-oneportal-top-tr,
.x-window-header-oneportal-top-br,
.x-window-header-oneportal-top-mr {
    padding-right: 1px;
}

.x-window-header-oneportal-top-tl,
.x-window-header-oneportal-top-bl,
.x-window-header-oneportal-top-ml {
    padding-left: 1px;
}

.x-window-header-oneportal-top-tc {
    height: 1px;
}

.x-window-header-oneportal-top-bc {
    height: 0;
}

.x-window-header-oneportal-top-mc {
    padding: 9px 9px 10px 9px;
}

.x-window-header-oneportal-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 1px;
    -webkit-border-top-right-radius: 1px;
    border-top-right-radius: 1px;
    -moz-border-radius-bottomright: 1px;
    -webkit-border-bottom-right-radius: 1px;
    border-bottom-right-radius: 1px;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 10px 10px 10px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: #0a6ebd;
}

.x-window-header-oneportal-right-mc {
    background-color: #0a6ebd;
}

.x-nbr .x-window-header-oneportal-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-oneportal-right-frameInfo {
    font-family: dh-1-1-1-0-0-0-0-0-10-10-10-10;
}

.x-window-header-oneportal-right-tl {
    background-position: 0 -2px;
}

.x-window-header-oneportal-right-tr {
    background-position: right -3px;
}

.x-window-header-oneportal-right-bl {
    background-position: 0 -4px;
}

.x-window-header-oneportal-right-br {
    background-position: right -5px;
}

.x-window-header-oneportal-right-ml {
    background-position: 0 top;
}

.x-window-header-oneportal-right-mr {
    background-position: right top;
}

.x-window-header-oneportal-right-tc {
    background-position: 0 0;
}

.x-window-header-oneportal-right-bc {
    background-position: 0 -1px;
}

.x-window-header-oneportal-right-tr,
.x-window-header-oneportal-right-br,
.x-window-header-oneportal-right-mr {
    padding-right: 1px;
}

.x-window-header-oneportal-right-tl,
.x-window-header-oneportal-right-bl,
.x-window-header-oneportal-right-ml {
    padding-left: 0;
}

.x-window-header-oneportal-right-tc {
    height: 1px;
}

.x-window-header-oneportal-right-bc {
    height: 1px;
}

.x-window-header-oneportal-right-mc {
    padding: 9px 9px 9px 10px;
}

.x-window-header-oneportal-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 1px;
    -webkit-border-bottom-right-radius: 1px;
    border-bottom-right-radius: 1px;
    -moz-border-radius-bottomleft: 1px;
    -webkit-border-bottom-left-radius: 1px;
    border-bottom-left-radius: 1px;
    padding: 10px 10px 10px 10px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: #0a6ebd;
}

.x-window-header-oneportal-bottom-mc {
    background-color: #0a6ebd;
}

.x-nbr .x-window-header-oneportal-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-oneportal-bottom-frameInfo {
    font-family: dh-0-1-1-1-0-0-0-0-10-10-10-10;
}

.x-window-header-oneportal-bottom-tl {
    background-position: 0 -2px;
}

.x-window-header-oneportal-bottom-tr {
    background-position: right -3px;
}

.x-window-header-oneportal-bottom-bl {
    background-position: 0 -4px;
}

.x-window-header-oneportal-bottom-br {
    background-position: right -5px;
}

.x-window-header-oneportal-bottom-ml {
    background-position: 0 top;
}

.x-window-header-oneportal-bottom-mr {
    background-position: right top;
}

.x-window-header-oneportal-bottom-tc {
    background-position: 0 0;
}

.x-window-header-oneportal-bottom-bc {
    background-position: 0 -1px;
}

.x-window-header-oneportal-bottom-tr,
.x-window-header-oneportal-bottom-br,
.x-window-header-oneportal-bottom-mr {
    padding-right: 1px;
}

.x-window-header-oneportal-bottom-tl,
.x-window-header-oneportal-bottom-bl,
.x-window-header-oneportal-bottom-ml {
    padding-left: 1px;
}

.x-window-header-oneportal-bottom-tc {
    height: 0;
}

.x-window-header-oneportal-bottom-bc {
    height: 1px;
}

.x-window-header-oneportal-bottom-mc {
    padding: 10px 9px 9px 9px;
}

.x-window-header-oneportal-left {
    -moz-border-radius-topleft: 1px;
    -webkit-border-top-left-radius: 1px;
    border-top-left-radius: 1px;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 1px;
    -webkit-border-bottom-left-radius: 1px;
    border-bottom-left-radius: 1px;
    padding: 10px 10px 10px 10px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: #0a6ebd;
}

.x-window-header-oneportal-left-mc {
    background-color: #0a6ebd;
}

.x-nbr .x-window-header-oneportal-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-oneportal-left-frameInfo {
    font-family: dh-1-0-1-1-0-0-0-0-10-10-10-10;
}

.x-window-header-oneportal-left-tl {
    background-position: 0 -2px;
}

.x-window-header-oneportal-left-tr {
    background-position: right -3px;
}

.x-window-header-oneportal-left-bl {
    background-position: 0 -4px;
}

.x-window-header-oneportal-left-br {
    background-position: right -5px;
}

.x-window-header-oneportal-left-ml {
    background-position: 0 top;
}

.x-window-header-oneportal-left-mr {
    background-position: right top;
}

.x-window-header-oneportal-left-tc {
    background-position: 0 0;
}

.x-window-header-oneportal-left-bc {
    background-position: 0 -1px;
}

.x-window-header-oneportal-left-tr,
.x-window-header-oneportal-left-br,
.x-window-header-oneportal-left-mr {
    padding-right: 0;
}

.x-window-header-oneportal-left-tl,
.x-window-header-oneportal-left-bl,
.x-window-header-oneportal-left-ml {
    padding-left: 1px;
}

.x-window-header-oneportal-left-tc {
    height: 1px;
}

.x-window-header-oneportal-left-bc {
    height: 1px;
}

.x-window-header-oneportal-left-mc {
    padding: 9px 10px 9px 9px;
}

.x-window-header-oneportal-collapsed-top {
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
    border-radius: 1px;
    padding: 10px 10px 10px 10px;
    border-width: 0;
    border-style: solid;
    background-color: #0a6ebd;
}

.x-window-header-oneportal-collapsed-top-mc {
    background-color: #0a6ebd;
}

.x-nbr .x-window-header-oneportal-collapsed-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-oneportal-collapsed-top-frameInfo {
    font-family: dh-1-1-1-1-0-0-0-0-10-10-10-10;
}

.x-window-header-oneportal-collapsed-top-tl {
    background-position: 0 -2px;
}

.x-window-header-oneportal-collapsed-top-tr {
    background-position: right -3px;
}

.x-window-header-oneportal-collapsed-top-bl {
    background-position: 0 -4px;
}

.x-window-header-oneportal-collapsed-top-br {
    background-position: right -5px;
}

.x-window-header-oneportal-collapsed-top-ml {
    background-position: 0 top;
}

.x-window-header-oneportal-collapsed-top-mr {
    background-position: right top;
}

.x-window-header-oneportal-collapsed-top-tc {
    background-position: 0 0;
}

.x-window-header-oneportal-collapsed-top-bc {
    background-position: 0 -1px;
}

.x-window-header-oneportal-collapsed-top-tr,
.x-window-header-oneportal-collapsed-top-br,
.x-window-header-oneportal-collapsed-top-mr {
    padding-right: 1px;
}

.x-window-header-oneportal-collapsed-top-tl,
.x-window-header-oneportal-collapsed-top-bl,
.x-window-header-oneportal-collapsed-top-ml {
    padding-left: 1px;
}

.x-window-header-oneportal-collapsed-top-tc {
    height: 1px;
}

.x-window-header-oneportal-collapsed-top-bc {
    height: 1px;
}

.x-window-header-oneportal-collapsed-top-mc {
    padding: 9px 9px 9px 9px;
}

.x-window-header-oneportal-collapsed-right {
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
    border-radius: 1px;
    padding: 10px 10px 10px 10px;
    border-width: 0;
    border-style: solid;
    background-color: #0a6ebd;
}

.x-window-header-oneportal-collapsed-right-mc {
    background-color: #0a6ebd;
}

.x-nbr .x-window-header-oneportal-collapsed-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-oneportal-collapsed-right-frameInfo {
    font-family: dh-1-1-1-1-0-0-0-0-10-10-10-10;
}

.x-window-header-oneportal-collapsed-right-tl {
    background-position: 0 -2px;
}

.x-window-header-oneportal-collapsed-right-tr {
    background-position: right -3px;
}

.x-window-header-oneportal-collapsed-right-bl {
    background-position: 0 -4px;
}

.x-window-header-oneportal-collapsed-right-br {
    background-position: right -5px;
}

.x-window-header-oneportal-collapsed-right-ml {
    background-position: 0 top;
}

.x-window-header-oneportal-collapsed-right-mr {
    background-position: right top;
}

.x-window-header-oneportal-collapsed-right-tc {
    background-position: 0 0;
}

.x-window-header-oneportal-collapsed-right-bc {
    background-position: 0 -1px;
}

.x-window-header-oneportal-collapsed-right-tr,
.x-window-header-oneportal-collapsed-right-br,
.x-window-header-oneportal-collapsed-right-mr {
    padding-right: 1px;
}

.x-window-header-oneportal-collapsed-right-tl,
.x-window-header-oneportal-collapsed-right-bl,
.x-window-header-oneportal-collapsed-right-ml {
    padding-left: 1px;
}

.x-window-header-oneportal-collapsed-right-tc {
    height: 1px;
}

.x-window-header-oneportal-collapsed-right-bc {
    height: 1px;
}

.x-window-header-oneportal-collapsed-right-mc {
    padding: 9px 9px 9px 9px;
}

.x-window-header-oneportal-collapsed-bottom {
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
    border-radius: 1px;
    padding: 10px 10px 10px 10px;
    border-width: 0;
    border-style: solid;
    background-color: #0a6ebd;
}

.x-window-header-oneportal-collapsed-bottom-mc {
    background-color: #0a6ebd;
}

.x-nbr .x-window-header-oneportal-collapsed-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-oneportal-collapsed-bottom-frameInfo {
    font-family: dh-1-1-1-1-0-0-0-0-10-10-10-10;
}

.x-window-header-oneportal-collapsed-bottom-tl {
    background-position: 0 -2px;
}

.x-window-header-oneportal-collapsed-bottom-tr {
    background-position: right -3px;
}

.x-window-header-oneportal-collapsed-bottom-bl {
    background-position: 0 -4px;
}

.x-window-header-oneportal-collapsed-bottom-br {
    background-position: right -5px;
}

.x-window-header-oneportal-collapsed-bottom-ml {
    background-position: 0 top;
}

.x-window-header-oneportal-collapsed-bottom-mr {
    background-position: right top;
}

.x-window-header-oneportal-collapsed-bottom-tc {
    background-position: 0 0;
}

.x-window-header-oneportal-collapsed-bottom-bc {
    background-position: 0 -1px;
}

.x-window-header-oneportal-collapsed-bottom-tr,
.x-window-header-oneportal-collapsed-bottom-br,
.x-window-header-oneportal-collapsed-bottom-mr {
    padding-right: 1px;
}

.x-window-header-oneportal-collapsed-bottom-tl,
.x-window-header-oneportal-collapsed-bottom-bl,
.x-window-header-oneportal-collapsed-bottom-ml {
    padding-left: 1px;
}

.x-window-header-oneportal-collapsed-bottom-tc {
    height: 1px;
}

.x-window-header-oneportal-collapsed-bottom-bc {
    height: 1px;
}

.x-window-header-oneportal-collapsed-bottom-mc {
    padding: 9px 9px 9px 9px;
}

.x-window-header-oneportal-collapsed-left {
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
    border-radius: 1px;
    padding: 10px 10px 10px 10px;
    border-width: 0;
    border-style: solid;
    background-color: #0a6ebd;
}

.x-window-header-oneportal-collapsed-left-mc {
    background-color: #0a6ebd;
}

.x-nbr .x-window-header-oneportal-collapsed-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-oneportal-collapsed-left-frameInfo {
    font-family: dh-1-1-1-1-0-0-0-0-10-10-10-10;
}

.x-window-header-oneportal-collapsed-left-tl {
    background-position: 0 -2px;
}

.x-window-header-oneportal-collapsed-left-tr {
    background-position: right -3px;
}

.x-window-header-oneportal-collapsed-left-bl {
    background-position: 0 -4px;
}

.x-window-header-oneportal-collapsed-left-br {
    background-position: right -5px;
}

.x-window-header-oneportal-collapsed-left-ml {
    background-position: 0 top;
}

.x-window-header-oneportal-collapsed-left-mr {
    background-position: right top;
}

.x-window-header-oneportal-collapsed-left-tc {
    background-position: 0 0;
}

.x-window-header-oneportal-collapsed-left-bc {
    background-position: 0 -1px;
}

.x-window-header-oneportal-collapsed-left-tr,
.x-window-header-oneportal-collapsed-left-br,
.x-window-header-oneportal-collapsed-left-mr {
    padding-right: 1px;
}

.x-window-header-oneportal-collapsed-left-tl,
.x-window-header-oneportal-collapsed-left-bl,
.x-window-header-oneportal-collapsed-left-ml {
    padding-left: 1px;
}

.x-window-header-oneportal-collapsed-left-tc {
    height: 1px;
}

.x-window-header-oneportal-collapsed-left-bc {
    height: 1px;
}

.x-window-header-oneportal-collapsed-left-mc {
    padding: 9px 9px 9px 9px;
}

.x-window-header-oneportal .x-window-header-icon {
    width: 16px;
    height: 16px;
    color: white;
    font-size: 16px;
    line-height: 16px;
    background-position: center center;
}

.x-window-header-oneportal .x-window-header-glyph {
    color: white;
    font-size: 16px;
    line-height: 16px;
}

.x-ie8 .x-window-header-oneportal .x-window-header-glyph {
    color: #fff;
}

.x-window-header-oneportal-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-window-header-oneportal-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-window-header-oneportal-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-window-header-oneportal-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-window-header-oneportal-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-window-header-oneportal-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-window-header-oneportal-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-window-header-oneportal-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-window-header-oneportal .x-tool-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-window-header-oneportal .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-window-header-oneportal .x-tool-focus,
.x-edge .x-keyboard-mode .x-window-header-oneportal .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-window-header-oneportal .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-window-header-oneportal .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-window-header-oneportal .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-window-header-oneportal {
    border-width: 0 !important;
}

.x-nbr .x-window-oneportal-collapsed .x-window-header {
    border-width: 0 !important;
}

.x-window-oneportal-outer-border-l {
    border-left-color: #0a68b4 !important;
    border-left-width: 1px !important;
}

.x-window-oneportal-outer-border-b {
    border-bottom-color: #0a68b4 !important;
    border-bottom-width: 1px !important;
}

.x-window-oneportal-outer-border-bl {
    border-bottom-color: #0a68b4 !important;
    border-bottom-width: 1px !important;
    border-left-color: #0a68b4 !important;
    border-left-width: 1px !important;
}

.x-window-oneportal-outer-border-r {
    border-right-color: #0a68b4 !important;
    border-right-width: 1px !important;
}

.x-window-oneportal-outer-border-rl {
    border-right-color: #0a68b4 !important;
    border-right-width: 1px !important;
    border-left-color: #0a68b4 !important;
    border-left-width: 1px !important;
}

.x-window-oneportal-outer-border-rb {
    border-right-color: #0a68b4 !important;
    border-right-width: 1px !important;
    border-bottom-color: #0a68b4 !important;
    border-bottom-width: 1px !important;
}

.x-window-oneportal-outer-border-rbl {
    border-right-color: #0a68b4 !important;
    border-right-width: 1px !important;
    border-bottom-color: #0a68b4 !important;
    border-bottom-width: 1px !important;
    border-left-color: #0a68b4 !important;
    border-left-width: 1px !important;
}

.x-window-oneportal-outer-border-t {
    border-top-color: #0a68b4 !important;
    border-top-width: 1px !important;
}

.x-window-oneportal-outer-border-tl {
    border-top-color: #0a68b4 !important;
    border-top-width: 1px !important;
    border-left-color: #0a68b4 !important;
    border-left-width: 1px !important;
}

.x-window-oneportal-outer-border-tb {
    border-top-color: #0a68b4 !important;
    border-top-width: 1px !important;
    border-bottom-color: #0a68b4 !important;
    border-bottom-width: 1px !important;
}

.x-window-oneportal-outer-border-tbl {
    border-top-color: #0a68b4 !important;
    border-top-width: 1px !important;
    border-bottom-color: #0a68b4 !important;
    border-bottom-width: 1px !important;
    border-left-color: #0a68b4 !important;
    border-left-width: 1px !important;
}

.x-window-oneportal-outer-border-tr {
    border-top-color: #0a68b4 !important;
    border-top-width: 1px !important;
    border-right-color: #0a68b4 !important;
    border-right-width: 1px !important;
}

.x-window-oneportal-outer-border-trl {
    border-top-color: #0a68b4 !important;
    border-top-width: 1px !important;
    border-right-color: #0a68b4 !important;
    border-right-width: 1px !important;
    border-left-color: #0a68b4 !important;
    border-left-width: 1px !important;
}

.x-window-oneportal-outer-border-trb {
    border-top-color: #0a68b4 !important;
    border-top-width: 1px !important;
    border-right-color: #0a68b4 !important;
    border-right-width: 1px !important;
    border-bottom-color: #0a68b4 !important;
    border-bottom-width: 1px !important;
}

.x-window-oneportal-outer-border-trbl {
    border-color: #0a68b4 !important;
    border-width: 1px !important;
}

.x-panel-oneportal-toc {
    border-color: #39a1f4;
    padding: 0;
}

.x-panel-oneportal-toc.x-masked {
    border-color: #9cd0fa;
}

.x-panel-header-oneportal-toc {
    font-size: 14px;
    border: 1px solid #39a1f4;
}

.x-panel-header-oneportal-toc .x-tool-tool-el {
    background-color: #39a1f4;
}

.x-panel-header-oneportal-toc-horizontal {
    padding: 10px;
}

.x-panel-header-oneportal-toc-horizontal .x-panel-header-oneportal-toc-tab-bar {
    margin-top: -10px;
    margin-bottom: -10px;
}

.x-panel-header-oneportal-toc-horizontal.x-header-noborder {
    padding: 11px 11px 10px 11px;
}

.x-panel-header-oneportal-toc-horizontal.x-header-noborder .x-panel-header-oneportal-toc-tab-bar {
    margin-top: -11px;
    margin-bottom: -10px;
}

.x-panel-header-oneportal-toc-vertical {
    padding: 10px 10px 10px 10px;
}

.x-panel-header-oneportal-toc-vertical .x-panel-header-oneportal-toc-tab-bar {
    margin-right: -10px;
    margin-left: -10px;
}

.x-panel-header-oneportal-toc-vertical.x-header-noborder {
    padding: 11px 11px 11px 10px;
}

.x-panel-header-oneportal-toc-vertical.x-header-noborder .x-panel-header-oneportal-toc-tab-bar {
    margin-right: -11px;
    margin-left: -10px;
}

.x-rtl.x-panel-header-oneportal-toc-vertical {
    padding: 10px 10px 10px 10px;
}

.x-rtl.x-panel-header-oneportal-toc-vertical .x-panel-header-oneportal-toc-tab-bar {
    margin-left: -10px;
    margin-right: -10px;
}

.x-rtl.x-panel-header-oneportal-toc-vertical.x-header-noborder {
    padding: 11px 10px 11px 11px;
}

.x-rtl.x-panel-header-oneportal-toc-vertical.x-header-noborder .x-panel-header-oneportal-toc-tab-bar {
    margin-left: -11px;
    margin-right: -10px;
}

.x-panel-header-title-oneportal-toc {
    color: white;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 22px;
}

.x-keyboard-mode .x-panel-header-title-oneportal-toc.x-title-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-title-oneportal-toc.x-title-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-title-oneportal-toc.x-title-focus,
.x-edge .x-keyboard-mode .x-panel-header-title-oneportal-toc.x-title-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-title-oneportal-toc.x-title-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-title-oneportal-toc.x-title-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-title-oneportal-toc.x-title-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-panel-header-title-oneportal-toc > .x-title-text-oneportal-toc {
    text-transform: none;
    padding: 0;
}

.x-panel-header-title-oneportal-toc > .x-title-text-oneportal-toc:after {
    font-family: ext-watermark;
    font-size: 31.5px;
    content: 'd';
    position: absolute;
    top: 0;
    right: 0;
    color: #88c7f9;
}

.x-panel-header-title-oneportal-toc > .x-title-text-oneportal-toc.x-rtl:after {
    right: auto;
    left: 0;
}

.x-panel-header-title-oneportal-toc > .x-title-icon-wrap-oneportal-toc.x-title-icon-top {
    height: 24px;
    padding-bottom: 8px;
}

.x-panel-header-title-oneportal-toc > .x-title-icon-wrap-oneportal-toc.x-title-icon-right {
    width: 24px;
    padding-left: 8px;
}

.x-panel-header-title-oneportal-toc > .x-title-icon-wrap-oneportal-toc.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 8px;
}

.x-panel-header-title-oneportal-toc > .x-title-icon-wrap-oneportal-toc.x-title-icon-bottom {
    height: 24px;
    padding-top: 8px;
}

.x-panel-header-title-oneportal-toc > .x-title-icon-wrap-oneportal-toc.x-title-icon-left {
    width: 24px;
    padding-right: 8px;
}

.x-panel-header-title-oneportal-toc > .x-title-icon-wrap-oneportal-toc.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-panel-header-title-oneportal-toc > .x-title-icon-wrap-oneportal-toc > .x-title-icon-oneportal-toc {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: white;
    background-position: center center;
}

.x-ie8 .x-panel-header-title-oneportal-toc > .x-title-icon-wrap-oneportal-toc > .x-title-icon-oneportal-toc.x-title-glyph {
    color: #fff;
}

.x-panel-body-oneportal-toc {
    background: #fff;
    border-color: #39a1f4;
    color: #111;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    border-width: 1px;
    border-style: solid;
}

.x-panel-oneportal-toc {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    padding: 0 0 0 0;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-panel-oneportal-toc-mc {
    background-color: #fff;
}

.x-nbr .x-panel-oneportal-toc {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-oneportal-toc-frameInfo {
    font-family: dh-10-10-10-10-1-1-1-1-9px-9px-9px-9px;
}

.x-panel-oneportal-toc-tl {
    background-position: 0 -20px;
}

.x-panel-oneportal-toc-tr {
    background-position: right -30px;
}

.x-panel-oneportal-toc-bl {
    background-position: 0 -40px;
}

.x-panel-oneportal-toc-br {
    background-position: right -50px;
}

.x-panel-oneportal-toc-ml {
    background-position: 0 top;
}

.x-panel-oneportal-toc-mr {
    background-position: right top;
}

.x-panel-oneportal-toc-tc {
    background-position: 0 0;
}

.x-panel-oneportal-toc-bc {
    background-position: 0 -10px;
}

.x-panel-oneportal-toc-tr,
.x-panel-oneportal-toc-br,
.x-panel-oneportal-toc-mr {
    padding-right: 10px;
}

.x-panel-oneportal-toc-tl,
.x-panel-oneportal-toc-bl,
.x-panel-oneportal-toc-ml {
    padding-left: 10px;
}

.x-panel-oneportal-toc-tc {
    height: 10px;
}

.x-panel-oneportal-toc-bc {
    height: 10px;
}

.x-panel-oneportal-toc-mc {
    padding: 0 0 0 0;
}

.x-panel-header-oneportal-toc-top {
    -moz-border-radius-topleft: 10px;
    -webkit-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 10px 10px 10px;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    background-color: #39a1f4;
}

.x-panel-header-oneportal-toc-top-mc {
    background-color: #39a1f4;
}

.x-nbr .x-panel-header-oneportal-toc-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-oneportal-toc-top-frameInfo {
    font-family: dh-10-10-0-10-1-1-0-1-10-10-10-10;
}

.x-panel-header-oneportal-toc-top-tl {
    background-position: 0 -20px;
}

.x-panel-header-oneportal-toc-top-tr {
    background-position: right -30px;
}

.x-panel-header-oneportal-toc-top-bl {
    background-position: 0 -40px;
}

.x-panel-header-oneportal-toc-top-br {
    background-position: right -50px;
}

.x-panel-header-oneportal-toc-top-ml {
    background-position: 0 top;
}

.x-panel-header-oneportal-toc-top-mr {
    background-position: right top;
}

.x-panel-header-oneportal-toc-top-tc {
    background-position: 0 0;
}

.x-panel-header-oneportal-toc-top-bc {
    background-position: 0 -10px;
}

.x-panel-header-oneportal-toc-top-tr,
.x-panel-header-oneportal-toc-top-br,
.x-panel-header-oneportal-toc-top-mr {
    padding-right: 10px;
}

.x-panel-header-oneportal-toc-top-tl,
.x-panel-header-oneportal-toc-top-bl,
.x-panel-header-oneportal-toc-top-ml {
    padding-left: 10px;
}

.x-panel-header-oneportal-toc-top-tc {
    height: 10px;
}

.x-panel-header-oneportal-toc-top-bc {
    height: 0;
}

.x-panel-header-oneportal-toc-top-mc {
    padding: 1px 1px 10px 1px;
}

.x-panel-header-oneportal-toc-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -webkit-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 10px 10px 10px;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    background-color: #39a1f4;
}

.x-rtl.x-panel-header-oneportal-toc-right {
    background-image: none;
    background-color: #39a1f4;
}

.x-panel-header-oneportal-toc-right-mc {
    background-color: #39a1f4;
}

.x-nbr .x-panel-header-oneportal-toc-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-oneportal-toc-right-frameInfo {
    font-family: dh-10-10-10-0-1-1-1-0-10-10-10-10;
}

.x-panel-header-oneportal-toc-right-tl {
    background-position: 0 -20px;
}

.x-panel-header-oneportal-toc-right-tr {
    background-position: right -30px;
}

.x-panel-header-oneportal-toc-right-bl {
    background-position: 0 -40px;
}

.x-panel-header-oneportal-toc-right-br {
    background-position: right -50px;
}

.x-panel-header-oneportal-toc-right-ml {
    background-position: right 0;
}

.x-panel-header-oneportal-toc-right-mr {
    background-position: right 0;
}

.x-panel-header-oneportal-toc-right-tc {
    background-position: 0 0;
}

.x-panel-header-oneportal-toc-right-bc {
    background-position: 0 -10px;
}

.x-panel-header-oneportal-toc-right-tr,
.x-panel-header-oneportal-toc-right-br,
.x-panel-header-oneportal-toc-right-mr {
    padding-right: 10px;
}

.x-panel-header-oneportal-toc-right-tl,
.x-panel-header-oneportal-toc-right-bl,
.x-panel-header-oneportal-toc-right-ml {
    padding-left: 0;
}

.x-panel-header-oneportal-toc-right-tc {
    height: 10px;
}

.x-panel-header-oneportal-toc-right-bc {
    height: 10px;
}

.x-panel-header-oneportal-toc-right-mc {
    padding: 1px 1px 1px 10px;
}

.x-panel-header-oneportal-toc-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 10px;
    -webkit-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    -webkit-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px 10px 10px 10px;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    background-color: #39a1f4;
}

.x-panel-header-oneportal-toc-bottom-mc {
    background-color: #39a1f4;
}

.x-nbr .x-panel-header-oneportal-toc-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-oneportal-toc-bottom-frameInfo {
    font-family: dh-0-10-10-10-0-1-1-1-10-10-10-10;
}

.x-panel-header-oneportal-toc-bottom-tl {
    background-position: 0 -20px;
}

.x-panel-header-oneportal-toc-bottom-tr {
    background-position: right -30px;
}

.x-panel-header-oneportal-toc-bottom-bl {
    background-position: 0 -40px;
}

.x-panel-header-oneportal-toc-bottom-br {
    background-position: right -50px;
}

.x-panel-header-oneportal-toc-bottom-ml {
    background-position: 0 bottom;
}

.x-panel-header-oneportal-toc-bottom-mr {
    background-position: right bottom;
}

.x-panel-header-oneportal-toc-bottom-tc {
    background-position: 0 0;
}

.x-panel-header-oneportal-toc-bottom-bc {
    background-position: 0 -10px;
}

.x-panel-header-oneportal-toc-bottom-tr,
.x-panel-header-oneportal-toc-bottom-br,
.x-panel-header-oneportal-toc-bottom-mr {
    padding-right: 10px;
}

.x-panel-header-oneportal-toc-bottom-tl,
.x-panel-header-oneportal-toc-bottom-bl,
.x-panel-header-oneportal-toc-bottom-ml {
    padding-left: 10px;
}

.x-panel-header-oneportal-toc-bottom-tc {
    height: 0;
}

.x-panel-header-oneportal-toc-bottom-bc {
    height: 10px;
}

.x-panel-header-oneportal-toc-bottom-mc {
    padding: 10px 1px 1px 1px;
}

.x-panel-header-oneportal-toc-left {
    -moz-border-radius-topleft: 10px;
    -webkit-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 10px;
    -webkit-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px 10px 10px 10px;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    background-color: #39a1f4;
}

.x-rtl.x-panel-header-oneportal-toc-left {
    background-image: none;
    background-color: #39a1f4;
}

.x-panel-header-oneportal-toc-left-mc {
    background-color: #39a1f4;
}

.x-nbr .x-panel-header-oneportal-toc-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-oneportal-toc-left-frameInfo {
    font-family: dh-10-0-10-10-1-0-1-1-10-10-10-10;
}

.x-panel-header-oneportal-toc-left-tl {
    background-position: 0 -20px;
}

.x-panel-header-oneportal-toc-left-tr {
    background-position: right -30px;
}

.x-panel-header-oneportal-toc-left-bl {
    background-position: 0 -40px;
}

.x-panel-header-oneportal-toc-left-br {
    background-position: right -50px;
}

.x-panel-header-oneportal-toc-left-ml {
    background-position: left 0;
}

.x-panel-header-oneportal-toc-left-mr {
    background-position: left 0;
}

.x-panel-header-oneportal-toc-left-tc {
    background-position: 0 0;
}

.x-panel-header-oneportal-toc-left-bc {
    background-position: 0 -10px;
}

.x-panel-header-oneportal-toc-left-tr,
.x-panel-header-oneportal-toc-left-br,
.x-panel-header-oneportal-toc-left-mr {
    padding-right: 0;
}

.x-panel-header-oneportal-toc-left-tl,
.x-panel-header-oneportal-toc-left-bl,
.x-panel-header-oneportal-toc-left-ml {
    padding-left: 10px;
}

.x-panel-header-oneportal-toc-left-tc {
    height: 10px;
}

.x-panel-header-oneportal-toc-left-bc {
    height: 10px;
}

.x-panel-header-oneportal-toc-left-mc {
    padding: 1px 10px 1px 1px;
}

.x-panel-header-oneportal-toc-collapsed-top {
    -moz-border-radius-topleft: 10px;
    -webkit-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -webkit-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    -webkit-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px 10px 10px 10px;
    border-width: 1px;
    border-style: solid;
    background-color: #39a1f4;
}

.x-panel-header-oneportal-toc-collapsed-top-mc {
    background-color: #39a1f4;
}

.x-nbr .x-panel-header-oneportal-toc-collapsed-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-oneportal-toc-collapsed-top-frameInfo {
    font-family: dh-10-10-10-10-1-1-1-1-10-10-10-10;
}

.x-panel-header-oneportal-toc-collapsed-top-tl {
    background-position: 0 -20px;
}

.x-panel-header-oneportal-toc-collapsed-top-tr {
    background-position: right -30px;
}

.x-panel-header-oneportal-toc-collapsed-top-bl {
    background-position: 0 -40px;
}

.x-panel-header-oneportal-toc-collapsed-top-br {
    background-position: right -50px;
}

.x-panel-header-oneportal-toc-collapsed-top-ml {
    background-position: 0 top;
}

.x-panel-header-oneportal-toc-collapsed-top-mr {
    background-position: right top;
}

.x-panel-header-oneportal-toc-collapsed-top-tc {
    background-position: 0 0;
}

.x-panel-header-oneportal-toc-collapsed-top-bc {
    background-position: 0 -10px;
}

.x-panel-header-oneportal-toc-collapsed-top-tr,
.x-panel-header-oneportal-toc-collapsed-top-br,
.x-panel-header-oneportal-toc-collapsed-top-mr {
    padding-right: 10px;
}

.x-panel-header-oneportal-toc-collapsed-top-tl,
.x-panel-header-oneportal-toc-collapsed-top-bl,
.x-panel-header-oneportal-toc-collapsed-top-ml {
    padding-left: 10px;
}

.x-panel-header-oneportal-toc-collapsed-top-tc {
    height: 10px;
}

.x-panel-header-oneportal-toc-collapsed-top-bc {
    height: 10px;
}

.x-panel-header-oneportal-toc-collapsed-top-mc {
    padding: 1px 1px 1px 1px;
}

.x-panel-header-oneportal-toc-collapsed-right {
    -moz-border-radius-topleft: 10px;
    -webkit-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -webkit-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    -webkit-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px 10px 10px 10px;
    border-width: 1px;
    border-style: solid;
    background-color: #39a1f4;
}

.x-rtl.x-panel-header-oneportal-toc-collapsed-right {
    background-image: none;
    background-color: #39a1f4;
}

.x-panel-header-oneportal-toc-collapsed-right-mc {
    background-color: #39a1f4;
}

.x-nbr .x-panel-header-oneportal-toc-collapsed-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-oneportal-toc-collapsed-right-frameInfo {
    font-family: dh-10-10-10-10-1-1-1-1-10-10-10-10;
}

.x-panel-header-oneportal-toc-collapsed-right-tl {
    background-position: 0 -20px;
}

.x-panel-header-oneportal-toc-collapsed-right-tr {
    background-position: right -30px;
}

.x-panel-header-oneportal-toc-collapsed-right-bl {
    background-position: 0 -40px;
}

.x-panel-header-oneportal-toc-collapsed-right-br {
    background-position: right -50px;
}

.x-panel-header-oneportal-toc-collapsed-right-ml {
    background-position: right 0;
}

.x-panel-header-oneportal-toc-collapsed-right-mr {
    background-position: right 0;
}

.x-panel-header-oneportal-toc-collapsed-right-tc {
    background-position: 0 0;
}

.x-panel-header-oneportal-toc-collapsed-right-bc {
    background-position: 0 -10px;
}

.x-panel-header-oneportal-toc-collapsed-right-tr,
.x-panel-header-oneportal-toc-collapsed-right-br,
.x-panel-header-oneportal-toc-collapsed-right-mr {
    padding-right: 10px;
}

.x-panel-header-oneportal-toc-collapsed-right-tl,
.x-panel-header-oneportal-toc-collapsed-right-bl,
.x-panel-header-oneportal-toc-collapsed-right-ml {
    padding-left: 10px;
}

.x-panel-header-oneportal-toc-collapsed-right-tc {
    height: 10px;
}

.x-panel-header-oneportal-toc-collapsed-right-bc {
    height: 10px;
}

.x-panel-header-oneportal-toc-collapsed-right-mc {
    padding: 1px 1px 1px 1px;
}

.x-panel-header-oneportal-toc-collapsed-bottom {
    -moz-border-radius-topleft: 10px;
    -webkit-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -webkit-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    -webkit-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px 10px 10px 10px;
    border-width: 1px;
    border-style: solid;
    background-color: #39a1f4;
}

.x-panel-header-oneportal-toc-collapsed-bottom-mc {
    background-color: #39a1f4;
}

.x-nbr .x-panel-header-oneportal-toc-collapsed-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-oneportal-toc-collapsed-bottom-frameInfo {
    font-family: dh-10-10-10-10-1-1-1-1-10-10-10-10;
}

.x-panel-header-oneportal-toc-collapsed-bottom-tl {
    background-position: 0 -20px;
}

.x-panel-header-oneportal-toc-collapsed-bottom-tr {
    background-position: right -30px;
}

.x-panel-header-oneportal-toc-collapsed-bottom-bl {
    background-position: 0 -40px;
}

.x-panel-header-oneportal-toc-collapsed-bottom-br {
    background-position: right -50px;
}

.x-panel-header-oneportal-toc-collapsed-bottom-ml {
    background-position: 0 bottom;
}

.x-panel-header-oneportal-toc-collapsed-bottom-mr {
    background-position: right bottom;
}

.x-panel-header-oneportal-toc-collapsed-bottom-tc {
    background-position: 0 0;
}

.x-panel-header-oneportal-toc-collapsed-bottom-bc {
    background-position: 0 -10px;
}

.x-panel-header-oneportal-toc-collapsed-bottom-tr,
.x-panel-header-oneportal-toc-collapsed-bottom-br,
.x-panel-header-oneportal-toc-collapsed-bottom-mr {
    padding-right: 10px;
}

.x-panel-header-oneportal-toc-collapsed-bottom-tl,
.x-panel-header-oneportal-toc-collapsed-bottom-bl,
.x-panel-header-oneportal-toc-collapsed-bottom-ml {
    padding-left: 10px;
}

.x-panel-header-oneportal-toc-collapsed-bottom-tc {
    height: 10px;
}

.x-panel-header-oneportal-toc-collapsed-bottom-bc {
    height: 10px;
}

.x-panel-header-oneportal-toc-collapsed-bottom-mc {
    padding: 1px 1px 1px 1px;
}

.x-panel-header-oneportal-toc-collapsed-left {
    -moz-border-radius-topleft: 10px;
    -webkit-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -webkit-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    -webkit-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px 10px 10px 10px;
    border-width: 1px;
    border-style: solid;
    background-color: #39a1f4;
}

.x-rtl.x-panel-header-oneportal-toc-collapsed-left {
    background-image: none;
    background-color: #39a1f4;
}

.x-panel-header-oneportal-toc-collapsed-left-mc {
    background-color: #39a1f4;
}

.x-nbr .x-panel-header-oneportal-toc-collapsed-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-oneportal-toc-collapsed-left-frameInfo {
    font-family: dh-10-10-10-10-1-1-1-1-10-10-10-10;
}

.x-panel-header-oneportal-toc-collapsed-left-tl {
    background-position: 0 -20px;
}

.x-panel-header-oneportal-toc-collapsed-left-tr {
    background-position: right -30px;
}

.x-panel-header-oneportal-toc-collapsed-left-bl {
    background-position: 0 -40px;
}

.x-panel-header-oneportal-toc-collapsed-left-br {
    background-position: right -50px;
}

.x-panel-header-oneportal-toc-collapsed-left-ml {
    background-position: left 0;
}

.x-panel-header-oneportal-toc-collapsed-left-mr {
    background-position: left 0;
}

.x-panel-header-oneportal-toc-collapsed-left-tc {
    background-position: 0 0;
}

.x-panel-header-oneportal-toc-collapsed-left-bc {
    background-position: 0 -10px;
}

.x-panel-header-oneportal-toc-collapsed-left-tr,
.x-panel-header-oneportal-toc-collapsed-left-br,
.x-panel-header-oneportal-toc-collapsed-left-mr {
    padding-right: 10px;
}

.x-panel-header-oneportal-toc-collapsed-left-tl,
.x-panel-header-oneportal-toc-collapsed-left-bl,
.x-panel-header-oneportal-toc-collapsed-left-ml {
    padding-left: 10px;
}

.x-panel-header-oneportal-toc-collapsed-left-tc {
    height: 10px;
}

.x-panel-header-oneportal-toc-collapsed-left-bc {
    height: 10px;
}

.x-panel-header-oneportal-toc-collapsed-left-mc {
    padding: 1px 1px 1px 1px;
}

.x-panel .x-panel-header-oneportal-toc-top {
    border-bottom-width: 1px !important;
}

.x-panel .x-panel-header-oneportal-toc-right {
    border-left-width: 1px !important;
}

.x-panel .x-panel-header-oneportal-toc-bottom {
    border-top-width: 1px !important;
}

.x-panel .x-panel-header-oneportal-toc-left {
    border-right-width: 1px !important;
}

.x-nbr .x-panel-header-oneportal-toc-collapsed-top {
    border-bottom-width: 0 !important;
}

.x-nbr .x-panel-header-oneportal-toc-collapsed-right {
    border-left-width: 0 !important;
}

.x-nbr .x-panel-header-oneportal-toc-collapsed-bottom {
    border-top-width: 0 !important;
}

.x-nbr .x-panel-header-oneportal-toc-collapsed-left {
    border-right-width: 0 !important;
}

.x-panel-header-oneportal-toc-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-panel-header-oneportal-toc-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-panel-header-oneportal-toc-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-panel-header-oneportal-toc-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-panel-header-oneportal-toc-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-oneportal-toc-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-oneportal-toc-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-panel-header-oneportal-toc-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-panel-header-oneportal-toc .x-tool-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-oneportal-toc .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-oneportal-toc .x-tool-focus,
.x-edge .x-keyboard-mode .x-panel-header-oneportal-toc .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-oneportal-toc .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-oneportal-toc .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-oneportal-toc .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-rtl.x-panel-header-oneportal-toc-collapsed-border-right {
    border-right-width: 1px !important;
}

.x-rtl.x-panel-header-oneportal-toc-collapsed-border-left {
    border-left-width: 1px !important;
}

.x-panel-oneportal-toc-resizable .x-panel-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-panel-oneportal-toc-outer-border-l {
    border-left-color: #39a1f4 !important;
    border-left-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-b {
    border-bottom-color: #39a1f4 !important;
    border-bottom-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-bl {
    border-bottom-color: #39a1f4 !important;
    border-bottom-width: 1px !important;
    border-left-color: #39a1f4 !important;
    border-left-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-r {
    border-right-color: #39a1f4 !important;
    border-right-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-rl {
    border-right-color: #39a1f4 !important;
    border-right-width: 1px !important;
    border-left-color: #39a1f4 !important;
    border-left-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-rb {
    border-right-color: #39a1f4 !important;
    border-right-width: 1px !important;
    border-bottom-color: #39a1f4 !important;
    border-bottom-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-rbl {
    border-right-color: #39a1f4 !important;
    border-right-width: 1px !important;
    border-bottom-color: #39a1f4 !important;
    border-bottom-width: 1px !important;
    border-left-color: #39a1f4 !important;
    border-left-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-t {
    border-top-color: #39a1f4 !important;
    border-top-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-tl {
    border-top-color: #39a1f4 !important;
    border-top-width: 1px !important;
    border-left-color: #39a1f4 !important;
    border-left-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-tb {
    border-top-color: #39a1f4 !important;
    border-top-width: 1px !important;
    border-bottom-color: #39a1f4 !important;
    border-bottom-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-tbl {
    border-top-color: #39a1f4 !important;
    border-top-width: 1px !important;
    border-bottom-color: #39a1f4 !important;
    border-bottom-width: 1px !important;
    border-left-color: #39a1f4 !important;
    border-left-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-tr {
    border-top-color: #39a1f4 !important;
    border-top-width: 1px !important;
    border-right-color: #39a1f4 !important;
    border-right-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-trl {
    border-top-color: #39a1f4 !important;
    border-top-width: 1px !important;
    border-right-color: #39a1f4 !important;
    border-right-width: 1px !important;
    border-left-color: #39a1f4 !important;
    border-left-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-trb {
    border-top-color: #39a1f4 !important;
    border-top-width: 1px !important;
    border-right-color: #39a1f4 !important;
    border-right-width: 1px !important;
    border-bottom-color: #39a1f4 !important;
    border-bottom-width: 1px !important;
}

.x-panel-oneportal-toc-outer-border-trbl {
    border-color: #39a1f4 !important;
    border-width: 1px !important;
}

.x-panel-oneportal-nav {
    border-color: #e4e4e4;
    padding: 0;
}

.x-menu-main .x-panel-oneportal-nav {
    width: 25%;
    min-height: 200px;
}

.x-panel-oneportal-nav.x-masked {
    border-color: #f2f2f2;
}

.x-panel-header-oneportal-nav {
    font-size: 14px;
    border: 1px solid #e4e4e4;
}

.x-panel-header-oneportal-nav .x-tool-tool-el {
    background-color: #e4e4e4;
}

.x-panel-header-oneportal-nav-horizontal {
    padding: 10px;
}

.x-panel-header-oneportal-nav-horizontal .x-panel-header-oneportal-nav-tab-bar {
    margin-top: -10px;
    margin-bottom: -10px;
}

.x-panel-header-oneportal-nav-horizontal.x-header-noborder {
    padding: 11px 11px 10px 11px;
}

.x-panel-header-oneportal-nav-horizontal.x-header-noborder .x-panel-header-oneportal-nav-tab-bar {
    margin-top: -11px;
    margin-bottom: -10px;
}

.x-panel-header-oneportal-nav-vertical {
    padding: 10px 10px 10px 10px;
}

.x-panel-header-oneportal-nav-vertical .x-panel-header-oneportal-nav-tab-bar {
    margin-right: -10px;
    margin-left: -10px;
}

.x-panel-header-oneportal-nav-vertical.x-header-noborder {
    padding: 11px 11px 11px 10px;
}

.x-panel-header-oneportal-nav-vertical.x-header-noborder .x-panel-header-oneportal-nav-tab-bar {
    margin-right: -11px;
    margin-left: -10px;
}

.x-rtl.x-panel-header-oneportal-nav-vertical {
    padding: 10px 10px 10px 10px;
}

.x-rtl.x-panel-header-oneportal-nav-vertical .x-panel-header-oneportal-nav-tab-bar {
    margin-left: -10px;
    margin-right: -10px;
}

.x-rtl.x-panel-header-oneportal-nav-vertical.x-header-noborder {
    padding: 11px 10px 11px 11px;
}

.x-rtl.x-panel-header-oneportal-nav-vertical.x-header-noborder .x-panel-header-oneportal-nav-tab-bar {
    margin-left: -11px;
    margin-right: -10px;
}

.x-panel-header-title-oneportal-nav {
    color: #686868;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    line-height: 22px;
}

.x-keyboard-mode .x-panel-header-title-oneportal-nav.x-title-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-title-oneportal-nav.x-title-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-title-oneportal-nav.x-title-focus,
.x-edge .x-keyboard-mode .x-panel-header-title-oneportal-nav.x-title-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-title-oneportal-nav.x-title-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-title-oneportal-nav.x-title-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-title-oneportal-nav.x-title-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-panel-header-title-oneportal-nav > .x-title-text-oneportal-nav {
    text-transform: none;
    padding: 0;
}

.x-panel-header-title-oneportal-nav > .x-title-text-oneportal-nav:after {
    font-family: ext-watermark;
    font-size: 31.5px;
    content: 'd';
    position: absolute;
    top: 0;
    right: 0;
    color: #b2b2b2;
}

.x-panel-header-title-oneportal-nav > .x-title-text-oneportal-nav.x-rtl:after {
    right: auto;
    left: 0;
}

.x-panel-header-title-oneportal-nav > .x-title-icon-wrap-oneportal-nav.x-title-icon-top {
    height: 24px;
    padding-bottom: 8px;
}

.x-panel-header-title-oneportal-nav > .x-title-icon-wrap-oneportal-nav.x-title-icon-right {
    width: 24px;
    padding-left: 8px;
}

.x-panel-header-title-oneportal-nav > .x-title-icon-wrap-oneportal-nav.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 8px;
}

.x-panel-header-title-oneportal-nav > .x-title-icon-wrap-oneportal-nav.x-title-icon-bottom {
    height: 24px;
    padding-top: 8px;
}

.x-panel-header-title-oneportal-nav > .x-title-icon-wrap-oneportal-nav.x-title-icon-left {
    width: 24px;
    padding-right: 8px;
}

.x-panel-header-title-oneportal-nav > .x-title-icon-wrap-oneportal-nav.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-panel-header-title-oneportal-nav > .x-title-icon-wrap-oneportal-nav > .x-title-icon-oneportal-nav {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: #686868;
    background-position: center center;
}

.x-ie8 .x-panel-header-title-oneportal-nav > .x-title-icon-wrap-oneportal-nav > .x-title-icon-oneportal-nav.x-title-glyph {
    color: #686868;
}

.x-panel-body-oneportal-nav {
    background: #fff;
    border-color: #e4e4e4;
    color: #111;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    border-width: 1px;
    border-style: solid;
}

.x-panel-header-oneportal-nav {
    background-image: none;
    background-color: #e4e4e4;
}

.x-panel-header-oneportal-nav-vertical {
    background-image: none;
    background-color: #e4e4e4;
}

.x-rtl.x-panel-header-oneportal-nav-vertical {
    background-image: none;
    background-color: #e4e4e4;
}

.x-panel .x-panel-header-oneportal-nav-collapsed-border-top {
    border-bottom-width: 1px !important;
}

.x-panel .x-panel-header-oneportal-nav-collapsed-border-right {
    border-left-width: 1px !important;
}

.x-panel .x-panel-header-oneportal-nav-collapsed-border-bottom {
    border-top-width: 1px !important;
}

.x-panel .x-panel-header-oneportal-nav-collapsed-border-left {
    border-right-width: 1px !important;
}

.x-panel-header-oneportal-nav-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-panel-header-oneportal-nav-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-panel-header-oneportal-nav-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-panel-header-oneportal-nav-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-panel-header-oneportal-nav-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-oneportal-nav-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-oneportal-nav-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-panel-header-oneportal-nav-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-panel-header-oneportal-nav .x-tool-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-oneportal-nav .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-oneportal-nav .x-tool-focus,
.x-edge .x-keyboard-mode .x-panel-header-oneportal-nav .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-oneportal-nav .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-oneportal-nav .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-oneportal-nav .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-rtl.x-panel-header-oneportal-nav-collapsed-border-right {
    border-right-width: 1px !important;
}

.x-rtl.x-panel-header-oneportal-nav-collapsed-border-left {
    border-left-width: 1px !important;
}

.x-panel-oneportal-nav-resizable .x-panel-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-panel-oneportal-nav-outer-border-l {
    border-left-color: #e4e4e4 !important;
    border-left-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-b {
    border-bottom-color: #e4e4e4 !important;
    border-bottom-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-bl {
    border-bottom-color: #e4e4e4 !important;
    border-bottom-width: 0 !important;
    border-left-color: #e4e4e4 !important;
    border-left-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-r {
    border-right-color: #e4e4e4 !important;
    border-right-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-rl {
    border-right-color: #e4e4e4 !important;
    border-right-width: 0 !important;
    border-left-color: #e4e4e4 !important;
    border-left-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-rb {
    border-right-color: #e4e4e4 !important;
    border-right-width: 0 !important;
    border-bottom-color: #e4e4e4 !important;
    border-bottom-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-rbl {
    border-right-color: #e4e4e4 !important;
    border-right-width: 0 !important;
    border-bottom-color: #e4e4e4 !important;
    border-bottom-width: 0 !important;
    border-left-color: #e4e4e4 !important;
    border-left-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-t {
    border-top-color: #e4e4e4 !important;
    border-top-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-tl {
    border-top-color: #e4e4e4 !important;
    border-top-width: 0 !important;
    border-left-color: #e4e4e4 !important;
    border-left-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-tb {
    border-top-color: #e4e4e4 !important;
    border-top-width: 0 !important;
    border-bottom-color: #e4e4e4 !important;
    border-bottom-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-tbl {
    border-top-color: #e4e4e4 !important;
    border-top-width: 0 !important;
    border-bottom-color: #e4e4e4 !important;
    border-bottom-width: 0 !important;
    border-left-color: #e4e4e4 !important;
    border-left-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-tr {
    border-top-color: #e4e4e4 !important;
    border-top-width: 0 !important;
    border-right-color: #e4e4e4 !important;
    border-right-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-trl {
    border-top-color: #e4e4e4 !important;
    border-top-width: 0 !important;
    border-right-color: #e4e4e4 !important;
    border-right-width: 0 !important;
    border-left-color: #e4e4e4 !important;
    border-left-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-trb {
    border-top-color: #e4e4e4 !important;
    border-top-width: 0 !important;
    border-right-color: #e4e4e4 !important;
    border-right-width: 0 !important;
    border-bottom-color: #e4e4e4 !important;
    border-bottom-width: 0 !important;
}

.x-panel-oneportal-nav-outer-border-trbl {
    border-color: #e4e4e4 !important;
    border-width: 0 !important;
}

.x-form-cb-wrap-oneportal-switch {
    height: 32px;
    min-width: 30px;
}

.x-form-cb-oneportal-switch {
    margin-top: 1px;
}

.x-form-checkbox-oneportal-switch,
.x-form-radio-oneportal-switch {
    width: 30px;
    height: 30px;
}

.x-form-radio-oneportal-switch {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    color: rgba(17, 17, 17, 0.54);
}

.x-form-radio-oneportal-switch:before {
    content: 'radio_button_unchecked';
}

.x-form-cb-checked .x-form-radio-oneportal-switch {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
}

.x-form-cb-checked .x-form-radio-oneportal-switch:before {
    content: 'radio_button_checked';
}

.x-form-checkbox-oneportal-switch {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
    color: rgba(17, 17, 17, 0.54);
}

.x-form-checkbox-oneportal-switch:before {
    content: "\f204";
}

.x-form-cb-checked .x-form-checkbox-oneportal-switch {
    font-family: 'Material Icons', 'Font Awesome 5 Free';
    line-height: 1;
}

.x-form-cb-checked .x-form-checkbox-oneportal-switch:before {
    content: "\f205";
}

.x-keyboard-mode .x-form-checkbox-focus.x-form-radio-oneportal-switch {
    color: #2196f3;
}

.x-keyboard-mode .x-form-checkbox-focus.x-form-checkbox-oneportal-switch {
    color: #2196f3;
}

.x-keyboard-mode .x-form-cb-checked .x-form-checkbox-focus.x-form-radio-oneportal-switch {
    color: #2196f3;
}

.x-keyboard-mode .x-form-cb-checked .x-form-checkbox-focus.x-form-checkbox-oneportal-switch {
    color: #2196f3;
}

.x-form-cb-label-oneportal-switch {
    margin-top: 8px;
    font: 400 12px/16px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #111;
}

.x-form-cb-label-oneportal-switch.x-form-cb-label-before {
    padding-right: 42px;
}

.x-form-cb-label-oneportal-switch.x-form-cb-label-before.x-rtl {
    padding-right: 0;
    padding-left: 42px;
}

.x-form-cb-label-oneportal-switch.x-form-cb-label-after {
    padding-left: 42px;
}

.x-form-cb-label-oneportal-switch.x-rtl {
    padding-left: 0;
    padding-right: 42px;
}

.x-checkbox-oneportal-switch-cell > .x-grid-cell-inner {
    padding-top: 8px;
    padding-bottom: 8px;
}

.x-btn-oneportal-status-button-small {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 8px 16px 8px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-btn-oneportal-status-button-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-oneportal-status-button-small {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-oneportal-status-button-small-frameInfo {
    font-family: th-2-2-2-2-0-0-0-0-8-16-8-16;
}

.x-btn-oneportal-status-button-small-tl {
    background-position: 0 -4px;
}

.x-btn-oneportal-status-button-small-tr {
    background-position: right -6px;
}

.x-btn-oneportal-status-button-small-bl {
    background-position: 0 -8px;
}

.x-btn-oneportal-status-button-small-br {
    background-position: right -10px;
}

.x-btn-oneportal-status-button-small-ml {
    background-position: 0 top;
}

.x-btn-oneportal-status-button-small-mr {
    background-position: right top;
}

.x-btn-oneportal-status-button-small-tc {
    background-position: 0 0;
}

.x-btn-oneportal-status-button-small-bc {
    background-position: 0 -2px;
}

.x-btn-oneportal-status-button-small-tr,
.x-btn-oneportal-status-button-small-br,
.x-btn-oneportal-status-button-small-mr {
    padding-right: 2px;
}

.x-btn-oneportal-status-button-small-tl,
.x-btn-oneportal-status-button-small-bl,
.x-btn-oneportal-status-button-small-ml {
    padding-left: 2px;
}

.x-btn-oneportal-status-button-small-tc {
    height: 2px;
}

.x-btn-oneportal-status-button-small-bc {
    height: 2px;
}

.x-btn-oneportal-status-button-small-mc {
    padding: 6px 14px 6px 14px;
}

.x-btn-oneportal-status-button-small {
    border-color: #5da8e5;
}

.x-btn-button-oneportal-status-button-small {
    min-height: 16px;
}

.x-ie9m .x-btn-button-oneportal-status-button-small {
    min-height: auto;
    height: 16px;
}

.x-btn-inner-oneportal-status-button-small {
    font: 600 13px/15px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #2196f3;
    padding: 0 5px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-oneportal-status-button-small,
.x-ie9 .x-btn-icon-left > .x-btn-inner-oneportal-status-button-small {
    max-width: calc(100% - 16px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-oneportal-status-button-small,
.x-safari8m .x-btn-icon-left > .x-btn-inner-oneportal-status-button-small {
    max-width: calc(100% - 15px);
}

.x-ie10p .x-btn-inner-oneportal-status-button-small {
    max-width: none;
}

.x-btn-icon-el-oneportal-status-button-small {
    font-size: 16px;
    height: 16px;
    color: #000;
    line-height: 16px;
}

.x-btn-icon-left > .x-btn-icon-el-oneportal-status-button-small,
.x-btn-icon-right > .x-btn-icon-el-oneportal-status-button-small {
    width: 16px;
}

.x-btn-icon-top > .x-btn-icon-el-oneportal-status-button-small,
.x-btn-icon-bottom > .x-btn-icon-el-oneportal-status-button-small {
    min-width: 16px;
}

.x-ie8 .x-btn-icon-el-oneportal-status-button-small.x-btn-glyph {
    color: #000;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-oneportal-status-button-small {
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-oneportal-status-button-small.x-rtl {
    margin-right: 0;
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-oneportal-status-button-small {
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-oneportal-status-button-small.x-rtl {
    margin-left: 0;
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-oneportal-status-button-small {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-oneportal-status-button-small {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-oneportal-status-button-small {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-oneportal-status-button-small {
    margin-right: 5px;
}

.x-btn-arrow-bottom > .x-btn-button-oneportal-status-button-small,
.x-btn-split-bottom > .x-btn-button-oneportal-status-button-small {
    padding-bottom: 8px;
}

.x-btn-wrap-oneportal-status-button-small.x-btn-arrow-right:after {
    width: 18px;
    content: 'arrow_drop_down';
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-oneportal-status-button-small.x-btn-arrow-bottom:after {
    height: 12px;
    content: 'arrow_drop_down';
    font: 16px/12px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-oneportal-status-button-small.x-btn-split-right > .x-btn-button {
    padding-right: 16px;
}

.x-btn-wrap-oneportal-status-button-small.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 16px;
}

.x-btn-wrap-oneportal-status-button-small.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #2196f3;
}

.x-btn-wrap-oneportal-status-button-small.x-btn-split-right:after {
    width: 16px;
    content: 'arrow_drop_down';
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
    padding-left: 16px;
}

.x-btn-wrap-oneportal-status-button-small.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 16px;
}

.x-btn-wrap-oneportal-status-button-small.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-oneportal-status-button-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #2196f3;
}

.x-btn-wrap-oneportal-status-button-small.x-btn-split-bottom:after {
    height: 16px;
    content: 'arrow_drop_down';
    font: 16px/16px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-oneportal-status-button-small {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-oneportal-status-button-small {
    margin-right: 5px;
}

.x-keyboard-mode .x-btn-focus.x-btn-oneportal-status-button-small {
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-btn-focus.x-btn-oneportal-status-button-small .x-btn-inner {
    color: #fff;
}

.x-btn-oneportal-status-button-small .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 32px;
    pointer-events: none;
}

.x-btn-oneportal-status-button-small .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-oneportal-status-button-small.x-arrow-focus .x-btn-arrow-el {
    border: 0 solid #d9edfd;
}

.x-btn-over.x-btn-oneportal-status-button-small {
    border-color: #569bd2;
    background-image: none;
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-oneportal-status-button-small {
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-menu-active.x-btn-oneportal-status-button-small,
.x-btn.x-btn-pressed.x-btn-oneportal-status-button-small {
    background-image: none;
    background-color: transparent;
}

.x-btn.x-btn-menu-active.x-btn-oneportal-status-button-small .x-btn-inner,
.x-btn.x-btn-pressed.x-btn-oneportal-status-button-small .x-btn-inner {
    color: #fff;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-oneportal-status-button-small,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-oneportal-status-button-small {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-disabled.x-btn-oneportal-status-button-small {
    background-image: none;
    background-color: transparent;
}

.x-btn.x-btn-disabled.x-btn-oneportal-status-button-small .x-btn-inner {
    color: rgba(0, 0, 0, 0.26);
}

.x-keyboard-mode .x-btn-focus .x-btn-oneportal-status-button-small-mc {
    background-color: transparent;
}

.x-btn-over .x-btn-oneportal-status-button-small-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-oneportal-status-button-small-mc {
    background-color: transparent;
}

.x-btn.x-btn-menu-active .x-btn-oneportal-status-button-small-mc,
.x-btn.x-btn-pressed .x-btn-oneportal-status-button-small-mc {
    background-color: transparent;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-oneportal-status-button-small-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-oneportal-status-button-small-mc {
    background-color: transparent;
}

.x-btn.x-btn-disabled .x-btn-oneportal-status-button-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-oneportal-status-button-small {
    background-image: none;
}

.x-btn-disabled.x-btn-oneportal-status-button-small {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=)";
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-status-button-small.x-segmented-button-first {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-status-button-small.x-segmented-button-first .x-btn-oneportal-status-button-small-mc {
    padding-right: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-status-button-small.x-segmented-button-middle {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-status-button-small.x-segmented-button-middle .x-btn-oneportal-status-button-small-mc {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-status-button-small.x-segmented-button-last .x-btn-oneportal-status-button-small-mc {
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-status-button-small.x-segmented-button-first {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-status-button-small.x-segmented-button-first .x-btn-oneportal-status-button-small-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-status-button-small.x-segmented-button-middle {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-status-button-small.x-segmented-button-middle .x-btn-oneportal-status-button-small-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-status-button-small.x-segmented-button-last .x-btn-oneportal-status-button-small-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-oneportal-status-button-small:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-status-button-small:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-status-button-small.x-segmented-button-first:after {
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-status-button-small.x-segmented-button-last:after {
    right: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-status-button-small:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-status-button-small.x-segmented-button-first:after {
    top: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-status-button-small.x-segmented-button-last:after {
    bottom: 0;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-oneportal-status-button-small:after {
    border-width: 0;
    border-color: #d9edfd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-oneportal-status-button-small:after {
    border-width: 0;
    border-color: rgba(33, 149, 241, 0.8150000000000001);
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-oneportal-status-button-small:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-oneportal-status-button-small:after {
    border-width: 0;
    border-color: #e1f1fd;
}

.x-button-oneportal-status-button-small-cell > .x-grid-cell-inner {
    padding-top: 8px;
    padding-bottom: 8px;
}

.x-button-oneportal-status-button-small-cell > .x-grid-cell-inner > .x-btn-oneportal-status-button-small {
    vertical-align: top;
}

.x-btn-oneportal-activestatus-button-small {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 8px 16px 8px 16px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-btn-oneportal-activestatus-button-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-oneportal-activestatus-button-small {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-oneportal-activestatus-button-small-frameInfo {
    font-family: th-2-2-2-2-0-0-0-0-8-16-8-16;
}

.x-btn-oneportal-activestatus-button-small-tl {
    background-position: 0 -4px;
}

.x-btn-oneportal-activestatus-button-small-tr {
    background-position: right -6px;
}

.x-btn-oneportal-activestatus-button-small-bl {
    background-position: 0 -8px;
}

.x-btn-oneportal-activestatus-button-small-br {
    background-position: right -10px;
}

.x-btn-oneportal-activestatus-button-small-ml {
    background-position: 0 top;
}

.x-btn-oneportal-activestatus-button-small-mr {
    background-position: right top;
}

.x-btn-oneportal-activestatus-button-small-tc {
    background-position: 0 0;
}

.x-btn-oneportal-activestatus-button-small-bc {
    background-position: 0 -2px;
}

.x-btn-oneportal-activestatus-button-small-tr,
.x-btn-oneportal-activestatus-button-small-br,
.x-btn-oneportal-activestatus-button-small-mr {
    padding-right: 2px;
}

.x-btn-oneportal-activestatus-button-small-tl,
.x-btn-oneportal-activestatus-button-small-bl,
.x-btn-oneportal-activestatus-button-small-ml {
    padding-left: 2px;
}

.x-btn-oneportal-activestatus-button-small-tc {
    height: 2px;
}

.x-btn-oneportal-activestatus-button-small-bc {
    height: 2px;
}

.x-btn-oneportal-activestatus-button-small-mc {
    padding: 6px 14px 6px 14px;
}

.x-btn-oneportal-activestatus-button-small {
    border-color: #5da8e5;
}

.x-btn-button-oneportal-activestatus-button-small {
    min-height: 16px;
}

.x-ie9m .x-btn-button-oneportal-activestatus-button-small {
    min-height: auto;
    height: 16px;
}

.x-btn-inner-oneportal-activestatus-button-small {
    font: 600 13px/15px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #2196f3;
    padding: 0 5px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-oneportal-activestatus-button-small,
.x-ie9 .x-btn-icon-left > .x-btn-inner-oneportal-activestatus-button-small {
    max-width: calc(100% - 16px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-oneportal-activestatus-button-small,
.x-safari8m .x-btn-icon-left > .x-btn-inner-oneportal-activestatus-button-small {
    max-width: calc(100% - 15px);
}

.x-ie10p .x-btn-inner-oneportal-activestatus-button-small {
    max-width: none;
}

.x-btn-icon-el-oneportal-activestatus-button-small {
    font-size: 16px;
    height: 16px;
    color: #000;
    line-height: 16px;
}

.x-btn-icon-left > .x-btn-icon-el-oneportal-activestatus-button-small,
.x-btn-icon-right > .x-btn-icon-el-oneportal-activestatus-button-small {
    width: 16px;
}

.x-btn-icon-top > .x-btn-icon-el-oneportal-activestatus-button-small,
.x-btn-icon-bottom > .x-btn-icon-el-oneportal-activestatus-button-small {
    min-width: 16px;
}

.x-ie8 .x-btn-icon-el-oneportal-activestatus-button-small.x-btn-glyph {
    color: #000;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-oneportal-activestatus-button-small {
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-oneportal-activestatus-button-small.x-rtl {
    margin-right: 0;
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-oneportal-activestatus-button-small {
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-oneportal-activestatus-button-small.x-rtl {
    margin-left: 0;
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-oneportal-activestatus-button-small {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-oneportal-activestatus-button-small {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-oneportal-activestatus-button-small {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-oneportal-activestatus-button-small {
    margin-right: 5px;
}

.x-btn-arrow-bottom > .x-btn-button-oneportal-activestatus-button-small,
.x-btn-split-bottom > .x-btn-button-oneportal-activestatus-button-small {
    padding-bottom: 8px;
}

.x-btn-wrap-oneportal-activestatus-button-small.x-btn-arrow-right:after {
    width: 18px;
    content: 'arrow_drop_down';
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-oneportal-activestatus-button-small.x-btn-arrow-bottom:after {
    height: 12px;
    content: 'arrow_drop_down';
    font: 16px/12px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-oneportal-activestatus-button-small.x-btn-split-right > .x-btn-button {
    padding-right: 16px;
}

.x-btn-wrap-oneportal-activestatus-button-small.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 16px;
}

.x-btn-wrap-oneportal-activestatus-button-small.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #2196f3;
}

.x-btn-wrap-oneportal-activestatus-button-small.x-btn-split-right:after {
    width: 16px;
    content: 'arrow_drop_down';
    font: 16px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
    padding-left: 16px;
}

.x-btn-wrap-oneportal-activestatus-button-small.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 16px;
}

.x-btn-wrap-oneportal-activestatus-button-small.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-oneportal-activestatus-button-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #2196f3;
}

.x-btn-wrap-oneportal-activestatus-button-small.x-btn-split-bottom:after {
    height: 16px;
    content: 'arrow_drop_down';
    font: 16px/16px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-oneportal-activestatus-button-small {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-oneportal-activestatus-button-small {
    margin-right: 5px;
}

.x-keyboard-mode .x-btn-focus.x-btn-oneportal-activestatus-button-small {
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-btn-focus.x-btn-oneportal-activestatus-button-small .x-btn-inner {
    color: #fff;
}

.x-btn-oneportal-activestatus-button-small .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 32px;
    pointer-events: none;
}

.x-btn-oneportal-activestatus-button-small .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-oneportal-activestatus-button-small.x-arrow-focus .x-btn-arrow-el {
    border: 0 solid #d9edfd;
}

.x-btn-over.x-btn-oneportal-activestatus-button-small {
    border-color: #569bd2;
    background-image: none;
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-oneportal-activestatus-button-small {
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-menu-active.x-btn-oneportal-activestatus-button-small,
.x-btn.x-btn-pressed.x-btn-oneportal-activestatus-button-small {
    background-image: none;
    background-color: transparent;
}

.x-btn.x-btn-menu-active.x-btn-oneportal-activestatus-button-small .x-btn-inner,
.x-btn.x-btn-pressed.x-btn-oneportal-activestatus-button-small .x-btn-inner {
    color: #fff;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-oneportal-activestatus-button-small,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-oneportal-activestatus-button-small {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-disabled.x-btn-oneportal-activestatus-button-small {
    background-image: none;
    background-color: transparent;
}

.x-btn.x-btn-disabled.x-btn-oneportal-activestatus-button-small .x-btn-inner {
    color: rgba(0, 0, 0, 0.26);
}

.x-keyboard-mode .x-btn-focus .x-btn-oneportal-activestatus-button-small-mc {
    background-color: transparent;
}

.x-btn-over .x-btn-oneportal-activestatus-button-small-mc {
    background-color: rgba(17, 17, 17, 0.075);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-oneportal-activestatus-button-small-mc {
    background-color: transparent;
}

.x-btn.x-btn-menu-active .x-btn-oneportal-activestatus-button-small-mc,
.x-btn.x-btn-pressed .x-btn-oneportal-activestatus-button-small-mc {
    background-color: transparent;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-oneportal-activestatus-button-small-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-oneportal-activestatus-button-small-mc {
    background-color: transparent;
}

.x-btn.x-btn-disabled .x-btn-oneportal-activestatus-button-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-oneportal-activestatus-button-small {
    background-image: none;
}

.x-btn-disabled.x-btn-oneportal-activestatus-button-small {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=)";
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-activestatus-button-small.x-segmented-button-first {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-activestatus-button-small.x-segmented-button-first .x-btn-oneportal-activestatus-button-small-mc {
    padding-right: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-activestatus-button-small.x-segmented-button-middle {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-activestatus-button-small.x-segmented-button-middle .x-btn-oneportal-activestatus-button-small-mc {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-activestatus-button-small.x-segmented-button-last .x-btn-oneportal-activestatus-button-small-mc {
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-activestatus-button-small.x-segmented-button-first {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-activestatus-button-small.x-segmented-button-first .x-btn-oneportal-activestatus-button-small-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-activestatus-button-small.x-segmented-button-middle {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-activestatus-button-small.x-segmented-button-middle .x-btn-oneportal-activestatus-button-small-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-activestatus-button-small.x-segmented-button-last .x-btn-oneportal-activestatus-button-small-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-oneportal-activestatus-button-small:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-activestatus-button-small:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-activestatus-button-small.x-segmented-button-first:after {
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-activestatus-button-small.x-segmented-button-last:after {
    right: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-activestatus-button-small:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-activestatus-button-small.x-segmented-button-first:after {
    top: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-activestatus-button-small.x-segmented-button-last:after {
    bottom: 0;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-oneportal-activestatus-button-small:after {
    border-width: 0;
    border-color: #d9edfd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-oneportal-activestatus-button-small:after {
    border-width: 0;
    border-color: rgba(33, 149, 241, 0.8150000000000001);
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-oneportal-activestatus-button-small:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-oneportal-activestatus-button-small:after {
    border-width: 0;
    border-color: #e1f1fd;
}

.x-button-oneportal-activestatus-button-small-cell > .x-grid-cell-inner {
    padding-top: 8px;
    padding-bottom: 8px;
}

.x-button-oneportal-activestatus-button-small-cell > .x-grid-cell-inner > .x-btn-oneportal-activestatus-button-small {
    vertical-align: top;
}

.x-btn-oneportal-status-button-small .x-btn-icon-el,
.x-btn-oneportal-activestatus-button-small .x-btn-icon-el {
    color: #d8e2e9;
}

.x-btn-oneportal-status-button-small.status-active .x-btn-icon-el,
.x-btn-oneportal-activestatus-button-small.status-active .x-btn-icon-el {
    color: #4bd28f;
}

.x-btn-oneportal-status-button-small.status-active2 .x-btn-icon-el,
.x-btn-oneportal-activestatus-button-small.status-active2 .x-btn-icon-el {
    color: #0095ff;
}

.x-btn-oneportal-status-button-small.status-inprogress .x-btn-icon-el,
.x-btn-oneportal-activestatus-button-small.status-inprogress .x-btn-icon-el {
    color: #fa0;
}

.x-btn-oneportal-status-button-small.status-deleted .x-btn-icon-el,
.x-btn-oneportal-activestatus-button-small.status-deleted .x-btn-icon-el {
    color: #d8e2e9;
}

.x-btn-oneportal-status-button-small.status-paused .x-btn-icon-el,
.x-btn-oneportal-activestatus-button-small.status-paused .x-btn-icon-el {
    color: #ff4d4d;
}

.x-btn-oneportal-status-button-small.x-btn-over,
.x-btn-oneportal-activestatus-button-small.x-btn-over {
    background-color: transparent;
}

.form-message.form-message-success {
    color: #3c9c6c;
}

.form-message.form-message-error {
    color: #ff4d4d;
}

.form-message.form-message-warning {
    color: #fa0;
}

.form-message.form-message-info {
    color: #0095ff;
}

.x-btn-oneportal-circle-button-large {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 8px 16px 8px 16px;
    border-width: 0;
    border-style: solid;
    background-color: #39a1f4;
}

.x-btn-oneportal-circle-button-large-mc {
    background-color: #39a1f4;
}

.x-nbr .x-btn-oneportal-circle-button-large {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-oneportal-circle-button-large-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-8-16-8-16;
}

.x-btn-oneportal-circle-button-large-tl {
    background-position: 0 0;
}

.x-btn-oneportal-circle-button-large-tr {
    background-position: right 0;
}

.x-btn-oneportal-circle-button-large-bl {
    background-position: 0 0;
}

.x-btn-oneportal-circle-button-large-br {
    background-position: right 0;
}

.x-btn-oneportal-circle-button-large-ml {
    background-position: 0 top;
}

.x-btn-oneportal-circle-button-large-mr {
    background-position: right top;
}

.x-btn-oneportal-circle-button-large-tc {
    background-position: 0 0;
}

.x-btn-oneportal-circle-button-large-bc {
    background-position: 0 0;
}

.x-btn-oneportal-circle-button-large-tr,
.x-btn-oneportal-circle-button-large-br,
.x-btn-oneportal-circle-button-large-mr {
    padding-right: 0;
}

.x-btn-oneportal-circle-button-large-tl,
.x-btn-oneportal-circle-button-large-bl,
.x-btn-oneportal-circle-button-large-ml {
    padding-left: 0;
}

.x-btn-oneportal-circle-button-large-tc {
    height: 0;
}

.x-btn-oneportal-circle-button-large-bc {
    height: 0;
}

.x-btn-oneportal-circle-button-large-mc {
    padding: 8px 16px 8px 16px;
}

.x-btn-oneportal-circle-button-large {
    border-color: #5da8e5;
}

.x-btn-button-oneportal-circle-button-large {
    min-height: 24px;
}

.x-ie9m .x-btn-button-oneportal-circle-button-large {
    min-height: auto;
    height: 24px;
}

.x-btn-inner-oneportal-circle-button-large {
    font: 600 16px/20px 'Nunito Sans', 'Open Sans', sans-serif;
    color: #fff;
    padding: 0 10px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-oneportal-circle-button-large,
.x-ie9 .x-btn-icon-left > .x-btn-inner-oneportal-circle-button-large {
    max-width: calc(100% - 24px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-oneportal-circle-button-large,
.x-safari8m .x-btn-icon-left > .x-btn-inner-oneportal-circle-button-large {
    max-width: calc(100% - 23px);
}

.x-ie10p .x-btn-inner-oneportal-circle-button-large {
    max-width: none;
}

.x-btn-icon-el-oneportal-circle-button-large {
    font-size: 24px;
    height: 24px;
    color: #fff;
    line-height: 24px;
}

.x-btn-icon-left > .x-btn-icon-el-oneportal-circle-button-large,
.x-btn-icon-right > .x-btn-icon-el-oneportal-circle-button-large {
    width: 24px;
}

.x-btn-icon-top > .x-btn-icon-el-oneportal-circle-button-large,
.x-btn-icon-bottom > .x-btn-icon-el-oneportal-circle-button-large {
    min-width: 24px;
}

.x-ie8 .x-btn-icon-el-oneportal-circle-button-large.x-btn-glyph {
    color: #fff;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-oneportal-circle-button-large {
    margin-right: 0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-oneportal-circle-button-large.x-rtl {
    margin-right: 0;
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-oneportal-circle-button-large {
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-oneportal-circle-button-large.x-rtl {
    margin-left: 0;
    margin-right: 0;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-oneportal-circle-button-large {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-oneportal-circle-button-large {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-oneportal-circle-button-large {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-oneportal-circle-button-large {
    margin-right: 10px;
}

.x-btn-arrow-bottom > .x-btn-button-oneportal-circle-button-large,
.x-btn-split-bottom > .x-btn-button-oneportal-circle-button-large {
    padding-bottom: 8px;
}

.x-btn-wrap-oneportal-circle-button-large.x-btn-arrow-right:after {
    width: 22px;
    content: 'arrow_drop_down';
    font: 24px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-oneportal-circle-button-large.x-btn-arrow-bottom:after {
    height: 16px;
    content: 'arrow_drop_down';
    font: 24px/16px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-wrap-oneportal-circle-button-large.x-btn-split-right > .x-btn-button {
    padding-right: 16px;
}

.x-btn-wrap-oneportal-circle-button-large.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 16px;
}

.x-btn-wrap-oneportal-circle-button-large.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #2196f3;
}

.x-btn-wrap-oneportal-circle-button-large.x-btn-split-right:after {
    width: 32px;
    content: 'arrow_drop_down';
    font: 24px/1 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
    padding-left: 16px;
}

.x-btn-wrap-oneportal-circle-button-large.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 16px;
}

.x-btn-wrap-oneportal-circle-button-large.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-oneportal-circle-button-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #2196f3;
}

.x-btn-wrap-oneportal-circle-button-large.x-btn-split-bottom:after {
    height: 32px;
    content: 'arrow_drop_down';
    font: 24px/32px 'Material Icons', 'Font Awesome 5 Free';
    color: #2196f3;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-oneportal-circle-button-large {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-oneportal-circle-button-large {
    margin-right: 10px;
}

.x-keyboard-mode .x-btn-focus.x-btn-oneportal-circle-button-large {
    background-image: none;
    background-color: #39a1f4;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn-oneportal-circle-button-large .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 48px;
    pointer-events: none;
}

.x-btn-oneportal-circle-button-large .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-oneportal-circle-button-large.x-arrow-focus .x-btn-arrow-el {
    border: 0 solid #d9edfd;
}

.x-btn-over.x-btn-oneportal-circle-button-large {
    border-color: #569bd2;
    background-image: none;
    background-color: #3595e1;
}

.x-btn-over.x-btn-oneportal-circle-button-large .x-btn-inner {
    color: #6ab8f7;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-oneportal-circle-button-large {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-menu-active.x-btn-oneportal-circle-button-large,
.x-btn.x-btn-pressed.x-btn-oneportal-circle-button-large {
    background-image: none;
    background-color: #2b79b7;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-oneportal-circle-button-large,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-oneportal-circle-button-large {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-btn.x-btn-disabled.x-btn-oneportal-circle-button-large {
    background-image: none;
    background-color: #39a1f4;
}

.x-keyboard-mode .x-btn-focus .x-btn-oneportal-circle-button-large-mc {
    background-color: #39a1f4;
}

.x-btn-over .x-btn-oneportal-circle-button-large-mc {
    background-color: #3595e1;
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-oneportal-circle-button-large-mc {
    background-color: #3595e1;
}

.x-btn.x-btn-menu-active .x-btn-oneportal-circle-button-large-mc,
.x-btn.x-btn-pressed .x-btn-oneportal-circle-button-large-mc {
    background-color: #2b79b7;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-oneportal-circle-button-large-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-oneportal-circle-button-large-mc {
    background-color: #2b79b7;
}

.x-btn.x-btn-disabled .x-btn-oneportal-circle-button-large-mc {
    background-color: #39a1f4;
}

.x-nbr .x-btn-oneportal-circle-button-large {
    background-image: none;
}

.x-btn-disabled.x-btn-oneportal-circle-button-large {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=)";
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-circle-button-large.x-segmented-button-first {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-circle-button-large.x-segmented-button-first .x-btn-oneportal-circle-button-large-mc {
    padding-right: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-circle-button-large.x-segmented-button-middle {
    border-right-width: 0 !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-circle-button-large.x-segmented-button-middle .x-btn-oneportal-circle-button-large-mc {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-circle-button-large.x-segmented-button-last .x-btn-oneportal-circle-button-large-mc {
    padding-left: 16px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-circle-button-large.x-segmented-button-first {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-circle-button-large.x-segmented-button-first .x-btn-oneportal-circle-button-large-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-circle-button-large.x-segmented-button-middle {
    border-bottom-width: 0 !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-circle-button-large.x-segmented-button-middle .x-btn-oneportal-circle-button-large-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-circle-button-large.x-segmented-button-last .x-btn-oneportal-circle-button-large-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-oneportal-circle-button-large:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-circle-button-large:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-circle-button-large.x-segmented-button-first:after {
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-oneportal-circle-button-large.x-segmented-button-last:after {
    right: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-circle-button-large:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-circle-button-large.x-segmented-button-first:after {
    top: 0;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-oneportal-circle-button-large.x-segmented-button-last:after {
    bottom: 0;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-oneportal-circle-button-large:after {
    border-width: 0;
    border-color: #d9edfd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-oneportal-circle-button-large:after {
    border-width: 0;
    border-color: rgba(33, 149, 241, 0.8150000000000001);
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-oneportal-circle-button-large:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-oneportal-circle-button-large:after {
    border-width: 0;
    border-color: #e1f1fd;
}

.x-button-oneportal-circle-button-large-cell > .x-grid-cell-inner {
    padding-top: 4px;
    padding-bottom: 4px;
}

.x-button-oneportal-circle-button-large-cell > .x-grid-cell-inner > .x-btn-oneportal-circle-button-large {
    vertical-align: top;
}

.x-btn-oneportal-circle-button-large {
    border-radius: 50%;
}

.x-window-header-title-oneportal > .x-title-text-oneportal:after,
.x-panel-header-title-oneportal-nav > .x-title-text-oneportal-nav:after,
.x-window-header-title-default > .x-title-text-default:after,
.x-panel-header-title-default > .x-title-text-default:after,
.x-panel-header-title-oneportal-toc > .x-title-text-oneportal-toc:after,
.x-panel-header-title-default-framed > .x-title-text-default-framed:after {
    content: '' !important;
}

.x-panel-oneportal-nav {
    border-right: 1px solid #ddd;
}

.x-panel-oneportal-nav .x-menu {
    border: none;
}

.op_tablechart .x-grid-item-alt {
    background-color: rgba(155, 155, 155, 0.15);
}

.op_tablechart .x-grid-item,
.op_tablechart .x-grid-row-summary-item {
    font-size: 12px;
}

.op_tablechart .x-grid-row-summary .x-grid-cell {
    font-weight: bold;
    font-size: 12px;
}

.op_tablechart .x-column-header {
    font-size: 14px;
    font-weight: bold;
}

.x-op_permissions_select-default-cell .x-panel-header {
    padding: 5px;
}

.x-overflow-visible {
    overflow: visible;
}

.x-padding-0 {
    padding: 0 !important;
}

.x-width-100 {
    width: 100%;
}

.x-text-left {
    text-align: left !important;
}
