@import url("https://fonts.googleapis.com/css?family=Nunito+Sans");

@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    src: local('Roboto Regular'), local('Roboto-Regular'),url('./../fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 100;
    font-style: normal;
    src: local('Roboto Light'), local('Roboto-Light'),url('./../fonts/roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 600;
    font-style: normal;
    src: local('Roboto Medium'), local('Roboto-Medium'),url('./../fonts/roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 900;
    font-style: normal;
    src: local('Roboto Bold'), local('Roboto-Bold'),url('./../fonts/roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: normal;
    font-style: italic;
    src: local('Roboto Italic'), local('Roboto-Italic'),url('./../fonts/roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    src: url('./../fonts/open-sans/OpenSans-Light.ttf');
    src: local("OpenSans-Light");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./../fonts/open-sans/OpenSans-LightItalic.ttf');
    src: local("OpenSans-LightItalic");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./../fonts/open-sans/OpenSans-Regular.ttf');
    src: local("OpenSans-Regular");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./../fonts/open-sans/OpenSans-Italic.ttf');
    src: local("OpenSans-Italic");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./../fonts/open-sans/OpenSans-Semibold.ttf');
    src: local("OpenSans-Semibold");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./../fonts/open-sans/OpenSans-SemiboldItalic.ttf');
    src: local("OpenSans-SemiboldItalic");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./../fonts/open-sans/OpenSans-Bold.ttf');
    src: local("OpenSans-Bold");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./../fonts/open-sans/OpenSans-BoldItalic.ttf');
    src: local("OpenSans-BoldItalic");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./../fonts/open-sans/OpenSans-ExtraBold.ttf');
    src: local("OpenSans-ExtraBold");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./../fonts/open-sans/OpenSans-ExtraBoldItalic.ttf');
    src: local("OpenSans-ExtraBoldItalic");
    font-weight: 800;
    font-style: italic;
}
