.grid-header {
  line-height: 30px;
}

.grid {
  position: relative;
}

.grid .grid-table table tr {
  height: 36px;
}

.grid .grid-table table tr td,
.grid .grid-table table tr th {
  padding: 9px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  font-size: 14px;
  border: 1px solid #eeeeee;
}

.grid .grid-table table tr td.thin .form-control {
  padding: 0 0.75rem;
  height: calc(1.5em + 2px);
}
.grid .grid-table table tr td.thin {
  padding: 5px;
}

.grid .grid-table table td,
.grid .grid-table table th {
  border-color: #dee2e6;
  border: none;
}

.grid .grid-table table th {
  font-weight: bold;
  background-color: #2196f3;
  color: #ffffff;
  font-size: 14px;
}
.grid .grid-table table.blue-rows th {
  background-color: #007bbf;
}

.grid .grid-table table thead th.action {
  width: 70px;
}

.grid .grid-table table thead th.seemore {
  width: 40px;
}

.grid .grid-table table thead th.sortable {
  padding-right: 30px;
  position: relative;
  cursor: pointer;
}

.grid .grid-table table thead th.sortable:before {
  right: 1em;
  content: '↑';
}

.grid .grid-table table thead th.sortable:after {
  right: 0.5em;
  content: '↓';
}

.grid .grid-table table thead th.sortable:after,
.grid .grid-table table thead th.sortable:before {
  position: absolute;
  bottom: 10px;
  display: block;
  font-size: 18px;
  opacity: 0.5;
}

.grid .grid-table table thead th.sortable.sortable-asc:before,
.grid .grid-table table thead th.sortable.sortable-desc:after {
  opacity: 1;
}

.grid .grid-table table tbody tr {
  background-color: transparent;
}
.grid .grid-table table tbody tr.grid-table-row.odd {
  background-color: #f8f9fa;
}
.grid .grid-table table.blue-rows tbody tr.grid-table-row.odd {
  background-color: #b2eafe;
}

.grid .grid-table table tbody tr.grid-table-row:hover {
  background-color: #e9ecef;
}
.grid .grid-table table.blue-rows tbody tr.grid-table-row:hover {
  background-color: rgba(233, 236, 239, 0.98);
}

.grid .grid-table table tbody tr.grid-table-row.selected {
  background-color: #ced4da;
}

.grid .grid-table table tbody tr td span[tabindex],
.grid .grid-table table tbody tr td button {
  color: #303030;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  text-decoration: underline;
  margin-left: 7px;
}

.grid .grid-table table tbody tr td span[tabindex]:hover {
  color: #08528d;
}

.grid .grid-table table tbody tr td button:focus,
.grid .grid-table table tbody tr td button:hover {
  color: #08528d;
}

.grid .grid-table table tbody tr td button:disabled {
  color: #b5b5b5;
  cursor: default;
}

.grid .grid-footer .grid-footer-text {
  line-height: 40px;
}

.grid .grid-footer .grid-footer-per-page-selector {
  height: 40px;
}

.grid .grid-footer .pagination .page-item .page-link {
  height: 40px;
  padding: 8px 12px;
  text-transform: none;
  border-radius: 0;
  color: #303030;
  background-color: #fff;
  border: 1px solid #303030;
}

.grid .grid-footer .pagination .page-item .page-link:hover,
.grid
  .grid-footer
  .pagination
  .page-item
  .page-link:not(:disabled):not(.disabled):active {
  color: #ffffff;
  background-color: #303030;
}

.grid .grid-footer .pagination .page-item .page-link:not(:disabled):focus,
.grid
  .grid-footer
  .pagination
  .page-item
  .page-link:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.grid .grid-footer .pagination .page-item.active .page-link {
  color: #ffffff;
  background-color: #303030;
  border: 1px solid #303030;
}
