.notification-list {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1310;
  width: 350px;
}

.notification-list .alert {
  font-size: 15px;
  background-color: #fff;
  border-left: 5px solid;
  border-radius: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.notification-list .alert.show {
  opacity: 1;
}

.notification-list .alert .close {
  font-size: 16px;
}
